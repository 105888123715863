import { FunctionComponent, useEffect, useState } from 'react';
import { HStack, Search, Skeleton, VStack } from '@montel/montelpro-shared-components-v2';
import useSetPageTitle from 'hooks/useSetPageTitle';
import { selectCanInviteCompanyOrUser } from 'store/user/userSelectors';
import { PublicCompanyInformation } from '../../common/interfaces';
import FilterDropDown from '../../components/Filter/FilterDropdown';
import SelectedFilters from '../../components/Filter/SelectedFilters';
import { isDataLoaded } from '../../components/Suspense/utils';
import { selectError, selectStatus } from '../../store/companies/companiesSelectors';
import { companyUpdated } from '../../store/companies/companiesSlice';
import { getCompanies } from '../../store/companies/companiesThunks';
import { useAppDispatch, useAppSelector, useOnMount } from '../../store/hooks';
import CompaniesListing from '../CompaniesListing';
import InviteCompanyModal from './NoCompanies/InviteCompanyModal';
import CompaniesMenu from './CompaniesMenu';
import { InviteCompanyButton } from './InviteCompanyButton';
import NoCompanies from './NoCompanies';
import QuickFilter from './QuickFilter';
import ResultsCounter from './ResultsCounter';
import { CompanyCardsSkeletonLoader } from './SkeletonLoaders';
import { Container, StyledSearchContainer } from './style';
import useFilters from './useFilters';

const Companies: FunctionComponent = () => {
	useSetPageTitle('Companies');
	const [isModalOpen, setIsModalOpen] = useState(false);
	/* Data loading */
	const dispatch = useAppDispatch();
	const status = useAppSelector(selectStatus);
	const error = useAppSelector(selectError);

	const setCompany = (company: PublicCompanyInformation) => dispatch(companyUpdated(company));

	const {
		filter,
		filterOptions,
		invitationsCount,
		onInvitationFilterChange,
		invitationsFilterValue,
		quickFilterValue,
		onQuickFilterChange,
		searchValue,
		onSearchValueChange,
		isFiltered,
		searchResults,
	} = useFilters();

	const { filterChoices, handleFilterClick } = filter;
	useOnMount(() => {
		dispatch(getCompanies());
	});

	useEffect(() => {
		getCompanies();
	}, []);

	const canInviteCompany = useAppSelector(selectCanInviteCompanyOrUser);

	return (
		<Container>
			<VStack gap="xl">
				<HStack fullWidth justify="space-between" align="center">
					<HStack align="center" gap="md">
						<CompaniesMenu
							invitationsCount={invitationsCount}
							value={invitationsFilterValue}
							onChange={onInvitationFilterChange}
						/>
					</HStack>
					<HStack>
						<Skeleton fitContent isLoaded={isDataLoaded(status)}>
							<FilterDropDown
								options={filterOptions}
								onClick={handleFilterClick}
								selectedOptions={filterChoices}
							/>
						</Skeleton>
						<Skeleton fitContent isLoaded={isDataLoaded(status)}>
							<InviteCompanyButton onClick={() => setIsModalOpen(true)} disabled={!canInviteCompany} />
						</Skeleton>
					</HStack>
				</HStack>
				<SelectedFilters filterChoices={filterChoices} handleDeleteFilterChoices={handleFilterClick} />
				<HStack fullWidth justify="space-between" align="center">
					<Skeleton fitContent isLoaded={isDataLoaded(status)}>
						<HStack wrap="nowrap" align="center" gap="lg">
							<StyledSearchContainer>
								<Search
									value={searchValue}
									onChange={onSearchValueChange}
									placeholder="Search for a company"
								/>
							</StyledSearchContainer>

							{invitationsFilterValue === 'all' ? (
								<QuickFilter value={quickFilterValue} onChange={onQuickFilterChange} />
							) : (
								<></>
							)}
						</HStack>
					</Skeleton>
					<Skeleton fitContent isLoaded={isDataLoaded(status)}>
						<ResultsCounter count={searchResults.length} />
					</Skeleton>
				</HStack>
				<CompanyCardsSkeletonLoader status={status} error={error}>
					{searchResults?.length ? (
						<CompaniesListing
							variant={invitationsFilterValue}
							companies={searchResults}
							setCompany={setCompany}
						/>
					) : (
						<NoCompanies
							variant={quickFilterValue}
							isFiltered={isFiltered}
							goBack={() => onInvitationFilterChange('all')}
						/>
					)}
				</CompanyCardsSkeletonLoader>
			</VStack>
			<InviteCompanyModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
		</Container>
	);
};

export default Companies;
