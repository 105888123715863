import { IconButton } from '@montel/montelpro-shared-components-v2';
import styled from 'styled-components';

export const StyledIconButton = styled(IconButton)`
	cursor: pointer;
	svg {
		width: 1.5rem;
		height: 1.5rem;
	}
`;

export const StyledContainer = styled.div`
	position: relative;
`;

export const StyledDot = styled.div`
	font-size: ${({ theme }) => theme.typography.size.xxs};
	width: 1.2rem;
	height: 1.2rem;
	border: 0.125rem solid ${({ theme }) => theme.colors.widget};
	background-color: ${({ theme }) => theme.colors.pink};
	border-radius: 100%;
	position: absolute;
	right: 0rem;
	top: 0.4rem;
	pointer-events: none;
	display: flex;
	justify-content: center;
	align-items: center;
`;
