export const useOrderPages = (currentPath?: string) => {
	const paths = {
		root: '/orders',
		endOfDay: '/orders/end-of-day',
		myOrders: '/orders/my-orders',
		companyOrders: '/orders/company-orders',
	};

	const isOrdersRootPage = currentPath === paths.root;
	const isEndOfDayPage = currentPath === paths.endOfDay;
	const isMyOrdersPage = currentPath === paths.myOrders;
	const isCompanyOrdersPage = currentPath === paths.companyOrders;

	return {
		paths,
		isOrdersRootPage,
		isEndOfDayPage,
		isMyOrdersPage,
		isCompanyOrdersPage,
	};
};
