import React, { FunctionComponent } from 'react';
import { useLocation } from 'react-router-dom';
import { MenuContextProvider } from '../menuContext';
import { MenuProps } from '../menuTypes';
import { useMenu } from '../useMenu';
import { Topbar } from './Topbar';
import TopMenuGroup from './TopMenuGroup';
import TopMenuItem from './TopMenuItem';
import { TopMenuSeparator } from './TopMenuSeparator';

/**
 * Top menu component which is displayed horizontally on the top of the page and serves as the secondary nav menu.
 */
const Index: FunctionComponent<MenuProps> = ({
	menuConfig = [],
	w = '100%',
	h = '40px',
	bg = 'transparent',
	className,
	...otherProps
}) => {
	const { items, isActiveItemByIndex } = useMenu({ items: menuConfig, locationProvider: useLocation });

	return (
		<Topbar $w={w} $h={h} $bg={bg} className={className} {...otherProps}>
			<TopMenuGroup>
				{items.map((item, index) => {
					if (item.type === 'Clickable') {
						const { text, link, icon } = item;
						return (
							<MenuContextProvider
								key={link}
								value={{
									index,
									isActive: isActiveItemByIndex(index),
								}}
							>
								<TopMenuItem key={link} icon={icon} text={text} link={link} />
							</MenuContextProvider>
						);
					}

					return <TopMenuSeparator key={index} />;
				})}
			</TopMenuGroup>
		</Topbar>
	);
};

export default Index;
