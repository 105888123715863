import React, { FunctionComponent } from 'react';
import { H1, HStack } from '@montel/montelpro-shared-components-v2';
import { styled } from 'styled-components';
import { TMenuItem, TopMenu } from 'components/Menu';

interface PageHeaderProps {
	title: string;
	children?: React.ReactNode | React.ReactNode[] | any;
	topMenuConfig?: TMenuItem[];
}

const StyledH1 = styled(H1)`
	white-space: nowrap;
`;

const PageHeader: FunctionComponent<PageHeaderProps> = ({ title, children, topMenuConfig = [] }) => {
	return (
		<HStack fullWidth align="center" justify="space-between" wrap="nowrap">
			<HStack fullWidth align="flex-start" wrap="nowrap" gap="md">
				<StyledH1>{title}</StyledH1>
				<TopMenu menuConfig={topMenuConfig} />
			</HStack>
			{children}
		</HStack>
	);
};

export default PageHeader;
