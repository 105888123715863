import { getMonthName } from './getMonthName';

export function getProductionPeriodValue(
	month: number | null,
	quarter: number | null,
	year: number | null | undefined,
): string | number {
	if (month === null && quarter === null) return year?.toString() ?? '';
	return quarter !== null ? `Q${quarter} ${year}` : `${getMonthName(month)} ${year}`;
}
