import { TAlertEditState } from '../types';

export interface TAlertEditStateValidation {
	region: false | string;
	productionPeriod: false | string;
}
export const getValidationErrors = (data: TAlertEditState): TAlertEditStateValidation => {
	const { region, productionYear, productionQuarter, productionMonth } = data;
	return {
		region: !region && 'Region is required',
		productionPeriod:
			!productionYear && (!productionMonth || !productionQuarter) && 'Production period is required',
	};
};
