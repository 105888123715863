import { FunctionComponent, ReactElement, useRef } from 'react';
import LazyLoad from './LazyLoad';

export interface LazySubject {
	ref: Element;
	onIntersect: () => void;
}

/**
 * __NOTE__: Children of LazyCollection __MUST__ implement {@link LazyLoadComponent}.
 */
export const LazyCollection: FunctionComponent<{
	children: ReactElement[];
}> = ({ children }) => {
	const subjects = useRef<LazySubject[]>([]);

	const observe = (ref: Element, onIntersect: () => void) => {
		subjects.current.push({ ref, onIntersect });
		observer.observe(ref);
	};
	const unobserve = (ref: Element) => {
		const subject = subjects.current.find((s) => s.ref === ref);
		if (!subject) return;

		observer.unobserve(subject.ref);
		subjects.current = subjects.current.filter((s) => s.ref !== ref);
	};

	const intersectionCallback = (entries: IntersectionObserverEntry[]) => {
		if (!subjects.current.length) {
			observer.disconnect();

			return;
		}

		let first = -1;
		let last = -1;
		for (const entry of entries) {
			const subjectIndex = subjects.current.findIndex((s) => s.ref === entry.target);
			const subject = subjects.current[subjectIndex];
			if (subject && entry.isIntersecting) {
				if (first < 0) first = subjectIndex;
				last = subjectIndex;
			}
		}

		/*
		InteractionObserver's frequency of calling the callback is limited by the browser's render cycle,
		and in cases where the user scrolls too fast it will miss intersecting elements.
		Because of this we check the first and last subject that was intersecting,
		and invoke the callback for them and all subjects inbetween.
		*/
		for (let i = first; i <= last; i++) {
			if (subjects.current[i]) subjects.current[i].onIntersect();
		}
	};
	const observer = new IntersectionObserver(intersectionCallback, { threshold: 0 });

	return (
		<>
			{children.map((child) => (
				<LazyLoad observe={observe} unobserve={unobserve} key={`LazyLoad-${child.props.id}`}>
					{child}
				</LazyLoad>
			))}
		</>
	);
};

export default LazyCollection;
