import React, { FunctionComponent } from 'react';
import { UserRole } from 'common/enums';
import { useAppSelector } from 'store/hooks';
import { PublicCompanyInformation } from '../../common/interfaces';
import { selectActiveCompanyId, selectUserRole } from '../../store/user/userSelectors';
import PrimaryActionButtons from './PrimaryActionButtons/PrimaryActionButtons';
import SecondaryActionButtons from './SecondaryActionButtons/SecondaryActionButtons';
import KycButton from './KycButton';
import { StyledActionButtonsGroup } from './style';

export interface ActionButtonsGroupProps {
	companyInformation: PublicCompanyInformation;
	refreshContent: () => void;
	showSecondaryActions?: boolean;
}

const ActionButtonsGroup: FunctionComponent<ActionButtonsGroupProps> = ({
	companyInformation,
	refreshContent,
	showSecondaryActions = false,
}) => {
	const userRole = useAppSelector(selectUserRole);
	const activeCompanyId = useAppSelector(selectActiveCompanyId);

	/* User should have a company and non-read only role in order to perform actions */
	const isPermissionsValid = activeCompanyId && userRole !== UserRole.ReadOnly && userRole !== UserRole.Default;

	const { isBlockingMyCompany, isBlockedByMyCompany, kycContactEmails } = companyInformation;
	/* We show primary action buttons only if the relation is not blocked or paused */
	const isRelationShipBlocked = isBlockedByMyCompany || isBlockingMyCompany;

	return (
		<StyledActionButtonsGroup
			onClick={(event: React.MouseEvent<HTMLDivElement>) => {
				event.stopPropagation();
			}}
		>
			<KycButton emailAddresses={kycContactEmails || []} />
			{isPermissionsValid && !isRelationShipBlocked && (
				<PrimaryActionButtons companyInformation={companyInformation} refreshContent={refreshContent} />
			)}
			{isPermissionsValid && showSecondaryActions && (
				<SecondaryActionButtons companyInformation={companyInformation} refreshContent={refreshContent} />
			)}
		</StyledActionButtonsGroup>
	);
};

export default ActionButtonsGroup;
