import { FunctionComponent } from 'react';
import { Quote } from 'common/interfaces/Quote';
import { Currency } from '../../../../common/enums';
import QuoteExpiryCell from '../../../QuotesTable/Cells/QuoteExpiryCell';
import QuoteOwnerCell from '../../../QuotesTable/Cells/QuoteOwnerCell';
import { useOrderDetailsContext } from '../../OrderDetailsContext';
import HeaderCell from './HeaderCell';
import NumericCell from './NumericCell';
import { StyledQuoteTable } from './style';

const Index: FunctionComponent = () => {
	const { selectedQuote } = useOrderDetailsContext();
	const { status, expiryTime, quantity, price, currency, isMyQuote } = selectedQuote || ({} as Quote);
	const headerCells = ['', 'Bid expires', 'Quantity', 'Unit price', 'Total price'];

	return (
		<StyledQuoteTable>
			<thead>
				<tr>
					{headerCells.map((column, idx) => (
						<HeaderCell key={column + idx.toString()} text={column} />
					))}
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>
						<QuoteOwnerCell isMyQuote={isMyQuote} align="center" justify="center" />
					</td>
					<td>
						<QuoteExpiryCell
							status={status}
							expiryTimestamp={new Date(expiryTime).getTime()}
							justify="center"
							countdownFontSize="sm"
						/>
					</td>
					<NumericCell value={quantity} unit="MWh" />
					<NumericCell value={price} unit={Currency[currency]} />
					<NumericCell value={price * quantity} unit={Currency[currency]} decimalPlaces={2} />
				</tr>
			</tbody>
		</StyledQuoteTable>
	);
};

export default Index;
