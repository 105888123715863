import { FunctionComponent } from 'react';
import { IconCrossCancel, Text } from '@montel/montelpro-shared-components-v2';
import { StyledChip } from './Chip.style';

export interface ChipProps {
	text: string;
	onClick?: () => void;
	className?: string;
	[otherProps: string]: any;
}

const Chip: FunctionComponent<ChipProps> = ({ text, onClick, className, ...otherProps }) => {
	if (!text) return null;
	return (
		<StyledChip className={className} {...otherProps} onClick={onClick}>
			<Text as="span" lineHeight="none" size="xxs" weight="semiBold">
				{text.toUpperCase()}
			</Text>
			{onClick && <IconCrossCancel color="white" boxSize="0.6rem" />}
		</StyledChip>
	);
};

export default Chip;
