import { FunctionComponent, useEffect } from 'react';
import { Box, VStack } from '@montel/montelpro-shared-components-v2';
import { Quote } from 'common/interfaces/Quote';
import getOrderFilterOptions from 'common/options/orderFilterOptions';
import useSetPageTitle from 'hooks/useSetPageTitle';
import { getActiveUserQuotes, getUserUntradedOrderHistory } from 'store/orders/ordersThunks';
import { selectActiveUserQuotes } from 'store/orders/userActiveQuotes/activeUserQuotesSelectors';
import {
	selectUserOrderHistory,
	selectUserOrderHistoryStatus,
} from 'store/orders/userUntradedOrderHistory/userUntradedOrderHistorySelectors';
import NewOrder from 'components/NewOrder';
import ExpiredOrdersTable from 'components/OrderTables/ExpiredOrdersTable';
import MarketOrdersTable from 'components/OrderTables/MarketOrdersTable';
import { quotesOverviewTableColumns } from 'components/QuotesTable/Columns/quotesOverviewTableColumns';
import QuoteOverviewTable from 'components/QuotesTable/QuotesOverviewTable';
import { TableSkeleton } from '../../../components/Skeletons';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
	selectActiveOrders,
	selectActiveOrdersStatus,
	selectMyAndCompanysActiveAskOrders,
	selectMyAndCompanysActiveBidOrders,
	selectMyProductionYears,
} from '../../../store/orders/activeOrders/activeOrdersSelectors';
import { useOrdersOutletContext } from '../Orders';
import { StyledContainer } from '../style';

const MyOrders: FunctionComponent = () => {
	useSetPageTitle('My Orders');
	const dispatch = useAppDispatch();
	const { onOrderClicked, setOrderFilterOptions, filterOrders, filterChoices } = useOrdersOutletContext();
	const orderStatus = useAppSelector(selectActiveOrdersStatus);
	const myAskOrders = useAppSelector(selectMyAndCompanysActiveAskOrders);
	const filteredMyAskOrders = filterOrders(myAskOrders, filterChoices);
	const myBidOrders = useAppSelector(selectMyAndCompanysActiveBidOrders);
	const filteredMyBidOrders = filterOrders(myBidOrders, filterChoices);
	const userOrderHistory = useAppSelector(selectUserOrderHistory);
	const userOrderHistoryStatus = useAppSelector(selectUserOrderHistoryStatus);
	const activeProductionYears = useAppSelector(selectMyProductionYears);
	const activeOrders = useAppSelector(selectActiveOrders);
	const myActiveQuotes = useAppSelector(selectActiveUserQuotes);
	const myActiveQuotesFiltered = filterOrders(myActiveQuotes, filterChoices);

	useEffect(() => {
		setOrderFilterOptions(getOrderFilterOptions(activeProductionYears));
		dispatch(getUserUntradedOrderHistory());
		dispatch(getActiveUserQuotes());
	}, []);

	return (
		<>
			<VStack gap="lg" fullWidth>
				<TableSkeleton
					status={orderStatus}
					config={[
						{ numberOfTables: 2, numberOfItems: 4 },
						{ numberOfTables: 1, numberOfItems: 4 },
					]}
				>
					<StyledContainer>
						<Box px="0" py="0" bg="widget">
							<MarketOrdersTable
								header="Buy orders"
								orders={filteredMyBidOrders}
								onRowClicked={onOrderClicked}
								noDataText="No active buy orders"
							/>
						</Box>
						<Box px="0" py="0" bg="widget">
							<MarketOrdersTable
								header="Sell orders"
								reverse
								orders={filteredMyAskOrders}
								onRowClicked={onOrderClicked}
								noDataText="No active sell orders"
							/>
						</Box>
					</StyledContainer>
					<Box px="0" py="0" bg="widget">
						<QuoteOverviewTable
							header={'Quotes'}
							quotes={myActiveQuotesFiltered}
							columns={quotesOverviewTableColumns}
							initialSorting={[{ id: 'delivery', desc: true }]}
							withPagination={false}
							onRowClicked={(quote: Quote) => {
								const order = activeOrders.find((order) => order.id === quote.orderId);
								if (order) onOrderClicked(order);
							}}
							noDataText={'No active quotes'}
						/>
					</Box>
				</TableSkeleton>
				<TableSkeleton status={userOrderHistoryStatus} config={[{ numberOfTables: 1, numberOfItems: 6 }]}>
					<VStack wrap="nowrap" fullWidth>
						<ExpiredOrdersTable orders={userOrderHistory} />
					</VStack>
				</TableSkeleton>
			</VStack>
			<NewOrder />
		</>
	);
};

export default MyOrders;
