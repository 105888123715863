import React, { FunctionComponent } from 'react';
import CompanyButton from './CompanyButton';
import { DropdownGroupWithBack } from './DropdownGroupWithBack';

export interface CompanyGroupProps {
	userCompanies: any;
	activeCompanyId?: number;
	onGoBack: () => void;
	onClick: (companyId: number) => void;
}

const CompanyGroup: FunctionComponent<CompanyGroupProps> = ({ userCompanies, activeCompanyId, onGoBack, onClick }) => {
	return (
		<DropdownGroupWithBack goBack={onGoBack}>
			{userCompanies &&
				userCompanies.map(
					({ companyName, companyId, role }: { companyName: string; companyId: number; role: number }) => {
						return (
							<CompanyButton
								key={`companySwitch-${companyId}`}
								isActive={activeCompanyId === companyId}
								onClick={() => onClick(companyId)}
								title={companyName}
								role={role}
							/>
						);
					},
				)}
		</DropdownGroupWithBack>
	);
};

export default CompanyGroup;
