import { ProductionPeriod } from 'common/interfaces';
import convertMonthToString from 'utils/convertMonthToString';
import convertQuarterToString from 'utils/convertQuarterToString';

export const getProductionPeriodString = (production: ProductionPeriod, withYear = true) =>
	`${withYear ? `${production.year} ` : ''}${
		production.month
			? `${convertMonthToString(production.month)}`
			: production.quarter
			? `${convertQuarterToString(production.quarter)}`
			: ''
	}`.trimEnd();
