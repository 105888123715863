import { RequestStatus } from '@montel/montelpro-shared-components/enums';
import {
	ACCEPT_QUOTE_API_ROUTE,
	CANCEL_QUOTE_API_ROUTE,
	CREATE_QUOTE_API_ROUTE,
	UPDATE_QUOTE_API_ROUTE,
} from '../../../apiRoutes';
import useDelete from '../../../hooks/useDelete';
import useSubmit, { POST } from '../../../hooks/useSubmit';

type PostReturnType = [
	postData: (payload: any) => Promise<{ success: boolean; data: any; statusCode: number | undefined }>,
	status: RequestStatus,
];

type DeleteReturnType = [deleteData: () => Promise<boolean>, status: RequestStatus];

interface BaseArgs {
	orderId: string;
	expectedVersion: number;
	quoteType?: string;
}

interface CreateQuoteArgs extends BaseArgs {}
interface UpdateQuoteArgs extends BaseArgs {
	quoteId: string;
}

interface IApiClient {
	useCreateQuote: (args: CreateQuoteArgs) => PostReturnType;
	useUpdateQuote: (args: UpdateQuoteArgs) => PostReturnType;
	useCancelQuote: (args: UpdateQuoteArgs) => DeleteReturnType;
	useAcceptQuote: (args: UpdateQuoteArgs) => PostReturnType;
}

const apiClient: IApiClient = {
	useCreateQuote: ({ orderId, expectedVersion, quoteType = 'quote' }) => {
		const url = CREATE_QUOTE_API_ROUTE({ orderId, expectedVersion: expectedVersion.toString() });
		const [postData, status] = useSubmit(url, POST, {
			messageOnSuccess: `The ${quoteType} has been created.`,
			messageOnError: `An error occurred when creating the ${quoteType}. Please refresh the page and try again.`,
			showApiError: false,
		});
		return [postData, status];
	},
	useUpdateQuote: ({ orderId, quoteId, expectedVersion, quoteType = 'quote' }) => {
		const url = UPDATE_QUOTE_API_ROUTE({ orderId, quoteId, expectedVersion: expectedVersion.toString() });
		const [postData, status] = useSubmit(url, POST, {
			messageOnSuccess: `The ${quoteType} has been updated.`,
			messageOnError: `An error occurred when updating the ${quoteType}. Please refresh the page and try again.`,
			showApiError: false,
		});
		return [postData, status];
	},
	useAcceptQuote: ({ orderId, quoteId, expectedVersion, quoteType = 'quote' }) => {
		const url = ACCEPT_QUOTE_API_ROUTE({ orderId, quoteId, expectedVersion: expectedVersion.toString() });
		const [postData, status] = useSubmit(url, POST, {
			messageOnSuccess: `The ${quoteType} has been accepted.`,
			messageOnError: `An error occurred when accepting the ${quoteType}. Please refresh the page and try again.`,
			showApiError: false,
		});
		return [postData, status];
	},
	useCancelQuote: ({ orderId, quoteId, expectedVersion, quoteType = 'quote' }) => {
		const { deleteItem, deleteStatus } = useDelete({
			messageOnError: `An error occurred when cancelling your ${quoteType}. Please refresh the page and try again.`,
			messageOnSuccess: `The ${quoteType} has been cancelled!`,
		});
		const url = CANCEL_QUOTE_API_ROUTE({ orderId, quoteId, expectedVersion: expectedVersion.toString() });
		const deleteData = async () => await deleteItem(url);
		return [deleteData, deleteStatus];
	},
};

export default apiClient;
