import { createSelector } from '@reduxjs/toolkit';
import { UserRole } from 'common/enums';
import { RootState } from '../store';
import checkMembership from './utils/checkMembership';

export const selectUserState = (state: RootState) => state.user;

export const selectStatus = (state: RootState) => selectUserState(state).status;

export const selectError = (state: RootState) => selectUserState(state).error;

export const selectIsInitialised = (state: RootState) => selectUserState(state).isInitialised;

export const selectToken = (state: RootState) => selectUserState(state).token;

export const selectUserId = (state: RootState) => selectUserState(state).userId;

export const selectFirstName = (state: RootState) => selectUserState(state).firstName;

export const selectLastName = (state: RootState) => selectUserState(state).lastName;

export const selectFullName = (state: RootState) => `${selectFirstName(state)} ${selectLastName(state)}`;

export const selectActiveCompanyId = (state: RootState) => selectUserState(state).activeCompanyId;

export const selectMarketPlaceRoles = (state: RootState) => selectUserState(state).marketPlaceRoles;

export const selectUserCompanies = (state: RootState) => selectUserState(state).userCompanies;

export const selectUserCompany = (state: RootState) => selectUserState(state).userCompany;

export const selectIsEmailVerified = (state: RootState) => selectUserState(state).isEmailVerified;

export const selectEmail = (state: RootState) => selectUserState(state).email;

/**
 * Selects the user role in the active company
 */
export const selectUserRole = createSelector(
	[selectActiveCompanyId, selectMarketPlaceRoles],
	(activeCompanyId, marketPlaceRoles) => {
		if (!activeCompanyId || !marketPlaceRoles) return UserRole.ReadOnly;

		const userRole = marketPlaceRoles.find((role) => role.companyId === activeCompanyId);

		return userRole ? userRole.role : UserRole.ReadOnly;
	},
);

export const selectIsBackOfficeOrReadonlyRole = createSelector(
	[selectUserRole],
	(roleInActiveCompany) => roleInActiveCompany === UserRole.BackOffice || roleInActiveCompany === UserRole.ReadOnly,
);

export const selectIsTraderManagerOrBackOfficeRole = createSelector(
	[selectUserRole],
	(roleInActiveCompany) =>
		roleInActiveCompany === UserRole.TradeManager || roleInActiveCompany === UserRole.BackOffice,
);

export const selectHasRole: (state: RootState, ...args: UserRole[]) => boolean = createSelector(
	[(state: RootState) => selectUserRole(state), (_: RootState, ...args: UserRole[]) => args],
	(userRole, args) => {
		if (!userRole) return false;
		return checkMembership(userRole, ...args);
	},
);

export const selectCanInviteCompanyOrUser = createSelector([selectUserRole], (roleInActiveCompany) => {
	const allowedRoles = [UserRole.BackOffice, UserRole.TradeManager, UserRole.Trader];

	return roleInActiveCompany && allowedRoles.includes(roleInActiveCompany);
});
