import { Region, Tech } from '@montel/montelpro-shared-components/enums';
import { TAlertEditState } from '../types';

export interface UpdateAlertRequest {
	id: number;
	technology: Tech;
	region: Region;
	productionYear: number;
	productionQuarter?: number | null;
	productionMonth?: number | null;
	includeUnderlyingContracts: boolean;
}

export const toUpdateAlertRequest = (data: TAlertEditState): UpdateAlertRequest => {
	const { id, technology, region, productionYear, productionQuarter, productionMonth, includeUnderlyingContracts } =
		data;
	return {
		id: id as number,
		technology: technology,
		region: region as Region,
		productionYear: productionYear as number,
		productionQuarter: productionQuarter ?? null,
		productionMonth: productionMonth === null ? null : productionMonth + 1,
		includeUnderlyingContracts: includeUnderlyingContracts,
	};
};
