import { useState } from 'react';
import { toast } from 'react-toastify';
import { RequestStatus } from '@montel/montelpro-shared-components/enums';
import axios from 'axios';
import { Side } from 'common/enums/OrderEnums';
import { Order } from 'common/interfaces/Order';

const useSellBuyOrder = (order: Order) => {
	const [sellBuyStatus, setSellBuyStatus] = useState<RequestStatus>(RequestStatus.UNLOADED);

	const sellBuyOrder = async () => {
		if (!order.id) return;
		setSellBuyStatus(RequestStatus.LOADING);

		try {
			const response = await axios.post(
				`${process.env.REACT_APP_MARKEDPLACE_API}/api/Order/AcceptOrder?orderId=${order.id}&expectedVersion=${order.version}`,
			);
			if (response) {
				toast.success(`You just ${order.side === Side.Bid ? 'sold' : 'bought'} this order.`);
				setSellBuyStatus(RequestStatus.SUCCESS);
			}
		} catch (error) {
			console.error(error);
			setSellBuyStatus(RequestStatus.ERROR);
			toast.error('An error has occured, please try again.');
		}
	};

	return {
		sellBuyOrder,
		sellBuyStatus,
	};
};

export default useSellBuyOrder;
