import { createBrowserRouter, Outlet } from 'react-router-dom';
import { ErrorBoundary, NotFound } from 'pages/Error';
import AppProvider from 'providers/AppProviders';
import PrivateRoute from 'components/PrivateRoute';
import protectedRoutes from './protectedRoutes';
import publicRoutes from './publicRoutes';
import routesForUnverifiedUsers from './routesForUnverifiedUsers';

const router = createBrowserRouter([
	{
		path: '/',
		element: <Outlet />,
		errorElement: <ErrorBoundary />,
		children: [
			{
				path: '/',
				element: (
					<AppProvider>
						<Outlet />
					</AppProvider>
				),
				children: [
					{
						path: '/',
						element: (
							<PrivateRoute>
								<Outlet />
							</PrivateRoute>
						),
						children: protectedRoutes,
					},
					...routesForUnverifiedUsers,
					{
						path: '*',
						element: <NotFound />,
					},
				],
			},
			...publicRoutes,
		],
	},
]);

export default router;
