import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router';
import { Button, H1, Text, VStack } from '@montel/montelpro-shared-components-v2';
import { styled } from 'styled-components';

const StyledContainer = styled(VStack)`
	margin: auto;
`;

const StyledTextContainer = styled(VStack)`
	width: 540px;
`;

const StyledH1 = styled(H1)`
	line-height: 75%;
`;

interface ErrorProps {
	heading: string;
	subHeading: string;
	bodyText: string;
	showHomepageLink?: boolean;
}

const Error: FunctionComponent<ErrorProps> = ({ heading, subHeading, bodyText: body, showHomepageLink = true }) => {
	const navigate = useNavigate();

	return (
		<StyledContainer align="center" justify="center" gap="xxl">
			<StyledH1 weight="semiBold" size="6rem">
				{heading}
			</StyledH1>
			<StyledTextContainer align="center" justify="center" gap="sm">
				<Text size="xxxl">{subHeading}</Text>
				<Text align="center" size="sm">
					{body}
				</Text>
			</StyledTextContainer>
			{showHomepageLink && <Button text="Go to the homepage" onClick={() => navigate('/')} />}
		</StyledContainer>
	);
};

export default Error;
