import { FunctionComponent } from 'react';
import { Text } from '@montel/montelpro-shared-components-v2';
import { StyledButton, StyledContainer } from './style';

export interface QuickFilterProps {
	value?: string;
	onChange: (value: string) => void;
}

export const quickFilterOptions = [
	{
		label: 'All',
		value: 'all',
	},
	{
		label: 'Counterparties',
		value: 'counterparties',
	},
	{
		label: 'Blocked/Paused',
		value: 'blocked',
	},
];

const QuickFilter: FunctionComponent<QuickFilterProps> = ({ value = 'all', onChange }) => {
	const isSelected = (val: string) => val === value;
	return (
		<StyledContainer>
			{quickFilterOptions.map(({ label, value }) => (
				<StyledButton key={value} $checked={isSelected(value)} onClick={() => onChange(value)}>
					<Text lineHeight="none">{label}</Text>
				</StyledButton>
			))}
		</StyledContainer>
	);
};

export default QuickFilter;
