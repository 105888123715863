import { FunctionComponent } from 'react';
import { flexRender, Header } from '@tanstack/react-table';
import { styled } from 'styled-components';

const StyledTh = styled.th<{ $width: string; $minWidth: string }>`
	${({ $width }) => `width: ${$width}`};
	${({ $minWidth }) => `min-width: ${$minWidth}`};
	padding: ${({ theme }) => `${theme.spacing.xxs} ${theme.spacing.xs}`};
`;

const Th: FunctionComponent<{ header: Header<any, any>; className?: string }> = ({ header, className }) => (
	<StyledTh
		$width={(header?.column.columnDef as any)?.meta?.width}
		$minWidth={(header?.column.columnDef as any)?.meta?.minWidth}
		className={className}
	>
		{header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
	</StyledTh>
);

export default Th;
