import { FunctionComponent } from 'react';
import { Box, Text, VStack } from '@montel/montelpro-shared-components-v2';
import { Currency } from 'common/enums';
import getTotalPriceFormatted from '../../utils/getTotalPriceFormatted';

export const TotalPrice: FunctionComponent<{
	quantity: string;
	pricePerUnit: string | undefined;
	currency: Currency;
	smaller?: boolean;
	isRfq?: boolean;
}> = ({ quantity, pricePerUnit, currency, smaller = false, isRfq = false }) => (
	<Box py={smaller ? '0' : 'sm'}>
		<VStack gap="sm" align="center" justify="center">
			<Text color="secondaryText" size={smaller ? 'md' : 'xxl'}>
				Total price:
			</Text>
			<Text weight="bold" size={smaller ? 'xl' : 'xxxl'}>
				{isRfq
					? 'Request for quote'
					: `${getTotalPriceFormatted(quantity, pricePerUnit ?? '0')} ${Currency[currency]}`}
			</Text>
		</VStack>
	</Box>
);
