import { useSelector } from 'react-redux';
import { Button, H1, HStack, Text, VStack } from '@montel/montelpro-shared-components-v2';
import useSetPageTitle from 'hooks/useSetPageTitle';
import EmptyPageContainer from 'primitives/EmptyPageContainer';
import { selectUserId } from 'store/user/userSelectors';
import { css, styled } from 'styled-components';
import img from '../../assets/background/welcome-page.webp';
import { ReactComponent as Logo } from '../../assets/brand/welcome_logo.svg';
import { setRegisterCookie } from './registerCookie';

const WelcomePage = () => {
	useSetPageTitle('Welcome');
	const userId = useSelector(selectUserId);

	const navigateWithTemporaryUnregisteredAccess = (navigateTo: string, durationInDays: number) => {
		setRegisterCookie(userId, durationInDays);
		window.location.assign(navigateTo);
	};

	return (
		<EmptyPageContainer bgImageUrl={img}>
			<StyledWrapper justify="center" align="center">
				<StyledContainer gap="xl">
					<Logo />
					<StyledH1>GO trading for everyone</StyledH1>
					<VStack gap="lg">
						<Text weight="bold" size="lg">
							Welcome to Montel Marketplace
						</Text>
						<Text>
							Montel Marketplace unites Guarantees of Origin buyers and sellers on an open-access
							platform.
						</Text>
						<Text>
							Simply create your account and log in to access market prices. Join us below to play your
							part in creating a more transparent market.
						</Text>
					</VStack>
					<VStack fullWidth gap="lg">
						<StyledWideButton
							text="Register new company"
							onClick={() => navigateWithTemporaryUnregisteredAccess('/company/registration', 14)}
						/>
						<HStack fullWidth wrap="nowrap">
							<StyledWideButton
								variant="secondary"
								text="Join existing company"
								onClick={() => navigateWithTemporaryUnregisteredAccess('/join-company', 7)}
							/>
							<StyledWideButton
								variant="secondary"
								text="View prices"
								onClick={() => navigateWithTemporaryUnregisteredAccess('/orders', 1)}
							/>
						</HStack>
					</VStack>
				</StyledContainer>
			</StyledWrapper>
		</EmptyPageContainer>
	);
};

const StyledWrapper = styled(VStack)`
	min-width: 1000px;
	width: 40%;
	height: 100%;
`;

const StyledContainer = styled(VStack)`
	margin: auto;
	width: 430px;
`;

const StyledH1 = styled(H1)`
	font-size: 64px;

	${({ theme }) => css`
		font-family: ${theme.typography.family.serif};
		line-height: ${theme.typography.lineHeight.none};
		font-weight: ${theme.typography.weight.medium};
	`}
`;

const StyledWideButton = styled(Button)`
	width: 100%;
`;

export default WelcomePage;
