import { FunctionComponent } from 'react';
import { AvailableTo, OrderTypeEnum, UserRole } from 'common/enums';
import { useAppSelector } from 'store/hooks';
import { selectUserRole } from 'store/user/userSelectors';
import { useOrderDetailsContext } from '../../OrderDetailsContext';
import FixedPriceTraderActions from './FixedPriceTraderActions';
import RFQTraderActions from './RFQTraderActions';

interface TraderActionsProps {}

const TraderActions: FunctionComponent<TraderActionsProps> = () => {
	const userRole = useAppSelector(selectUserRole);
	const { order } = useOrderDetailsContext();

	const traderRole = userRole === UserRole.Trader || userRole === UserRole.TradeManager;
	const allowedToTrade =
		traderRole && (order.availability.availableTo === AvailableTo.Public || order.isCounterpartysOrder);

	const isRFQ = order.orderType === OrderTypeEnum.RequestForQuote;

	return <>{allowedToTrade && (isRFQ ? <RFQTraderActions /> : <FixedPriceTraderActions />)}</>;
};

export default TraderActions;
