import styled from 'styled-components';
import IndeterminateCheckbox from './IndeterminateCheckbox';

export const StyledTable = styled.table`
	width: 100%;
	border-collapse: collapse;
`;

export const StyledIndeterminateCheckbox = styled(IndeterminateCheckbox)`
	margin-left: ${({ theme }) => theme.spacing.lg};
`;
