import { SelectOptionType } from '@montel/montelpro-shared-components-v2';
import { OrderTypeEnum } from 'common/enums';
import { getOrderTypeName } from 'common/enums/valueLookups';
import { getEnumKeys } from 'utils/enumUtils';

const getOrderTypeOptions = (): SelectOptionType[] => {
	return getEnumKeys(OrderTypeEnum)
		.slice(1)
		.map((t) => ({
			value: OrderTypeEnum[t as keyof typeof OrderTypeEnum],
			label: getOrderTypeName(OrderTypeEnum[t as keyof typeof OrderTypeEnum]),
		}));
};

export default getOrderTypeOptions;
