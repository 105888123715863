import { FunctionComponent } from 'react';
import { Countdown, HStack, Text } from '@montel/montelpro-shared-components-v2';
import { useOrderDetailsContext } from '../OrderDetailsContext';

const OrderCountDown: FunctionComponent = () => {
	const { minutesUntilExpiry, order, priorityColor, setIsExpired, isExpired } = useOrderDetailsContext();
	const remainingDays = minutesUntilExpiry / 60 / 24;

	return (
		<HStack align="center" justify="flex-start" gap="md">
			{isExpired ? (
				<Text color="error" lineHeight="none">
					EXPIRED
				</Text>
			) : remainingDays > 1 ? (
				<Text color={priorityColor} lineHeight="none">
					Order expires in {Math.round(remainingDays)} days
				</Text>
			) : (
				<Countdown
					onExpiry={() => setIsExpired(true)}
					expiration={new Date(order.expiryTime)}
					prefix="Order expires in"
					color={priorityColor}
				/>
			)}
		</HStack>
	);
};

export default OrderCountDown;
