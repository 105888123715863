import { FunctionComponent } from 'react';
import { Table } from '@tanstack/react-table';
import { Order } from 'common/interfaces/Order';
import Pagination from './Pagination';

interface TanStackPaginationProps {
	table: Table<Order>;
}

const TanStackPagination: FunctionComponent<TanStackPaginationProps> = ({ table }) => {
	return (
		<Pagination
			pageIndex={table.getState().pagination.pageIndex}
			setPageIndex={(pageIndex: number) => table.setPageIndex(pageIndex)}
			pageSize={table.getState().pagination.pageSize}
			setPageSize={(pageSize: number) => table.setPageSize(pageSize)}
			pageCount={table.getPageCount()}
		/>
	);
};

export default TanStackPagination;
