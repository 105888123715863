import { Tech } from '../../../common/enums/OrderEnums';
import { AlertType } from '../types';
import { TAlertEditState } from './types';

const defaultState: TAlertEditState = {
	id: undefined,
	product: undefined,
	technology: Tech.AnyRenewable,
	region: undefined,
	productionYear: null,
	productionQuarter: null,
	productionMonth: null,
	active: true,
	type: AlertType.Ask,
	includeUnderlyingContracts: false,
	productionPeriod: null,
};

export default defaultState;
