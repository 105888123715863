import { HeadCell } from '@montel/montelpro-shared-components-v2';
import moment from 'moment';

export const getColumns = (previousDate: Date, currentDate: Date): HeadCell[] => [
	{
		id: 'productionYear',
		label: 'Production',
		numeric: true,
		sortable: false,
	},
	{
		id: 'previous',
		label: previousDate ? moment(previousDate).format('DD MMM YY') : '-',
		numeric: true,
		sortable: false,
		alignLabel: 'right',
	},
	{
		id: 'spacer',
		label: '',
		numeric: false,
		sortable: false,
	},
	{
		id: 'change',
		label: 'Change',
		numeric: false,
		sortable: false,
	},
	{
		id: 'current',
		label: currentDate ? moment(currentDate).format('DD MMM YY') : '-',
		numeric: true,
		sortable: false,
		alignLabel: 'right',
	},
];
