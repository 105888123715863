import { INotificationLibraryAsset } from '../types';
import companyTemplates from './company';
import counterpartyTemplates from './counterparty';
import orderTemplates from './order';
import systemNotificationTemplates from './systemNotification';

const templates: INotificationLibraryAsset = {
	type: 'template',
	company: { ...companyTemplates },
	counterparty: { ...counterpartyTemplates },
	order: { ...orderTemplates },
	systemNotification: { ...systemNotificationTemplates },
};

export default templates;
