import { FunctionComponent } from 'react';
import { HStack, IconCrossCancel } from '@montel/montelpro-shared-components-v2';
import { Text } from '@montel/montelpro-shared-components-v2';
import { useTheme } from 'styled-components';

export const TradeCancelled: FunctionComponent = () => {
	const theme = useTheme();

	return (
		<HStack align="center">
			<IconCrossCancel color={theme.colors.secondaryText} boxSize="9px" />
			<Text size="xs" color={theme.colors.secondaryText}>
				Cancelled
			</Text>
		</HStack>
	);
};
