import React, { FunctionComponent, useEffect, useState } from 'react';
import qs from 'qs';
import { MARKETPLACE_ALERTS_API_ROUTE } from '../../apiRoutes';
import { Alert } from '../../common/interfaces/Alert';
import { Suspense } from '../../components/Suspense';
import useDelete from '../../hooks/useDelete';
import useSubmit, { PUT } from '../../hooks/useSubmit';
import { useAlertSettingsContext } from '../../providers/AlertSettingsProvider';
import CustomAlertsHeader from './AlertsTable/CustomAlertsHeader';
import CustomAlertsTable from './AlertsTable/CustomAlertsTable';
import EditAlertModal from './EditAlertModal/EditAlertModal';
import NoAlerts from './NoAlerts/NoAlerts';
import { InnerContainer } from './style';
import { AlertType } from './types';
import useLoadAllAlerts from './useLoadAllAlerts';

const CustomAlerts: FunctionComponent = () => {
	const [alerts, setAlerts, status] = useLoadAllAlerts();
	const { enabledAlertTypes, updateAllAskOrdersEnabled, updateAllBidOrdersEnabled, updateAllTradesEnabled } =
		useAlertSettingsContext();

	const [editing, setEditing] = useState<Alert | null>(null);
	const [isModalOpen, setIsModalOpen] = React.useState(false);

	const onOpen = () => {
		setIsModalOpen(true);
	};
	const onClose = () => {
		setIsModalOpen(false);
	};

	const [selectedAlertIDs, setSelectedAlertIDs] = useState<number[]>([]);
	const [rowSelection, setRowSelection] = useState({});
	useEffect(() => {
		const selectedAlertIDs = Object.keys(rowSelection)
			.map((key) => parseInt(key))
			.map((id) => alerts[id].id);
		setSelectedAlertIDs(selectedAlertIDs as number[]);
	}, [rowSelection]);

	const someSelected = selectedAlertIDs.length > 0;
	const allSelected = selectedAlertIDs.length === alerts.length;

	const [updateAlert] = useSubmit(`${MARKETPLACE_ALERTS_API_ROUTE}/UpdateAlert`, PUT, {
		messageOnError: 'Something went wrong. Please try again.',
	});
	const { deleteItem } = useDelete({
		messageOnSuccess: 'Successfully deleted',
		messageOnError: 'An error has occured, please try again.',
	});

	const onToggleActive = async (alert: Alert) => {
		const oldState = alerts;
		const newState = alerts.map((a) =>
			a.id === alert.id
				? {
						...a,
						active: !a.active,
				  }
				: a,
		);

		setAlerts(newState);

		const result = await updateAlert({ ...alert, active: !alert.active });
		if (!result.success) setAlerts(oldState);
	};

	const onEdit = (alert: Alert) => {
		setEditing(alert);
		onOpen();
	};

	const onAddAlert = () => {
		setEditing(null);
		onOpen();
	};

	const onAlertSaved = async (alert: Alert) => {
		const { id, type } = alert;
		const newState = alerts.filter((a) => a.id !== id);
		newState.push(alert);
		if (type === AlertType.Ask) await updateAllAskOrdersEnabled(false);
		if (type === AlertType.Bid) await updateAllBidOrdersEnabled(false);
		if (type === AlertType.Trade) await updateAllTradesEnabled(false);
		setAlerts(newState);
	};

	const onDelete = async (alert: Alert) => {
		const success = await deleteItem(`${MARKETPLACE_ALERTS_API_ROUTE}/DeleteAlert`, {
			params: {
				id: alert.id,
			},
		});

		if (success) {
			const newState = alerts.filter((a) => a.id !== alert.id);
			setAlerts(newState);
			setRowSelection({});
		}
	};

	const onMassDelete = async () => {
		const success = await deleteItem(`${MARKETPLACE_ALERTS_API_ROUTE}/DeleteAlerts`, {
			params: {
				ids: selectedAlertIDs,
			},
			paramsSerializer: (params: any) => qs.stringify(params, { arrayFormat: 'repeat' }),
		});

		if (success) {
			const newState = alerts.filter((a) => a.id && !selectedAlertIDs.includes(a.id));
			setAlerts(newState);
			setRowSelection({});
		}
	};

	return (
		<Suspense status={status}>
			<InnerContainer>
				<CustomAlertsHeader
					someSelected={someSelected}
					allSelected={allSelected}
					onMassDelete={onMassDelete}
					onAddAlert={onAddAlert}
				/>
				{alerts.length > 0 ? (
					<CustomAlertsTable
						alerts={alerts}
						enabledAlertTypes={enabledAlertTypes}
						onEdit={onEdit}
						onDelete={onDelete}
						onToggleActive={onToggleActive}
						setSelectedAlertIDs={setSelectedAlertIDs}
						rowSelection={rowSelection}
						setRowSelection={setRowSelection}
					/>
				) : (
					<NoAlerts onClick={onAddAlert} />
				)}
			</InnerContainer>
			<EditAlertModal isOpen={isModalOpen} onClose={onClose} alert={editing} onSaveAlert={onAlertSaved} />
		</Suspense>
	);
};

export default CustomAlerts;
