import { FunctionComponent } from 'react';
import { IconButton, IconCrossCancel } from '@montel/montelpro-shared-components-v2';
import { styled } from 'styled-components';

const CloseToastButtonContainer = styled.div`
	display: flex;
	align-items: center;
	padding-right: 8px;
`;

const StyledIconButton = styled(IconButton)`
	color: inherit;
	&:hover {
		color: inherit;
		border-color: transparent;
	}
`;

export const CloseToastButton: FunctionComponent<{
	closeToast: (e: React.MouseEvent<HTMLElement>) => void;
}> = ({ closeToast }) => {
	return (
		<CloseToastButtonContainer>
			<StyledIconButton onClick={closeToast} icon={<IconCrossCancel boxSize="18px" />} variant="tertiary" />
		</CloseToastButtonContainer>
	);
};
