export function getProgressColor(minutesUntilExpiry: number) {
	if (minutesUntilExpiry <= 30) return 'error';
	else if (minutesUntilExpiry <= 180) return 'heritageOrange';
	else return 'success';
}

export function getProgressValue(minutesUntilExpiry: number) {
	const daysTilExpiry = minutesUntilExpiry / 60 / 24;

	if (daysTilExpiry >= 1) return 100;

	return calculateNonLinearProgress(minutesUntilExpiry);
}

/* 	The progress bar displays the time until expiry in a non-linear fashion.
	0,5h	=	25%
	3h		=	50%
	12h		=	75%
	24h		=	100%
*/
function calculateNonLinearProgress(minutesUntilExpiry: number) {
	const hoursRemaining = minutesUntilExpiry / 60;

	if (hoursRemaining > 24) return 100;

	let startPercentage: number;
	let min: number;
	let max: number;
	const barSectionPercentage = 25;

	if (hoursRemaining >= 12) {
		startPercentage = 75;
		min = 12;
		max = 24;
	} else if (hoursRemaining >= 3) {
		startPercentage = 50;
		min = 3;
		max = 12;
	} else if (hoursRemaining >= 0.5) {
		startPercentage = 25;
		min = 0.5;
		max = 3;
	} else {
		startPercentage = 0;
		min = 0;
		max = 0.5;
	}

	const remainder = hoursRemaining - min;
	const remainingPercent = (remainder / (max - min)) * 100;
	const remainingPercentScaled = remainingPercent * (barSectionPercentage / 100);

	return startPercentage + remainingPercentScaled;
}

export function getRemainingTime(endMs: number): number {
	// We compare with UTC timestamp.
	const timeNow = Date.now();
	const delta = endMs - timeNow;
	return delta > 0 ? delta : 0;
}

export function getMsAsDays(ms: number): number {
	return Math.floor(ms / 1000 / 60 / 60 / 24);
}
export function getMsAsMinutes(ms: number) {
	return Math.floor(ms / 1000 / 60);
}
