import { FunctionComponent } from 'react';
import { HStack, Text, VStack } from '@montel/montelpro-shared-components-v2';
import { LazyCollection } from 'primitives/LazyLoad';
import { CounterpartyRelationStatus } from '../common/enums/counterpartyStateEnum';
import { PublicCompanyInformation } from '../common/interfaces/Company';
import CompanyCard from '../components/CompanyCard';

export interface CompaniesProps {
	variant: 'all' | 'invitations' | string;
	companies: PublicCompanyInformation[];
	setCompany: (company: any) => void;
}

const CompaniesListing: FunctionComponent<CompaniesProps> = ({ variant, companies, setCompany }) => {
	if (variant === 'all') {
		return (
			<HStack fullWidth wrap="wrap" gap="1rem">
				<LazyCollection>
					{companies.map((companyInfo: PublicCompanyInformation) => (
						<CompanyCard
							companyInformation={companyInfo}
							setCompany={setCompany}
							key={`${companyInfo.id}`}
							id={`${companyInfo.id}`}
						/>
					))}
				</LazyCollection>
			</HStack>
		);
	}

	if (variant === 'invitations') {
		const incomingInvitations = companies.filter(
			(company: PublicCompanyInformation) =>
				company.counterpartyStatus === CounterpartyRelationStatus.Pending && !company.isInvitedByMyCompany,
		);
		const incomingInvitationsCount = incomingInvitations.length;

		const outgoingInvitations = companies.filter(
			(company: PublicCompanyInformation) =>
				company.counterpartyStatus === CounterpartyRelationStatus.Pending && company.isInvitedByMyCompany,
		);
		const outgoingInvitationsCount = outgoingInvitations.length;

		return (
			<VStack gap="xl">
				{incomingInvitationsCount > 0 && (
					<VStack gap="md">
						<div>
							<Text as="span" size="lg" weight="semiBold">
								Counterparty invitations
							</Text>
							<Text
								as="span"
								size="lg"
								weight="semiBold"
								color="secondaryText"
							>{` (${incomingInvitationsCount})`}</Text>
						</div>
						<HStack fullWidth wrap="wrap" gap="1rem">
							{incomingInvitations.map((companyInfo: PublicCompanyInformation, index: number) => (
								<CompanyCard
									companyInformation={companyInfo}
									setCompany={setCompany}
									key={`incomingInvitations-${companyInfo.id}-${index}`}
								/>
							))}
						</HStack>
					</VStack>
				)}
				{outgoingInvitationsCount > 0 && (
					<VStack gap="md">
						<div>
							<Text as="span" size="lg" weight="semiBold">
								Pending answer
							</Text>
							<Text
								as="span"
								size="lg"
								weight="semiBold"
								color="secondaryText"
							>{` (${outgoingInvitationsCount})`}</Text>
						</div>

						<HStack fullWidth wrap="wrap" gap="1rem">
							{outgoingInvitations.map((companyInfo: PublicCompanyInformation, index: number) => (
								<CompanyCard
									companyInformation={companyInfo}
									setCompany={setCompany}
									key={`incomingInvitations-${companyInfo.id}-${index}`}
								/>
							))}
						</HStack>
					</VStack>
				)}
			</VStack>
		);
	}
	return <></>;
};

export default CompaniesListing;
