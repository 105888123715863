/* eslint-disable no-console */
import React, { createContext, FunctionComponent, useContext, useEffect, useState } from 'react';
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { useAuth } from '@montel/montelpro-auth';
import { RequestStatus } from '@montel/montelpro-shared-components/enums';
import axios from 'axios';
import useSubscribeToOrders from './useSubscribeToOrders';
import useSubscribeToUserInfo from './useSubscribeToUserInfo';

const hubContext = createContext<any>(null);

const HubProvider: FunctionComponent<{ children: React.ReactNode }> = ({ children }) => {
	if (window.opener && window.opener !== window) {
		return children as React.ReactElement;
	}

	const hub = useHubProvider();

	return <hubContext.Provider value={hub}>{children}</hubContext.Provider>;
};

const useHubProvider = () => {
	const { getAccessToken, isLoggedIn } = useAuth();

	const [hubConnection, setHubConnection] = useState<HubConnection | null>();
	const [hubConnectionStatus, setHubConnectionStatus] = useState<RequestStatus>(RequestStatus.UNLOADED);

	useSubscribeToOrders({ hubConnection });
	useSubscribeToUserInfo({ hubConnection });

	useEffect(() => {
		if (isLoggedIn) {
			setHubConnectionStatus(RequestStatus.LOADING);
			const connect = new HubConnectionBuilder()
				.withUrl(process.env.REACT_APP_AZURE_SIGNALR as string, {
					accessTokenFactory: () => getAccessToken(),
				})
				.withAutomaticReconnect()
				.configureLogging(LogLevel.Warning)
				.build();

			const startHubConnection = async () => {
				try {
					await connect.start();
					setHubConnection(connect);
					await axios.post(`${process.env.REACT_APP_MARKEDPLACE_API}/api/SignalR/subscribe`);
					setHubConnectionStatus(RequestStatus.SUCCESS);
				} catch (error) {
					console.error(error);
					setTimeout(() => startHubConnection(), 5000);
				}
			};
			startHubConnection();

			return function cleanup() {
				axios
					.post(`${process.env.REACT_APP_MARKEDPLACE_API}/api/SignalR/unsubscribe`)
					.catch(() => console.log('Failed to unsubscribe from SignalR'));
				connect.stop();
			};
		}
	}, [isLoggedIn]);

	useEffect(() => {
		if (hubConnection) {
			hubConnection.onreconnecting(async () => {
				console.log('Reconnecting orders and trades stream...');
				setHubConnectionStatus(RequestStatus.LOADING);
			});
			hubConnection.onreconnected(async () => {
				try {
					await axios.post(`${process.env.REACT_APP_MARKEDPLACE_API}/api/SignalR/subscribe`);
					setHubConnectionStatus(RequestStatus.SUCCESS);
					console.log('Reconnected!');
				} catch (error) {
					console.error(error);
					setHubConnectionStatus(RequestStatus.ERROR);
				}
			});
			hubConnection.onclose((error) => {
				setHubConnectionStatus(RequestStatus.ERROR);
				console.error('Connection closed due to error:', error);
			});
		}
	}, [hubConnection]);

	return { hubConnection, hubConnectionStatus };
};

const useHub = () => {
	const context: any = useContext(hubContext);
	if (context === undefined) {
		throw new Error(`useHub must be used within a HubProvider`);
	}
	return context;
};

export { HubProvider, useHub };
