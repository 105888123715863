import { Order } from 'common/interfaces/Order';
import { getTechName } from './../../../common/enums/valueLookups/TechLookup';

const sortingTech = (a: number[], b: number[]): number => {
	//tech[] array is sorted from before at the moment when we get data from the hub connection
	const firstTechNameA = getTechName(a[0]);
	const firstTechNameB = getTechName(b[0]);
	return firstTechNameA.localeCompare(firstTechNameB);
};

export default sortingTech;
