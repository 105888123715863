import { FunctionComponent } from 'react';
import { IconBellOutlined, IconProps } from '@montel/montelpro-shared-components-v2';
import Moustache from 'mustache';
import { INotification } from '../INotification';
import icons from './icons';
import linkTemplates from './links';
import templates from './templates';
import translators from './translators';
import { INotificationLibraryAsset } from './types';

export type TTemplateLanguage = 'en';

export interface IGetNotificationAsset {
	/**
	 * The name of the template. It must be dot separated and include two and only two parts.
	 * The first part generally designates the entity type and the second part the action.
	 */
	name: string;
	/**
	 * The version of the template/data shape.
	 */
	version: number;
	/**
	 * The language of the template. It defaults to 'en' and it's a provision for future usages.
	 */
	lang?: TTemplateLanguage;
}

/**
 * Returns the asset from the asset library based on the name, version and language provided.
 * We have the following asset libraries: templates, translators, icons and actions.
 * @param name
 * @param version
 * @param lang
 * @param assetLibrary
 */
export function getNotificationAsset(
	{ name, version, lang }: IGetNotificationAsset,
	assetLibrary: INotificationLibraryAsset,
) {
	try {
		const [primaryKey, secondaryKey] = name.split('.');
		const languageKey = lang || 'en';
		const versionKey = `version_${version}`;
		const { type } = assetLibrary;

		switch (type) {
			case 'template':
				return assetLibrary[primaryKey][secondaryKey][languageKey][versionKey];
			case 'translator':
				return assetLibrary[primaryKey][secondaryKey][languageKey][versionKey];
			case 'icon':
				return assetLibrary[primaryKey][secondaryKey];
			case 'link':
				return assetLibrary[primaryKey][secondaryKey];
			default:
				return null;
		}
	} catch (e) {
		return null;
	}
}

/**
 * Returns a function that replaces relevant enums, binaries and similar attributes with a human-readable text.
 * @param name
 * @param version
 * @param lang
 * @param translatorLibrary
 */
export function getNotificationTranslator(
	{ name, version, lang }: IGetNotificationAsset,
	translatorLibrary: INotificationLibraryAsset = translators,
): (data: any) => any {
	const translator = getNotificationAsset({ name, version, lang }, translatorLibrary);
	return translator || ((data: any) => data);
}

/**
 * Returns the text template of a notification based on the notification provided by the backend.
 * @param name
 * @param version
 * @param lang
 * @param templateLibrary
 */
export function getTextTemplate(
	{ name, version, lang }: IGetNotificationAsset,
	templateLibrary: INotificationLibraryAsset = templates,
): string {
	const template = getNotificationAsset({ name, version, lang }, templateLibrary);
	return template || '';
}

/**
 * Returns the icon of a notification based on the notification provided by the backend.
 * @param notification
 * @param iconLibrary
 */
export function getNotificationIcon(
	{ name, version }: IGetNotificationAsset,
	iconLibrary: INotificationLibraryAsset = icons,
): FunctionComponent<IconProps> {
	const icon = getNotificationAsset({ name, version }, iconLibrary);
	return icon || IconBellOutlined;
}

/**
 * Returns the link template of a notification based on the notification provided by the backend.
 * @param name
 * @param version
 * @param linkTemplateLibrary
 * @param iconLibrary
 */
export function getNotificationLinkTemplate(
	{ name, version }: IGetNotificationAsset,
	linkTemplateLibrary: INotificationLibraryAsset = linkTemplates,
): string {
	const linkTemplate = getNotificationAsset({ name, version }, linkTemplateLibrary);
	return linkTemplate || '';
}

/**
 * Returns a rendered text based on the template and data provided.
 * This a wrapper around the mustache render function in order to provided interoperability.
 * @param template
 * @param data
 */
export function renderTextTemplate(template: string, data: any) {
	return Moustache.render(template, data);
}

/**
 * Returns the text of a notification based on the notification provided by the backend.
 * @param notification
 * @param lang
 */
export function getNotificationText(notification: INotification, lang?: TTemplateLanguage) {
	const { name, version } = notification.type;
	const template = getTextTemplate({ name, version, lang });
	if (!template) {
		return '';
	}
	const translator = getNotificationTranslator({ name, version, lang });
	const data = translator(notification.data);

	return renderTextTemplate(template, data);
}

/**
 * Returns the link of a notification based on the notification provided by the backend.
 * Not all notifications have a link.
 * @param notification
 * @param lang
 */
export function getNotificationLink(notification: INotification, lang?: TTemplateLanguage) {
	const { name, version } = notification.type;
	const template = getNotificationLinkTemplate({ name, version });
	if (!template) {
		return '';
	}
	const translator = getNotificationTranslator({ name, version, lang });
	const data = translator(notification.data);

	return renderTextTemplate(template, data);
}
