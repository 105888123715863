import { RequestStatus } from '@montel/montelpro-shared-components/enums';
import { createSlice } from '@reduxjs/toolkit';
import { Order } from '../../../common/interfaces';
import { getCompanyUntradedOrderHistory } from '../ordersThunks';
import { translateApiOrders } from '../utils/translateApiOrder';

export interface ICompanyUntradedOrderHistoryState {
	orders: Order[];
	status: RequestStatus;
	error?: any;
}

export const initialState: ICompanyUntradedOrderHistoryState = {
	orders: [],
	status: RequestStatus.UNLOADED,
	error: undefined,
};

export const companyUntradedOrderHistorySlice = createSlice({
	name: 'companyUntradedOrderHistory',
	initialState,
	reducers: {
		companyOrderExpired: (state, { payload: order }) => {
			state.orders.push(order);
		},
		companyOrderCancelled: (state, { payload: order }) => {
			state.orders.push(order);
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getCompanyUntradedOrderHistory.pending, (state) => {
				state.status = RequestStatus.LOADING;
				state.error = undefined;
			})
			.addCase(getCompanyUntradedOrderHistory.rejected, (state, { error }) => {
				state.status = RequestStatus.ERROR;
				state.error = error;
			})
			.addCase(getCompanyUntradedOrderHistory.fulfilled, (state, { payload }) => {
				state.orders = translateApiOrders(payload);
				state.status = RequestStatus.SUCCESS;
				state.error = undefined;
			});
	},
});

export const { companyOrderExpired, companyOrderCancelled } = companyUntradedOrderHistorySlice.actions;

export default companyUntradedOrderHistorySlice.reducer;
