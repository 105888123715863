import { Dispatch, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import { DropdownButton, IconEye, IconSettings, Text } from '@montel/montelpro-shared-components-v2';
import { StyledKebabMenu } from './NotificationsCenter.style';

type Props = {
	setKebabOpen: Dispatch<SetStateAction<boolean>>;
	markAllNotificationsAsSeen: () => void;
	closeNotificationMenu: () => void;
};

const KebabMenu = ({ setKebabOpen, markAllNotificationsAsSeen, closeNotificationMenu }: Props) => {
	const navigate = useNavigate();

	const onCloseKebab = () => {
		setKebabOpen(false);
	};

	return (
		<StyledKebabMenu closeMenu={onCloseKebab} topPosition="2rem">
			<DropdownButton
				onClick={() => {
					markAllNotificationsAsSeen();
					onCloseKebab();
				}}
			>
				<IconEye />
				<Text size="xs">Mark all as read</Text>
			</DropdownButton>
			<DropdownButton
				onClick={() => {
					navigate('/notification-settings');
					closeNotificationMenu();
				}}
			>
				<IconSettings />
				<Text size="xs">Notifications settings</Text>
			</DropdownButton>
		</StyledKebabMenu>
	);
};

export default KebabMenu;
