const parseQueryString = (search: string): any => {
	if (!search) {
		return {};
	}
	const object = JSON.parse(
		'{"' + decodeURI(search.substring(1)).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}',
	);
	return object;
};

export default parseQueryString;
