import { MarketRoleEnum } from '../MarketRoleEnum';

export const marketRoleNames: { [key in keyof typeof MarketRoleEnum]: string } = {
	Default: 'Default',
	Corporate: 'Corporate',
	Portfolio: 'Portfolio',
	Trading: 'Trading',
	PowerGenerator: 'Power Generator',
	Broker: 'Broker',
	Bank: 'Bank',
	Investment: 'Investment',
	Distribution: 'Distribution',
	Other: 'Other',
};
