import { FunctionComponent } from 'react';
import { styled } from 'styled-components';

const StyledNoData = styled.div`
	width: 100%;
	padding: ${({ theme }) => theme.spacing.lg};
	display: flex;
	justify-content: center;
	align-items: center;
	color: ${({ theme }) => theme.colors.secondaryText};
	font-style: italic;
`;

export const NoData: FunctionComponent<{ children: React.ReactNode }> = ({ children }) => {
	return <StyledNoData>{children}</StyledNoData>;
};
