import { FunctionComponent, useState } from 'react';
import { NoData, SelectOptionType } from '@montel/montelpro-shared-components';
import { RequestStatus } from '@montel/montelpro-shared-components/enums';
import { useGetOnMount } from '@montel/montelpro-shared-components/hooks';
import { Button, H2, Select, Text, VStack } from '@montel/montelpro-shared-components-v2';
import { USER_COMPANY_API_ROUTE } from 'apiRoutes';
import { UserCompany } from 'common/interfaces/Company';
import useSetPageTitle from 'hooks/useSetPageTitle';
import { Suspense } from 'components/Suspense';
import getOptions from './getOptions';
import { StyledJoinCompany } from './JoinCompany.style';
import useCancelJoinCompany from './useCancelJoinCompany';
import useNewPendingCompanies from './useNewPendingCompanies';
import useSendJoinRequest from './useSendJoinRequest';
const MIN_NUMBER_CHARS_TO_SEARCH = 2;

const JoinCompany: FunctionComponent = () => {
	useSetPageTitle('Join Company');
	const { data, error, status } = useGetOnMount(USER_COMPANY_API_ROUTE);
	const allCompanies = (data || []) as UserCompany[];
	const [chosenCompany, setChosenCompany] = useState<SelectOptionType | null>(null);

	const { newPendingCompanies, addNewPendingCompany, removeNewPendingCompany } = useNewPendingCompanies();
	const { cancelJoinCompany } = useCancelJoinCompany(removeNewPendingCompany);
	const { sendJoinRequest, showValidationError, submitStatus } = useSendJoinRequest(
		chosenCompany,
		setChosenCompany,
		addNewPendingCompany,
		cancelJoinCompany,
	);

	const selectOptions = getOptions(allCompanies, newPendingCompanies);
	const [isSelectMenuOpen, setIsSelectMenuOpen] = useState(false);

	return (
		<StyledJoinCompany>
			<VStack gap="xl">
				<H2>Join Company</H2>
				<Suspense status={status} error={error}>
					<VStack gap="xl">
						{selectOptions.length ? (
							<>
								<Text>
									If your company is already registered on Marketplace, you will see it listed below.
									When you send a request to join a company, it will be subject to approval by a
									company administrator.
								</Text>
								<Select
									label="Choose your company"
									placeholder="Start typing to search for company"
									options={selectOptions}
									onChange={setChosenCompany}
									disabled={submitStatus === RequestStatus.LOADING}
									value={chosenCompany}
									errorMessage={showValidationError && !chosenCompany && 'This field is required'}
									filterOption={filterAfterMinNumberChars}
									menuIsOpen={isSelectMenuOpen}
									isClearable
									onInputChange={(input: string) =>
										setIsSelectMenuOpen(input.length >= MIN_NUMBER_CHARS_TO_SEARCH)
									}
									components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
								/>
								<Button
									text="Join as employee"
									isLoading={submitStatus === RequestStatus.LOADING}
									onClick={sendJoinRequest}
								/>
							</>
						) : (
							<NoData mainText={`No companies to join`} />
						)}
					</VStack>
				</Suspense>
			</VStack>
		</StyledJoinCompany>
	);
};

const filterAfterMinNumberChars = (option: SelectOptionType, input: string) => {
	if (input.length < MIN_NUMBER_CHARS_TO_SEARCH) return false;
	return option.label.toLowerCase().includes(input.toLowerCase());
};

export default JoinCompany;
