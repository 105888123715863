import { createColumnHelper } from '@tanstack/react-table';
import { Currency } from 'common/enums/CurrencyEnum';
import { QuoteStatusEnum } from 'common/enums/quoteStatusEnum';
import { getRegionName } from 'common/enums/valueLookups/RegionLookup';
import { Quote } from 'common/interfaces/Quote';
import moment from 'moment';
import { sortingProductionOrder } from 'components/OrderTables/sorting';
import { NumericCell, SortableHeadCell, TextCell } from 'components/Tables/Cells';
import { toTitleCase } from '../../../utils/string-extension';
import { getTypeOfQuote } from '../../OrderDetails/PlaceQuoteForm/getTypeOfQuote';
import { OwnerIconCell, TechCell } from '../../OrderTables/Cells';
import ProductionCell from '../../OrderTables/Cells/ProductionPeriodCell';
import sortingTech from '../../OrderTables/sorting/sortingTech';
import QuoteExpiryCell from '../Cells/QuoteExpiryCell';
import QuoteStatusCell from '../Cells/QuoteStatusCell';
import {
	DeliveryColumnProps,
	ExpiryColumnProps,
	OwnerColumnProps,
	PriceColumnProps,
	ProductionPeriodColumnProps,
	QuantityColumnProps,
	QuoteStatusColumnProps,
	RegionColumnProps,
	TechColumnProps,
	TotalPriceColumnProps,
	TypeColumnProps,
} from './quoteColumnTypes';

const columnHelper = createColumnHelper<Quote>();

function isCellDisabled(status: QuoteStatusEnum) {
	return status === QuoteStatusEnum.Cancelled || status === QuoteStatusEnum.Expired;
}

export const quoteTableColumns = {
	productionPeriod: (overrideProps?: ProductionPeriodColumnProps) =>
		columnHelper.accessor((row) => row.production.toString(), {
			id: 'productionPeriod',
			enableSorting: true,
			header: ({ header }) => (
				<SortableHeadCell text="Production" header={header} {...overrideProps?.headCellProps} />
			),
			cell: ({ row }) => (
				<ProductionCell
					disabled={isCellDisabled(row.original.status)}
					production={row.original.production}
					{...overrideProps?.cellProps}
				/>
			),
			sortingFn: (a, b) => sortingProductionOrder(a.original.production, b.original.production),
			...overrideProps?.columnDefProps,
		}),
	expiry: (overrideProps?: ExpiryColumnProps) =>
		columnHelper.accessor((row) => row.expiryTime, {
			id: 'expiryTime',
			enableSorting: true,
			header: ({ header }) => (
				<SortableHeadCell
					text="Bid expires"
					justify="flex-end"
					header={header}
					{...overrideProps?.headCellProps}
				/>
			),
			cell: (info) => (
				<QuoteExpiryCell
					status={info.row.original.status}
					expiryTimestamp={new Date(info.getValue()).getTime()}
					{...overrideProps?.cellProps}
				/>
			),
			...overrideProps?.columnDefProps,
		}),
	tech: (overrideProps?: TechColumnProps) =>
		columnHelper.accessor((row) => row.tech, {
			id: 'tech',
			enableSorting: true,
			sortingFn: (a, b) => sortingTech(a.original.tech, b.original.tech),
			header: ({ header }) => <SortableHeadCell text="Tech" header={header} {...overrideProps?.headCellProps} />,
			cell: (info) => (
				<TechCell
					disabled={isCellDisabled(info.row.original.status)}
					tech={info.getValue()}
					numberOfTechToShow={3}
					{...overrideProps?.cellProps}
				/>
			),
			...overrideProps?.columnDefProps,
		}),
	ownerIcon: (overrideProps?: OwnerColumnProps) =>
		columnHelper.accessor((row) => row.isMyQuote, {
			id: 'ownerIcon',
			enableSorting: true,
			header: ({ header }) => <SortableHeadCell text="" header={header} {...overrideProps?.headCellProps} />,
			cell: ({ row }) => (
				<OwnerIconCell
					order={row.original}
					disabled={isCellDisabled(row.original.status)}
					{...overrideProps?.cellProps}
				/>
			),
			...overrideProps?.columnDefProps,
		}),

	region: (overrideProps?: RegionColumnProps) =>
		columnHelper.accessor((row) => getRegionName(row.region), {
			id: 'region',
			enableSorting: true,
			header: ({ header }) => (
				<SortableHeadCell text="Region" header={header} {...overrideProps?.headCellProps} />
			),
			cell: (info) => (
				<TextCell
					disabled={isCellDisabled(info.row.original.status)}
					text={info.getValue()}
					{...overrideProps?.cellProps}
				/>
			),
			...overrideProps?.columnDefProps,
		}),
	delivery: (overrideProps?: DeliveryColumnProps) =>
		columnHelper.accessor((row) => row.deliveryTime, {
			id: 'delivery',
			enableSorting: true,
			header: ({ header }) => (
				<SortableHeadCell text="Delivery (UTC)" header={header} {...overrideProps?.headCellProps} />
			),
			cell: (info) => (
				<TextCell
					text={info.getValue() ? moment(info.getValue()).format('DD MMM YYYY') : 'Spot'}
					disabled={isCellDisabled(info.row.original.status)}
					{...overrideProps?.cellProps}
				/>
			),
			...overrideProps?.columnDefProps,
		}),
	quantity: (overrideProps?: QuantityColumnProps) =>
		columnHelper.accessor((row) => row.quantity, {
			id: 'quantity',
			enableSorting: true,
			header: ({ header }) => (
				<SortableHeadCell
					text="Quantity"
					header={header}
					justify="flex-end"
					{...overrideProps?.headCellProps}
				/>
			),
			cell: (info) => (
				<NumericCell
					value={info.getValue()}
					unit={'MWh'}
					decimalPlaces={0}
					align="flex-end"
					disabled={isCellDisabled(info.row.original.status)}
					{...overrideProps?.cellProps}
				/>
			),
			...overrideProps?.columnDefProps,
		}),
	price: (overrideProps?: PriceColumnProps) =>
		columnHelper.accessor((row) => row.price, {
			id: 'price',
			enableSorting: true,
			header: ({ header }) => (
				<SortableHeadCell
					text="Unit price"
					header={header}
					justify="flex-end"
					{...overrideProps?.headCellProps}
				/>
			),
			cell: (info) => (
				<NumericCell
					value={info.getValue()}
					unit={Currency[info.row.original.currency]}
					disabled={isCellDisabled(info.row.original.status)}
					align="flex-end"
					{...overrideProps?.cellProps}
				/>
			),
			...overrideProps?.columnDefProps,
		}),
	totalPrice: (overrideProps?: TotalPriceColumnProps) =>
		columnHelper.accessor(() => 'totalPrice', {
			id: 'totalPrice',
			enableSorting: true,
			header: ({ header }) => (
				<SortableHeadCell
					text="Total price"
					header={header}
					justify="flex-end"
					{...overrideProps?.headCellProps}
				/>
			),
			cell: ({ row }) => (
				<NumericCell
					value={row.original.price * row.original.quantity}
					unit={Currency[row.original.currency]}
					align="flex-end"
					decimalPlaces={2}
					disabled={isCellDisabled(row.original.status)}
					{...overrideProps?.cellProps}
				/>
			),
			...overrideProps?.columnDefProps,
		}),
	status: (overrideProps?: QuoteStatusColumnProps) =>
		columnHelper.accessor((row) => row.status, {
			id: 'status',
			enableSorting: true,
			header: ({ header }) => (
				<SortableHeadCell
					text="Status"
					justify="flex-start"
					header={header}
					{...overrideProps?.headCellProps}
				/>
			),
			cell: ({ row }) => (
				<QuoteStatusCell
					disabled={isCellDisabled(row.original.status)}
					quote={row.original}
					{...overrideProps?.cellProps}
				/>
			),
			...overrideProps?.columnDefProps,
		}),
	type: (overrideProps?: TypeColumnProps) =>
		columnHelper.accessor((row) => getTypeOfQuote(row.side), {
			id: 'type',
			header: ({ header }) => (
				<SortableHeadCell text="Bid / Offer" header={header} {...overrideProps?.headCellProps} />
			),
			cell: (info) => (
				<TextCell
					disabled={isCellDisabled(info.row.original.status)}
					text={toTitleCase(info.getValue())}
					{...overrideProps?.cellProps}
				/>
			),
			...overrideProps?.columnDefProps,
		}),
};
