import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { MontelThemeProvider, themes } from '@montel/montelpro-shared-components-v2';
import App from 'App';
import isMockingEnabled from 'utils/environment/isMockingEnabled';
import enableMocking from 'utils/mocking/enableMocking';
import { store } from './store/store';

if (isMockingEnabled()) {
	enableMocking().then(() => renderApp());
} else {
	renderApp();
}

function renderApp() {
	const container = document.getElementById('root');
	if (container) {
		const root = createRoot(container);
		root.render(
			<MontelThemeProvider theme={themes.dark}>
				<Provider store={store}>
					<App />
				</Provider>
			</MontelThemeProvider>,
		);
	}
}
