import { useState } from 'react';
import { MARKETPLACE_ALERTS_API_ROUTE } from '../../../apiRoutes';
import { Alert } from '../../../common/interfaces/Alert';
import useSubmit, { Method, POST, PUT } from '../../../hooks/useSubmit';
import { getValidationErrors } from './utils/getValidationErrors';
import { toCreateAlertRequest } from './utils/toCreateAlertRequest';
import { toUpdateAlertRequest } from './utils/toUpdateAlertRequest';
import { TAlertEditState } from './types';

export interface UseSaveAlertProps {
	alert: Alert | null;
	state: TAlertEditState;
	onSaveAlert: (alert: Alert) => void;
	onClose: () => void;
}

const useSaveAlert = ({ alert, state, onSaveAlert, onClose }: UseSaveAlertProps) => {
	const [isSubmitPressed, setIsSubmitPressed] = useState(false);
	const errors = getValidationErrors(state);
	const isValid = Object.values(errors).every((value) => !value);

	const operation = alert
		? {
				url: `${MARKETPLACE_ALERTS_API_ROUTE}/UpdateAlert`,
				method: PUT,
				successMessage: 'Alert has been updated.',
		  }
		: {
				url: `${MARKETPLACE_ALERTS_API_ROUTE}/CreateAlert`,
				method: POST,
				successMessage: 'Alert has been created.',
		  };

	const [submit, status] = useSubmit(operation.url, operation.method as Method, {
		messageOnSuccess: operation.successMessage,
		messageOnError: 'An error occured, please try again!',
		showApiError: true,
	});

	const onSubmit = async () => {
		setIsSubmitPressed(true);
		if (!isValid) return;

		const request = alert ? toUpdateAlertRequest(state) : toCreateAlertRequest(state);

		await submit(request)
			.then((response) => {
				if (!response.success) return;
				onSaveAlert(response.data);
			})
			.finally(() => {
				onClose();
			});
	};

	return {
		onSubmit,
		isSubmitPressed,
		setIsSubmitPressed,
		status,
		errors,
	};
};

export default useSaveAlert;
