import { FunctionComponent } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { RouterProvider } from 'react-router-dom';
import { createGlobalStyle, useTheme } from 'styled-components';
import { CloseToastButton, StyledToastContainer, ToastIcon } from 'components/Toasts';
import router from './router';
import 'react-toastify/dist/ReactToastify.css';

const GlobalStyle = createGlobalStyle`
  body {
	min-height: 100vh;
	margin: 0;
	padding: 0;
	scroll-behavior: smooth;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeSpeed;
    background: ${({ theme }) => theme.colors.background};
  }

  body, button, select, input, textarea, code, tt, pre {
	font-family: ${({ theme }) => theme.typography.family.sansSerif};
  }
`;

const App: FunctionComponent = () => {
	const { typography } = useTheme();

	return (
		<HelmetProvider>
			<GlobalStyle />
			<Helmet>
				<title>Marketplace | Montel</title>
				<meta name="description" content="Montel Marketplace application" />
				<link href={typography.fontSource} rel="stylesheet" />
			</Helmet>
			<RouterProvider router={router} />
			<StyledToastContainer
				position="bottom-right"
				hideProgressBar
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				icon={ToastIcon}
				closeButton={CloseToastButton}
			/>
		</HelmetProvider>
	);
};

export default App;
