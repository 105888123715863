import { FunctionComponent } from 'react';
import { Text, TSizes, VStack } from '@montel/montelpro-shared-components-v2';

const LabelAndValue: FunctionComponent<{ label: string; value?: any; size?: TSizes | string }> = ({
	label,
	value,
	size = 'xs',
}) => (
	<VStack fullWidth gap="xxxs">
		<Text size={size} color={'secondaryText'}>
			{label}
		</Text>
		<Value value={value} size={size} />
	</VStack>
);

const Value: FunctionComponent<{ value?: any; size?: TSizes | string }> = ({ value, size }) => {
	if (!value) {
		return (
			<Text size={size} color={'primaryText'}>
				-
			</Text>
		);
	}
	if (typeof value === 'string') {
		return (
			<Text size={size} color={'primaryText'}>
				{value}
			</Text>
		);
	}
	//div to group if multiple elements
	return <div>{value}</div>;
};

export default LabelAndValue;
