import { createColumnHelper } from '@tanstack/react-table';
import { Trade } from 'common/interfaces/Trade';
import moment from 'moment';
import { getTransaction } from 'components/OrderTables/helpers';
import { SortableHeadCell, TextCell } from 'components/Tables/Cells';
import TradeStatusCell from '../Cells/OrderStatusCell/TradeStatusCell';
import {
	CounterpartyColumnProps,
	TraderColumnProps,
	TradeStatusColumnProps,
	TradeTimeColumnProps,
	TransactionColumnProps,
} from './tradeColumnTypes';

const columnHelper = createColumnHelper<Trade>();

export const tradeTableColumns = {
	tradeTime: (overrideProps?: TradeTimeColumnProps) =>
		columnHelper.accessor('tradeTime', {
			id: 'tradeTime',
			enableSorting: true,
			header: ({ header }) => (
				<SortableHeadCell text="Trading time" header={header} {...overrideProps?.headCellProps} />
			),
			cell: (info) => (
				<TextCell text={moment(info.getValue()).format('DD MMM YY HH:mm')} {...overrideProps?.cellProps} />
			),
			...overrideProps?.columnDefProps,
		}),

	transaction: (overrideProps?: TransactionColumnProps) =>
		columnHelper.accessor((row) => getTransaction(row.isMyCompanysOrder, row.side), {
			id: 'transaction',
			enableSorting: true,
			header: ({ header }) => (
				<SortableHeadCell text="Transaction" header={header} {...overrideProps?.headCellProps} />
			),
			cell: (info) => <TextCell text={info.getValue()} {...overrideProps?.cellProps} />,
			...overrideProps?.columnDefProps,
		}),

	counterparty: (overrideProps?: CounterpartyColumnProps) =>
		columnHelper.accessor('counterpartyCompanyName', {
			id: 'counterparty',
			enableSorting: true,
			header: ({ header }) => (
				<SortableHeadCell text="Counterparty" header={header} {...overrideProps?.headCellProps} />
			),
			cell: (info) => <TextCell truncateAt={12} text={info.getValue() ?? '-'} {...overrideProps?.cellProps} />,
			...overrideProps?.columnDefProps,
		}),

	trader: (overrideProps?: TraderColumnProps) =>
		columnHelper.accessor('myCompanyTradersName', {
			id: 'trader',
			enableSorting: true,
			header: ({ header }) => (
				<SortableHeadCell text="Trader" header={header} {...overrideProps?.headCellProps} />
			),
			cell: (info) => <TextCell text={info.getValue() ?? '-'} {...overrideProps?.cellProps} />,
			...overrideProps?.columnDefProps,
		}),

	tradeStatus: (overrideProps?: TradeStatusColumnProps) =>
		columnHelper.accessor('tradeStatus', {
			id: 'tradeStatus',
			enableSorting: true,
			header: ({ header }) => (
				<SortableHeadCell text="Status" header={header} {...overrideProps?.headCellProps} />
			),
			cell: (info) => <TradeStatusCell trade={info.row.original} {...overrideProps?.cellProps} />,
			...overrideProps?.columnDefProps,
		}),
};
