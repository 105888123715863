import { FunctionComponent, useState } from 'react';
import { Button } from '@montel/montelpro-shared-components-v2';
import AcceptUserModal from './AcceptUserModal';

const AcceptButton: FunctionComponent<{ userId: string; userName: string }> = ({ userId, userName }) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	return (
		<>
			<Button text="Accept" onClick={() => setIsModalOpen(true)} />
			{isModalOpen && (
				<AcceptUserModal
					isOpen={isModalOpen}
					onClose={() => setIsModalOpen(false)}
					userId={userId}
					userName={userName}
				/>
			)}
		</>
	);
};

export default AcceptButton;
