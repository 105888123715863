import { FunctionComponent } from 'react';
import { VStack } from '@montel/montelpro-shared-components-v2';
import AllCompanyUsers from './AllCompanyUsers/AllCompanyUsers';
import RequestsToJoin from './RequestsToJoin/RequestsToJoin';
import UsersProvider from './UsersProvider';

const Users: FunctionComponent = () => {
	return (
		<VStack gap="xl" fullWidth>
			<UsersProvider>
				<RequestsToJoin />
				<AllCompanyUsers />
			</UsersProvider>
		</VStack>
	);
};

export default Users;
