import { css, styled } from 'styled-components';
import Td from '../Tables/Td';

export const ScrollableContainer = styled.div<{ $showScroll: boolean; $maxHeight: string }>`
	padding-right: 0.5rem;

	${({ $showScroll, $maxHeight }) => {
		return $showScroll
			? `
	max-height: ${$maxHeight};
	overflow-y: auto;
			`
			: '';
	}};
`;

export const StyledQuotesTable = styled.table`
	table-layout: fixed;
	width: 100%;
	border-collapse: collapse;
	thead th {
		position: sticky;
		top: 0;
		${({ theme }) => css`
			background-color: ${theme.colors.widget};
			z-index: ${theme.zIndices.modal};
		`};
	}
`;

export const StyledHeadRow = styled.tr`
	text-align: left;
	vertical-align: middle;
	${({ theme }) => `
		padding-bottom: ${theme.spacing.sm};
		height: ${theme.spacing.xxl};
	`};
`;

export const StyledTableRow = styled.tr<{ $clickable?: boolean }>`
	${({ $clickable }) => $clickable && `cursor: pointer;`}
	&:hover {
		background-color: ${({ theme }) => theme.colors.element};
		td:first-child {
			border-radius: ${({ theme }) =>
				css`
					${theme.borderRadii.sm} 0 0 ${theme.borderRadii.sm}
				`};
		}
		td:last-child {
			border-radius: ${({ theme }) => css`0 ${theme.borderRadii.sm} ${theme.borderRadii.sm} 0`};
		}
	}
`;

export const StyledTableData = styled(Td)<{ $width?: string }>`
	padding: 0.25rem;
	${({ $width }) => $width && `width: ${$width};`}
`;
