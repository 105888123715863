import { UserRole } from 'common/enums/UserRoleEnum';
import { userRoleNames } from 'common/enums/valueLookups/UserRoleLookup';

export const getBackOfficeUserRoleOptions = (): { label: string; value: UserRole }[] => {
	return [
		{ value: UserRole.BackOffice, label: userRoleNames[UserRole.BackOffice] },
		{ value: UserRole.Trader, label: userRoleNames[UserRole.Trader] },
		{ value: UserRole.ReadOnly, label: userRoleNames[UserRole.ReadOnly] },
	];
};

export const getTradeManagerUserRoleOptions = (): {
	label: string;
	value: UserRole;
}[] => {
	return [
		...getBackOfficeUserRoleOptions(),
		{ value: UserRole.TradeManager, label: userRoleNames[UserRole.TradeManager] },
	];
};
