import { FunctionComponent } from 'react';
import { QuoteStatusEnum } from 'common/enums/quoteStatusEnum';
import { useOrderDetailsContext } from 'components/OrderDetails/OrderDetailsContext';
import { ActionButton } from '../OrderDetailsBody.styles';

interface FixedPriceOwnerActionsProps {
	setShowCancel: (value: boolean) => void;
}

const RFQOwnerActions: FunctionComponent<FixedPriceOwnerActionsProps> = ({ setShowCancel }) => {
	const { order, isExpired } = useOrderDetailsContext();
	const hasAcceptedQuotes = order.quotes.some((quote) => quote.status === QuoteStatusEnum.Accepted);

	return (
		<ActionButton
			isDisabled={isExpired}
			variant="negative"
			text={hasAcceptedQuotes ? 'Close order' : 'Cancel order'}
			onClick={() => setShowCancel(true)}
		/>
	);
};

export default RFQOwnerActions;
