import { FunctionComponent } from 'react';
import { Center, Text, TSizes } from '@montel/montelpro-shared-components-v2';
import { QuoteStatusEnum } from '../../../../common/enums/quoteStatusEnum';
import { getMsAsDays, getRemainingTime } from '../../../Progress';
import CountdownWithProgress from './CountdownWithProgress';

export interface QuoteExpiryCellPops {
	status: QuoteStatusEnum;
	/**
	 * Expiration date timestamp in milliseconds, UTC.
	 */
	expiryTimestamp: number;
	/**
	 * Callback function that is called when the countdown expires.
	 */
	onExpiry?: () => void;
	/**
	 * Font size of the countdown text.
	 */
	countdownFontSize?: TSizes;
	/**
	 * Align counter horizontally.
	 */
	justify?: 'flex-start' | 'center' | 'flex-end';
}

const QuoteExpiryCell: FunctionComponent<QuoteExpiryCellPops> = ({
	status,
	expiryTimestamp,
	onExpiry,
	countdownFontSize = 'sm',
	justify,
}) => {
	const isNotActive = status !== QuoteStatusEnum.Active || expiryTimestamp < Date.now();
	const remainingDays = getMsAsDays(getRemainingTime(expiryTimestamp));
	const showDays = remainingDays > 0;

	if (isNotActive) {
		return (
			<Center>
				<Text color="secondaryText">-</Text>
			</Center>
		);
	}

	return (
		<>
			{showDays ? (
				<Center>{`${remainingDays} day${remainingDays > 1 ? 's' : ''}`}</Center>
			) : (
				<CountdownWithProgress
					countdownFontSize={countdownFontSize}
					expiryTimestamp={expiryTimestamp}
					onExpiry={onExpiry}
					justify={justify}
				/>
			)}
		</>
	);
};

export default QuoteExpiryCell;
