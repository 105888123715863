import { FunctionComponent } from 'react';
import { getOptionByValue } from '@montel/montelpro-shared-components/utils';
import { Select, SelectOptionType, TextArea, VStack } from '@montel/montelpro-shared-components-v2';
import getRegistryOperatorOptions from 'common/options/getRegistryOperatorOptions';
import { filterLastProductionPeriod } from 'utils/orderUtils/filterLastProductionPeriod';
import FormFooter from '../../Footer';
import { getMinimumValidityOptions } from '../../options';
import { useOrderFormContext } from '../../OrderFormProvider';
import { EqualColumnsContainer } from '../ProductStep/ProductStep.styles';
import { ProductionPeriodOption } from '../ProductStep/ProductStep.types';
import DeliveryPeriod from './DeliveryPeriod';
import { DeliverySchema, fields } from './DeliveryStep.types';

const DeliveryStep: FunctionComponent<{ onProgress: () => void; onBack: () => void }> = ({ onProgress, onBack }) => {
	const { formState, setFormState, errors, validate, validateSingle } = useOrderFormContext();

	const { registryOperator, minimumValidity, comment } = formState.delivery;
	const { productionPeriods } = formState.product;
	const lastProductionPeriod =
		productionPeriods && filterLastProductionPeriod(productionPeriods as ProductionPeriodOption[]);

	const minimumValidityOptions = getMinimumValidityOptions();

	const validateAndProgress = () => {
		const result = validate(DeliverySchema, { ...formState.product, ...formState.delivery });

		if (result.success) {
			onProgress();
		}
	};

	const onRegistryOperatorChange = (registryOperator: SelectOptionType) => {
		validateSingle(fields.registryOperator, registryOperator.value, 'registryOperator');
		setFormState({
			...formState,
			delivery: { ...formState.delivery, registryOperator: registryOperator.value },
		});
	};

	const onMinimumValidityChange = (validity: SelectOptionType) => {
		const numericValue = Number(validity.value);
		validateSingle(fields.minimumValidity, numericValue, 'validity');
		setFormState({
			...formState,
			delivery: { ...formState.delivery, minimumValidity: numericValue },
		});
	};

	const onCommentChange = (event: any) => {
		const comment = event.target.value;
		validateSingle(fields.comment, comment, 'comment');
		setFormState({
			...formState,
			delivery: { ...formState.delivery, comment: comment },
		});
	};

	return (
		<VStack gap="lg" align="initial" fullWidth>
			<DeliveryPeriod productionPeriod={lastProductionPeriod} />

			<EqualColumnsContainer>
				<Select
					label="Registry operator"
					required
					options={getRegistryOperatorOptions()}
					errorMessage={errors?.registryOperator && 'This field is required'}
					onChange={onRegistryOperatorChange}
					value={getOptionByValue(getRegistryOperatorOptions(true, true), registryOperator)}
				/>
				<Select
					label="Minimum validity (weeks)"
					placeholder="Select minimum validity"
					required
					onChange={onMinimumValidityChange}
					options={minimumValidityOptions}
					errorMessage={errors?.validity && errors.validity}
					value={getOptionByValue(minimumValidityOptions, minimumValidity)}
				/>
			</EqualColumnsContainer>
			<TextArea
				label="Comment"
				value={comment}
				onChange={onCommentChange}
				placeholder="Anything special to clarify?"
				resize="none"
				rows={4}
			/>
			<FormFooter onPrev={onBack} onNext={validateAndProgress} showPrev />
		</VStack>
	);
};

DeliveryStep.displayName = 'DeliveryStep';
export default DeliveryStep;
