import { FC } from 'react';
import { RequestStatus } from '@montel/montelpro-shared-components/enums';
import {
	ApiErrorDescription,
	ErrorAlert,
	HStack,
	Skeleton,
	TBorderRadii,
	TSizes,
	VStack,
} from '@montel/montelpro-shared-components-v2';
import { isError, isSuccessful } from '../Suspense/utils';

export interface ITableSkeletonConfig {
	/**
	 * Number of skeleton tables to render in a "row"
	 */
	numberOfTables: number;
	/**
	 * Number of skeleton items to render in a table
	 */
	numberOfItems: number;
}

export interface TableSkeletonProps {
	status: RequestStatus;
	error?: any;
	disabled?: boolean;
	children: any;
	w?: string;
	h?: string;
	borderRadius?: TSizes | TBorderRadii;
	/**
	 * Configuration for the skeleton tables. Each configuration object will render a row of skeleton tables.
	 */
	config?: ITableSkeletonConfig[];
}
const TableSkeleton: FC<TableSkeletonProps> = ({
	status,
	error = 'Failed to load data',
	disabled = false,
	children,
	w = '100%',
	h = '2.5rem',
	borderRadius = 'base',
	config = [{ numberOfTables: 1, numberOfItems: 11 }],
}) => {
	if (isSuccessful(status) || disabled) {
		return children;
	}

	if (isError(status)) {
		return (
			<ErrorAlert text="Try to reload the page">
				<ApiErrorDescription error={error} />
			</ErrorAlert>
		);
	}

	return (
		<VStack fullWidth wrap="nowrap" gap="md">
			{config.map(({ numberOfTables, numberOfItems }, idx) => (
				<HStack key={idx} fullWidth gap="md" wrap="nowrap">
					{Array.from(Array(numberOfTables).keys()).map((idx) => (
						<VStack key={idx} fullWidth wrap="nowrap" gap="0.5rem">
							<Skeleton w="133px" h={h} borderRadius={borderRadius} />
							{Array.from(Array(numberOfItems).keys()).map((idx) => (
								<Skeleton key={idx} w={w} h={h} borderRadius={borderRadius} />
							))}
						</VStack>
					))}
				</HStack>
			))}
		</VStack>
	);
};

export default TableSkeleton;
