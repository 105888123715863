import { FunctionComponent } from 'react';
import { StyledCell } from '../styles';
import LabelCancelled from './Labels/LabelCancelled';
import { StatusCellProps } from './types';
import { getCellProps, getStatusCellProps } from './utils';

const CancelledCell: FunctionComponent<StatusCellProps> = (props) => {
	const color = 'error';
	const styledCellProps = getCellProps(props);
	const { offsetLeft, offsetRight, ...restStyle } = styledCellProps;
	const { size, boxSize } = getStatusCellProps(props);

	return (
		<StyledCell $offsetLeft={offsetLeft} $offsetRight={offsetRight} {...restStyle}>
			<LabelCancelled color={color} boxSize={boxSize} size={size} />
		</StyledCell>
	);
};

export default CancelledCell;
