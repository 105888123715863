import styled, { css } from 'styled-components';

export const StyledHeadRow = styled.tr`
	text-align: left;
	vertical-align: middle;
	${({ theme }) => css`
		padding-bottom: ${theme.spacing.sm};
		height: ${theme.spacing.xxl};
		border-bottom: 1px solid ${theme.colors.element};
	`};
`;
