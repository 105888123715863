import { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { useAuth } from '@montel/montelpro-auth';
import { Text } from '@montel/montelpro-shared-components-v2';
import useSubmit, { POST } from 'hooks/useSubmit';
import { selectEmail } from 'store/user/userSelectors';
import { styled } from 'styled-components';

const StyledResendLink = styled(Text)`
	cursor: pointer;
	text-decoration: underline;
`;

const ResendVerificationLink: FunctionComponent<{ children: string }> = ({ children }) => {
	const { authRoutes } = useAuth();
	const email = useSelector(selectEmail);
	const [resendMail] = useSubmit(authRoutes.resendEmail(email), POST, {
		messageOnSuccess: 'New verification email sent',
		messageOnError:
			'Something went wrong, and we were unable to send a new verification email. If this issue persists, please contact our support team.',
	});

	return (
		<StyledResendLink as={'span'} onClick={() => resendMail(undefined)}>
			{children}
		</StyledResendLink>
	);
};

export default ResendVerificationLink;
