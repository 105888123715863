import { FunctionComponent, ReactNode } from 'react';
import { IconInfo } from '@montel/montelpro-shared-components-v2';
import { StyledIcon, StyledNotice } from './style';

/**
 * Notice component used to display information to the user.
 */
export const Notice: FunctionComponent<{
	children: ReactNode | ReactNode[];
}> = ({ children }) => (
	<StyledNotice>
		<StyledIcon>
			<IconInfo boxSize="19px" />
		</StyledIcon>
		{children}
	</StyledNotice>
);
