import React, { FunctionComponent } from 'react';
import { RequestStatus } from '@montel/montelpro-shared-components/enums';
import { Button } from '@montel/montelpro-shared-components-v2';
import { CounterpartyRelationStatus } from '../../../common/enums';
import { PublicCompanyInformation } from '../../../common/interfaces';
import { getCompanies } from '../../../store/companies/companiesThunks';
import { useAppDispatch } from '../../../store/hooks';
import Client, { TApiResponse } from '../api/Client';

export interface PrimaryActionButtonsProps {
	companyInformation: PublicCompanyInformation;
	refreshContent: () => void;
}

const PrimaryActionButtons: FunctionComponent<PrimaryActionButtonsProps> = ({ companyInformation, refreshContent }) => {
	const { id, counterpartyStatus, isInvitedByMyCompany } = companyInformation;

	const dispatch = useAppDispatch();

	const { useSendInvitation, useCancelInvitation, useAcceptInvitation, useRejectInvitation } = Client;

	const [sendInvitation, sendStatus] = useSendInvitation(id);
	const [cancelInvitation, cancelStatus] = useCancelInvitation(id);
	const [acceptInvitation, acceptStatus] = useAcceptInvitation(id);
	const [rejectInvitation, rejectStatus] = useRejectInvitation(id);

	const isAbleToConnect =
		counterpartyStatus !== CounterpartyRelationStatus.Approved &&
		counterpartyStatus !== CounterpartyRelationStatus.Pending;

	/* There is a pending invitation to connect */
	const isPending = counterpartyStatus === CounterpartyRelationStatus.Pending;

	/* If the invitation is initiated by us we can revoke it. */
	const isAbleToCancelInvitation = isPending && isInvitedByMyCompany;

	/* If it is an incoming invitation we can either accept or decline it. */
	const isAbleToAcceptInvitation = isPending && !isInvitedByMyCompany;

	return (
		<>
			{isAbleToConnect && (
				<Button
					text="Connect"
					onClick={async () => {
						await sendInvitation();
						refreshContent();
					}}
					isLoading={sendStatus === RequestStatus.LOADING}
					loadingText="Sending"
				/>
			)}
			{isAbleToCancelInvitation && (
				<Button
					onClick={async () => {
						await cancelInvitation();
						refreshContent();
					}}
					isLoading={cancelStatus === RequestStatus.LOADING}
					text="Cancel invitation"
					variant="secondary"
				/>
			)}
			{isAbleToAcceptInvitation && (
				<>
					<Button
						text="Accept invitation"
						onClick={async () => {
							const result = await acceptInvitation();

							if (result === true || (result as TApiResponse).success) {
								dispatch(getCompanies());
								refreshContent();
							}
						}}
						isLoading={acceptStatus === RequestStatus.LOADING}
					/>
					<Button
						text="Decline"
						onClick={async () => {
							await rejectInvitation();
							refreshContent();
						}}
						isLoading={rejectStatus === RequestStatus.LOADING}
						variant="secondary"
					/>
				</>
			)}
		</>
	);
};

export default PrimaryActionButtons;
