import { FunctionComponent } from 'react';
import Pagination from './Pagination';
import { useServerSideTableContext } from './ServerSideTableContext';

interface ServerSidePaginationProps {}

const ServerSidePagination: FunctionComponent<ServerSidePaginationProps> = ({}) => {
	const { pageIndex, setPageIndex, pageSize, setPageSize, pageCount } = useServerSideTableContext();

	return (
		<Pagination
			pageIndex={pageIndex}
			setPageIndex={setPageIndex}
			pageSize={pageSize}
			setPageSize={setPageSize}
			pageCount={pageCount}
		/>
	);
};

export default ServerSidePagination;
