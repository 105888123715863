import { INotificationLibraryAsset } from '../types';
import companyIcons from './company';
import counterpartyIcons from './counterparty';
import orderIcons from './order';
import systemNotificationIcons from './systemNotification';

const icons: INotificationLibraryAsset = {
	type: 'icon',
	company: { ...companyIcons },
	counterparty: { ...counterpartyIcons },
	order: { ...orderIcons },
	systemNotification: { ...systemNotificationIcons },
};

export default icons;
