import { Order } from 'common/interfaces/Order';
import { filterFirstProductionPeriod } from './filterFirstProductionPeriod';
import { isStrip } from './isStrip';

export const getAllProductionYears = (orderList: Order[]): number[] => {
	const productions = orderList.filter((order) => !isStrip(order)).map((order: Order) => order.product.production);
	const productionYears = productions.flatMap((prod) => filterFirstProductionPeriod(prod.periods).year);

	return Array.from(new Set(productionYears));
};
