import { FunctionComponent } from 'react';
import { HStack, Text } from '@montel/montelpro-shared-components-v2';
import NoChange from './IconChangeValue/NoChange';
import IconChangeValue from './IconChangeValue';
import { IEndOfDayRow } from './types';

export const translateData = (data: IEndOfDayRow[] | undefined) => {
	if (!data) return [];

	return data.map((item) => {
		const { productionYear, previous, current } = item;
		return {
			productionYear,
			previous: <PriceCell price={previous} />,
			change: <IconChangeValue currentValue={current} previousValue={previous} />,
			current: <PriceCell price={current} />,
			spacer: '',
		};
	});
};

const PriceCell: FunctionComponent<{ price: number | null }> = ({ price }) => (
	<HStack justify="flex-end" fullWidth>
		<Text>{price ? `${price.toDefaultNumberFormat()} EUR` : <NoChange />}</Text>
	</HStack>
);
