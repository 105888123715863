import { FunctionComponent } from 'react';
import { Checkbox } from '@montel/montelpro-shared-components-v2';
import { DropdownItemProps } from '@montel/montelpro-shared-components-v2';
import { useFilterContext } from '../filterContext';
import { StyledCheckBoxItem } from './style';

export interface CheckboxItemProps extends DropdownItemProps {
	value: number;
	onClick: (value: number, type: string) => void;
}

const CheckboxItem: FunctionComponent<CheckboxItemProps> = ({ label, value, onClick }) => {
	const { activeCategory, selectedOptions } = useFilterContext();
	const isSelected = selectedOptions[activeCategory]?.includes(value);

	return (
		<StyledCheckBoxItem
			onClick={() => {
				onClick(value, activeCategory);
			}}
		>
			<Checkbox
				label={label}
				value={value}
				checked={isSelected}
				onClick={(e: any) => e.stopPropagation()}
				onChange={() => null}
			/>
		</StyledCheckBoxItem>
	);
};

export default CheckboxItem;
