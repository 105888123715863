import { CounterpartyRelationStatus, FilterCategoryEnum, RelationEnum } from 'common/enums';
import { PublicCompanyInformation } from 'common/interfaces/Company';
import { toCamelCase } from 'utils/string-extension';
import useFilter from './useFilter';

const filterChoiceOptions = {
	Tech: [],
	TradingPosition: [],
	Country: [],
	Currency: [],
	Relation: [],
};

const filter = (member: PublicCompanyInformation, filters: any, key: string) => {
	if (!filters[key].length) return true;

	const selectedFilters = filters[key];

	switch (key) {
		case FilterCategoryEnum.Country:
		case FilterCategoryEnum.Region:
			return selectedFilters.includes(member.countryCode);

		case FilterCategoryEnum.Technology:
			return selectedFilters.every((value: number) => member.technologies.includes(value));

		case FilterCategoryEnum.Currency:
			return member.currencies.some((value: number) => selectedFilters.includes(value));

		case FilterCategoryEnum.Relation:
			return selectedFilters.some((value: string) => {
				switch (value) {
					case RelationEnum.Blocked:
						return member.isBlockedByMyCompany;

					case RelationEnum.Counterparty:
						return member.counterpartyStatus === CounterpartyRelationStatus.Approved;

					default:
						return false;
				}
			});

		default:
			return selectedFilters.includes((member as any)[toCamelCase(key)]);
	}
};

const customFilter = (items: any, filters: any) => {
	const filterKeys = Object.keys(filters);
	return items.filter((item: any) => filterKeys.every((key) => filter(item, filters, key)));
};

const useMembersFilter = () => {
	const {
		filterChoices,
		filterItems: filterMembers,
		handleFilterClick,
	} = useFilter({ defaultOptions: filterChoiceOptions, customFilter });

	return { filterChoices, filterMembers, handleFilterClick };
};

export default useMembersFilter;
