import { useState } from 'react';
import { ColumnDef, getCoreRowModel, getSortedRowModel, SortingState, useReactTable } from '@tanstack/react-table';

interface UseSortedTableProps<T> {
	data: Array<T>;
	columns: ColumnDef<T, any>[];
	initialSorting?: SortingState;
	enableSortingRemoval?: boolean;
}

export function useSortedTable<T>({
	data,
	columns,
	initialSorting,
	enableSortingRemoval = false,
}: UseSortedTableProps<T>) {
	const [sorting, setSorting] = useState<SortingState>(initialSorting ?? []);
	const table = useReactTable({
		data,
		columns,
		state: {
			sorting,
		},
		onSortingChange: setSorting,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		enableSortingRemoval: enableSortingRemoval,
	});
	return table;
}

export default useSortedTable;
