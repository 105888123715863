import { createSelector } from '@reduxjs/toolkit';
import { Side } from 'common/enums';
import { Order } from 'common/interfaces';
import { RootState } from 'store/store';
import { getAllProductionYears } from '../utils/getAllProductionYears';
import { isStrip } from '../utils/isStrip';

export const selectActiveOrdersState = (state: RootState) => state.orders.activeOrders;

export const selectActiveOrdersStatus = (state: RootState) => selectActiveOrdersState(state).status;

export const selectActiveOrdersError = (state: RootState) => selectActiveOrdersState(state).error;

export const selectActiveOrders = (state: RootState) => selectActiveOrdersState(state).orders;

export const selectMyCompanyActiveOrders = createSelector([selectActiveOrders], (orders) =>
	orders.filter((order: Order) => order.isMyCompanysOrder),
);

export const selectMyActiveOrders = createSelector([selectActiveOrders], (orders) =>
	orders.filter((order: Order) => order.isMyOrder),
);

export const selectMyAndCompanysActiveOrders = createSelector([selectMyActiveOrders], (orders) =>
	orders.filter((order: Order) => order.isMyCompanysOrder),
);

export const selectMyAndCompanysActiveBidOrders = createSelector([selectMyAndCompanysActiveOrders], (orders) =>
	orders.filter((order: Order) => order.side === Side.Bid),
);

export const selectMyAndCompanysActiveAskOrders = createSelector([selectMyAndCompanysActiveOrders], (orders) =>
	orders.filter((order: Order) => order.side === Side.Ask),
);

export const selectMyCompanysActiveBidOrders = createSelector([selectMyCompanyActiveOrders], (orders) =>
	orders.filter((order: Order) => order.side === Side.Bid),
);

export const selectMyCompanysActiveAskOrders = createSelector([selectMyCompanyActiveOrders], (orders) =>
	orders.filter((order: Order) => order.side === Side.Ask),
);

export const selectActiveSellOrders = createSelector([selectActiveOrders], (orders) =>
	orders.filter((order) => order.side === Side.Ask),
);

export const selectActiveBuyOrders = createSelector([selectActiveOrders], (orders) =>
	orders.filter((order) => order.side === Side.Bid),
);

export const selectActiveOrderStrips = createSelector([selectActiveOrders], (orders) =>
	orders.filter((order) => isStrip(order)),
);

export const selectActiveAskStrips = createSelector([selectActiveOrderStrips], (strips) =>
	strips.filter((strip) => strip.side === Side.Ask),
);

export const selectActiveBidStrips = createSelector([selectActiveOrderStrips], (strips) =>
	strips.filter((strip) => strip.side === Side.Bid),
);

export const selectActiveProductionYears = createSelector([selectActiveOrders], (orders) =>
	getAllProductionYears(orders),
);

export const selectMyCompanyProductionYears = createSelector([selectMyCompanyActiveOrders], (orders) =>
	getAllProductionYears(orders),
);

export const selectMyProductionYears = createSelector([selectMyActiveOrders], (orders) =>
	getAllProductionYears(orders),
);

export const selectOrderById = createSelector(
	[(state: RootState) => selectActiveOrders(state), (_: RootState, orderId: string) => orderId],
	(orders, orderId) => {
		if (!orderId) return undefined;
		return orders.find((order) => order.id === orderId);
	},
);
