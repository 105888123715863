import { ReactNode } from 'react';
import { HStack, Text } from '@montel/montelpro-shared-components-v2';
import { Tech } from 'common/enums/OrderEnums';
import { getTechIcon, getTechName } from 'common/enums/valueLookups/TechLookup';

export const getTechnologyOptionsWithIcon = (): { label: ReactNode; value: number }[] => {
	const values = Object.keys(Tech)
		.filter((v) => !isNaN(Number(v)))
		.slice(1)
		.map(Number);

	return values.map((t) => {
		const techName = getTechName(t);
		const TechIcon = getTechIcon(t);
		return {
			value: t,
			label: (
				<HStack align="center" justify="space-between">
					<Text as="span">{techName}</Text>
					<TechIcon boxSize="1.5rem" />
				</HStack>
			),
		};
	});
};
