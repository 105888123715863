import { DeliveryDatePresetEnum, RegistryOperator } from 'common/enums';
import { isDeprecatedRegistryOperator } from 'common/enums/valueLookups/RegistryOperatorLookup';
import moment, { Moment } from 'moment';
import { filterLastProductionPeriod } from 'utils/orderUtils/filterLastProductionPeriod';
import { z } from 'zod';
import { getMinimumValidityOptions } from 'components/NewOrder/options';
import { isWithinRange } from 'components/NewOrder/utils/isWithinRange';
import getFirstDateAfterProdPeriodUTC from '../../utils/getFirstDateAfterProdPeriodUTC';
import { fields as productFields, ProductionPeriodOption } from '../ProductStep/ProductStep.types';

export const fields = {
	registryOperator: z.nativeEnum(RegistryOperator).refine((val) => val && !isDeprecatedRegistryOperator(val), {
		message: 'Registry operator is deprecated. Please select another.',
	}),
	deliveryDatePreset: z.nativeEnum(DeliveryDatePresetEnum).optional(),
	deliveryDate: z
		.date()
		.nullable()
		.refine((val) => !val || moment(val, 'DD/MM/YYYY')?.isValid(), 'Please choose a valid date (dd/mm/yyyy)')
		.refine((val) => !val || isAfterToday(moment(val, 'DD/MM/YYYY')), 'Delivery date should be in the future'),
	minimumValidity: z
		.number()
		.refine(
			(val) => isWithinRange(val, getMinimumValidityOptions()),
			'Minimum validity must be within the selectable range',
		),
	comment: z.string().optional(),
};

export const deliveryStep = z.object(fields);

export const DeliverySchema = z
	.object({ ...productFields, ...fields })
	.refine((fields) => IsDeliveryPresetOrCustomDate(fields.deliveryDatePreset, fields.deliveryDate), {
		message: 'If delivery period "date" is chosen, you must choose a delivery date',
		path: ['deliveryDate'],
	})
	.refine(
		(fields) => {
			if (!fields.deliveryDate) return true;

			const { productionPeriods, deliveryDate } = fields;
			const lastProductionPeriod =
				productionPeriods && filterLastProductionPeriod(productionPeriods as ProductionPeriodOption[]);
			const firstValidDeliveryDateUTC = getFirstDateAfterProdPeriodUTC(lastProductionPeriod);

			return isAfterProductionPeriod(moment(deliveryDate, 'DD/MM/YYYY'), firstValidDeliveryDateUTC);
		},
		{ message: `Delivery must be after production period`, path: ['deliveryDate'] },
	);

export type DeliveryState = z.infer<typeof DeliverySchema>;

const isAfterToday = (value: Moment) => value.isSameOrAfter(moment.utc().startOf('day').add(1, 'day'));

const isAfterProductionPeriod = (value: Moment, firstValidDate: Moment | undefined) =>
	value.isSameOrAfter(firstValidDate);

const IsDeliveryPresetOrCustomDate = (
	deliveryDatePreset: DeliveryDatePresetEnum | undefined,
	deliveryDate: Date | null,
) => (deliveryDatePreset && deliveryDatePreset !== DeliveryDatePresetEnum.DATE) || deliveryDate;
