import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { H1, HStack, IconLogoMontel } from '@montel/montelpro-shared-components-v2';
import getEnvironment from 'utils/environment/getEnvironment';
import isProd from 'utils/environment/isProd';
import { StyledDivider, StyledHeaderBar } from './HeaderBar.style';
import NotificationsCenter from './NotificationsCenter';
import Profile from './Profile';

interface HeaderProps {
	className?: string;
	showCompanyLinks?: boolean;
	showProfile?: boolean;
}

const Header: FunctionComponent<HeaderProps> = ({ showCompanyLinks = true, showProfile = true }) => {
	const environment = isProd() ? '' : getEnvironment();

	return (
		<StyledHeaderBar>
			<HStack align="center" justify="space-between" fullWidth>
				<Link to="/">
					<HStack align="center">
						<IconLogoMontel color="white" h="5rem" w="12rem" />
						<H1 size="xxxl">{environment}</H1>
					</HStack>
				</Link>
				{showProfile && (
					<HStack align="center" gap="md">
						<StyledDivider />
						<NotificationsCenter />
						<Profile showCompanyLinks={showCompanyLinks} />
					</HStack>
				)}
			</HStack>
		</StyledHeaderBar>
	);
};

export default Header;
