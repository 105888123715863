import { FunctionComponent } from 'react';
import { Button, HStack, IconChevronLeft, ModalHeader } from '@montel/montelpro-shared-components-v2';
import styled from 'styled-components';
import OrderCountDown from '../OrderCountDown';
import { useOrderDetailsContext } from '../OrderDetailsContext';

const StyledHeader = styled(ModalHeader)`
	gap: 1rem;
	.title {
		max-width: none;
		flex-grow: 1;
	}
`;
const QuoteDetailsHeader: FunctionComponent = () => {
	const { onCloseQuoteView } = useOrderDetailsContext();
	return (
		<StyledHeader>
			<HStack align="center" justify="space-between">
				<Button text="Go back" variant="tertiary" leftIcon={<IconChevronLeft />} onClick={onCloseQuoteView} />
				<OrderCountDown />
			</HStack>
		</StyledHeader>
	);
};

export default QuoteDetailsHeader;
