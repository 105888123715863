import React, { FunctionComponent } from 'react';
import {
	Checkbox,
	CheckboxList,
	HStack,
	Input,
	RadioButtonList,
	Select,
	VStack,
} from '@montel/montelpro-shared-components-v2';
import { styled } from 'styled-components';
import getCurrencyOptions from '../../../common/options/getCurrencyOptions';
import getLabelOptions from '../../../common/options/getLabelOptions';
import getRegistryOperatorOptions from '../../../common/options/getRegistryOperatorOptions';
import getTradingFrequencyOptions from '../../../common/options/getTradingFrequencyOptions';
import getTradingPositionOptions from '../../../common/options/getTradingPositionOptions';
import { CompanyRegistrationState } from '../types';
import getTechnologyOptions from './getTechnologyOptions';
import getValidationErrorsPositionStep from './getValidationErrorsPositionStep';

const PositionStep: FunctionComponent<{
	state: CompanyRegistrationState;
	setState: React.Dispatch<any>;
	showErrors: boolean;
}> = ({ state, setState, showErrors }) => {
	const errors = getValidationErrorsPositionStep(state);
	return (
		<VStack gap={'lg'} align={'stretch'}>
			<CheckboxList
				options={getTechnologyOptions()}
				label={'Technologies'}
				required
				variant="spaced"
				onChange={(value) => {
					setState({ ...state, technologies: value });
				}}
				value={state.technologies}
				errorMessage={showErrors && errors.technologies}
			/>
			<RadioButtonList
				label={'Trading position'}
				required
				options={getTradingPositionOptions()}
				onChange={(value) => {
					setState({ ...state, tradingPosition: value });
				}}
				value={state.tradingPosition}
				errorMessage={showErrors && errors.tradingPosition}
			/>
			<RadioButtonList
				label={'Trading frequency'}
				required
				options={getTradingFrequencyOptions()}
				onChange={(value) => setState({ ...state, tradingFrequency: value })}
				value={state.tradingFrequency}
				errorMessage={showErrors && errors.tradingFrequency}
			/>
			<Input
				label="Number of certificates per year"
				type="number"
				value={state.numberOfCertificatesPerYear}
				onChange={(event: any) => setState({ ...state, numberOfCertificatesPerYear: event.target.value })}
				errorMessage={showErrors && errors.numberOfCertificatesPerYear}
			/>
			<Select
				label="Currencies"
				required
				options={getCurrencyOptions()}
				value={state.currencies}
				onChange={(value) => setState({ ...state, currencies: value })}
				errorMessage={showErrors && errors.currencies}
				isMulti
			/>

			<Select
				label="Registry operators"
				options={getRegistryOperatorOptions(false)}
				value={state.registryOperators}
				onChange={(value) => setState({ ...state, registryOperators: value })}
				isMulti
			/>
			<Select
				label="Labels"
				options={getLabelOptions()}
				value={state.labels}
				onChange={(value) => setState({ ...state, labels: value })}
				errorMessage={showErrors && errors.labels}
				isMulti
			/>
			<CheckboxContainer gap={'md'} wrap={'nowrap'}>
				<Checkbox
					label="EECS registry"
					checked={state.eecsRegistry}
					onChange={() => setState({ ...state, eecsRegistry: !state.eecsRegistry })}
				/>
				<Checkbox
					label="Subsidised"
					checked={state.subsidized}
					onChange={() => setState({ ...state, subsidized: !state.subsidized })}
				/>
			</CheckboxContainer>
		</VStack>
	);
};

const CheckboxContainer = styled(HStack)`
	& > div {
		flex-grow: 1;
	}
`;

export default PositionStep;
