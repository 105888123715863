import React, { FunctionComponent } from 'react';
import { IconRefresh } from '@montel/montelpro-shared-components-v2';
import { useTheme } from 'styled-components';
import { getIcon } from './getIcon';
import { HStackConfirmation, Rotate } from './style';
import { TAction } from './types';

export interface ConfirmationProps {
	action: TAction;
	onConfirm: (arg: any) => void | any;
	onCancel: () => void;
	isLoading?: boolean;
}

const Confirmation: FunctionComponent<ConfirmationProps> = ({ action, onConfirm, onCancel, isLoading = false }) => {
	const Icon = isLoading ? IconRefresh : getIcon(action);
	const theme = useTheme();

	return (
		<HStackConfirmation>
			<span>{`${action}?`}</span>
			<button onClick={onConfirm}>Yes</button>
			<button
				onClick={(event) => {
					onCancel();
					event.stopPropagation();
				}}
			>
				No
			</button>
			{isLoading ? (
				<Rotate>
					<Icon color={theme.colors.background} boxSize="16px" />
				</Rotate>
			) : (
				<Icon color={theme.colors.background} boxSize="16px" />
			)}
		</HStackConfirmation>
	);
};

export default Confirmation;
