import { FunctionComponent, useState } from 'react';
import { Button, HStack, VStack } from '@montel/montelpro-shared-components-v2';
import { styled } from 'styled-components';
import About from './formSections/About';
import Contact from './formSections/Contact';
import TradingInterests from './formSections/TradingInterests';
import { Divider } from './myCompany.styles';
import { MyCompanyState } from './MyCompanyState';
import SaveChangesButton from './SaveChangesButton';

const Form: FunctionComponent<{
	data: MyCompanyState;
	setIsEdit: (value: boolean) => void;
	fetchData: () => void;
}> = ({ data, setIsEdit, fetchData }) => {
	const [newLogo, setNewLogo] = useState<File>();
	const [state, setState] = useState<MyCompanyState>(data);

	return (
		<VStack gap="xl" fullWidth>
			<StyledContainer divider={<Divider />} justify="stretch" fullWidth>
				<About state={state} setState={setState} newLogo={newLogo} setNewLogo={setNewLogo} />
				<TradingInterests state={state} setState={setState} />
				<Contact state={state} setState={setState} />
			</StyledContainer>
			<StyledButtonsBar gap="md">
				<Button text="Cancel" variant="secondary" onClick={() => setIsEdit(false)} />
				<SaveChangesButton fetchData={fetchData} setIsEdit={setIsEdit} state={state} newLogo={newLogo} />
			</StyledButtonsBar>
		</VStack>
	);
};

const StyledContainer = styled(HStack)`
	& > div {
		flex: 1 1 22.5rem;
	}
`;

const StyledButtonsBar = styled(HStack)`
	align-self: flex-end;
`;

export default Form;
