export function getMonthName(monthNumber: number | null | undefined): string {
	if (monthNumber === null || monthNumber === undefined) return '';

	const date = new Date();
	date.setMonth(monthNumber - 1);

	return date.toLocaleString('en-US', {
		month: 'long',
	});
}
