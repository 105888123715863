import { toCamelCase } from 'utils/string-extension';
import { Tech } from '../../common/enums';
import useFilter from './useFilter';

const filterChoiceOptions = {
	Tech: [],
	Region: [],
	Label: [],
	Currency: [],
	ProductionYear: [],
	Status: [],
};

const filter = (order: any, filters: any, key: string) => {
	if (!filters[key].length) {
		return true;
	}
	const { product, status } = order;
	if (key === 'Currency') return filters[key].includes(order[toCamelCase(key)]);
	if (key === 'Tech') return product.tech.some((tech: Tech) => filters[key].includes(tech));
	if (key === 'ProductionYear') {
		const productProductionYears = product.production.periods.map((period: any) => period.year);
		return productProductionYears.some((year: number) => filters[key].includes(year));
	}
	if (key === 'Status') {
		return filters[key].includes(status);
	}

	return filters[key].includes(product[toCamelCase(key)]);
};

const customFilter = (items: any, filters: any) => {
	const filterKeys = Object.keys(filters);
	return items.filter((item: any) => filterKeys.every((key) => filter(item, filters, key)));
};

export const useProductFilter = () => {
	const {
		filterChoices,
		filterItems: filterOrders,
		handleFilterClick,
	} = useFilter({ defaultOptions: filterChoiceOptions, customFilter, localStorageKey: 'ProductFilterChoices' });

	return { filterChoices, filterOrders, handleFilterClick };
};

export const useTradeFilter = () => {
	const {
		filterChoices,
		filterItems: filterOrders,
		handleFilterClick,
	} = useFilter({ defaultOptions: filterChoiceOptions, customFilter, localStorageKey: 'TradeFilterChoices' });

	return { filterChoices, filterOrders, handleFilterClick };
};
