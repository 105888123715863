import { Table } from '@montel/montelpro-shared-components-v2';
import styled, { css } from 'styled-components';

export const StyledEndOfDayContainer = styled.div`
	display: flex;
	flex-direction: column;

	.end-of-day-header {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	${({ theme }) => {
		const { spacing } = theme;
		return css`
			.end-of-day-header {
				gap: ${spacing.xs};
				padding-bottom: ${spacing.lg};
			}
		`;
	}}
`;

export const StyledContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: ${({ theme }) => theme.spacing.md};
	width: 100%;
`;

export const StyledTable = styled(Table)<{ $windowWidth: number }>`
	table-layout: auto;

	td,
	th {
		padding-left: 0;
		padding-right: 0;
	}

	th:nth-child(3),
	td:nth-child(3) {
		width: ${({ $windowWidth }) => ($windowWidth > 2000 ? '15%' : '10%')};
	}

	td:first-child,
	th:first-child,
	td:last-child,
	th:last-child {
		padding-right: 0;
		padding-left: 0;
	}
`;
