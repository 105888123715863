import { FunctionComponent, HTMLProps, useEffect, useRef } from 'react';
import { StyledIndeterminateCheckbox } from './IndeterminateCheckbox.style';

export interface IndeterminateCheckboxProps extends HTMLProps<HTMLInputElement> {
	indeterminate?: boolean;
	className?: string;
	[otherProps: string]: any;
}

const IndeterminateCheckbox: FunctionComponent<IndeterminateCheckboxProps> = ({
	indeterminate,
	className = '',
	...otherProps
}) => {
	const ref = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (ref.current && typeof indeterminate === 'boolean') {
			ref.current.indeterminate = !otherProps.checked && indeterminate;
		}
	}, [ref, indeterminate]);

	return (
		<StyledIndeterminateCheckbox className={className}>
			<input type="checkbox" ref={ref} {...otherProps} />
		</StyledIndeterminateCheckbox>
	);
};

export default IndeterminateCheckbox;
