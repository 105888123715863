import React, { FunctionComponent } from 'react';
import { HStack, IconCheck, Text } from '@montel/montelpro-shared-components-v2';
import { UserRole } from 'common/enums/UserRoleEnum';
import { roleNames } from 'common/enums/valueLookups/roleLookup';
import { StyledProfileMenuItem } from '../../Profile.style';

interface CompanyButtonProps {
	onClick: () => void;
	isActive: boolean;
	title: string;
	role: number;
}

const CompanyButton: FunctionComponent<CompanyButtonProps> = ({ onClick, isActive, title, role }) => {
	return (
		<StyledProfileMenuItem $isActive={isActive} onClick={() => !isActive && onClick()}>
			<HStack fullWidth wrap="nowrap" justify="space-between" align="center">
				<div>
					<Text size="xs">{title}</Text>
					<Text size="xxs" color="secondaryText">
						{roleNames[UserRole[role] as keyof typeof UserRole]}
					</Text>
				</div>
				{isActive && <IconCheck color="white" />}
			</HStack>
		</StyledProfileMenuItem>
	);
};

export default CompanyButton;
