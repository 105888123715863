import React, { FunctionComponent } from 'react';
import { RoundSpinner } from '@montel/montelpro-shared-components';
import { RequestStatus } from '@montel/montelpro-shared-components/enums';
import { ErrorAlert } from '@montel/montelpro-shared-components-v2';
import { useHub } from 'providers/HubProvider';
import styled from 'styled-components';

const SuspenceStream: FunctionComponent<{ status: RequestStatus; children: React.ReactNode }> = ({
	status,
	children,
}) => {
	const { hubConnectionStatus } = useHub();

	if (
		status === RequestStatus.LOADING ||
		hubConnectionStatus === RequestStatus.UNLOADED ||
		hubConnectionStatus === RequestStatus.LOADING
	) {
		return (
			<StyledWrapper>
				<RoundSpinner text="Connecting..." />
			</StyledWrapper>
		);
	}

	if (hubConnectionStatus === RequestStatus.ERROR) {
		return <ErrorAlert text="Could not connect to orders and trades stream. Try refreshing this page" />;
	}

	if (status === RequestStatus.SUCCESS) return <>{children}</>;

	return null;
};

const StyledWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
	justify-content: center;
`;

export default SuspenceStream;
