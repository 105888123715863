import React, { FunctionComponent } from 'react';
import { DropdownSeparator, HStack, IconChevronLeft, Text } from '@montel/montelpro-shared-components-v2';
import { StyledDropdownGroupWithBack } from './DropdownGroupWithBack.style';

interface DropdownGroupWithBackProps {
	backText?: string;
	goBack: () => void;
	children: React.ReactNode;
}

export const DropdownGroupWithBack: FunctionComponent<DropdownGroupWithBackProps> = ({
	backText = 'Back',
	goBack,
	children,
}) => {
	return (
		<StyledDropdownGroupWithBack>
			<li className="back">
				<button onClick={() => goBack()} className="backButton">
					<HStack align="center" gap="md">
						<IconChevronLeft color="white" />
						<Text size="xs">{backText}</Text>
					</HStack>
				</button>
				<DropdownSeparator />
			</li>
			{children}
		</StyledDropdownGroupWithBack>
	);
};
