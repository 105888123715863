import { toast } from 'react-toastify';
import { JOIN_COMPANY_API_ROUTE } from 'apiRoutes';
import useDelete from 'hooks/useDelete';

type useCancelJoinCompanyType = {
	cancelJoinCompany: (id: number) => Promise<void>;
};
const useCancelJoinCompany = (removeNewPendingCompany: (id: number) => void): useCancelJoinCompanyType => {
	const { deleteItem } = useDelete();
	const cancelJoinCompany = async (id: number) => {
		try {
			const deleted = await deleteItem(JOIN_COMPANY_API_ROUTE.replace(':companyId', id?.toString()));
			if (deleted) {
				toast.success('Join request is cancelled!');
				removeNewPendingCompany(id);
			}
		} catch (error) {
			toast.error('An error occurred when cancelling the join request, please try again!');
		}
	};
	return {
		cancelJoinCompany,
	};
};

export default useCancelJoinCompany;
