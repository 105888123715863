export const steps = {
	Form: 0,
	Summary: 1,
};

interface AnimationHeights {
	from: number;
	to: number;
}

const formStep = {
	minHeight: 376,
	maxHeight: 416,
};

const summaryStep = {
	minHeight: 246,
	maxHeight: 356,
};

export const getFormStepPromptHeights = (numberOfQuotes: number, previousStep: number): AnimationHeights => {
	switch (numberOfQuotes) {
		case 0:
		case 1:
			return {
				from: previousStep === steps.Summary ? summaryStep.minHeight + 24 : 0,
				to: formStep.minHeight,
			};
		case 2:
			return {
				from: previousStep === steps.Summary ? summaryStep.minHeight + 70 : 0,
				to: formStep.minHeight,
			};
		default:
			return {
				from: previousStep === steps.Summary ? summaryStep.maxHeight : 0,
				to: formStep.maxHeight,
			};
	}
};

export const getSummaryStepPromptHeights = (numberOfQuotes: number): AnimationHeights => {
	switch (numberOfQuotes) {
		case 0:
			return {
				from: formStep.minHeight,
				to: summaryStep.minHeight,
			};
		case 1:
			return {
				from: formStep.minHeight,
				to: summaryStep.minHeight + 24,
			};
		case 2:
			return {
				from: formStep.minHeight,
				to: summaryStep.minHeight + 70,
			};
		default:
			return {
				from: formStep.maxHeight,
				to: summaryStep.maxHeight,
			};
	}
};
