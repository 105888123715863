import { SelectOptionType } from '@montel/montelpro-shared-components-v2';
import { Label } from 'common/enums/OrderEnums';
import { deprecatedLabels, getLabelName } from 'common/enums/valueLookups/LabelLookup';

export const isDeprecatedLabel = (label: Label) => deprecatedLabels.includes(label);

export const hasDeprecatedLabel = (labels: (SelectOptionType | undefined)[]) => getDeprecatedLabels(labels).length > 0;

export const getDeprecatedLabels = (labels: (SelectOptionType | undefined)[]) =>
	labels?.filter((l) => isDeprecatedLabel(l?.value));

export const getDeprecatedLabelsError = (labels: (SelectOptionType | undefined)[]) => {
	const deprecated = getDeprecatedLabels(labels);
	const deprecatedLabelNames = deprecated?.map((l) => getLabelName(l?.value));

	return deprecatedLabelNames.length > 1
		? `Labels ${deprecatedLabelNames.join(', ')} are deprecated. Please remove them from your selection.`
		: `Label ${deprecatedLabelNames[0]} is deprecated. Please remove it from your selection.`;
};
