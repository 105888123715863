import { IconBellOutlined, IconNewOrder, IconSync } from '@montel/montelpro-shared-components-v2';

export default {
	created: IconBellOutlined,
	ownAccepted: IconSync,
	accepted: IconBellOutlined,
	tradeCancelled: IconSync,
	orderWithActiveQuotesClosed: IconNewOrder,
	quoteCreated: IconNewOrder,
	quoteUpdated: IconNewOrder,
	partiallyTraded: IconNewOrder,
	quoteAccepted: IconSync,
};
