import { Navigate } from 'react-router-dom';
import Alerts from 'pages/Alerts';
import Companies from 'pages/Companies';
import CompanyRegistration from 'pages/CompanyRegistration/CompanyRegistration';
import { NotFound } from 'pages/Error';
import JoinCompany from 'pages/JoinCompany';
import MyCompany from 'pages/MyCompany';
import MyCompanyRoot from 'pages/MyCompany/MyCompanyRoot';
import Orders from 'pages/Orders';
import CompanyOrders from 'pages/Orders/CompanyOrders';
import CurrentOrders from 'pages/Orders/CurrentOrders';
import EndOfDay from 'pages/Orders/EndOfDay';
import MyOrders from 'pages/Orders/MyOrders';
import Root from 'pages/Root';
import Trades from 'pages/Trades';
import AllTrades from 'pages/Trades/AllTrades';
import CompanyTrades from 'pages/Trades/CompanyTrades';
import MyTrades from 'pages/Trades/MyTrades';
import Users from 'pages/Users';
import PrivateRoute from 'components/PrivateRoute';

const protectedRoutes = [
	{
		path: '/',
		element: <Root />,
		children: [
			{
				index: true,
				element: <Navigate to="/orders" />,
			},
			{
				path: 'orders',
				element: <Orders />,
				children: [
					{
						index: true,
						element: <CurrentOrders />,
					},
					{
						path: 'company-orders',
						element: (
							<PrivateRoute restrictedTo="company">
								<CompanyOrders />
							</PrivateRoute>
						),
					},
					{
						path: 'my-orders',
						element: (
							<PrivateRoute restrictedTo="traders">
								<MyOrders />
							</PrivateRoute>
						),
					},
					{
						path: 'end-of-day',
						element: <EndOfDay />,
					},
				],
			},
			{
				path: 'notification-settings',
				element: <Alerts />,
			},
			{
				path: 'companies',
				element: (
					<PrivateRoute restrictedTo="company">
						<Companies />
					</PrivateRoute>
				),
			},
			{
				path: 'my-company',
				element: (
					<PrivateRoute restrictedTo="company">
						<MyCompanyRoot />
					</PrivateRoute>
				),
				children: [
					{
						index: true,
						element: (
							<PrivateRoute restrictedTo="company">
								<MyCompany />
							</PrivateRoute>
						),
					},
					{
						path: 'users',
						element: (
							<PrivateRoute restrictedTo="company">
								<Users />
							</PrivateRoute>
						),
					},
				],
			},

			{
				path: 'join-company',
				element: <JoinCompany />,
			},

			{
				path: 'trades',
				element: <Trades />,
				children: [
					{
						index: true,
						element: <AllTrades />,
					},
					{
						path: 'company-trades',
						element: (
							<PrivateRoute restrictedTo="company">
								<CompanyTrades />
							</PrivateRoute>
						),
					},
					{
						path: 'my-trades',
						element: (
							<PrivateRoute restrictedTo="traders">
								<MyTrades />
							</PrivateRoute>
						),
					},
				],
			},
			{
				path: 'company/registration',
				element: <CompanyRegistration />,
			},
			{
				path: '*',
				element: <NotFound />,
			},
		],
	},
];

export default protectedRoutes;
