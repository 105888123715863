import { FunctionComponent } from 'react';
import { Button, HCenter, IconEdit, VStack } from '@montel/montelpro-shared-components-v2';
import { LOGOS_API_ROUTE } from 'apiRoutes';
import { useAppSelector } from 'store/hooks';
import { selectIsTraderManagerOrBackOfficeRole } from 'store/user/userSelectors';
import { styled } from 'styled-components';
import AboutOverview from './overviewSections/AboutOverview';
import ContactOverview from './overviewSections/ContactOverview';
import TradingInterestsOverview from './overviewSections/TradingInterestsOverview';
import LogoAndName from './LogoAndName';
import { StyledContainer } from './myCompany.styles';
import { MyCompanyState } from './MyCompanyState';

const Overview: FunctionComponent<{
	state: MyCompanyState;
	setIsEdit: (value: boolean) => void;
}> = ({ state, setIsEdit }) => {
	const isTraderManagerOrBackOfficeRole = useAppSelector(selectIsTraderManagerOrBackOfficeRole);
	const logoUrl = state.companyLogo && LOGOS_API_ROUTE.replace(':filename', state.companyLogo);

	return (
		<HCenter>
			<StyledVStack gap="xxl" fullWidth>
				<LogoAndName src={logoUrl} companyName={state.companyName} />
				<VStack gap="xl" fullWidth>
					<StyledContainer>
						<AboutOverview state={state} />
						<TradingInterestsOverview state={state} />
						<ContactOverview state={state} />
					</StyledContainer>
					{isTraderManagerOrBackOfficeRole && (
						<StyledButton text="Edit Profile" onClick={() => setIsEdit(true)} leftIcon={<IconEdit />} />
					)}
				</VStack>
			</StyledVStack>
		</HCenter>
	);
};

const StyledButton = styled(Button)`
	align-self: flex-end;
`;

const StyledVStack = styled(VStack)`
	max-width: 82.5rem;
`;

export default Overview;
