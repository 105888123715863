import { FunctionComponent } from 'react';
import { Outlet, ScrollRestoration } from 'react-router-dom';
import PageContainer from 'primitives/PageContainer';
import Header from 'components/Header';
import { SideMenu } from 'components/Menu';
import menuConfig from './menuConfig';

const Root: FunctionComponent = () => (
	<>
		<div>
			<Header />
			<SideMenu menuConfig={menuConfig} />
		</div>

		<PageContainer>
			<Outlet />
			<ScrollRestoration />
		</PageContainer>
	</>
);

export default Root;
