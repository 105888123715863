import { SelectOptionType } from '@montel/montelpro-shared-components-v2';

export const isWithinRange = (value: number, options: SelectOptionType[]) => {
	const min = options[0].value;
	const max = options[options.length - 1].value;

	if (!Number(min) || !Number(max)) return false;

	return value >= min && value <= max;
};
