import { FunctionComponent } from 'react';
import { IconArrowDropUp } from '@montel/montelpro-shared-components-v2';
import { StyledIcon } from './StyledIcon';

const IconUp: FunctionComponent = () => {
	return (
		<StyledIcon $color="success">
			<IconArrowDropUp />
		</StyledIcon>
	);
};

export default IconUp;
