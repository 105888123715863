import React, { FunctionComponent } from 'react';
import { useAuth } from '@montel/montelpro-auth';
import { HStack, IconSignOut, Text } from '@montel/montelpro-shared-components-v2';
import { StyledProfileMenuItem } from './Profile.style';

const SignOutButton: FunctionComponent = () => {
	const { authRoutes } = useAuth();
	return (
		<StyledProfileMenuItem>
			<a href={authRoutes.logout}>
				<HStack fullWidth align="center" justify="space-between">
					<Text size="xs">Sign out</Text>
					<IconSignOut color="white" />
				</HStack>
			</a>
		</StyledProfileMenuItem>
	);
};

export default SignOutButton;
