import { FunctionComponent } from 'react';
import { VStack } from '@montel/montelpro-shared-components-v2';
import DeliveryDatePresetEnum from 'common/enums/DeliveryDatePresetEnum';
import { getRegistryOperatorName } from 'common/enums/valueLookups/RegistryOperatorLookup';
import moment from 'moment';
import getLabelByValue from 'utils/getOptionLabelByValue';
import { getDeliveryDatePresetOptions } from 'utils/orderUtils/getDeliveryOptions';
import { useOrderFormContext } from '../../OrderFormProvider';
import ReviewBox from './ReviewBox';
import Value from './Value';

interface DeliveryReviewProps {
	onStepChange: (step: number) => void;
	isSubmitted?: boolean;
}
const DeliveryReview: FunctionComponent<DeliveryReviewProps> = ({ onStepChange, isSubmitted }) => {
	const { formState } = useOrderFormContext();
	const { deliveryDatePreset, deliveryDate, registryOperator, minimumValidity } = formState.delivery;
	return (
		<ReviewBox onStepChange={onStepChange} isSubmitted={isSubmitted} gotoStep={1} header="Delivery">
			<VStack gap="xxs">
				<Value label="Delivery date">{getDeliveryPeriod(deliveryDatePreset, deliveryDate)}</Value>
				<Value label="Registry operator">{getRegistryOperatorName(registryOperator)}</Value>
				<Value label="Minimum validity (weeks)">{minimumValidity}</Value>
			</VStack>
		</ReviewBox>
	);
};

const getDeliveryPeriod = (
	deliveryDatePreset: DeliveryDatePresetEnum | undefined,
	deliveryDate: string | Date | null,
) => {
	if (deliveryDatePreset !== DeliveryDatePresetEnum.DATE)
		return getLabelByValue(getDeliveryDatePresetOptions(), deliveryDatePreset);
	return moment(deliveryDate).utc().format('DD/MM/YYYY');
};

export default DeliveryReview;
