import { CounterpartyRelationStatus } from '../../../common/enums/counterpartyStateEnum';
import { TCounterpartyStatus } from '../types';

export function getCounterpartyStatus(
	counterpartyStatus: CounterpartyRelationStatus,
	isBlockedByMyCompany: boolean,
	isBlockingMyCompany: boolean,
	isInvitedByMyCompany: boolean,
): TCounterpartyStatus {
	if (
		counterpartyStatus === CounterpartyRelationStatus.Pending &&
		!isBlockedByMyCompany &&
		!isBlockingMyCompany &&
		isInvitedByMyCompany
	)
		return 'pending';
	if (counterpartyStatus === CounterpartyRelationStatus.Approved && isBlockedByMyCompany) return 'paused';
	if (counterpartyStatus !== CounterpartyRelationStatus.Approved && isBlockedByMyCompany) return 'blocked';
	if (counterpartyStatus === CounterpartyRelationStatus.Approved && !isBlockedByMyCompany && !isBlockingMyCompany)
		return 'counterparty';
	return 'default';
}
