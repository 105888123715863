import { RequestStatus } from '@montel/montelpro-shared-components/enums';
import { createSlice } from '@reduxjs/toolkit';
import { Order } from '../../../common/interfaces';
import { getActiveOrders } from '../ordersThunks';
import { translateApiOrders } from '../utils/translateApiOrder';

export interface IActiveOrdersState {
	orders: Order[];
	status: RequestStatus;
	error?: any;
	selectedOrderId: string | null;
}

export const initialState: IActiveOrdersState = {
	orders: [],
	status: RequestStatus.UNLOADED,
	error: undefined,
	selectedOrderId: null,
};

export const activeOrdersSlice = createSlice({
	name: 'activeOrders',
	initialState,
	reducers: {
		orderCreated: (state, { payload: order }) => {
			state.orders.push(order);
		},
		orderUpdated: (state, { payload: { order, index } }) => {
			state.orders[index] = order;
		},
		orderRemoved: (state, { payload: index }) => {
			state.orders.splice(index, 1);
		},
		orderSelected: (state, { payload }) => {
			state.selectedOrderId = payload;
		},
		orderDeselected: (state) => {
			state.selectedOrderId = null;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getActiveOrders.pending, (state) => {
				state.status = RequestStatus.LOADING;
				state.error = undefined;
			})
			.addCase(getActiveOrders.rejected, (state, { error }) => {
				state.status = RequestStatus.ERROR;
				state.error = error;
			})
			.addCase(getActiveOrders.fulfilled, (state, { payload }) => {
				state.orders = translateApiOrders(payload);
				state.status = RequestStatus.SUCCESS;
				state.error = undefined;
			});
	},
});

export const { orderCreated, orderUpdated, orderRemoved, orderSelected, orderDeselected } = activeOrdersSlice.actions;

export default activeOrdersSlice.reducer;
