import { HStack, ModalBody } from '@montel/montelpro-shared-components-v2';
import styled from 'styled-components';

export const EditPresetsModalRow = styled(HStack).attrs({
	fullWidth: true,
	justify: 'space-between',
	align: 'center',
})``;

export const StyledModalBody = styled(ModalBody)`
	padding-inline-end: 2rem;
`;
