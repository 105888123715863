import { Currency, PeriodTypeEnum, SubsidizedEnum } from 'common/enums';
import { ProductionPeriod } from 'common/interfaces';
import { DtoOrder } from 'common/interfaces/DtoOrder';
import { getExpiryDate } from 'utils/getExpiryDate';
import { OrderFormState } from '../types';
import getDeliveryDate from './getDeliveryDate';

const getPayload = (state: OrderFormState): DtoOrder => ({
	orderType: state.price.orderType,
	product: {
		tech: state.product.technologies,
		deliveryDate: getDeliveryDate(state)?.toDate() || null,
		registryOperator: state.delivery.registryOperator,
		region: state.product.region ?? 0,
		label: state.product.label,
		plantMaxAge: state.product.plantMaxAge,
		plantCommissioningYear: state.product.plantCommissioningYear,
		production: {
			type: state.product.periodType as PeriodTypeEnum,
			periods: (() => {
				state.product.productionPeriods?.forEach(
					(period) => (period.month = getProductionMonth_1_12(period.month)),
				);

				return state.product.productionPeriods as ProductionPeriod[];
			})(),
		},
		subsidized:
			state.product.subsidized === SubsidizedEnum.YES
				? true
				: state.product.subsidized === SubsidizedEnum.NO
				? false
				: null,
	},
	price: state.price.pricePerUnit?.toFloat() || 0,
	quantity: state.price.quantity.toFloat(),
	expiryTime: getExpiryDate(state.price.expiryPreset, state.price.expires)?.format() || null,
	side: state.side,
	currency: state.price.currency || Currency.EUR,
	availableTo: state.price.availableTo,
	minimumValidity: state.delivery.minimumValidity || 0,
	comments: state.delivery.comment,
	orderCounterparties: state.price.orderCounterparties?.map((c) => c.value) || [],
});

export const getProductionMonth_1_12 = (month: number | undefined): number | undefined =>
	month === undefined ? undefined : month + 1;

export default getPayload;
