import { RequestStatus } from '@montel/montelpro-shared-components/enums';
import { Trade } from 'common/interfaces/Trade';
import { useGetV2 } from 'hooks/useGetV2';
import qs from 'qs';
import { TradeQueryParams } from './TradeQueryParams';

interface IGetTradesApiResponse {
	items: Trade[];
	totalCount: number;
}

interface IUseTradesReturn {
	getTrades: (params: TradeQueryParams) => Promise<IGetTradesApiResponse>;
	status: RequestStatus;
}

export const useGetTrades = (url: string): IUseTradesReturn => {
	const { status, getData } = useGetV2(url);

	const getTrades = async (params: TradeQueryParams): Promise<IGetTradesApiResponse> => {
		const result = await getData({
			params: params,
			paramsSerializer: (params: TradeQueryParams) => {
				return qs.stringify(params, { arrayFormat: 'repeat' });
			},
		});

		return result;
	};

	return {
		getTrades,
		status,
	};
};
