import { FunctionComponent } from 'react';
import { IconEdit } from '@montel/montelpro-shared-components-v2';
import { useOrderDetailsContext } from 'components/OrderDetails/OrderDetailsContext';
import { ActionButton } from '../OrderDetailsBody.styles';

interface FixedPriceOwnerActionsProps {
	onEditOrder: () => void;
	setShowCancel: (value: boolean) => void;
}

const FixedPriceOwnerActions: FunctionComponent<FixedPriceOwnerActionsProps> = ({ onEditOrder, setShowCancel }) => {
	const { isExpired } = useOrderDetailsContext();
	return (
		<>
			<ActionButton
				isDisabled={isExpired}
				variant="secondary"
				text="Edit order"
				leftIcon={<IconEdit />}
				onClick={onEditOrder}
			/>
			<ActionButton
				isDisabled={isExpired}
				variant="negative"
				text="Cancel order"
				onClick={() => setShowCancel(true)}
			/>
		</>
	);
};

export default FixedPriceOwnerActions;
