import { SelectOptionType } from '@montel/montelpro-shared-components';
import { RegistryOperator } from 'common/enums/OrderEnums';
import { isDeprecatedRegistryOperator, registryOperatorNames } from 'common/enums/valueLookups/RegistryOperatorLookup';

const getRegistryOperatorOptions = (
	withAny: boolean = true,
	includeDeprecated: boolean = false,
): SelectOptionType[] => {
	return Object.entries(registryOperatorNames)
		.slice(withAny ? 1 : 2)
		.map(([key, value]) => ({
			value: parseInt(key) as RegistryOperator,
			label: value,
		}))
		.filter((o) => includeDeprecated || !isDeprecatedRegistryOperator(o.value));
};

export default getRegistryOperatorOptions;
