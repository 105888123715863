import { IToken } from '@montel/montelpro-shared-components/interfaces';
import getJwtPayload from 'utils/apiUtils/getJwtPayload';

export type TokenPayload = {
	email: string;
	roles: string[];
};

const getTokenPayload = (accessToken: string | undefined | null): TokenPayload => {
	const tokenPayload = accessToken ? (getJwtPayload(accessToken) as IToken | undefined) : undefined;
	return {
		email: tokenPayload?.email || '',
		roles: tokenPayload?.roles || [],
	};
};

export default getTokenPayload;
