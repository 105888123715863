import { createContext, Dispatch, FunctionComponent, useContext, useEffect, useState } from 'react';
import { useStepper } from '@montel/montelpro-shared-components-v2';
import { ExpiresPresetEnum } from 'common/enums';
import { QuoteFormState } from './PlaceQuoteForm.types';

export interface IPlaceQuoteFormContext {
	isQuoteFormOpen: boolean;
	setIsQuoteFormOpen: Dispatch<boolean>;
	activeStep: number;
	previousStep: number;
	goToNext: () => void;
	goToPrevious: () => void;
	resetQuoteForm: () => void;
	formState: QuoteFormState;
	setFormState: Dispatch<any>;
	isCreateAction: boolean;
}

export const PlaceQuoteFormContext = createContext<IPlaceQuoteFormContext | null>(null);

export const initialFormState: QuoteFormState = {
	quantity: '',
	pricePerUnit: '',
	expires: null,
	expiryPreset: ExpiresPresetEnum._17_00_CET,
};

export const PlaceQuoteFormContextProvider: FunctionComponent<{
	children: any;
}> = ({ children }) => {
	const [isQuoteFormOpen, setIsQuoteFormOpen] = useState(false);
	const [previousStep, setPreviousStep] = useState(0);
	const [formState, setFormState] = useState<QuoteFormState>(initialFormState);

	const { activeStep, goToNext, goToPrevious, setActiveStep } = useStepper();
	const isCreateAction = !Boolean(formState.id);

	useEffect(() => {
		setPreviousStep(activeStep);
	}, [activeStep]);

	const resetQuoteForm = () => {
		setActiveStep(0);
		setFormState(initialFormState);
	};

	return (
		<PlaceQuoteFormContext.Provider
			value={{
				isQuoteFormOpen,
				setIsQuoteFormOpen,
				activeStep,
				previousStep,
				goToNext,
				goToPrevious,
				resetQuoteForm,
				formState,
				setFormState,
				isCreateAction,
			}}
		>
			{children}
		</PlaceQuoteFormContext.Provider>
	);
};

export const usePlaceQuoteFormContext = () => {
	const context = useContext(PlaceQuoteFormContext);
	if (!context) {
		throw new Error(`usePlaceQuoteFormContext must be used within a PlaceQuoteFormContext`);
	}

	return context;
};
