import { FunctionComponent } from 'react';
import { Text } from '@montel/montelpro-shared-components-v2';
import { css, styled } from 'styled-components';

const Label = styled(Text)`
	${({ theme }) => css`
		color: ${theme.colors.secondaryText};
	`}
`;

const Value: FunctionComponent<{ label: string; children?: any }> = ({ label, children }) => (
	<Text>
		<Label as={'span'}>{label}: </Label>
		{children}
	</Text>
);

export default Value;
