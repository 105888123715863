import { FunctionComponent } from 'react';
import { IconArrowDropDown } from '@montel/montelpro-shared-components-v2';
import { StyledIcon } from './StyledIcon';

const IconDown: FunctionComponent = () => {
	return (
		<StyledIcon $color="error">
			<IconArrowDropDown />
		</StyledIcon>
	);
};

export default IconDown;
