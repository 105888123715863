import React, { FunctionComponent } from 'react';
import { MenuGroupProps } from '../menuTypes';
import { StyledSideMenuGroup } from './SideMenuGroup.styles';

/**
 * The component represents a group of menu items in the side menu. (Might become a category like container in future)
 */
const SideMenuGroup: FunctionComponent<MenuGroupProps> = ({ children, className, ...rest }) => (
	<StyledSideMenuGroup className={className} {...rest}>
		{children}
	</StyledSideMenuGroup>
);

export default SideMenuGroup;
