import { ProductionPeriod } from 'common/interfaces';
import { sortingProductionOrderByPeriod } from '../../../components/OrderTables/sorting/sortingProduction';

export enum Extreme {
	first,
	last,
}

export const filterExtremeProductionPeriod = (periods: ProductionPeriod[], extreme: Extreme) => {
	if (periods.length === 1) return periods[0];
	let sorted = [...periods];
	sorted = sorted.sort((a, b) => sortingProductionOrderByPeriod(a, b));
	return extreme === Extreme.first ? sorted[0] : sorted[sorted.length - 1];
};
