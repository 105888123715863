/* eslint-disable react/jsx-no-target-blank */
import { useState } from 'react';
import { AuthStatus, useAuth } from '@montel/montelpro-auth';
import { FullPageLoader } from '@montel/montelpro-shared-components';
import { Button, Toggle, ToggleOption } from '@montel/montelpro-shared-components-v2';
import { ReactComponent as MarketplaceLogo } from 'assets/brand/marketplace-logo-with-icon.svg';
import { Side } from 'common/enums/OrderEnums';
import useWindowSize from 'hooks/useWindowSize';
import OrderTradeList, { DataType } from 'pages/Embedded/OrderTradeList/OrderTradeList';
import FontStyles from 'pages/Embedded/styles/fontStyles';
import GenericError from 'pages/Error/GenericError';
import styled, { createGlobalStyle } from 'styled-components';

const Page = styled.div`
	height: 100vh;
	background: #030303;
	padding: 15px 25px 0 25px;
	display: flex;
	justify-content: center;
	font-family: 'Roboto', sans-serif;
`;

const GlobalStyle = createGlobalStyle`
  html, body {
    width: 100%;
	height: 100%;
  }
`;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 1500px;
	width: 100%;
	height: 100%;
	margin: 0 10px;
`;

const Header = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin: 0 0 40px 0;
`;

const Logo = styled.div`
	height: 40px;
	width: 260px;
`;

const Footer = styled.div`
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100px;
	background-image: linear-gradient(to bottom, rgba(3, 3, 3, 0), rgba(3, 3, 3, 1));
	display: flex;
	justify-content: center;
	align-items: center;
`;

const MarketplaceLink = styled.a`
	text-decoration: none;
`;

const toggleOptions = [
	{ label: 'BUY', value: `${Side.Bid}` },
	{ label: 'SELL', value: `${Side.Ask}` },
];

const Embedded = () => {
	const { authStatus } = useAuth();
	const [activeToggleOption, setActiveToggleOption] = useState<ToggleOption>(toggleOptions[0]);

	const [dataType, setDataType] = useState<DataType>('orders');

	const [windowWidth] = useWindowSize();
	const windowIsWide = windowWidth >= 1280;

	switch (authStatus) {
		case AuthStatus.SUCCESS:
			return (
				<Page>
					<GlobalStyle />
					<FontStyles />
					<Container>
						<Header>
							<Logo>
								<a href="https://montelgroup.com/products/marketplace" target="_blank">
									<MarketplaceLogo />
								</a>
							</Logo>
							{dataType === 'orders' && !windowIsWide && (
								<Toggle
									left={toggleOptions[0]}
									right={toggleOptions[1]}
									setActive={(option: ToggleOption) => setActiveToggleOption(option)}
									active={activeToggleOption}
								/>
							)}
						</Header>
						<OrderTradeList
							sideBySideOrders={windowIsWide}
							setType={setDataType}
							side={Number.parseInt(activeToggleOption.value) as Side}
						/>
					</Container>
					<Footer>
						<MarketplaceLink href="https://montelgroup.com/products/marketplace" target="_blank">
							<Button text="See more at Montel Marketplace" />
						</MarketplaceLink>
					</Footer>
				</Page>
			);

		case AuthStatus.ERROR:
			return <GenericError />;

		default:
			return <FullPageLoader />;
	}
};

export default Embedded;
