import React, { FunctionComponent } from 'react';
import { HStack } from '@montel/montelpro-shared-components-v2';
import { selectCountries } from 'store/lists/listsSelectors';
import getEnumTypeValue from 'utils/getEnumTypeValue';
import { useAppSelector } from '../../../store/hooks';
import Chip from './Chip';

interface ISelectedFilters {
	filterChoices: any;
	handleDeleteFilterChoices: (value: number, type: string) => void;
}

const SelectedFilters: FunctionComponent<ISelectedFilters> = ({ filterChoices, handleDeleteFilterChoices }) => {
	const filterKeys = Object.keys(filterChoices);
	const countryOptions = useAppSelector(selectCountries);

	const isFiltered = Object.keys(filterChoices)
		.map((key) => filterChoices[key].length > 0)
		.some((x) => x);

	if (!isFiltered) return null;

	return (
		<HStack justify="flex-start" align="center" fullWidth>
			{filterKeys.map((key) => {
				if (filterChoices[key].length > 0) {
					return filterChoices[key].map((filterValue: number, index: number) => {
						return (
							<Chip
								text={getEnumTypeValue(key, filterValue, countryOptions)}
								key={index}
								onClick={() => handleDeleteFilterChoices(filterValue, key)}
							/>
						);
					});
				}
			})}
		</HStack>
	);
};

export default SelectedFilters;
