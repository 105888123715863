import { AvailableTo } from 'common/enums';
import { Order } from 'common/interfaces';
import getTechnologyOptions from 'common/options/getTechnologyOptions';
import sortBySortingOrder from 'utils/sorting/sortBySortingOrder';

const techSortingOrder = getTechnologyOptions(true, true).map((item) => item.value);

const isOrderAvailableToTrade = (order: Order): boolean => {
	if (order.availability?.availableTo === AvailableTo.Public) {
		return true;
	}

	if (
		order.availability?.availableTo === AvailableTo.Counterparties ||
		order.availability?.availableTo === AvailableTo.Selected
	) {
		return order.isCounterpartysOrder;
	}

	return true;
};

const translateApiOrder = (order: Order): Order => ({
	...order,
	product: {
		...order.product,
		tech: sortBySortingOrder(order.product.tech, techSortingOrder),
	},
	isAvailableToTrade: isOrderAvailableToTrade(order),
});

export const translateApiOrders = (orders: Order[]): Order[] => orders.map(translateApiOrder);

export default translateApiOrder;
