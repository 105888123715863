import React, { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';
import { useMenuContext } from '../menuContext';
import { MenuItemProps } from '../menuTypes';
import { Square, StyledSideMenuItem, StyledSideMenuItemTag } from './SideMenuItem.styles';

const SideMenuItem: FunctionComponent<MenuItemProps> = ({
	text,
	icon,
	link,
	h = '40px',
	bg = 'transparent',
	color = 'white',
	hoverBg = 'element',
	className,
	tabIndex,
}) => {
	const ItemIcon = icon;
	const { isActive } = useMenuContext();

	return (
		<StyledSideMenuItem
			$backgroundColor={bg}
			$boxSize={h}
			$color={color}
			$hoverBackgroundColor={hoverBg}
			$isActive={isActive}
			className={className}
			tabIndex={tabIndex}
		>
			{ItemIcon && (
				<Square className="square" $boxSize={h}>
					<ItemIcon boxSize="20px" />
				</Square>
			)}
			<StyledSideMenuItemTag bg="element" borderRadius="4px" py="0px">
				<NavLink to={link} tabIndex={-1}>
					{text}
				</NavLink>
			</StyledSideMenuItemTag>
		</StyledSideMenuItem>
	);
};

export default SideMenuItem;
