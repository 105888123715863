import { Company } from 'common/interfaces/Company';
import getEncodedInBase64 from 'utils/fileUtils/getEncodedInBase64';
import getFileExtension from 'utils/fileUtils/getFileExtension';
import getValuesFromOptions from 'utils/getValuesFromOptions';
import { MyCompanyState } from './MyCompanyState';

const getPayload = async (data: MyCompanyState, newLogo?: File): Promise<Company> => {
	return {
		...data,
		marketRole: data.marketRole?.value,
		currencies: getValuesFromOptions(data.currencies),
		products: getValuesFromOptions(data.products),
		tradingFrequency: data.tradingFrequency?.value,
		tradingPosition: data.tradingPosition?.value,
		labels: getValuesFromOptions(data.labels),
		registryOperators: getValuesFromOptions(data.registryOperators),
		eecsRegistry: data.eecsRegistry?.value,
		subsidised: data.subsidized?.value,
		countryCode: data.countryCode?.value,
		logo: newLogo && (await getEncodedInBase64(newLogo)),
		logoExtension: getFileExtension(newLogo),
	};
};

export default getPayload;
