import { ToastContainer } from 'react-toastify';
import { styled } from 'styled-components';

export const StyledToastContainer = styled(ToastContainer)`
	${({ theme }) => `
    color: ${theme.colors.primaryText};
    width: 540px;
    max-width: 100%;

    .Toastify__toast {
      font-family: inherit;
      font-size: ${theme.typography.size.xs};
      border-radius: ${theme.borderRadii.md};
    } 

    .Toastify__toast-icon {
      margin-inline-end: ${theme.spacing.sm};
      width: ${theme.spacing.lg};
    }

    .Toastify__toast--error {
      background-color: ${theme.colors.error}!important;
      color: ${theme.colors.background};
    }

    .Toastify__toast--success {
      background-color: ${theme.colors.success}!important;
      color: ${theme.colors.widget};
    }

    .Toastify__toast--info {
      background-color: ${theme.colors.info}!important;
      color: ${theme.colors.primaryText};
    }
  `}
`;
