import { Stepper } from '@montel/montelpro-shared-components-v2';
import { styled } from 'styled-components';

export const FormContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;

	form {
		width: 100%;
	}
`;

export const StyledStepper = styled(Stepper)`
	padding: 0 18px 60px 14px;
	& p {
		font-size: ${({ theme }) => theme.typography.size.xs};
		line-height: unset;
		font-weight: unset;
	}
`;

export const HeaderAndPresets = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: ${({ theme }) => theme.spacing.md};
	height: 2.5rem;
`;

export const Header = styled.div`
	max-width: 18rem;
`;

export const PresetsError = styled.span`
	padding-right: 2em;
	font-size: ${({ theme }) => theme.typography.size.xs};
	color: ${({ theme }) => theme.colors.error};
`;
