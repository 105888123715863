import { styled } from 'styled-components';

export const StyledContainer = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
	gap: ${({ theme }) => theme.spacing.md};
	flex-wrap: wrap;
	& > div {
		flex: 0 1 22.5rem;
	}
`;

export const Divider = styled.hr`
	border: 1px solid ${({ theme }) => theme.colors.element};
	margin: 0 ${({ theme }) => theme.spacing.xl};
	height: 48rem;
`;
