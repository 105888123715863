import { RequestStatus } from '@montel/montelpro-shared-components/enums';
import { AxiosError } from 'axios';
import {
	BLOCK_COMPANY_ROUTE,
	COUNTERPARTY_API_ROUTE,
	DELETE_CONNECTED_COUNTERPARTY_ROUTE,
	UNBLOCK_COMPANY_ROUTE,
} from '../../../apiRoutes';
import useDelete from '../../../hooks/useDelete';
import useSubmit, { POST, PUT } from '../../../hooks/useSubmit';

export type TApiResponse = { data: any; success: boolean; statusCode: any };
export type TClientReturn = [() => Promise<TApiResponse | boolean>, RequestStatus, AxiosError<any> | undefined];

export interface IClient {
	useSendInvitation: (id: number) => TClientReturn;
	useCancelInvitation: (id: number) => TClientReturn;
	useAcceptInvitation: (id: number) => TClientReturn;
	useRejectInvitation: (id: number) => TClientReturn;
	useBlockCompany: (id: number) => TClientReturn;
	useUnblockCompany: (id: number) => TClientReturn;
	usePauseCompany: (id: number) => TClientReturn;
	useResumeCompany: (id: number) => TClientReturn;
	useRemoveCompany: (id: number) => TClientReturn;
}

const Client: IClient = {
	useSendInvitation: (id: number) => {
		const [postData, status, error] = useSubmit(`${COUNTERPARTY_API_ROUTE}?counterpartyCompanyId=${id}`, POST, {
			messageOnSuccess: 'Invitation sent!',
			messageOnError: 'An error occurred when connecting to a counterparty!',
		});
		const callApi = async () => await postData({});
		return [callApi, status, error];
	},
	useCancelInvitation: (id: number) => {
		const { deleteItem, deleteError, deleteStatus } = useDelete({
			messageOnSuccess: 'Invitation canceled!',
			messageOnError: 'An error occurred when cancelling the invitation, please try again!',
		});
		const callApi = async () => await deleteItem(`${COUNTERPARTY_API_ROUTE}?counterpartyCompanyId=${id}`);
		return [callApi, deleteStatus, deleteError];
	},
	useAcceptInvitation: (id: number) => {
		const [postData, status, error] = useSubmit(
			`${COUNTERPARTY_API_ROUTE}?counterpartyCompanyId=${id}&hasAccepted=true`,
			PUT,
			{
				messageOnSuccess: 'Invitation accepted!',
				messageOnError: 'An error occurred when accepting the invitation!',
			},
		);
		const callApi = async () => await postData({});

		return [callApi, status, error];
	},
	useRejectInvitation: (id: number) => {
		const [postData, status, error] = useSubmit(`${COUNTERPARTY_API_ROUTE}?counterpartyCompanyId=${id}`, PUT, {
			messageOnSuccess: 'Invitation rejected!',
			messageOnError: 'An error occurred when rejecting the invitation!',
		});
		const callApi = async () => await postData({});
		return [callApi, status, error];
	},
	useBlockCompany: (id: number) => {
		const [blockCompany, status, error] = useSubmit(`${BLOCK_COMPANY_ROUTE}`, POST, {
			messageOnSuccess: 'Company blocked!',
			messageOnError: 'An error occurred when blocking the company!',
		});
		const callApi = async () =>
			await blockCompany({
				companyId: id,
			});

		return [callApi, status, error];
	},
	useUnblockCompany: (id: number) => {
		const [blockCompany, status, error] = useSubmit(`${UNBLOCK_COMPANY_ROUTE}`, POST, {
			messageOnSuccess: 'Counterparty unblocked!',
			messageOnError: 'An error occurred when unblocking the counterparty!',
		});
		const callApi = async () =>
			await blockCompany({
				companyId: id,
			});

		return [callApi, status, error];
	},
	usePauseCompany: (id: number) => {
		const [blockCompany, status, error] = useSubmit(`${BLOCK_COMPANY_ROUTE}`, POST, {
			messageOnSuccess: 'Counterparty paused!',
			messageOnError: 'An error occurred when pausing the counterparty!',
		});
		const callApi = async () =>
			await blockCompany({
				companyId: id,
			});

		return [callApi, status, error];
	},
	useResumeCompany: (id: number) => {
		const [blockCompany, status, error] = useSubmit(`${UNBLOCK_COMPANY_ROUTE}`, POST, {
			messageOnSuccess: 'Counterparty resumed!',
			messageOnError: 'An error occurred when resuming the counterparty!',
		});
		const callApi = async () =>
			await blockCompany({
				companyId: id,
			});

		return [callApi, status, error];
	},
	useRemoveCompany: (id: number) => {
		const { deleteItem, deleteError, deleteStatus } = useDelete({
			messageOnSuccess: 'Counterparty removed!',
			messageOnError: 'An error occurred when removing the counterparty!',
		});
		const callApi = async () =>
			await deleteItem(`${DELETE_CONNECTED_COUNTERPARTY_ROUTE}?counterpartyCompanyId=${id}`);
		return [callApi, deleteStatus, deleteError];
	},
};

export default Client;
