import { FunctionComponent } from 'react';
import { useOrderDetailsContext } from '../../OrderDetailsContext';
import { useQuoteDetailsContext } from '../quoteDetailsContext';
import QuoteAcceptPrompt from './QuoteAcceptPrompt';
import QuoteCancelPrompt from './QuoteCancelPrompt';

const QuotePromptsRouter: FunctionComponent = () => {
	const { prompt, setPrompt } = useQuoteDetailsContext();
	const { selectedQuote } = useOrderDetailsContext();

	const onClose = () => setPrompt(null);

	return (
		<>
			<QuoteCancelPrompt quote={selectedQuote} isOpen={prompt === 'cancelPrompt'} onClose={onClose} />
			<QuoteAcceptPrompt quote={selectedQuote} isOpen={prompt === 'acceptPrompt'} onClose={onClose} />
		</>
	);
};

export default QuotePromptsRouter;
