export default {
	created: {
		en: {
			version_1:
				'Marketplace {{side}} alert! {{product.production.periods}}, {{product.tech}}, {{product.region}}, {{cost.quantity}}, {{cost.price}}{{cost.currency}}',
		},
	},
	ownAccepted: {
		en: {
			version_1:
				'Your trade for {{product.production.periods}}, {{product.tech}}, {{product.region}} has been agreed',
		},
	},
	accepted: {
		en: {
			version_1:
				'Marketplace trade alert! {{product.production.periods}}, {{product.tech}}, {{product.region}}, {{cost.quantity}}, {{cost.price}}{{cost.currency}}',
		},
	},
	tradeCancelled: {
		en: {
			version_1:
				'Your trade for {{product.production.periods}}, {{product.tech}}, {{product.region}} has been cancelled',
		},
	},
	orderWithActiveQuotesClosed: {
		en: {
			version_1: 'The RFQ {{product.production.periods}}, {{product.tech}}, {{product.region}} is now closed',
		},
	},
	quoteCreated: {
		en: {
			version_1: 'You have received a new quote on your RFQ order',
		},
	},
	quoteUpdated: {
		en: {
			version_1: 'There has been an update to one of the quotes on your RFQ order',
		},
	},
	partiallyTraded: {
		en: {
			version_1: "There has been a partial volume trade on an RFQ order you're involved in",
		},
	},
	quoteAccepted: {
		en: {
			version_1:
				'Your quote for {{product.production.periods}}, {{product.tech}}, {{product.region}} has been accepted',
		},
	},
};
