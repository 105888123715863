import { HStack } from '@montel/montelpro-shared-components-v2';
import { createColumnHelper } from '@tanstack/react-table';
import { styled } from 'styled-components';
import SortableHeadCell from 'components/Tables/Cells/HeadCell/SortableHeadCell';
import AcceptButton from './AcceptButton';
import DeclineButton from './DeclineButton';
import { User } from './types';

const columnHelper = createColumnHelper<User>();
const columnDefinitions = [
	columnHelper.accessor('fullName', {
		id: 'fullName',
		enableSorting: true,
		header: ({ header }) => <SortableHeadCell text="Name" header={header} />,
	}),
	columnHelper.accessor('email', {
		id: 'email',
		enableSorting: true,
		header: ({ header }) => <SortableHeadCell text="Email" header={header} />,
	}),
	columnHelper.display({
		id: 'actions',
		cell: ({ row }) => (
			<StyledHStack gap="md" justify="flex-end">
				<AcceptButton userId={row.original.userId} userName={row.getValue('fullName')} />
				<DeclineButton userId={row.original.userId} userName={row.getValue('fullName')} />
			</StyledHStack>
		),
	}),
];

const StyledHStack = styled(HStack)`
	line-height: normal;
`;

export default columnDefinitions;
