const getEncodedInBase64 = (file: File): Promise<string | undefined> => {
	function removeFileDescription(result: string): string | undefined {
		return result.split(',').pop();
	}
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(removeFileDescription(reader.result as string));
		reader.onerror = (error: any) => {
			console.warn("Couldn't encode in base64", error);
			reject(undefined);
		};
	});
};

export default getEncodedInBase64;
