import React, { ReactElement } from 'react';
import { Text } from '@montel/montelpro-shared-components-v2';
import { Region } from 'common/enums/OrderEnums';
import { regionCountries } from 'common/enums/valueLookups/RegionLookup';

export const getRegionTooltip = (region: number | null): ReactElement | undefined => {
	const countries = region ? regionCountries[Region[region as number] as keyof typeof Region] : [];
	if (countries.length === 0) return undefined;
	return (
		<div>
			<Text size="xs" weight="semiBold">
				Selected region includes:
			</Text>
			<Text size="xs" weight="normal">
				{countries.reduce((p, c) => p + `, ${c}`)}
			</Text>
		</div>
	);
};

export const getDeliveryDateTooltip = (): ReactElement => (
	<div>
		<Text size="xs" weight="semiBold">
			Standard:
		</Text>
		<Text size="xs" weight="normal">
			January 31st of the year after the production date
		</Text>
		<br />
		<Text size="xs" weight="semiBold">
			Spot:
		</Text>
		<Text size="xs" weight="normal">
			delivery after signature.
		</Text>
	</div>
);
