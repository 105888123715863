import { HStack } from '@montel/montelpro-shared-components-v2';
import { styled } from 'styled-components';

export const StyledPagination = styled(HStack)`
	height: 29px;
`;

export const Navigation = styled(HStack)`
	svg {
		cursor: pointer;
	}
`;
