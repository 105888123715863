import { useEffect, useState } from 'react';
import { RequestStatus, Side } from '@montel/montelpro-shared-components/enums';
import { Button, HStack, Text, VStack } from '@montel/montelpro-shared-components-v2';
import { motion } from 'framer-motion';
import useSellBuyOrder from 'components/OrderList/useSellBuyOrder';
import { useOrderDetailsContext } from '../OrderDetailsContext';
import { StyledCloseButton, StyledContainer, StyledVStack } from './TradePrompt.styles';

const TradePrompt = () => {
	const { order, tradePromptOpen, closeTradePrompt, setOrderAsTraded, tradeSubmitted } = useOrderDetailsContext();
	const { sellBuyOrder, sellBuyStatus } = useSellBuyOrder(order);
	const [disabled, setDisabled] = useState<boolean>(false);
	const action = order.side === Side.Ask ? 'Buy' : 'Sell';
	const loading = sellBuyStatus === RequestStatus.LOADING;

	useEffect(() => {
		if (sellBuyStatus === RequestStatus.SUCCESS || tradeSubmitted) {
			setOrderAsTraded();
			setDisabled(true);
		}
	}, [sellBuyStatus]);

	if (!tradePromptOpen) return null;
	return (
		<StyledContainer
			as={motion.div}
			layout
			initial={{
				height: 0,
			}}
			animate={{
				height: tradePromptOpen ? 200 : 0,
			}}
		>
			<StyledVStack justify="space-between">
				<VStack fullWidth gap="md" align="flex-start">
					<HStack fullWidth justify="space-between">
						<Text size="md" weight="600">{`${action} unit`}</Text>
						<StyledCloseButton color="white" onClick={closeTradePrompt} />
					</HStack>
					<Text>{`Do you want to ${action.toLowerCase()} this unit?`}</Text>
				</VStack>
				<HStack fullWidth gap="md" justify="flex-end">
					<Button variant="secondary" text="Cancel" isDisabled={disabled} onClick={closeTradePrompt} />
					<Button
						type="submit"
						text={`${action} unit`}
						loadingText="Submitting"
						isLoading={loading}
						isDisabled={disabled}
						onClick={async () => {
							await sellBuyOrder();
							closeTradePrompt();
						}}
					/>
				</HStack>
			</StyledVStack>
		</StyledContainer>
	);
};

export default TradePrompt;
