import { FunctionComponent, useState } from 'react';
import { StyledTableHeadCellContent, StyledText, StyledTooltip } from './HeadCell.style';
import TableSortingIndicator from './TableSortingIndicator';

export interface HeadCellProps {
	text?: string;
	sortable?: boolean;
	order?: 'asc' | 'desc';
	onClick?: (event: unknown) => void;
	color?: string;
	hoverColor?: string;
	className?: string;
	justify?: 'flex-start' | 'flex-end';
	offsetLeft?: string;
	tooltipText?: string;
}

const HeadCell: FunctionComponent<HeadCellProps> = ({
	text = '',
	sortable = false,
	order = 'asc',
	onClick,
	color = 'secondaryText',
	hoverColor = 'primaryText',
	justify = 'flex-start',
	offsetLeft,
	className,
	tooltipText,
}) => {
	const [isHovered, setIsHovered] = useState(false);
	const showIndicator = sortable && (order || isHovered);

	return (
		<StyledTableHeadCellContent
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
			onClick={onClick}
			$isSortable={sortable}
			$justify={justify}
			className={className}
			$offsetLeft={offsetLeft}
		>
			{tooltipText && <StyledTooltip $justify={justify}>{tooltipText}</StyledTooltip>}
			<StyledText
				forwardedAs="span"
				size="xs"
				lineHeight="base"
				weight="normal"
				color={showIndicator ? hoverColor : color}
			>
				{text}
			</StyledText>
			{showIndicator ? <TableSortingIndicator order={order} /> : <div style={{ width: '8px' }} />}
		</StyledTableHeadCellContent>
	);
};

export default HeadCell;
