import { FunctionComponent } from 'react';
import { Tech } from '@montel/montelpro-shared-components/enums';
import { CheckboxList, HStack, IconTechnologyNuclear } from '@montel/montelpro-shared-components-v2';
import getTechnologyOptions from 'common/options/getTechnologyOptions';
import { styled } from 'styled-components';
import { useOrderFormContext } from '../../OrderFormProvider';
import { fields, Technologies } from './ProductStep.types';

const StyledCheckboxList = styled(CheckboxList)`
	label {
		max-width: 4rem;
	}
`;

const StyledCheckboxListOneOption = styled(CheckboxList)`
	max-width: 4rem;
	margin-top: 30px;
`;

const Technology: FunctionComponent = () => {
	const {
		formState: orderFormState,
		setFormState: setOrderFormState,
		errors,
		validateSingle,
	} = useOrderFormContext();
	const { technologies } = orderFormState.product;

	const onTechChange = (tech: Technologies) => {
		validateSingle(fields.technologies, tech, 'technologies');
		setOrderFormState({
			...orderFormState,
			product: { ...orderFormState.product, technologies: getValidTechValue(tech, technologies) },
		});
	};

	return (
		<HStack wrap="nowrap" gap="md" justify="space-between" align="flex-start">
			<StyledCheckboxList
				label="Technology"
				options={getTechnologyOptions(true, false)}
				errorMessage={errors?.technologies && 'Choose at least one technology'}
				required
				onChange={onTechChange}
				name="technology"
				value={technologies}
				tooltip="Nuclear cannot be selected with other technologies"
			/>
			<StyledCheckboxListOneOption
				label="Technology"
				name="technology"
				hideLabel
				required
				options={[
					{
						label: <IconTechnologyNuclear boxSize="18px" />,
						value: Tech.Nuclear,
						caption: 'NUCLEAR',
					},
				]}
				onChange={onTechChange}
				value={technologies}
				errorMessage={errors?.technologies && ' '}
			/>
		</HStack>
	);
};

const getValidTechValue = (value: Technologies, previousValue: Technologies): Technologies => {
	const added = value.filter((tech) => !previousValue.includes(tech));

	if (added.includes(Tech.Nuclear)) {
		return [Tech.Nuclear];
	}
	if (added.includes(Tech.AnyRenewable)) {
		return [Tech.AnyRenewable];
	}
	return value.filter((tech) => tech !== Tech.Nuclear && tech !== Tech.AnyRenewable) as Technologies;
};

export default Technology;
