import { FunctionComponent } from 'react';
import { RequestStatus } from '@montel/montelpro-shared-components/enums';
import {
	Button,
	Modal,
	ModalBody,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
} from '@montel/montelpro-shared-components-v2';
import apiClient from '../apiClient';
import { useUsersContext } from '../UsersProvider';

const DeclineUserModal: FunctionComponent<{
	isOpen: boolean;
	onClose: () => void;
	userName: string;
	userId: string;
}> = ({ isOpen, onClose, userName, userId }) => {
	const [postData, status] = apiClient.useRespondJoinCompanyRequest(false);

	const { requestsToJoin } = useUsersContext();

	const onSend = async () => {
		const result = await postData({ userId, approve: false });
		if (result.success) {
			requestsToJoin.refetch();
			onClose();
		}
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent size={6}>
				<ModalHeader>Decline user</ModalHeader>
				<ModalBody>
					<Text size="md">Are you sure you want to decline this user {userName} to join your company?</Text>
				</ModalBody>
				<ModalFooter>
					<Button text="Cancel" variant="secondary" onClick={onClose} />
					<Button
						text="Decline request"
						onClick={onSend}
						isLoading={status === RequestStatus.LOADING}
						loadingText="Declining"
					/>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default DeclineUserModal;
