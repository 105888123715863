export default {
	approved: {
		en: {
			version_1: '{{companyName}} is now approved on Marketplace',
		},
	},
	deactivated: {
		en: {
			version_1: '{{companyName}} has been deactivated on Marketplace',
		},
	},
	joinCompanyInviteReceived: {
		en: {
			version_1: '{{invitedBy}} from {{companyName}} has invited you to join their company on Marketplace',
		},
	},
	joinRequestAccepted: {
		en: {
			version_1: 'Your request to join {{companyName}} on Marketplace has been accepted',
		},
	},
	joinRequestDeclined: {
		en: {
			version_1: 'Your request to join {{companyName}} on Marketplace has been declined',
		},
	},
	joinRequestReceived: {
		en: {
			version_1: '{{requesterName}} has requested to join {{companyName}} on Marketplace',
		},
	},
	userRemoved: {
		en: {
			version_1: 'Your user has been removed from {{companyName}}',
		},
	},
	roleChanged: {
		en: {
			version_1: 'Your role in {{companyName}} on Marketplace has been changed to {{newRole}}',
		},
	},
};
