import { IconCompany, IconCompanyDisable, IconPace, IconPaused } from '@montel/montelpro-shared-components-v2';
import { TCounterpartyStatus } from '../types';

export function getOverlayIcon(variant: TCounterpartyStatus) {
	switch (variant) {
		case 'pending':
			return IconPace;
		case 'paused':
			return IconPaused;
		case 'blocked':
			return IconCompanyDisable;
		default:
			return IconCompany;
	}
}
