import styled, { css } from 'styled-components';
import { MenuBarProps } from '../menuTypes';

/**
 * Topbar styled component that serves as the container for the top menu.
 */
export const Topbar = styled.nav<MenuBarProps>`
	margin: 0;
	box-sizing: border-box;
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	${({ $w, $h, $bg, theme }) => {
		const { colors } = theme;
		return css`
			height: ${$h};
			width: ${$w};
			background: ${colors[$bg] || $bg};
		`;
	}}
`;
