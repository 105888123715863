import { createContext, FunctionComponent, useContext, useEffect, useState } from 'react';
import { OnChangeFn, SortingState } from '@tanstack/react-table';

export interface IServerSideTableContextReturn {
	pageSize: number;
	setPageSize: React.Dispatch<number>;
	pageCount: number;
	setPageCount: React.Dispatch<number>;
	pageIndex: number;
	setPageIndex: React.Dispatch<number>;
	onTotalCountChanged: (count: number) => void;
	sortBy: SortBy;
	sortDirection: SortDirection;
	sorting: SortingState;
	setSorting: OnChangeFn<SortingState>;
}

interface ServerSideTableContextProps {
	children: any;
	defaultSort: SortingState;
}

export enum SortBy {
	TradeTime,
	ProductionYear,
	Quantity,
	UnitPrice,
}

export enum SortDirection {
	Descending,
	Ascending,
}

export const ServerSideTableContext = createContext<IServerSideTableContextReturn | null>(null);

export const ServerSideTableContextProvider: FunctionComponent<ServerSideTableContextProps> = ({
	children,
	defaultSort,
}) => {
	const [pageSize, setPageSize] = useState<number>(10);
	const [pageCount, setPageCount] = useState<number>(1);
	const [pageIndex, setPageIndex] = useState<number>(0);
	const [sorting, setSorting] = useState<SortingState>(defaultSort);
	const [sortBy, setSortBy] = useState<SortBy>(SortBy.TradeTime);
	const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.Descending);

	const onTotalCountChanged = (totalCount: number) => {
		if (totalCount == 0) {
			setPageCount(1);
			setPageIndex(0);

			return;
		}

		const newPageCount = Math.ceil(totalCount / pageSize);
		setPageCount(newPageCount);

		if (pageIndex > newPageCount - 1) {
			setPageIndex(newPageCount - 1);
		}
	};

	useEffect(() => {
		if (sorting && sorting.length > 0) {
			const selectedSorting = sorting[0];

			switch (selectedSorting.id) {
				case 'tradeTime':
					setSortBy(SortBy.TradeTime);
					break;
				case 'productionPeriod':
					setSortBy(SortBy.ProductionYear);
					break;
				case 'quantity':
					setSortBy(SortBy.Quantity);
					break;
				case 'price':
					setSortBy(SortBy.UnitPrice);
					break;
			}

			setSortDirection(selectedSorting.desc ? SortDirection.Descending : SortDirection.Ascending);
		}
	}, [sorting]);

	return (
		<ServerSideTableContext.Provider
			value={{
				pageSize,
				setPageSize,
				pageCount,
				setPageCount,
				pageIndex,
				setPageIndex,
				onTotalCountChanged,
				sortBy,
				sortDirection,
				sorting,
				setSorting,
			}}
		>
			{children}
		</ServerSideTableContext.Provider>
	);
};

export const useServerSideTableContext = () => {
	const context = useContext(ServerSideTableContext);
	if (!context) {
		throw new Error(`useServerSideTableContext must be used within a ServerSideTableContext`);
	}

	return context;
};
