import { FunctionComponent } from 'react';
import { GET_COMPANY_TRADES_API_ROUTE } from 'apiRoutes';
import { TradeStatusEnum } from 'common/interfaces/Trade';
import { orderTableColumns } from 'components/OrderTables/Columns/orderTableColumns';
import { tradeTableColumns } from 'components/OrderTables/Columns/tradeTableColumns';
import { setWidth } from 'components/Tables/Cells/utils/setWidth';
import TradePage from '../TradePage';

const CompanyTrades: FunctionComponent = () => {
	const columns = [
		tradeTableColumns.tradeTime(),
		orderTableColumns.productionPeriod({ columnDefProps: { meta: { width: '8%' } } }),
		orderTableColumns.tech({ columnDefProps: { enableSorting: false, meta: { width: '8%' } } }),
		orderTableColumns.region({ columnDefProps: { enableSorting: false } }),
		orderTableColumns.label({ columnDefProps: { enableSorting: false } }),
		orderTableColumns.delivery({ columnDefProps: { enableSorting: false } }),
		tradeTableColumns.transaction({ columnDefProps: { enableSorting: false } }),
		tradeTableColumns.counterparty({ columnDefProps: { enableSorting: false } }),
		orderTableColumns.quantity(setWidth('5%')),
		orderTableColumns.price(),
		tradeTableColumns.trader({
			columnDefProps: { enableSorting: false },
			cellProps: { offsetLeft: '1.5rem' },
			headCellProps: { offsetLeft: '1.5rem' },
		}),
		tradeTableColumns.tradeStatus({
			headCellProps: { text: 'Status' },
			columnDefProps: {
				enableSorting: false,
				meta: {
					width: '180px',
				},
			},
		}),
	];

	return (
		<TradePage
			pageTitle="Company Trades"
			tableHeader="Company trades"
			statuses={[TradeStatusEnum.Pending, TradeStatusEnum.Signed, TradeStatusEnum.Cancelled]}
			columns={columns}
			url={GET_COMPANY_TRADES_API_ROUTE}
		/>
	);
};

export default CompanyTrades;
