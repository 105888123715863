import { createContext, FunctionComponent, ReactNode, useContext } from 'react';
import { RequestStatus } from '@montel/montelpro-shared-components/enums';
import { useGetOnMount } from '@montel/montelpro-shared-components/hooks';
import { ALL_COMPANY_USERS_API_ROUTE, REQUESTS_TO_JOIN_API_ROUTE } from 'apiRoutes';
import { AxiosError, AxiosResponse } from 'axios';
import { CompanyUser } from './AllCompanyUsers/types';
import { User } from './RequestsToJoin/types';

interface IUserContext {
	requestsToJoin: {
		data: User[];
		status: RequestStatus;
		error?: AxiosError;
		refetch: () => Promise<AxiosResponse<any> | undefined>;
	};
	allCompanyUsers: {
		data: CompanyUser[];
		status: RequestStatus;
		error?: AxiosError;
		refetch: () => Promise<AxiosResponse<any> | undefined>;
	};
}

const usersContext = createContext<IUserContext>({
	requestsToJoin: {
		data: [],
		status: RequestStatus.UNLOADED,
		refetch: () => Promise.resolve(undefined),
	},
	allCompanyUsers: {
		data: [],
		status: RequestStatus.UNLOADED,
		refetch: () => Promise.resolve(undefined),
	},
});

const UsersProvider: FunctionComponent<{ children: ReactNode }> = ({ children }) => {
	const {
		data: dataRequestsToJoin,
		status: statusRequestsToJoin,
		error: errorRequestsToJoin,
		getData: getRequestsToJoin,
	} = useGetOnMount(REQUESTS_TO_JOIN_API_ROUTE);
	const {
		data: dataAllCompanyUsers,
		status: statusAllCompanyUsers,
		error: errorAllCompanyUsers,
		getData: getAllCompanyUsers,
	} = useGetOnMount(ALL_COMPANY_USERS_API_ROUTE);

	return (
		<usersContext.Provider
			value={{
				requestsToJoin: {
					data: dataRequestsToJoin,
					status: statusRequestsToJoin,
					error: errorRequestsToJoin,
					refetch: getRequestsToJoin,
				},
				allCompanyUsers: {
					data: dataAllCompanyUsers,
					status: statusAllCompanyUsers,
					error: errorAllCompanyUsers,
					refetch: getAllCompanyUsers,
				},
			}}
		>
			{children}
		</usersContext.Provider>
	);
};

export const useUsersContext = () => {
	const context = useContext(usersContext);
	if (!context) {
		throw new Error('useUsersContext must be used within a UsersProvider');
	}
	return context;
};

export default UsersProvider;
