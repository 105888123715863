import { Button, HStack, Text, VStack } from '@montel/montelpro-shared-components-v2';
import Prompt from '../../Prompt';
import { useOrderDetailsContext } from '../OrderDetailsContext';
import { getTypeOfQuote } from '../PlaceQuoteForm/getTypeOfQuote';
import apiClient from '../QuoteDetails/apiClient';
import QuoteSummary from './QuoteSummary';

const AcceptQuotePrompt = () => {
	const { order, setAcceptQuoteWithId, acceptQuoteWithId } = useOrderDetailsContext();
	const { isMyOrder, quotes, side } = order || {};
	const quoteType = getTypeOfQuote(side);

	const onClose = () => setAcceptQuoteWithId(null);
	const defaultHeight = 250;

	const selectedQuote = quotes.find(({ id }) => id === acceptQuoteWithId);

	const apiParameters = {
		orderId: selectedQuote?.orderId ?? '',
		quoteId: selectedQuote?.id ?? '',
		expectedVersion: selectedQuote?.version ?? -1,
	};

	const [postData] = apiClient.useAcceptQuote(apiParameters);

	const onAccept = async () => {
		if (!acceptQuoteWithId) return;
		const success = await postData({});
		if (success) {
			onClose();
		}
	};

	if (!isMyOrder || quotes.length === 0) return null;

	return (
		<Prompt
			animateFromHeight={0}
			animateToHeight={acceptQuoteWithId ? defaultHeight : 0}
			headerText={`Accept ${quoteType}`}
			body={
				<VStack fullWidth gap="lg">
					<Text>Accepting the {quoteType} will complete the trade</Text>
					<QuoteSummary quote={selectedQuote} order={order} />
				</VStack>
			}
			footer={
				<HStack fullWidth justify="flex-end">
					<Button text="Cancel" variant="secondary" onClick={onClose} />
					<Button text={`Accept ${quoteType}`} onClick={onAccept} />
				</HStack>
			}
			onClosePrompt={onClose}
		/>
	);
};
export default AcceptQuotePrompt;
