import { FunctionComponent } from 'react';
import { Header } from '@tanstack/react-table';
import { HeadCell, HeadCellProps } from 'components/Tables/Cells';

export interface SortableHeadCellProps extends HeadCellProps {
	header: Header<any, any>;
}

const SortableHeadCell: FunctionComponent<SortableHeadCellProps> = ({
	header,
	text,
	color = 'secondaryText',
	hoverColor = 'primaryText',
	justify = 'flex-start',
	offsetLeft,
	className,
	tooltipText: tooltip,
}) => (
	<HeadCell
		text={text}
		sortable
		onClick={header.column.getToggleSortingHandler()}
		order={header.column.getIsSorted() as 'asc' | 'desc'}
		color={color}
		hoverColor={hoverColor}
		justify={justify}
		offsetLeft={offsetLeft}
		className={className}
		tooltipText={tooltip}
	/>
);

export default SortableHeadCell;
