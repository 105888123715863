import { ExpiresPresetEnum } from 'common/enums';
import moment from 'moment-timezone';
import { now } from './dateUtil';

export const getExpiryDate = (expiryPreset: string | null, expires: Date | null): moment.Moment => {
	switch (expiryPreset) {
		case ExpiresPresetEnum._17_00_CET:
			const now_cet = now().tz('CET');
			const today_cet_1700 = now_cet.clone().set({ hour: 17, minute: 0, second: 0 });
			const tomorrow_cet_1700 = today_cet_1700.clone().add(1, 'day');

			if (now_cet.isAfter(today_cet_1700)) {
				return tomorrow_cet_1700;
			}

			return today_cet_1700;
		case ExpiresPresetEnum._1_HOUR:
			return moment().add(1, 'hour');
		default:
			return moment(expires);
	}
};
