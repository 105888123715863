import { FunctionComponent } from 'react';
import { HStack, Text } from '@montel/montelpro-shared-components-v2';
import { EllipsisText } from 'primitives/EllipsisText';

export interface NumericCellProps {
	value: number;
	unit?: string;
	decimalPlaces?: number;
}

const NumericCell: FunctionComponent<NumericCellProps> = ({ value, unit, decimalPlaces = 4 }) => {
	const textProps = { color: 'primaryText', size: 'sm', weight: 'semiBold' };

	return (
		<td>
			<HStack fullWidth gap="xxs" align="center" justify="center">
				<EllipsisText {...textProps}>{value.toDefaultNumberFormat(decimalPlaces)}</EllipsisText>

				{unit && <Text {...textProps}>{unit}</Text>}
			</HStack>
		</td>
	);
};

export default NumericCell;
