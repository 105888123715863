import { FunctionComponent } from 'react';
import { Button, IconAdd, Tooltip } from '@montel/montelpro-shared-components-v2';

interface InviteUserButtonProps {
	disabled: boolean;
	onClick: () => void;
}

export const InviteUserButton: FunctionComponent<InviteUserButtonProps> = ({ disabled, onClick }) => {
	return disabled ? (
		<Tooltip contentSize="lg" component={<Button text="Invite user" leftIcon={<IconAdd />} isDisabled />}>
			Your current user role does not have access to this feature
		</Tooltip>
	) : (
		<Button text="Invite user" onClick={onClick} leftIcon={<IconAdd />} />
	);
};
