import { commonFields, expiryPresetIsSpecifyAndExpiresIsNotSet } from 'common/forms/commonFields';
import { z } from 'zod';

export const fields = {
	id: z.string().optional(),
	quantity: commonFields.quantity,
	pricePerUnit: commonFields.pricePerUnit.refine((val) => Boolean(val), 'Price per unit is required'),
	expires: commonFields.expires,
	expiryPreset: commonFields.expiryPreset,
};

export const QuoteFormSchema = z
	.object(fields)
	.refine((fields) => !expiryPresetIsSpecifyAndExpiresIsNotSet(fields.expiryPreset, fields.expires), {
		message: 'Please specify expiry time',
		path: ['expires'],
	});

export type QuoteFormState = z.infer<typeof QuoteFormSchema>;
