import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

const StyledNoChange = styled.div`
	width: 1.5rem;
	height: 1.5rem;
	display: flex;
	align-content: center;
	justify-items: center;
	line-height: 1.5rem;
	color: ${({ theme }) => theme.colors.white};
`;

const NoChange: FunctionComponent = () => {
	return <StyledNoChange>&#8212;</StyledNoChange>;
};

export default NoChange;
