import {
	IconCompanyOrder,
	IconEndOfDayPrices,
	IconMyOrder,
	IconProductGo,
} from '@montel/montelpro-shared-components-v2';
import { TMenuItem } from '../../components/Menu';
import { useOrderPages } from './useOrderPages';

const { paths } = useOrderPages();

export const ordersMenuConfig: TMenuItem[] = [
	{
		type: 'Separator',
	},
	{
		type: 'Clickable',
		text: `Power GOs`,
		link: paths.root,
		icon: IconProductGo,
		isEnd: true,
	},
	{
		type: 'Separator',
	},
	{
		type: 'Clickable',
		text: 'My activity',
		link: paths.myOrders,
		icon: IconMyOrder,
		isEnd: true,
	},
	{
		type: 'Clickable',
		text: 'Company activity',
		link: paths.companyOrders,
		icon: IconCompanyOrder,
		isEnd: true,
	},
	{
		type: 'Separator',
	},
	{
		type: 'Clickable',
		text: `End-of-Day prices`,
		link: paths.endOfDay,
		icon: IconEndOfDayPrices,
		isEnd: true,
	},
];
