import React, { FunctionComponent, ReactElement } from 'react';
import { Tech } from '@montel/montelpro-shared-components/enums';
import { HeadCell } from '@montel/montelpro-shared-components-v2';
import { getTechIcon } from '../../common/enums/valueLookups/TechLookup';
import { EndOfDayTable } from './EndOfDayTable';
import { StyledContainer } from './styles';
import { translateData } from './translateData';
import { IEndOfDayTables } from './types';

interface EndOfDayTablesComponentProps {
	data?: IEndOfDayTables;
	columns: HeadCell[];
}

const EndOfDayTables: FunctionComponent<EndOfDayTablesComponentProps> = ({ data, columns }) => {
	const dataKeys = [
		{ tech: Tech.AnyRenewable, label: 'Any', dataKey: 'any' },
		{ tech: Tech.Hydro, label: 'Hydro', dataKey: 'hydro' },
		{ tech: Tech.Wind, label: 'Wind', dataKey: 'wind' },
	];

	return (
		<StyledContainer>
			{
				dataKeys.map(({ tech, label, dataKey }) => {
					const techIcon = getTechIcon(tech);
					const techData = translateData(
						data && data[dataKey as keyof Omit<IEndOfDayTables, 'previousDate' | 'currentDate'>],
					);
					return (
						<EndOfDayTable
							key={tech}
							columns={columns}
							rows={techData}
							techName={label}
							techIcon={techIcon}
						/>
					);
				}) as ReactElement[]
			}
		</StyledContainer>
	);
};

export default EndOfDayTables;
