import { DropdownMenu } from '@montel/montelpro-shared-components-v2';
import styled, { css } from 'styled-components';

export const StyledProfileMenuItem = styled.li<{ $isActive?: boolean }>`
	cursor: pointer;
	height: 100%;

	${({ theme, $isActive }) => {
		const { colors, spacing, borderRadii } = theme;
		const { sm } = borderRadii;
		return css`
			color: ${colors.white};
			background-color: ${$isActive ? colors.pineGreen : colors.element};
			> * {
				padding: ${spacing.xs} ${spacing.md};
			}
			&:focus {
				${!$isActive && `background: ${colors.elementHighlight};`};
			}
			&:hover {
				${!$isActive && `background: ${colors.elementHighlight};`};
				cursor: ${!$isActive ? 'pointer' : 'default'};
				&:first-child {
					border-radius: ${sm} ${sm} 0 0;
				}
				&:last-child {
					border-radius: 0 0 ${sm} ${sm};
				}
			}
			a {
				padding: 0;
				outline: none;
				> * {
					padding: ${spacing.xs} ${spacing.md};
				}
			}
		`;
	}}
`;

export const StyledProfileContainer = styled(DropdownMenu)`
	width: 256px;
	right: 0.5rem;
	overflow: hidden;
`;
