import { Order } from 'common/interfaces/Order';
import { Trade } from 'common/interfaces/Trade';

export const isStrip = (order: Order | Trade) => {
	const periods = order.product.production.periods;

	const uniqueYears = periods.map((period) => period.year).filter(onlyUnique);

	return uniqueYears.length > 1;
};

const onlyUnique = (value: any, index: number, array: any[]) => array.indexOf(value) === index;
