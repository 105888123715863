import styled from 'styled-components';

export const StyledIcon = styled.div<{ $color: string }>`
	width: 24px;
	height: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: ${({ $color, theme }) => theme.colors[$color] || $color};
`;
