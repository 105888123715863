import { SelectOptionType } from '@montel/montelpro-shared-components';
import { Currency } from 'common/enums/CurrencyEnum';
import { getEnumKeys } from 'utils/enumUtils';

const getCurrencyOptions = (): SelectOptionType[] => {
	return getEnumKeys(Currency)
		.slice(1)
		.map((c) => ({
			value: Currency[c as keyof typeof Currency],
			label: c,
		}));
};

export default getCurrencyOptions;
