import { FunctionComponent } from 'react';
import { Text, TextProps } from '@montel/montelpro-shared-components-v2';
import { styled } from 'styled-components';

const EllipsisText: FunctionComponent<TextProps> = ({ children, color = 'primaryText', ...rest }) => (
	<StyledTextWrapper $color={color}>
		<Text as={'span'} color={color} {...rest}>
			{children}
		</Text>
	</StyledTextWrapper>
);

const StyledTextWrapper = styled.div<{ $color: string }>`
	max-width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	color: ${({ $color, theme }) => theme.colors[$color] || $color};
`;

export default EllipsisText;
