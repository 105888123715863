import { VStack } from '@montel/montelpro-shared-components-v2';
import { motion } from 'framer-motion';
import { css, styled } from 'styled-components';

export const StyledContainer = styled(motion.div)`
	position: absolute;
	bottom: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;

	${({ theme }) => css`
		background-color: ${theme.colors.element};
		z-index: ${theme.zIndices.popover};
	`}
`;

export const StyledVStack = styled(VStack)`
	height: 100%;

	${({ theme }) => css`
		padding: ${theme.spacing.lg} ${theme.spacing.xxxl};
	`}
`;
