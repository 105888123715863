import { FunctionComponent } from 'react';
import { Tech } from 'common/enums/OrderEnums';
import ActiveTech from 'components/ActiveTech';
import { StyledIconCell } from 'components/Tables/Cells/styles';
import { CellProps } from 'components/Tables/Cells/types';

export interface TechCellProps extends CellProps {
	tech: Tech[];
	numberOfTechToShow?: number;
	disabled: boolean;
}

const TechCell: FunctionComponent<TechCellProps> = ({ tech, numberOfTechToShow, disabled }) => {
	const cellColor = disabled ? 'subElement' : 'white';

	return (
		<StyledIconCell>
			<ActiveTech color={cellColor} tech={tech} boxSize="1.25rem" numberOfTechToShow={numberOfTechToShow} />
		</StyledIconCell>
	);
};

export default TechCell;
