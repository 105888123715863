import { FunctionComponent } from 'react';
import { RequestStatus } from '@montel/montelpro-shared-components/enums';
import { Asterisk, Button } from '@montel/montelpro-shared-components-v2';
import { IconChevronLeft, IconChevronRight } from '@montel/montelpro-shared-components-v2';
import { styled } from 'styled-components';

const StyledFooter = styled.div<{ $justify: string }>`
	position: absolute;
	display: flex;
	bottom: 2rem;
	max-width: 34.5rem;
	width: 100%;
	justify-content: ${({ $justify }) => $justify};
	align-items: center;
`;

const Buttons = styled.div`
	display: flex;
	gap: ${({ theme }) => theme.spacing.md};
`;

interface FooterProps {
	onNext: () => void;
	onPrev?: () => void;
	showPrev: boolean;
	children?: React.ReactNode;
	isSubmit?: boolean;
	submitStatus?: RequestStatus;
	justify?: string;
}

const FormFooter: FunctionComponent<FooterProps> = ({
	onNext,
	onPrev,
	showPrev,
	children = null,
	isSubmit = false,
	submitStatus,
	justify = 'space-between',
}) => {
	return (
		<StyledFooter $justify={justify}>
			{children ? (
				children
			) : (
				<>
					{!isSubmit && (
						<p>
							<Asterisk /> Field is required
						</p>
					)}
					{submitStatus !== RequestStatus.SUCCESS && (
						<Buttons>
							{showPrev && (
								<Button
									text="Back"
									variant="secondary"
									onClick={onPrev}
									leftIcon={<IconChevronLeft />}
								/>
							)}
							{isSubmit ? (
								<Button
									text="Submit"
									type="submit"
									onClick={onNext}
									isLoading={submitStatus === RequestStatus.LOADING}
									loadingText="Submitting"
								/>
							) : (
								<Button text="Next" onClick={onNext} rightIcon={<IconChevronRight />} />
							)}
						</Buttons>
					)}
				</>
			)}
		</StyledFooter>
	);
};

export default FormFooter;
