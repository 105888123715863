import { Region } from '../OrderEnums';

export const regionNames: { [key in keyof typeof Region]: string } = {
	Default: 'Default',
	AIB: 'AIB',
	AIBGridConnected: 'AIB grid-connected',
	Alpine: 'Alpine',
	Nordic: 'Nordic',
	NordicGridConnected: 'Nordic grid-connected',
	EU: 'EU',
	Albania: 'Albania',
	Andorra: 'Andorra',
	Armenia: 'Armenia',
	Austria: 'Austria',
	Azerbaijan: 'Azerbaijan',
	Belarus: 'Belarus',
	Belgium: 'Belgium',
	BosniaAndHerzegovina: 'Bosnia and Herzegovina',
	Bulgaria: 'Bulgaria',
	Croatia: 'Croatia',
	Cyprus: 'Cyprus',
	CzechRepublic: 'Czech Republic',
	Denmark: 'Denmark',
	Estonia: 'Estonia',
	Finland: 'Finland',
	France: 'France',
	Georgia: 'Georgia',
	Germany: 'Germany',
	Greece: 'Greece',
	Hungary: 'Hungary',
	Iceland: 'Iceland',
	Ireland: 'Ireland',
	Italy: 'Italy',
	Kazakhstan: 'Kazakhstan',
	Latvia: 'Latvia',
	Liechtenstein: 'Liechtenstein',
	Lithuania: 'Lithuania',
	Luxembourg: 'Luxembourg',
	Macedonia: 'Macedonia',
	Malta: 'Malta',
	Moldova: 'Moldova',
	Monaco: 'Monaco',
	Montenegro: 'Montenegro',
	Netherlands: 'Netherlands',
	Norway: 'Norway',
	Poland: 'Poland',
	Portugal: 'Portugal',
	Romania: 'Romania',
	Russia: 'Russia',
	SanMarino: 'San Marino',
	Serbia: 'Serbia',
	Slovakia: 'Slovakia',
	Slovenia: 'Slovenia',
	Spain: 'Spain',
	Sweden: 'Sweden',
	Switzerland: 'Switzerland',
	Turkey: 'Turkey',
	Ukraine: 'Ukraine',
	UnitedKingdom: 'United Kingdom',
	VaticanCity: 'Vatican City',
};

const regionNamesV2 = {
	[Region.Default]: 'Default',
	[Region.AIB]: 'AIB',
	[Region.AIBGridConnected]: 'AIB grid-connected',
	[Region.Alpine]: 'Alpine',
	[Region.Nordic]: 'Nordic',
	[Region.NordicGridConnected]: 'Nordic grid-connected',
	[Region.EU]: 'EU',
	[Region.Albania]: 'Albania',
	[Region.Andorra]: 'Andorra',
	[Region.Armenia]: 'Armenia',
	[Region.Austria]: 'Austria',
	[Region.Azerbaijan]: 'Azerbaijan',
	[Region.Belarus]: 'Belarus',
	[Region.Belgium]: 'Belgium',
	[Region.BosniaAndHerzegovina]: 'Bosnia and Herzegovina',
	[Region.Bulgaria]: 'Bulgaria',
	[Region.Croatia]: 'Croatia',
	[Region.Cyprus]: 'Cyprus',
	[Region.CzechRepublic]: 'Czech Republic',
	[Region.Denmark]: 'Denmark',
	[Region.Estonia]: 'Estonia',
	[Region.Finland]: 'Finland',
	[Region.France]: 'France',
	[Region.Georgia]: 'Georgia',
	[Region.Germany]: 'Germany',
	[Region.Greece]: 'Greece',
	[Region.Hungary]: 'Hungary',
	[Region.Iceland]: 'Iceland',
	[Region.Ireland]: 'Ireland',
	[Region.Italy]: 'Italy',
	[Region.Kazakhstan]: 'Kazakhstan',
	[Region.Latvia]: 'Latvia',
	[Region.Liechtenstein]: 'Liechtenstein',
	[Region.Lithuania]: 'Lithuania',
	[Region.Luxembourg]: 'Luxembourg',
	[Region.Macedonia]: 'Macedonia',
	[Region.Malta]: 'Malta',
	[Region.Moldova]: 'Moldova',
	[Region.Monaco]: 'Monaco',
	[Region.Montenegro]: 'Montenegro',
	[Region.Netherlands]: 'Netherlands',
	[Region.Norway]: 'Norway',
	[Region.Poland]: 'Poland',
	[Region.Portugal]: 'Portugal',
	[Region.Romania]: 'Romania',
	[Region.Russia]: 'Russia',
	[Region.SanMarino]: 'San Marino',
	[Region.Serbia]: 'Serbia',
	[Region.Slovakia]: 'Slovakia',
	[Region.Slovenia]: 'Slovenia',
	[Region.Spain]: 'Spain',
	[Region.Sweden]: 'Sweden',
	[Region.Switzerland]: 'Switzerland',
	[Region.Turkey]: 'Turkey',
	[Region.Ukraine]: 'Ukraine',
	[Region.UnitedKingdom]: 'United Kingdom',
	[Region.VaticanCity]: 'Vatican City',
};
export const getRegionName = (region: Region) => regionNamesV2[region];

export const regionCountries: { [key in keyof typeof Region]: string[] | never[] } = {
	Default: [],
	AIB: [
		'Austria',
		'Belgium',
		'Croatia',
		'Cyprus',
		'Czech Republic',
		'Denmark',
		'Estonia',
		'Finland',
		'France',
		'Germany',
		'Greece',
		'Iceland',
		'Ireland',
		'Italy',
		'Latvia',
		'Lithuania',
		'Luxembourg',
		'The Netherlands',
		'Norway',
		'Portugal',
		'Serbia',
		'Slovakia',
		'Slovenia',
		'Spain',
		'Sweden',
		'Switzerland',
	],
	AIBGridConnected: [
		'Austria',
		'Belgium',
		'Croatia',
		'Cyprus',
		'Czech Republic',
		'Denmark',
		'Estonia',
		'Finland',
		'France',
		'Germany',
		'Greece',
		'Ireland',
		'Italy',
		'Latvia',
		'Lithuania',
		'Luxembourg',
		'The Netherlands',
		'Norway',
		'Portugal',
		'Serbia',
		'Slovakia',
		'Slovenia',
		'Spain',
		'Sweden',
		'Switzerland',
	],
	Alpine: ['Austria', 'France', 'Italy', 'Slovakia', 'Slovenia', 'Switzerland'],
	Nordic: ['Norway', 'Sweden', 'Denmark', 'Finland', 'Iceland'],
	NordicGridConnected: ['Norway', 'Sweden', 'Denmark', 'Finland'],
	EU: [
		'Austria',
		'Belgium',
		'Bulgaria',
		'Croatia',
		'Cyprus',
		'Czech Republic',
		'Denmark',
		'Estonia',
		'Finland',
		'France',
		'Germany',
		'Greece',
		'Hungary',
		'Ireland',
		'Italy',
		'Latvia',
		'Lithuania',
		'Luxemburg',
		'Malta',
		'Poland',
		'Portugal',
		'Romania',
		'Slovakia',
		'Slovenia',
		'Spain',
		'Sweden',
	],
	Albania: [],
	Andorra: [],
	Armenia: [],
	Austria: [],
	Azerbaijan: [],
	Belarus: [],
	Belgium: [],
	BosniaAndHerzegovina: [],
	Bulgaria: [],
	Croatia: [],
	Cyprus: [],
	CzechRepublic: [],
	Denmark: [],
	Estonia: [],
	Finland: [],
	France: [],
	Georgia: [],
	Germany: [],
	Greece: [],
	Hungary: [],
	Iceland: [],
	Ireland: [],
	Italy: [],
	Kazakhstan: [],
	Latvia: [],
	Liechtenstein: [],
	Lithuania: [],
	Luxembourg: [],
	Macedonia: [],
	Malta: [],
	Moldova: [],
	Monaco: [],
	Montenegro: [],
	Netherlands: [],
	Norway: [],
	Poland: [],
	Portugal: [],
	Romania: [],
	Russia: [],
	SanMarino: [],
	Serbia: [],
	Slovakia: [],
	Slovenia: [],
	Spain: [],
	Sweden: [],
	Switzerland: [],
	Turkey: [],
	Ukraine: [],
	UnitedKingdom: [],
	VaticanCity: [],
};
