import { FunctionComponent } from 'react';
import { RequestStatus } from '@montel/montelpro-shared-components/enums';
import { Button, HStack, Text, VStack } from '@montel/montelpro-shared-components-v2';
import { ORDER_DELETE_API_ROUTE } from '../../../apiRoutes';
import { Order } from '../../../common/interfaces/Order';
import useDelete from '../../../hooks/useDelete';
import { useOrderDetailsContext } from '../OrderDetailsContext';
import { StyledCloseButton } from '../TradePrompt/TradePrompt.styles';
import { StyledContainer, StyledVStack } from './style';

export interface CancelOrderPromptProps {
	isOpen: boolean;
	onClose: () => void;
	order: Order;
}
const CancelOrderPrompt: FunctionComponent<CancelOrderPromptProps> = ({ isOpen, onClose, order }) => {
	const { closeModal: closeOrderDetailsModal } = useOrderDetailsContext();

	const { id, version } = order;

	const { deleteItem, deleteStatus } = useDelete({
		messageOnSuccess: 'Your order has been cancelled.',
		messageOnError: 'An error has occurred, please try again.',
	});

	const callApi = async () => {
		let url = ORDER_DELETE_API_ROUTE.replace('{orderId}', id);
		url = url.replace('{orderVersion}', version.toString());
		const success = await deleteItem(url);
		if (success) {
			onClose();
			closeOrderDetailsModal();
		}
	};
	if (!isOpen) return null;

	return (
		<StyledContainer
			layout
			initial={{
				height: 0,
			}}
			animate={{
				height: isOpen ? 200 : 0,
			}}
		>
			<StyledVStack justify="space-between">
				<VStack fullWidth gap="md" align="flex-start">
					<HStack fullWidth justify="space-between">
						<Text size="md" weight="600">
							Cancel order
						</Text>
						<StyledCloseButton color="white" onClick={onClose} />
					</HStack>
					<Text>Do you want to cancel this order?</Text>
				</VStack>
				<HStack fullWidth gap="md" justify="flex-end">
					<Button variant="secondary" text="Cancel" onClick={onClose} />
					<Button
						text="Cancel order"
						onClick={callApi}
						isLoading={deleteStatus === RequestStatus.LOADING}
						loadingText="Cancelling"
					/>
				</HStack>
			</StyledVStack>
		</StyledContainer>
	);
};

export default CancelOrderPrompt;
