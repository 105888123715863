import styled from 'styled-components';

export const StyledTopMenuGroup = styled.ul`
	margin: 0;
	padding: 0;
	display: flex;
	flex-direction: row;
	height: 100%;
	gap: 1rem;
	flex-wrap: wrap;
`;
