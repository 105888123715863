import { FunctionComponent } from 'react';
import { Button, HStack, Tooltip } from '@montel/montelpro-shared-components-v2';
import { getTypeOfQuote } from 'components/OrderDetails/PlaceQuoteForm/getTypeOfQuote';
import { useOrderDetailsContext } from '../../OrderDetailsContext';
import { useQuoteDetailsContext } from '../quoteDetailsContext';

const OrderOwnerActions: FunctionComponent = () => {
	const { setPrompt } = useQuoteDetailsContext();
	const { selectedQuote, order } = useOrderDetailsContext();
	const quoteType = selectedQuote ? getTypeOfQuote(selectedQuote.side) : undefined;
	const quoteExceedsRemainingQuantity = selectedQuote && selectedQuote.quantity > order.cost.remainingQuantity;

	return (
		<HStack wrap="nowrap" gap="md">
			{quoteExceedsRemainingQuantity ? (
				<Tooltip component={<Button text="Accept" isDisabled />}>
					{"Quote cannot be accepted as it exceeds the order's remaining quantity"}
				</Tooltip>
			) : (
				<Button text={`Accept ${quoteType}`} onClick={() => setPrompt('acceptPrompt')} />
			)}
		</HStack>
	);
};

export default OrderOwnerActions;
