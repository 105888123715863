import { IconCrossCancel, VStack } from '@montel/montelpro-shared-components-v2';
import styled, { css } from 'styled-components';

export const StyledCloseButton = styled(IconCrossCancel)`
	cursor: pointer;
`;

export const StyledVStack = styled(VStack)`
	height: 100%;

	${({ theme }) => css`
		padding: ${theme.spacing.lg} ${theme.spacing.xxxl};
	`}
`;
