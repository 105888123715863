import { RequestStatus } from '@montel/montelpro-shared-components/enums';
import {
	CHANGE_ROLE_COMPANY_USER_API_ROUTE,
	REMOVE_COMPANY_USER_API_ROUTE,
	RESPOND_TO_JOIN_COMPANY_API_ROUTE,
	SEND_INVITE_USER_API_ROUTE,
} from 'apiRoutes';
import useDelete from 'hooks/useDelete';
import useSubmit, { POST, PUT } from 'hooks/useSubmit';

type PostReturnType = [
	postData: (payload: any) => Promise<{ success: boolean; data: any; statusCode: number | undefined }>,
	status: RequestStatus,
];

type DeleteReturnType = [deleteData: () => Promise<boolean>, status: RequestStatus];

interface IApiClient {
	useSendInviteUser: () => PostReturnType;
	useRespondJoinCompanyRequest: (accept: boolean) => PostReturnType;
	useChangeRoleCompanyUser: () => PostReturnType;
	useRemoveCompanyUser: (userId: string) => DeleteReturnType;
}

const apiClient: IApiClient = {
	useSendInviteUser: () => {
		const [postData, status] = useSubmit(SEND_INVITE_USER_API_ROUTE, POST, {
			messageOnSuccess: 'Invite sent!',
			messageOnError: 'An error has occurred, please try again.',
			showApiError: true,
		});
		return [postData, status];
	},
	useRespondJoinCompanyRequest: (accept: boolean) => {
		const [postData, status] = useSubmit(RESPOND_TO_JOIN_COMPANY_API_ROUTE, PUT, {
			messageOnError: `An error occurred when ${accept ? 'accepting' : 'declining'} the join company request`,
			messageOnSuccess: `The join company request was ${accept ? 'accepted' : 'declined'}!`,
			showApiError: true,
		});
		return [postData, status];
	},
	useChangeRoleCompanyUser: () => {
		const [postData, status] = useSubmit(CHANGE_ROLE_COMPANY_USER_API_ROUTE, PUT, {
			messageOnError: 'An error occurred when changing the user role, please try again!',
			messageOnSuccess: 'The user role is changed!',
			showApiError: true,
		});

		return [postData, status];
	},
	useRemoveCompanyUser: (userId: string) => {
		const { deleteItem, deleteStatus } = useDelete({
			messageOnError: 'An error occurred when removing user´s access from company',
			messageOnSuccess: 'User´s access is removed!',
		});
		const url = REMOVE_COMPANY_USER_API_ROUTE.replace('{userId}', userId);
		const deleteData = async () => await deleteItem(url);
		return [deleteData, deleteStatus];
	},
};

export default apiClient;
