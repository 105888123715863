import { FunctionComponent, useState } from 'react';
import { RequestStatus } from '@montel/montelpro-shared-components/enums';
import {
	Button,
	Modal,
	ModalBody,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
} from '@montel/montelpro-shared-components-v2';
import { SEND_INVITE_COMPANY_API_ROUTE } from 'apiRoutes';
import { getErrorMessageEmails, MultipleEmails } from '../../../components/MultipleEmails';
import useSubmit, { POST } from '../../../hooks/useSubmit';

export interface InviteCompanyModalProps {
	title?: string;
	isOpen: boolean;
	onClose: () => void;
}

const InviteCompanyModal: FunctionComponent<InviteCompanyModalProps> = ({
	isOpen,
	onClose,
	title = 'Invite a company to join Montel Marketplace',
}) => {
	const [emails, setEmails] = useState<string[]>([]);
	const errorMessage = getErrorMessageEmails(emails);
	const [showErrors, setShowErrors] = useState(false);

	const [postData, status] = useSubmit(SEND_INVITE_COMPANY_API_ROUTE, POST, {
		messageOnSuccess: 'Invite sent!',
		messageOnError: 'An error has occurred, please try again.',
	});

	const onSend = async () => {
		setShowErrors(true);
		if (errorMessage) {
			return;
		}
		const result = await postData(emails);
		if (result.success) {
			setEmails([]);
			setShowErrors(false);
			onClose();
		}
	};

	const onModalClose = () => {
		setEmails([]);
		setShowErrors(false);
		onClose();
	};

	return (
		<Modal isOpen={isOpen} onClose={onModalClose}>
			<ModalOverlay />
			<ModalContent size={6}>
				<ModalHeader>{title}</ModalHeader>
				<ModalBody>
					<MultipleEmails
						label={'Enter one or more email addresses to send invites'}
						value={emails}
						setValue={(value: string[]) => setEmails(value)}
						errorMessage={showErrors && errorMessage}
					/>
				</ModalBody>
				<ModalFooter>
					<Button text="Cancel" variant="secondary" onClick={onModalClose} />
					<Button
						text="Send"
						onClick={onSend}
						isLoading={status === RequestStatus.LOADING}
						loadingText="Sending"
					/>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default InviteCompanyModal;
