import isAllObjectValuesFalsy from 'utils/validationUtils/isAllObjectValuesFalsy';
import getValidationErrorsInfoStep from '../steps/getValidationErrorsInfoStep';
import getValidationErrorsMediaStep from '../steps/getValidationErrorsMediaStep';
import getValidationErrorsPositionStep from '../steps/getValidationErrorsPositionStep';
import { CompanyRegistrationState } from '../types';

type StepValidationFunction = (state: CompanyRegistrationState) => any;

const validationByStep: { [key: number]: StepValidationFunction | undefined } = {
	0: getValidationErrorsInfoStep,
	1: getValidationErrorsPositionStep,
	2: getValidationErrorsMediaStep,
	3: undefined,
};

const isCurrentStepValid = (currentStep: number, state: CompanyRegistrationState): boolean => {
	const func = validationByStep[currentStep];
	return func ? isAllObjectValuesFalsy(func(state)) : true;
};

export default isCurrentStepValid;
