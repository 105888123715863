import React, { FunctionComponent } from 'react';
import { HStack, IconChevronRight, Text, VStack } from '@montel/montelpro-shared-components-v2';
import { StyledProfileMenuItem } from '../Profile.style';

interface ActiveCompanyItemProps {
	onClick: () => void;
	active: string;
}

const ActiveCompanyItem: FunctionComponent<ActiveCompanyItemProps> = ({ onClick, active }) => {
	return (
		<StyledProfileMenuItem onClick={() => onClick()}>
			<HStack align="center" justify="space-between" fullWidth>
				<VStack gap="xxs">
					<Text size="xxs" color="secondaryText">
						Active company:
					</Text>
					<Text size="xs" color="lightOrange">
						{active}
					</Text>
				</VStack>
				<IconChevronRight color="white" />
			</HStack>
		</StyledProfileMenuItem>
	);
};

export default ActiveCompanyItem;
