import { useEffect, useState } from 'react';
import { RequestStatus } from '@montel/montelpro-shared-components/enums';
import axios, { AxiosError, AxiosResponse } from 'axios';

interface useGetReturn {
	getData: (overrideOptions?: any) => Promise<any | undefined>;
	data: any;
	status: RequestStatus;
	error?: AxiosError<any> | undefined;
}

export const useGetV2 = (url: string, options: any = {}): useGetReturn => {
	const [status, setStatus] = useState(RequestStatus.UNLOADED);
	const [error, setError] = useState<AxiosError<any> | undefined>(undefined);
	const [data, setData] = useState<AxiosResponse<any> | undefined>(undefined);
	const controller = new AbortController();

	const getData = async (overrideOptions?: any) => {
		if (!url) return;
		setStatus(RequestStatus.LOADING);
		try {
			const opt = overrideOptions ?? options;
			const response = await axios.get(url, {
				...opt,
				signal: controller.signal,
			});
			setData(response.data);
			setStatus(RequestStatus.SUCCESS);

			return response.data;
		} catch (error) {
			if (axios.isCancel(error)) return;

			const axiosError = error as AxiosError<any>;
			setError(axiosError);

			if (axiosError.response?.status === 403) {
				setStatus(RequestStatus.FORBIDDEN);
			} else {
				setStatus(RequestStatus.ERROR);
			}
		}
	};

	useEffect(() => {
		return () => controller.abort();
	}, []);

	return { getData, data, error, status };
};
