import { FunctionComponent } from 'react';
import { IconButton, IconDelete, Tooltip } from '@montel/montelpro-shared-components-v2';
import { styled } from 'styled-components';

const StyledIconButton = styled(IconButton)`
	min-width: ${({ theme }) => theme.spacing.xxxl};
`;

interface DeleteAllOrdersActionProps {
	onClick: () => void;
}

export const DeleteAllOrdersAction: FunctionComponent<DeleteAllOrdersActionProps> = ({ onClick }) => (
	<Tooltip
		component={<StyledIconButton icon={<IconDelete />} onClick={onClick} variant="negative" type="reset" />}
		contentSize="sm"
	>
		Cancel all my active orders
	</Tooltip>
);
