import { Text } from '@montel/montelpro-shared-components-v2';
import EmailPage from './EmailPage';
import ResendVerificationLink from './ResendVerificationEmail';

const EmailVerificationError = () => (
	<EmailPage subHeading="Something went wrong" iconColor="error">
		<Text>
			An unexpected error occurred and your email address might not have been properly verified. Please try again,
			or <ResendVerificationLink>click here to get a new verification email.</ResendVerificationLink>
		</Text>
	</EmailPage>
);

export default EmailVerificationError;
