import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { HStack, RadioButtonList, Select, SelectOptionType } from '@montel/montelpro-shared-components-v2';
import { AvailableTo } from 'common/enums';
import { styled } from 'styled-components';
import { getAvailableToOptions } from 'components/NewOrder/options';
import { selectCounterPartiesOptions } from '../../../../store/companies/companiesSelectors';
import { useAppSelector } from '../../../../store/hooks';
import { useOrderFormContext } from '../../OrderFormProvider';
import { fields } from './PriceStep.types';

const SelectWrapper = styled.div`
	width: 100%;
`;

const AvailableToGroup: FunctionComponent = ({}) => {
	const { formState, setFormState, errors, validateSingle } = useOrderFormContext();
	const counterpartyOptions = useAppSelector(selectCounterPartiesOptions);
	const [orderCounterpartyOptions] = useState<SelectOptionType[]>(counterpartyOptions);

	const { price } = formState;
	const { availableTo, orderCounterparties } = price;
	const selectRef = useRef<HTMLInputElement>();

	const onAvailableToChanged = (value: AvailableTo) => {
		validateSingle(fields.availableTo, value, 'availableTo');

		setFormState({
			...formState,
			price: { ...formState.price, availableTo: value, orderCounterparties: [] },
		});

		if (value === AvailableTo.Selected && selectRef.current) {
			selectRef.current.focus();
		}
	};

	const onSelectedCounterpartiesChanged = (selectedCounterparties: SelectOptionType[]) => {
		validateSingle(fields.orderCounterparties, selectedCounterparties, 'orderCounterparties');

		setFormState({
			...formState,
			price: { ...formState.price, orderCounterparties: selectedCounterparties },
		});
	};

	const onClickSelectWrapper = () => {
		if (availableTo !== AvailableTo.Selected) {
			onAvailableToChanged(AvailableTo.Selected);
		}
	};

	return (
		<HStack gap="md" wrap="nowrap" fullWidth>
			<RadioButtonList
				label="Make order available to"
				options={getAvailableToOptions()}
				errorMessage={errors?.isPublic && 'This field is required'}
				onChange={onAvailableToChanged}
				value={availableTo}
			/>
			<SelectWrapper onClick={onClickSelectWrapper}>
				<Select
					ref={selectRef}
					label="Select counterparties"
					required
					placeholder={`Select counterparties`}
					options={orderCounterpartyOptions}
					errorMessage={
						!errors?.availableTo && errors?.orderCounterparties ? errors.orderCounterparties : null
					}
					disabled={availableTo !== AvailableTo.Selected}
					hideLabel
					keepLabelHeightWhenHidden
					onChange={onSelectedCounterpartiesChanged}
					value={orderCounterparties}
					itemName={orderCounterparties && orderCounterparties.length > 1 ? 'counterpartie' : 'counterparty'}
					isClearable={false}
					openMenuOnFocus
					isMulti
					variant="checkbox"
				/>
			</SelectWrapper>
		</HStack>
	);
};

export default AvailableToGroup;
