import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { HStack, RadioButtonList, Select } from '@montel/montelpro-shared-components-v2';
import { DeliveryDatePresetEnum, PeriodTypeEnum } from 'common/enums';
import { getPeriodTypeName } from 'common/enums/valueLookups/PeriodTypeLookup';
import { ProductionPeriod } from 'common/interfaces';
import { filterLastProductionPeriod } from 'utils/orderUtils/filterLastProductionPeriod';
import isStandardDeliveryValid from 'utils/orderUtils/isStandardDeliveryValid';
import { getProductionPeriodOptions, getProductionPeriodTypeOptions } from '../../options';
import { useOrderFormContext } from '../../OrderFormProvider';
import { fields, ProductionPeriodOption } from './ProductStep.types';

const Production: FunctionComponent = () => {
	const [productionPeriodOptions, setProductionPeriodOptions] = useState<ProductionPeriodOption[]>(
		getProductionPeriodOptions(null),
	);

	const { formState, setFormState, errors, validateSingle } = useOrderFormContext();
	const { productionPeriods, periodType } = formState.product;
	const selectRef = useRef<HTMLInputElement>();

	const onProductionValuesChanged = (selectedOptions: ProductionPeriodOption[]) => {
		validateSingle(fields.productionPeriods, selectedOptions, 'productionPeriods');

		setFormState({
			...formState,
			product: { ...formState.product, productionPeriods: selectedOptions },
		});
	};

	const onPeriodTypeChanged = (period: PeriodTypeEnum) => {
		validateSingle(fields.periodType, period, 'periodType');

		setFormState({
			...formState,
			product: { ...formState.product, periodType: period, productionPeriods: [] },
		});

		openProductionPeriodDropdown();
	};

	useEffect(() => {
		setProductionPeriodOptions(getProductionPeriodOptions(periodType ?? null));
	}, [periodType]);

	useEffect(() => {
		const latestPeriod = productionPeriods && filterLastProductionPeriod(productionPeriods as ProductionPeriod[]);
		if (
			formState.delivery.deliveryDatePreset === DeliveryDatePresetEnum.STANDARD &&
			latestPeriod?.year &&
			!isStandardDeliveryValid(latestPeriod?.year)
		) {
			setFormState({
				...formState,
				delivery: { ...formState.delivery, deliveryDatePreset: undefined },
			});
		}
	}, [productionPeriods]);

	const openProductionPeriodDropdown = () => selectRef.current?.focus();

	return (
		<HStack wrap="nowrap" gap="md">
			<RadioButtonList
				label="Production period"
				required
				options={getProductionPeriodTypeOptions()}
				errorMessage={errors?.periodType && errors.periodType}
				onChange={onPeriodTypeChanged}
				value={periodType}
			/>
			<Select
				ref={selectRef}
				label="Production period"
				required
				placeholder={`Select up to four ${getPeriodTypeName(periodType)}s`}
				options={productionPeriodOptions}
				errorMessage={!errors?.periodType && errors?.productionPeriods && errors.productionPeriods}
				disabled={!periodType}
				hideLabel
				keepLabelHeightWhenHidden
				onChange={onProductionValuesChanged}
				value={productionPeriods ? productionPeriods : null}
				itemName={getPeriodTypeName(periodType)}
				openMenuOnFocus
				isMulti
				limit={4}
				variant={'checkbox'}
			/>
		</HStack>
	);
};

export default Production;
