import { FunctionComponent, ReactNode } from 'react';
import { Cell, flexRender } from '@tanstack/react-table';
import styled from 'styled-components';

const StyledTd = styled.td`
	padding: ${({ theme }) => `${theme.spacing.xxs} ${theme.spacing.xs}`};
`;

const Td: FunctionComponent<{ cell?: Cell<any, any>; className?: string; children?: ReactNode; colSpan?: number }> = ({
	cell,
	className,
	children,
	colSpan = 1,
}) => (
	<StyledTd colSpan={colSpan} className={className}>
		{cell && flexRender(cell.column.columnDef.cell, cell.getContext())}
		{children}
	</StyledTd>
);

export default Td;
