import { FunctionComponent, useState } from 'react';
import { RequestStatus } from '@montel/montelpro-shared-components/enums';
import {
	Button,
	Modal,
	ModalBody,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Select,
} from '@montel/montelpro-shared-components-v2';
import { UserRole } from 'common/enums/UserRoleEnum';
import { useAppSelector } from 'store/hooks';
import { selectUserRole } from 'store/user/userSelectors';
import getOptionByValue from 'utils/getOptionByValue';
import apiClient from '../apiClient';
import { getBackOfficeUserRoleOptions, getTradeManagerUserRoleOptions } from '../userRoleOptions';
import { useUsersContext } from '../UsersProvider';

const ManageUserRoleModal: FunctionComponent<{
	isOpen: boolean;
	onClose: () => void;
	userName: string;
	userId: string;
	role: UserRole;
}> = ({ isOpen, onClose, userName, userId, role }) => {
	const tradeManagerOptions = getTradeManagerUserRoleOptions();
	const [roleOption, setRoleOption] = useState(getOptionByValue(tradeManagerOptions, role));
	const userRole = useAppSelector(selectUserRole);

	const [postData, status] = apiClient.useChangeRoleCompanyUser();

	const { allCompanyUsers } = useUsersContext();

	const onSend = async () => {
		const result = await postData({ UserId: userId, Role: roleOption?.value });
		if (result.success) {
			allCompanyUsers.refetch();
			onClose();
		}
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent size={6}>
				<ModalHeader>Manage roles</ModalHeader>
				<ModalBody>
					<Select
						label={`Change ${userName}´s role within the company, this will notify them`}
						value={roleOption}
						onChange={(value: { value: UserRole; label: string }) => setRoleOption(value)}
						options={
							userRole === UserRole.TradeManager
								? getTradeManagerUserRoleOptions()
								: getBackOfficeUserRoleOptions()
						}
					/>
				</ModalBody>
				<ModalFooter>
					<Button text="Cancel" variant="secondary" onClick={onClose} />
					<Button
						text="Change"
						onClick={onSend}
						isLoading={status === RequestStatus.LOADING}
						loadingText="Changing"
					/>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default ManageUserRoleModal;
