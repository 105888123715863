import { INotificationLibraryAsset } from '../types';
import companyLinks from './company';
import counterpartyLinks from './counterparty';
import orderLinks from './order';
import systemNotificationLinks from './systemNotification';

const templates: INotificationLibraryAsset = {
	type: 'link',
	company: { ...companyLinks },
	counterparty: { ...counterpartyLinks },
	order: { ...orderLinks },
	systemNotification: { ...systemNotificationLinks },
};

export default templates;
