import { FunctionComponent } from 'react';
import { HStack, Switch, Text, VStack } from '@montel/montelpro-shared-components-v2';
import { useAlertSettingsContext } from '../../providers/AlertSettingsProvider';
import BrowserAlert from './BrowserAlert/BrowserAlert';
import { StyledSwitchBox } from './style';

const AlertSettings: FunctionComponent = () => {
	const {
		emailNotificationsEnabled,
		allAskOrdersEnabled,
		allBidOrdersEnabled,
		allTradesEnabled,
		updateEmailEnabled,
		updateAllAskOrdersEnabled,
		updateAllBidOrdersEnabled,
		updateAllTradesEnabled,
	} = useAlertSettingsContext();

	return (
		<VStack gap="lg">
			<Text weight="semiBold">Receive notifications by:</Text>
			<HStack fullWidth gap="md">
				<StyledSwitchBox>
					<VStack>
						<Text weight="semiBold">Email</Text>
						<Text color="secondaryText">Notifications will be sent to your selected email address</Text>
					</VStack>
					<Switch
						isChecked={emailNotificationsEnabled}
						onChange={async (event) => {
							await updateEmailEnabled(event.target.checked);
						}}
					/>
				</StyledSwitchBox>
				<BrowserAlert />
			</HStack>
			<Text weight="semiBold">Receive notifications about:</Text>
			<HStack gap="md">
				<StyledSwitchBox>
					<VStack>
						<Text weight="semiBold">All ask orders</Text>
						<Text color="secondaryText">
							Receive notifications about all new ask orders on Marketplace. If you set up custom alerts,
							this toggle will be automatically deactivated
						</Text>
					</VStack>
					<Switch
						isChecked={allAskOrdersEnabled}
						onChange={async (event) => {
							await updateAllAskOrdersEnabled(event.target.checked);
						}}
					/>
				</StyledSwitchBox>
				<StyledSwitchBox>
					<VStack>
						<Text weight="semiBold">All bid orders</Text>
						<Text color="secondaryText">
							Receive notifications about all new bid orders on Marketplace. If you set up custom alerts,
							this toggle will be automatically deactivated
						</Text>
					</VStack>
					<Switch
						isChecked={allBidOrdersEnabled}
						onChange={async (event) => {
							await updateAllBidOrdersEnabled(event.target.checked);
						}}
					/>
				</StyledSwitchBox>
				<StyledSwitchBox>
					<VStack>
						<Text weight="semiBold">All completed trades</Text>
						<Text color="secondaryText">
							Receive notifications about all completed trades on Marketplace. If you set up custom
							alerts, this toggle will be automatically deactivated
						</Text>
					</VStack>
					<Switch
						isChecked={allTradesEnabled}
						onChange={async (event) => {
							await updateAllTradesEnabled(event.target.checked);
						}}
					/>
				</StyledSwitchBox>
			</HStack>
		</VStack>
	);
};

export default AlertSettings;
