import { RequestStatus } from '@montel/montelpro-shared-components/enums';
import { createSlice } from '@reduxjs/toolkit';
import { Quote } from 'common/interfaces/Quote';
import { getActiveCompanyQuotes } from '../ordersThunks';
import translateApiQuotes from '../utils/translateApiQuote';

export interface IActiveQuotesState {
	quotes: Quote[];
	status: RequestStatus;
	error?: any;
	selectedQuoteId: string | null;
}

export const initialState: IActiveQuotesState = {
	quotes: [],
	status: RequestStatus.UNLOADED,
	error: undefined,
	selectedQuoteId: null,
};

export const activeCompanyQuotesSlice = createSlice({
	name: 'activeCompanyQuotes',
	initialState,
	reducers: {
		quoteCreated: (state, { payload: order }) => {
			state.quotes.push(order);
		},
		quoteUpdated: (state, { payload: { order, index } }) => {
			state.quotes[index] = order;
		},
		quoteRemoved: (state, { payload: index }) => {
			state.quotes.splice(index, 1);
		},
		quoteSelected: (state, { payload }) => {
			state.selectedQuoteId = payload;
		},
		quoteDeselected: (state) => {
			state.selectedQuoteId = null;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getActiveCompanyQuotes.pending, (state) => {
				state.status = RequestStatus.LOADING;
				state.error = undefined;
			})
			.addCase(getActiveCompanyQuotes.rejected, (state, { error }) => {
				state.status = RequestStatus.ERROR;
				state.error = error;
			})
			.addCase(getActiveCompanyQuotes.fulfilled, (state, { payload }) => {
				state.quotes = translateApiQuotes(payload);
				state.status = RequestStatus.SUCCESS;
				state.error = undefined;
			});
	},
});

export default activeCompanyQuotesSlice.reducer;
