import { SelectOptionType } from '@montel/montelpro-shared-components';
import { Currency } from 'common/enums/CurrencyEnum';
import { Label, OrderStatus, Region } from 'common/enums/OrderEnums';
import { TradingPositionEnum } from 'common/enums/TradingPositionEnum';
import { labelNames } from 'common/enums/valueLookups/LabelLookup';
import { regionNames } from 'common/enums/valueLookups/RegionLookup';
import { getRegistryOperatorName } from 'common/enums/valueLookups/RegistryOperatorLookup';
import { statusNames } from 'common/enums/valueLookups/StatusLookup';
import { getTechName } from 'common/enums/valueLookups/TechLookup';
import { tradingPositionNames } from 'common/enums/valueLookups/TradingPositionLookup';
import getRelationOptions from 'common/options/getRelationOptions';

function getEnumTypeValue(type: string, value: number, countryOptions: SelectOptionType[]): string {
	switch (type) {
		case 'Technologies':
		case 'Tech':
			return `Tech: ${getTechName(value)}`;
		case 'Region':
			return `Region: ${regionNames[Region[value] as keyof typeof Region]}`;
		case 'Label':
			return `Label: ${labelNames[Label[value] as keyof typeof Label]}`;
		case 'Currencies':
		case 'Currency':
			return `Currency: ${Currency[value] as keyof typeof Currency}`;
		case 'RegistryOperator':
			return `Registry operator: ${getRegistryOperatorName(value)}`;
		case 'Status':
			return `Status: ${statusNames[OrderStatus[value] as keyof typeof OrderStatus]}`;
		case 'ProductionYear':
			return `Year: ${value}`;
		case 'TradingPosition':
			return `Trading position: ${
				tradingPositionNames[TradingPositionEnum[value] as keyof typeof TradingPositionEnum]
			}`;
		case 'Country':
			return `Region: ${countryOptions.find((c) => c.value == value)?.label}`;
		case 'Relation':
			return `Relation: ${getRelationOptions().find((option) => option.value === value)?.label}`;
		default:
			return '';
	}
}

export default getEnumTypeValue;
