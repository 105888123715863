import React, { FunctionComponent } from 'react';
import { AvatarSize } from '@montel/montelpro-shared-components-v2';
import { AvatarImage, AvatarName } from '@montel/montelpro-shared-components-v2';
import { Text } from '@montel/montelpro-shared-components-v2';
import { LOGOS_API_ROUTE } from '../../../apiRoutes';
import { CompanyLogoWithOverlay, StyledCompanyLogo } from '../style';
import { TCounterpartyStatus } from '../types';
import { getOverlayIcon } from './getOverlayIcon';

export interface CompanyLogoProps {
	src?: string;
	size?: AvatarSize;
	variant?: TCounterpartyStatus;
}

const CompanyLogo: FunctionComponent<CompanyLogoProps> = ({ src, size = '4xl', variant = 'primary' }) => {
	const logoUrl = src && LOGOS_API_ROUTE.replace(':filename', src);
	const text =
		variant !== 'default' ? (
			<Text size="xxs" className="company-logo-text">
				{variant?.toUpperCase()}
			</Text>
		) : (
			''
		);

	if (variant === 'default' || variant === 'counterparty') {
		return (
			<StyledCompanyLogo>
				<AvatarImage variant="company" src={logoUrl} size={size} />
				{text}
			</StyledCompanyLogo>
		);
	}

	const Icon = getOverlayIcon(variant as TCounterpartyStatus);
	const statusIcon = <Icon />;

	if (!src) {
		return (
			<StyledCompanyLogo>
				<AvatarName
					size={size}
					fallback={statusIcon}
					color="element"
					textColor="secondaryText"
					borderRadius="lg"
					iconScaling="55%"
				/>
				{text}
			</StyledCompanyLogo>
		);
	}

	return (
		<StyledCompanyLogo>
			<CompanyLogoWithOverlay>
				<AvatarImage variant="company" src={logoUrl} size={size} borderRadius="lg" />
				<AvatarName
					size={size}
					fallback={statusIcon}
					color="rgba(0, 0, 0, 0.5)"
					textColor="primaryText"
					borderRadius="lg"
					iconScaling="55%"
					className="company-logo-overlay"
				/>
			</CompanyLogoWithOverlay>
			{text}
		</StyledCompanyLogo>
	);
};

export default CompanyLogo;
