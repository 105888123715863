import moment from 'moment';
import { IMarketPlaceRole } from 'store/user/userTypes';

const NAME = 'register_cookie';
const setRegisterCookie = (key: string, days: number): void => {
	const name = NAME + '_' + key;
	const date = new Date();
	date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
	const expires = '; expires=' + date.toUTCString();
	const value = date.toUTCString();
	document.cookie = name + '=' + value + expires + '; path=/';
};

const checkRegisterCookie = (userId: string): boolean => {
	const ca = document.cookie.split(';');
	let cookie = null;
	for (let i = 0; i < ca.length; i++) {
		const c = ca[i];
		const bits = c.split('=');
		const name = bits[0];
		const value = bits[1];
		if (name.includes(userId)) {
			cookie = value;
		}
	}
	if (cookie) {
		const cookieExpiry = moment(cookie);
		return cookieExpiry !== null && moment().isBefore(cookieExpiry);
	}
	return false;
};

const shouldRegister = (userId: string | undefined, marketPlaceRoles: IMarketPlaceRole[]): boolean => {
	if (userId) {
		const isValidCookie = checkRegisterCookie(userId);
		const userHaveRoles = marketPlaceRoles && marketPlaceRoles.length;
		return !userHaveRoles && !isValidCookie;
	}
	return false;
};

export { setRegisterCookie, shouldRegister };
