import { FunctionComponent } from 'react';
import { Text, VStack } from '@montel/montelpro-shared-components-v2';

interface DetailProps {
	header: string;
	subheader: string;
}

const Detail: FunctionComponent<DetailProps> = ({ header, subheader }) => {
	return (
		<VStack align="center">
			<Text size="lg" weight="bold">
				{header}
			</Text>
			<Text size="xs">{subheader}</Text>
		</VStack>
	);
};

export default Detail;
