import { Box } from '@montel/montelpro-shared-components-v2';
import styled, { css, keyframes } from 'styled-components';

export const StyledContainer = styled.div`
	position: relative;
`;

export const StyledActionButton = styled.button`
	border: none;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: 34px;
	${({ theme }) => {
		const { colors, spacing, typography, borderRadii } = theme;
		return css`
			padding: ${spacing.xs};
			color: ${colors.white};
			background-color: ${colors.element};
			font-size: ${typography.size.xs};
			font-weight: ${typography.weight.medium};
			&:hover {
				background-color: ${colors.subElement};
				cursor: pointer;
			}
			&:first-child:not(:last-child) {
				border-radius: ${borderRadii.base} ${borderRadii.base} 0 0;
			}
			&:last-child:not(:first-child) {
				border-radius: 0 0 ${borderRadii.base} ${borderRadii.base};
			}
			&:only-child {
				border-radius: ${borderRadii.base};
			}
		`;
	}}
`;

export const HStackConfirmation = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	${({ theme }) => {
		const { colors, spacing, typography, borderRadii } = theme;
		return css`
			padding: ${spacing.xs};
			color: ${colors.background};
			background-color: ${colors.error};
			font-size: ${typography.size.xs};
			font-weight: ${typography.weight.medium};
			&:first-child:not(:last-child) {
				border-radius: ${borderRadii.base} ${borderRadii.base} 0 0;
			}
			&:last-child:not(:first-child) {
				border-radius: 0 0 ${borderRadii.base} ${borderRadii.base};
			}
			&:only-child {
				border-radius: ${borderRadii.base};
			}
		`;
	}}
	button {
		background-color: transparent;
		padding: 0;
		margin: 0;
		border: none;
		&:hover {
			cursor: pointer;
		}
		${({ theme }) => {
			const { typography } = theme;
			return css`
				font-size: ${typography.size.xs};
				font-weight: ${typography.weight.medium};
				text-decoration: underline;
			`;
		}};
	}
`;

export const StyledActionsContainer = styled(Box).attrs({
	borderRadius: 'base',
	bg: 'element',
	w: '200px',
	padding: '0',
	h: 'auto',
})`
	position: absolute;
	top: 40px;
	${({ theme }) => {
		const { zIndices } = theme;
		return css`
			z-index: ${zIndices.modal};
		`;
	}}
`;

const rotateClockwise = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Rotate = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	animation: ${rotateClockwise} 2s linear infinite;
`;
