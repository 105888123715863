import { FunctionComponent } from 'react';
import { Text } from '@montel/montelpro-shared-components-v2';
import { useOrderFormContext } from '../../OrderFormProvider';
import ReviewBox from './ReviewBox';

interface ProductReviewProps {
	onStepChange: (step: number) => void;
	isSubmitted?: boolean;
}

const CommentReview: FunctionComponent<ProductReviewProps> = ({ onStepChange, isSubmitted }) => {
	const { formState } = useOrderFormContext();
	const { comment } = formState.delivery;

	return (
		<ReviewBox onStepChange={onStepChange} isSubmitted={isSubmitted} gotoStep={1} minHeight="auto">
			<Text>
				<Text color="secondaryText" as={'span'}>
					Comment:{' '}
				</Text>
				{comment}
			</Text>
		</ReviewBox>
	);
};

export default CommentReview;
