import { FunctionComponent } from 'react';
import { CheckboxList, HStack, Input, Select, VStack } from '@montel/montelpro-shared-components-v2';
import getLabelOptions from 'common/options/getLabelOptions';
import getOptionsForBoolean from 'common/options/getOptionsForBoolean';
import getProductOptions from 'common/options/getProductOptions';
import getRegistryOperatorOptions from 'common/options/getRegistryOperatorOptions';
import getTradingFrequencyOptions from 'common/options/getTradingFrequencyOptions';
import getTradingPositionOptions from 'common/options/getTradingPositionOptions';
import getTechnologyOptions from 'pages/CompanyRegistration/steps/getTechnologyOptions';
import { MyCompanyState } from '../MyCompanyState';
import getTradingInterestsErrors from './getTradingInterestsErrors';

const TradingInterests: FunctionComponent<{ state: MyCompanyState; setState: React.Dispatch<MyCompanyState> }> = ({
	state,
	setState,
}) => {
	const errors = getTradingInterestsErrors(state);

	return (
		<VStack gap="md">
			<CheckboxList
				options={getTechnologyOptions()}
				label={'Technologies'}
				required
				variant="spaced"
				onChange={(value) => {
					setState({ ...state, technologies: value });
				}}
				value={state.technologies}
				errorMessage={errors.technologies}
			/>
			<Select
				disabled
				label="Products"
				required
				options={getProductOptions()}
				value={state.products}
				onChange={(value) => {
					setState({ ...state, products: value });
				}}
				isMulti
				errorMessage={errors.products}
			/>
			<Select
				label="Trading position"
				required
				options={getTradingPositionOptions()}
				value={state.tradingPosition}
				onChange={(value) => {
					setState({ ...state, tradingPosition: value });
				}}
				errorMessage={errors.tradingPosition}
			/>
			<Select
				label="Registry operators"
				options={getRegistryOperatorOptions(false)}
				isMulti
				value={state.registryOperators}
				onChange={(value) => {
					setState({ ...state, registryOperators: value });
				}}
				errorMessage={errors.registryOperators}
			/>
			<Select
				label="Labels"
				required
				options={getLabelOptions()}
				isMulti
				value={state.labels}
				onChange={(value) => {
					setState({ ...state, labels: value });
				}}
				errorMessage={errors.labels}
			/>
			<HStack gap="xs" wrap="nowrap" fullWidth>
				<Input
					label="Certificates per year"
					value={state.numberOfCertificatesPerYear}
					onChange={(event) => setState({ ...state, numberOfCertificatesPerYear: event.target.value })}
					type="number"
				/>
				<Select
					label="Trading frequency"
					required
					options={getTradingFrequencyOptions()}
					value={state.tradingFrequency}
					onChange={(value) => {
						setState({ ...state, tradingFrequency: value });
					}}
					errorMessage={errors.tradingFrequency}
				/>
			</HStack>
			<HStack gap="xs" wrap="nowrap" fullWidth>
				<Select
					label="EECS registry"
					options={getOptionsForBoolean()}
					value={state.eecsRegistry}
					onChange={(value) => {
						setState({ ...state, eecsRegistry: value });
					}}
				/>
				<Select
					label="Subsidised"
					options={getOptionsForBoolean()}
					value={state.subsidized}
					onChange={(value) => {
						setState({ ...state, subsidized: value });
					}}
				/>
			</HStack>
		</VStack>
	);
};

export default TradingInterests;
