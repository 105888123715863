import { FunctionComponent } from 'react';
import { UserRole } from '@montel/montelpro-shared-components/enums';
import { Box, Text } from '@montel/montelpro-shared-components-v2';
import { useAppSelector } from 'store/hooks';
import { selectHasRole } from 'store/user/userSelectors';
import Td from 'components/Tables/Td';
import Th from 'components/Tables/Th';
import useSortedTable from 'components/Tables/useSortedTable';
import StyledTable from '../Table/styles/StyledTable';
import columnDefinitions from './columnDefinitions';
import { User } from './types';

const DataTable: FunctionComponent<{ data: User[] }> = ({ data }) => {
	const columns = columnDefinitions;
	const showActions = useAppSelector((state) => selectHasRole(state, UserRole.BackOffice, UserRole.TradeManager));
	const table = useSortedTable({ data, columns });
	if (table.getRowModel().rows.length === 0) {
		return (
			<Box px="xl" bg="widget">
				<Text>There is currently nothing to show here</Text>
			</Box>
		);
	}
	return (
		<StyledTable>
			<thead>
				<tr>
					{table.getFlatHeaders().map((header) => (
						<Th key={header.id} header={header} />
					))}
				</tr>
			</thead>
			<tbody>
				{table.getRowModel().rows.map((row) => (
					<tr key={row.id}>
						{row.getVisibleCells().map((cell) => {
							if (cell.column.id !== 'actions' || showActions) {
								return <Td key={cell.id} cell={cell} />;
							}
							return <td key={cell.id} />;
						})}
					</tr>
				))}
			</tbody>
		</StyledTable>
	);
};

export default DataTable;
