import { OrderTypeEnum } from '../OrderEnums';

const OrderTypeNames = {
	[OrderTypeEnum.Default]: 'Default',
	[OrderTypeEnum.FixedPrice]: 'Fixed',
	[OrderTypeEnum.RequestForQuote]: 'RFQ',
};

export const getOrderTypeName = (orderType: OrderTypeEnum) => {
	if (orderType === undefined || orderType === null) {
		return OrderTypeNames[OrderTypeEnum.Default];
	}
	return OrderTypeNames[orderType];
};
