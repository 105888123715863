import { FilterCategoryEnum } from '../FilterCategoryEnum';

const categories: { [key in FilterCategoryEnum]: string } = {
	[FilterCategoryEnum.Default]: '',
	[FilterCategoryEnum.Technology]: 'Tech',
	[FilterCategoryEnum.Region]: 'Region',
	[FilterCategoryEnum.Label]: 'Label',
	[FilterCategoryEnum.Currency]: 'Currency',
	[FilterCategoryEnum.ProductionYear]: 'Year',
	[FilterCategoryEnum.Country]: 'Region',
	[FilterCategoryEnum.TradingPosition]: 'Trading Position',
	[FilterCategoryEnum.Relation]: 'Relation',
};

export const getFilterCategoryTitle = (filterCategory: FilterCategoryEnum): string => {
	return categories[filterCategory] || filterCategory;
};
