import { FunctionComponent } from 'react';
import { Text } from '@montel/montelpro-shared-components-v2';
import { StyledCell } from 'components/Tables/Cells/styles';
import Td from 'components/Tables/Td';

const EmptyRow: FunctionComponent<{ noDataText?: string; displayNoDataText: boolean; nrOfColumns: number }> = ({
	noDataText,
	displayNoDataText,
	nrOfColumns,
}) => (
	<tr>
		<Td colSpan={nrOfColumns}>
			<StyledCell fullWidth justify="center" align="center">
				{displayNoDataText && (
					<Text color="secondaryText" fontStyle="italic">
						{noDataText}
					</Text>
				)}
			</StyledCell>
		</Td>
	</tr>
);

export default EmptyRow;
