import { FunctionComponent } from 'react';
import { VStack } from '@montel/montelpro-shared-components-v2';
import { EllipsisText } from 'primitives/EllipsisText';
import { getProductionPeriodString } from 'utils/orderUtils/getProductionPeriodString';
import PeriodTypeEnum from '../../../../common/enums/PeriodTypeEnum';
import { ProductionPeriod } from '../../../../common/interfaces';
import { StyledCell } from '../../../Tables/Cells/styles';
import StyledTooltip from './styles';
import { getMultiText } from './utils';

export interface MultiPeriodProps {
	/**
	 * This production period will be displayed in the cell.
	 */
	period: ProductionPeriod | undefined;
	/**
	 * All the production periods. They will be displayed in the tooltip.
	 */
	periods: ProductionPeriod[];
	/**
	 * Period type determines the text in the subscript in the cell.
	 */
	periodType: PeriodTypeEnum;
	/**
	 * If true, the cell will render as disabled - text color will be secondaryText (grayish).
	 */
	disabled?: boolean;
	[otherProps: string]: any;
}

const MultiplePeriod: FunctionComponent<MultiPeriodProps> = ({
	period,
	periods,
	periodType,
	disabled,
	...otherProps
}) => {
	const cellText = period && `${getProductionPeriodString(period)}+`;
	const tooltipText = periods.map((p) => getProductionPeriodString(p)).join(', ');
	const textColor = disabled ? 'subElement' : 'primaryText';
	const periodTypeTextColor = disabled ? 'subElement' : 'secondaryText';

	return (
		<StyledCell fullWidth wrap="nowrap" gap="xxs" {...otherProps}>
			<StyledTooltip
				component={
					<VStack fullWidth wrap="nowrap" gap="xxs" {...otherProps}>
						<EllipsisText size="sm" color={textColor}>
							{cellText}
						</EllipsisText>

						<EllipsisText color={periodTypeTextColor} size="xxs">
							{getMultiText(periodType)}
						</EllipsisText>
					</VStack>
				}
			>
				{tooltipText}
			</StyledTooltip>
		</StyledCell>
	);
};

export default MultiplePeriod;
