import { SelectOptionType } from '@montel/montelpro-shared-components-v2';
import { isDeprecatedRegistryOperator } from 'common/enums/valueLookups/RegistryOperatorLookup';
import { getDeprecatedLabelsError, hasDeprecatedLabel } from 'utils/labelUtils';
import hasAtLeastOneItem from 'utils/validationUtils/hasAtLeastOneItem';
import { MyCompanyState } from '../MyCompanyState';

const getTradingInterestsErrors = (state: MyCompanyState) => ({
	technologies: !hasAtLeastOneItem(state.technologies) && 'At least one required',
	products: !hasAtLeastOneItem(state.products) && 'At least one required',
	tradingPosition: !state.tradingPosition && 'This field is required',
	tradingFrequency: !state.tradingFrequency && 'This field is required',
	registryOperators: geRegistryOperatorsError(state.registryOperators),
	labels:
		(!hasAtLeastOneItem(state.labels) && 'At least one required') ||
		(hasDeprecatedLabel(state.labels) && getDeprecatedLabelsError(state.labels)),
});

const geRegistryOperatorsError = (registryOperators: (SelectOptionType | undefined)[]): string | undefined => {
	if (!hasAtLeastOneItem(registryOperators)) {
		return 'At least one required';
	}

	const deprecatedOperators = registryOperators
		.filter((option) => isDeprecatedRegistryOperator(option?.value))
		.map((option) => option?.label);

	if (deprecatedOperators.length === 0) {
		return undefined;
	}

	return deprecatedOperators.length > 1
		? `Registry operators ${deprecatedOperators.join(', ')} are deprecated. Please remove them from your selection.`
		: `Registry operator ${deprecatedOperators[0]} is deprecated. Please remove it from your selection.`;
};
export default getTradingInterestsErrors;
