import { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import { NOTIFICATIONS_MARK_ALL_READ_API_ROUTE, NOTIFICATIONS_MARK_READ_API_ROUTE } from '../../../apiRoutes';
import useSubmit, { PUT } from '../../../hooks/useSubmit';
import { useHub } from '../../../providers/HubProvider';
import { ENotificationStatus, INotification } from './INotification';

const useNotifications = () => {
	const { hubConnection } = useHub();
	const [notifications, setNotifications] = useState<INotification[]>([]);
	const [lastSeen, setLastSeen] = useState<string>();

	const unseenNotificationsCount = useMemo(() => {
		if (!lastSeen) return 0;

		const unseenNotifications = notifications?.filter((item) => new Date(item.created) > new Date(lastSeen));

		return unseenNotifications?.length ?? 0;
	}, [notifications, lastSeen]);

	const [markAllRead] = useSubmit(NOTIFICATIONS_MARK_ALL_READ_API_ROUTE, PUT, {
		messageOnSuccess: 'All notifications marked read',
		messageOnError: 'An error occurred',
	});

	function addNotification(newNotification: INotification) {
		const isDuplicate = notifications.findIndex((item) => item.id === newNotification.id) !== -1;
		if (isDuplicate) return;
		setNotifications((prevState) => [newNotification, ...prevState]);
	}

	function addNotifications(newNotifications: INotification[]) {
		const sortedNotifications = [...newNotifications].sort((a, b) => b.created.localeCompare(a.created));
		setNotifications((prevState) => prevState.concat(sortedNotifications));
	}

	function markNotificationAsSeen(notification: INotification) {
		setNotifications((prevState) => {
			return prevState.map((item) => {
				return { ...item, status: item.id === notification.id ? ENotificationStatus.Read : item.status };
			});
		});

		axios
			.put(NOTIFICATIONS_MARK_READ_API_ROUTE({ notificationId: notification.id }))
			.catch(() => toast.error('Failed to mark notification as seen on the server', { autoClose: 2000 }));
	}

	async function markAllNotificationsAsRead() {
		const { success } = await markAllRead({});
		if (success) {
			setNotifications((prevState) =>
				prevState.map((notification) => ({ ...notification, status: ENotificationStatus.Read })),
			);
		}
	}

	useEffect(() => {
		if (hubConnection) {
			hubConnection.on('notification_new', (newNotification: any) => {
				try {
					addNotification(newNotification);
				} catch (e) {
					console.log('Error parsing notification');
				}
			});
		}
		return () => {
			if (hubConnection) {
				hubConnection.off('notification_new');
			}
		};
	}, [hubConnection]);

	return {
		notifications,
		unseenNotificationsCount,
		addNotifications,
		addNotification,
		setLastSeen,
		markNotificationAsSeen,
		markAllNotificationsAsRead,
	};
};

export type TUseNotificationsReturn = ReturnType<typeof useNotifications>;
export default useNotifications;
