import { useState } from 'react';
import { toast } from 'react-toastify';
import { RequestStatus } from '@montel/montelpro-shared-components/enums';
import axios, { AxiosError } from 'axios';

interface useDeleteReturn {
	deleteItem: (url: string, options?: any) => Promise<boolean>;
	deleteStatus: RequestStatus;
	deleteError?: AxiosError<any> | undefined;
}

const useDelete = (toastOptions?: {
	messageOnSuccess?: React.ReactElement | string | undefined;
	messageOnError?: React.ReactElement | string | undefined;
}): useDeleteReturn => {
	const [deleteStatus, setDeleteStatus] = useState(RequestStatus.UNLOADED);
	const [deleteError, setDeleteError] = useState<AxiosError<any> | undefined>(undefined);
	const { messageOnSuccess, messageOnError } = toastOptions || {};
	const deleteItem = async (url: string, options: any = {}) => {
		setDeleteStatus(RequestStatus.LOADING);
		let success = false;
		try {
			await axios.delete(url, options);
			setDeleteStatus(RequestStatus.SUCCESS);
			success = true;
			messageOnSuccess && toast.success(messageOnSuccess, { autoClose: 3000 });
		} catch (error) {
			const axiosError = error as AxiosError<any>;
			setDeleteError(axiosError);
			setDeleteStatus(RequestStatus.ERROR);
			messageOnError && toast.error(messageOnError, { autoClose: 3000 });
		}
		return success;
	};

	return { deleteItem, deleteError, deleteStatus };
};

export default useDelete;
