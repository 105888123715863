import {
	selectBlockedCompanies,
	selectCompanies,
	selectCompanyInvitations,
	selectCounterParties,
} from '../../store/companies/companiesSelectors';
import { useAppSelector } from '../../store/hooks';

export function applyQuickFilter(criterion: string) {
	const companies = useAppSelector(selectCompanies);
	const invitations = useAppSelector(selectCompanyInvitations);
	const counterparties = useAppSelector(selectCounterParties);
	const blocked = useAppSelector(selectBlockedCompanies);

	switch (criterion) {
		case 'all':
			return companies;
		case 'invitations':
			return invitations;
		case 'counterparties':
			return counterparties;
		case 'blocked':
			return blocked;
		default:
			return companies;
	}
}
