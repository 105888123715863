import { FunctionComponent } from 'react';
import { Text, Tooltip } from '@montel/montelpro-shared-components-v2';
import { EllipsisText } from 'primitives/EllipsisText';
import { styled } from 'styled-components';
import { truncate } from 'utils/truncate';
import { StyledCell } from './styles';
import { CellProps } from './types';

export interface TextCellProps extends CellProps {
	text: string;
	truncateAt?: number;
}

const StyledTooltip = styled(Tooltip)`
	margin-left: 0;
`;

const TruncatedTextWithTooltip: FunctionComponent<{
	fullText: string;
	truncatedText: string;
	cellColor: string;
}> = ({ fullText, truncatedText, cellColor }) => (
	<StyledTooltip
		component={
			<Text size="sm" color={cellColor}>
				{truncatedText}
			</Text>
		}
	>
		{fullText}
	</StyledTooltip>
);

const TextCell: FunctionComponent<TextCellProps> = ({
	text,
	disabled,
	align = 'flex-start',
	justify = 'flex-start',
	offsetLeft,
	truncateAt: maxCharacters = 0,
}) => {
	const cellColor = disabled ? 'subElement' : 'primaryText';
	const showTooltip = maxCharacters > 0 && text.length > maxCharacters;
	const truncatedText = truncate(text, maxCharacters > 0 ? maxCharacters : 999);

	return (
		<StyledCell align={align} justify={justify} $offsetLeft={offsetLeft}>
			{showTooltip ? (
				<TruncatedTextWithTooltip fullText={text} truncatedText={truncatedText} cellColor={cellColor} />
			) : (
				<EllipsisText as={'span'} size="sm" color={cellColor}>
					{text}
				</EllipsisText>
			)}
		</StyledCell>
	);
};

export default TextCell;
