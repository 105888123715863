import { FunctionComponent } from 'react';
import { StyledCell } from '../styles';
import LabelPending from './Labels/LabelPending';
import { StatusCellProps } from './types';
import { getCellProps, getStatusCellProps } from './utils';

const PendingCell: FunctionComponent<StatusCellProps> = (props) => {
	const color = 'white';
	const styledCellProps = getCellProps(props);
	const { offsetLeft, offsetRight, ...restStyle } = styledCellProps;
	const { size, boxSize } = getStatusCellProps(props);

	return (
		<StyledCell $offsetLeft={offsetLeft} $offsetRight={offsetRight} {...restStyle}>
			<LabelPending color={color} boxSize={boxSize} size={size} />
		</StyledCell>
	);
};

export default PendingCell;
