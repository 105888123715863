import { FunctionComponent } from 'react';
import {
	HStack,
	IconChevronJumpToEnd,
	IconChevronJumpToStart,
	IconChevronLeft,
	IconChevronRight,
	Text,
} from '@montel/montelpro-shared-components-v2';
import { PaginationButton } from './PaginationButton';
import SimpleSelect from './SimpleSelect';
import { Navigation, StyledPagination } from './styles';

interface PaginationProps {
	pageIndex: number;
	setPageIndex: (pageIndex: number) => void;
	pageSize: number;
	setPageSize: (pageSize: number) => void;
	pageCount: number;
}

const Pagination: FunctionComponent<PaginationProps> = ({
	pageIndex,
	setPageIndex,
	pageSize,
	setPageSize,
	pageCount,
}) => {
	const isFirstPage = pageIndex === 0;
	const lastPage = pageCount - 1;

	return (
		<StyledPagination gap="lg" align="center" fullWidth justify="flex-end">
			<HStack gap="xs" align="center">
				<Text size="xs">Rows per page:</Text>
				<SimpleSelect
					value={pageSize}
					onChange={(value: string | number) => setPageSize(Number(value))}
					options={[10, 20, 30, 40, 50]}
				/>
			</HStack>
			<Navigation gap="sm" align="center">
				<GoToStartButton disabled={isFirstPage} onClick={() => setPageIndex(0)} />
				<GoToPreviousButton disabled={isFirstPage} onClick={() => setPageIndex(pageIndex - 1)} />
				<Text size="xs">{`${pageIndex + 1} of ${pageCount}`}</Text>
				<GoToNextButton disabled={pageIndex === lastPage} onClick={() => setPageIndex(pageIndex + 1)} />
				<GoToEndButton disabled={pageIndex === lastPage} onClick={() => setPageIndex(lastPage)} />
			</Navigation>
		</StyledPagination>
	);
};

const GoToStartButton: FunctionComponent<{ disabled: boolean; onClick: () => void }> = ({ disabled, onClick }) => (
	<PaginationButton disabled={disabled} onClick={onClick} icon={<IconChevronJumpToStart boxSize="12px" />} />
);

const GoToPreviousButton: FunctionComponent<{ disabled: boolean; onClick: () => void }> = ({ disabled, onClick }) => (
	<PaginationButton disabled={disabled} onClick={onClick} icon={<IconChevronLeft boxSize="19px" />} />
);

const GoToNextButton: FunctionComponent<{ disabled: boolean; onClick: () => void }> = ({ disabled, onClick }) => (
	<PaginationButton disabled={disabled} onClick={onClick} icon={<IconChevronRight boxSize="19px" />} />
);

const GoToEndButton: FunctionComponent<{ disabled: boolean; onClick: () => void }> = ({ disabled, onClick }) => (
	<PaginationButton disabled={disabled} onClick={onClick} icon={<IconChevronJumpToEnd boxSize="12px" />} />
);

export default Pagination;
