import { FunctionComponent, useEffect, useState } from 'react';
import { Outlet, useOutletContext } from 'react-router';
import { useGetOnMount } from '@montel/montelpro-shared-components/hooks';
import { HStack, VStack } from '@montel/montelpro-shared-components-v2';
import { GET_TRADE_PRODUCTION_YEARS } from 'apiRoutes';
import getOrderFilterOptions from 'common/options/orderFilterOptions';
import PageHeader from 'primitives/PageHeader';
import SuspenseStream from 'primitives/SuspenseStream';
import { css, styled } from 'styled-components';
import { OrderOption } from 'components/Filter/types';
import { useTradeFilter } from 'components/Filter/useProductFilter';
import { ServerSideTableContextProvider } from 'components/Tables/Pagination/ServerSideTableContext';
import FilterDropDown from '../../components/Filter/FilterDropdown';
import SelectedFilters from '../../components/Filter/SelectedFilters';
import { tradesMenuConfig } from './tradesMenuConfig';

const StyledSection = styled(HStack)`
	${({ theme }) => css`
		margin-bottom: ${theme.spacing.md};
	`}
`;

type TradesContext = {
	filterChoices: any;
};

export function useTradesContext() {
	return useOutletContext<TradesContext>();
}

const Trades: FunctionComponent = () => {
	const [productFilterOptions, setProductFilterOptions] = useState<OrderOption[]>([]);
	const tradeFilters = useTradeFilter();
	const { filterChoices, handleFilterClick } = tradeFilters;
	const { data: productionYears, status } = useGetOnMount(GET_TRADE_PRODUCTION_YEARS);

	useEffect(() => {
		productionYears && setProductFilterOptions(getOrderFilterOptions(productionYears));
	}, [productionYears]);

	return (
		<SuspenseStream status={status}>
			<VStack fullWidth gap="xxl">
				<PageHeader title={'Trade log'} topMenuConfig={tradesMenuConfig}>
					<HStack fullWidth align="center" wrap="nowrap" justify="flex-end">
						<FilterDropDown
							options={productFilterOptions}
							onClick={handleFilterClick}
							selectedOptions={filterChoices}
							alignToRight
						/>
					</HStack>
				</PageHeader>
				<SelectedFilters filterChoices={filterChoices} handleDeleteFilterChoices={handleFilterClick} />
				<StyledSection fullWidth gap="md" wrap="nowrap">
					<ServerSideTableContextProvider
						defaultSort={[
							{
								id: 'tradeTime',
								desc: true,
							},
						]}
					>
						<Outlet
							context={{
								filterChoices,
							}}
						/>
					</ServerSideTableContextProvider>
				</StyledSection>
			</VStack>
		</SuspenseStream>
	);
};

export default Trades;
