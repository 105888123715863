import React, { FunctionComponent } from 'react';
import { Text, VStack } from '@montel/montelpro-shared-components-v2';
import { MarketRoleEnum } from '../../../common/enums/MarketRoleEnum';
import { marketRoleNames } from '../../../common/enums/valueLookups/MarketRoleLookup';
import { PublicCompanyInformation } from '../../../common/interfaces/Company';
import ActiveTech from '../../ActiveTech';
import ActionButtonsGroup from '../ActionButtonsGroup';
import { HStackFullWidth, StyledCompanyName } from '../style';
import { VStackFullWidth } from '../style';
import CompanyLogo from './CompanyLogo';
import { getCounterpartyStatus } from './getCounterpartyStatus';
import TradingPositionAndCountry from './TradingPositionAndCountry';

export interface CompanyDetailsShortProps {
	companyInformation: PublicCompanyInformation;
	showSecondaryActions?: boolean;
	refreshContent: () => void;
}

const CompanyDetailsShort: FunctionComponent<CompanyDetailsShortProps> = ({
	companyInformation,
	showSecondaryActions = false,
	refreshContent,
}) => {
	const {
		companyName,
		companyLogo,
		counterpartyStatus,
		countryCode,
		tradingPosition,
		marketRole,
		technologies,
		isBlockedByMyCompany,
		isBlockingMyCompany,
		isInvitedByMyCompany,
	} = companyInformation;

	const status = getCounterpartyStatus(
		counterpartyStatus,
		isBlockedByMyCompany,
		isBlockingMyCompany,
		isInvitedByMyCompany,
	);
	const isActive = !isBlockingMyCompany && status !== 'paused' && status !== 'blocked';
	const companyNameColor = isActive ? 'primaryText' : 'secondaryText';
	const techIconsColor = isActive ? 'white' : 'subElement';

	return (
		<VStackFullWidth gap="xs">
			<HStackFullWidth justify="space-between">
				<VStack gap="xxs">
					<TradingPositionAndCountry tradingPosition={tradingPosition} countryCode={countryCode} />
					<StyledCompanyName size="lg" weight="bold" color={companyNameColor}>
						{companyName}
					</StyledCompanyName>
					<Text color="secondaryText">
						{marketRoleNames[MarketRoleEnum[marketRole] as keyof typeof MarketRoleEnum]}
					</Text>
				</VStack>
				<CompanyLogo src={companyLogo} variant={status} />
			</HStackFullWidth>
			<ActiveTech tech={technologies} color={techIconsColor} />
			<ActionButtonsGroup
				companyInformation={companyInformation}
				showSecondaryActions={showSecondaryActions}
				refreshContent={refreshContent}
			/>
		</VStackFullWidth>
	);
};

export default CompanyDetailsShort;
