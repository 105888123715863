import React, { FunctionComponent, useMemo } from 'react';
import { Order } from 'common/interfaces/Order';
import { orderTableColumns } from 'components/OrderTables/Columns/orderTableColumns';
import OrderTable from 'components/OrderTables/OrderTable';
import { setWidth } from 'components/Tables/Cells/utils/setWidth';

interface ExpiredOrdersTableProps {
	orders: Order[];
}

const ExpiredOrdersTable: FunctionComponent<ExpiredOrdersTableProps> = ({ orders }) => {
	const columns = useMemo(
		() => [
			orderTableColumns.createdTime(setWidth('15%')),
			orderTableColumns.productionPeriod({
				headCellProps: { text: 'Production' },
				columnDefProps: {
					meta: {
						width: '10%',
					},
				},
			}),
			orderTableColumns.tech(setWidth('7.5%')),
			orderTableColumns.region(setWidth('15%')),
			orderTableColumns.delivery(),
			orderTableColumns.owner(),
			orderTableColumns.type(setWidth('5%')),
			orderTableColumns.quantity(setWidth('7.5%')),
			orderTableColumns.price(setWidth('10%')),
			orderTableColumns.status({
				cellProps: { offsetLeft: '1.5rem' },
				headCellProps: { offsetLeft: '1.5rem' },
				columnDefProps: {
					meta: {
						width: '180px',
					},
				},
			}),
		],
		[orders],
	);

	return (
		<OrderTable
			header="Expired/cancelled orders"
			orders={orders}
			columns={columns}
			initialSorting={[{ id: 'createdTime', desc: true }]}
		/>
	);
};

export default ExpiredOrdersTable;
