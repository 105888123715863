import { createContext, FunctionComponent, useContext, useEffect, useState } from 'react';
import { useUpdate } from '@montel/montelpro-shared-components/hooks';
import { initializeApp } from 'firebase/app';
import { getMessaging, isSupported as isFirebaseMessagingSupported, Messaging, onMessage } from 'firebase/messaging';

interface IFirebaseContext {
	messaging: Messaging | undefined;
	vapidKey: string;
	isSupported: boolean;
}

interface IFirebaseConfig {
	apiKey: string | undefined;
	authDomain: string | undefined;
	projectId: string | undefined;
	storageBucket: string | undefined;
	messagingSenderId: string | undefined;
	appId: string | undefined;
}

const FirebaseContext = createContext<any>({});

const FirebaseProvider: FunctionComponent<{
	children: any;
	config: IFirebaseConfig;
	vapidKey: string | undefined;
}> = ({ children, config, vapidKey }) => {
	const [messaging, setMessaging] = useState<Messaging | undefined>();
	const [isSupported, setIsSupported] = useState(false);

	const [onMessageRegistered, setOnMessageRegistered] = useState(false);

	useEffect(() => {
		(async () => {
			if (await isFirebaseMessagingSupported()) {
				setIsSupported(true);

				const app = initializeApp(config);
				setMessaging(getMessaging(app));
			}
		})();
	}, []);

	useUpdate(() => {
		if (!messaging || onMessageRegistered) return;

		const getLocalUnixTimestampInMs = (date: Date) => {
			const localOffsetInMinutes = date.getTimezoneOffset();
			const localOffsetInMs = localOffsetInMinutes * 60000;
			const absoluteOffset = Math.abs(localOffsetInMs);

			return date.getTime() + absoluteOffset;
		};

		onMessage(messaging, (message) => {
			if (document.visibilityState === 'visible') {
				const timeStamp = message.data?.DateTime
					? getLocalUnixTimestampInMs(new Date(message.data.DateTime))
					: undefined;

				new Notification(message.data?.Title ?? 'Marketplace Alert', {
					body: message.data?.Body,
					timestamp: timeStamp,
					icon: '/marketplace.svg',
				});
			}
		});

		setOnMessageRegistered(true);
	}, [messaging]);

	return (
		<FirebaseContext.Provider
			value={{
				messaging,
				vapidKey,
				isSupported,
			}}
		>
			{children}
		</FirebaseContext.Provider>
	);
};

const useFirebaseContext = () => {
	const context: IFirebaseContext = useContext(FirebaseContext);
	if (context === undefined) {
		throw new Error(`useFirebaseContext must be used within a FirebaseProvider`);
	}

	return context;
};

export { FirebaseProvider, useFirebaseContext };
