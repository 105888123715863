import { FunctionComponent } from 'react';
import { HStack } from '@montel/montelpro-shared-components-v2';
import { useOrderDetailsContext } from '../../OrderDetailsContext';
import Detail from './Detail';

const RFQTopDetails: FunctionComponent = () => {
	const { order } = useOrderDetailsContext();
	const { quantity, remainingQuantity } = order.cost;

	return (
		<HStack align="center" justify="space-evenly">
			<Detail
				header={`${remainingQuantity?.toDefaultNumberFormat(0)} MWh`}
				subheader={order.isMyOrder ? `Original quantity: ${quantity} MWh` : 'Partially tradable'}
			/>
			<Detail header={'RFQ'} subheader={'Request for quote'} />
		</HStack>
	);
};

export default RFQTopDetails;
