import { FunctionComponent } from 'react';
import { Button, IconAdd, Tooltip } from '@montel/montelpro-shared-components-v2';
import { styled } from 'styled-components';

const NewOrderButton: FunctionComponent<{ active: boolean; disabled: boolean; onClick: () => void; text?: string }> = ({
	active,
	disabled,
	onClick,
	text = 'New order',
}) => {
	const Button = () => (
		<StyledButton
			$noPointerEvents={active}
			isDisabled={disabled}
			text={text}
			leftIcon={<IconAdd />}
			onClick={onClick}
		/>
	);

	return disabled ? <Tooltip component={<Button />}>Upgrade your user</Tooltip> : <Button />;
};

const StyledButton = styled(Button)<{ $noPointerEvents: boolean }>`
	${({ $noPointerEvents }) => $noPointerEvents && `pointer-events: none`}
`;

export default NewOrderButton;
