import { RegistryOperator } from '../OrderEnums';

export const registryOperatorNames = {
	[RegistryOperator.Default]: 'Default',
	[RegistryOperator.Any]: 'Any',
	[RegistryOperator.Borzen]: 'Borzen',
	[RegistryOperator.CancellationStatement]: 'Cancellation Statement',
	[RegistryOperator.VertiCer]: 'VertiCer',
	[RegistryOperator.Cecar]: 'Cesar',
	[RegistryOperator.CecarWithOutEECS]: 'Cesar- without EECS',
	[RegistryOperator.CMODenmark]: 'CMO.Denmark',
	[RegistryOperator.Deprecated_CMOGrexel]: 'CMO.Grexel',
	[RegistryOperator.CNMC]: 'CNMC',
	[RegistryOperator.CREG]: 'CREG',
	[RegistryOperator.EControl]: 'E-Control',
	[RegistryOperator.ECwape]: 'e-cwape',
	[RegistryOperator.Elering]: 'Elering',
	[RegistryOperator.Finextra]: 'Finextra',
	[RegistryOperator.GSE]: 'GSE',
	[RegistryOperator.NECS]: 'NECS',
	[RegistryOperator.Ofgem]: 'Ofgem',
	[RegistryOperator.OKTE]: 'OKTE',
	[RegistryOperator.OTE]: 'OTE',
	[RegistryOperator.Portugal]: 'Portugal',
	[RegistryOperator.Powernext]: 'Powernext',
	[RegistryOperator.Pronovo]: 'Pronovo',
	[RegistryOperator.TGE]: 'TGE',
	[RegistryOperator.TSOCyprus]: 'TSO Cyprus',
	[RegistryOperator.UBA]: 'UBA',
	[RegistryOperator.VREG]: 'VREG',
	[RegistryOperator.GREX]: 'G-REX',
	[RegistryOperator.SEDABulgaria]: 'SEDA- Bulgaria',
	[RegistryOperator.HROTE]: 'HROTE',
};
export const getRegistryOperatorName = (registryOperator: RegistryOperator) => registryOperatorNames[registryOperator];

//These labels have been deprecated and should not be available as options in the UI
export const deprecatedRegistryOperators = [RegistryOperator.Deprecated_CMOGrexel];

export const isDeprecatedRegistryOperator = (registryOperator: RegistryOperator) =>
	deprecatedRegistryOperators.includes(registryOperator);
