import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSearchFilter } from '@montel/montelpro-shared-components/hooks';
import { selectCountries } from 'store/lists/listsSelectors';
import { PublicCompanyInformation } from '../../common/interfaces';
import useCompaniesFilter from '../../components/Filter/useMembersFilter';
import { selectCompanies, selectCompanyInvitationsRecievedCount } from '../../store/companies/companiesSelectors';
import { useAppSelector } from '../../store/hooks';
import getCompanyFilterOptions from './companyFilterOptions';
import { applyQuickFilter } from './getFilter';

const useFilters = () => {
	const allCompanies = useAppSelector(selectCompanies);
	const invitationsCount = useAppSelector(selectCompanyInvitationsRecievedCount);

	const [searchParams, setSearchParams] = useSearchParams();
	const baseQuickFilter = 'all';
	const allowedQuickFilters = ['all', 'counterparties', 'blocked'];
	const quickFilterParam = searchParams.get('quickFilter');

	const [primaryFilterValue, setPrimaryFilterValue] = useState<string>(baseQuickFilter);
	const [quickFilter, setQuickFilter] = useState<string>(baseQuickFilter);
	const [invitationsFilter, setInvitationsFilter] = useState<string>(baseQuickFilter);

	const clearFilterParams = () => {
		setSearchParams((params) => {
			params.delete('quickFilter');
			return params;
		});
	};

	useEffect(() => {
		if (!quickFilterParam || !allowedQuickFilters.includes(quickFilterParam)) return;
		setQuickFilter(quickFilterParam);
		setPrimaryFilterValue(quickFilterParam);
	}, [quickFilterParam]);

	const onInvitationFilterChange = (value: string) => {
		setInvitationsFilter(value);
		setPrimaryFilterValue(value);
		setQuickFilter(value);
		clearFilterParams();
	};

	const onQuickFilterChange = (value: string) => {
		setQuickFilter(value);
		setPrimaryFilterValue(value);
		clearFilterParams();
	};

	const primaryFilteredCompanies = applyQuickFilter(primaryFilterValue);

	/* Secondary Filtering */
	const filter = useCompaniesFilter();
	const countryOptions = useAppSelector(selectCountries);

	const options = getCompanyFilterOptions(allCompanies, countryOptions);
	const { filterMembers, filterChoices } = filter;
	const secondaryFilteredCompanies = filterMembers(
		primaryFilteredCompanies,
		filterChoices,
	) as unknown as PublicCompanyInformation[];

	/* Search */
	const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
	const { filteredData: searchResults, setSearchFilter } = useSearchFilter(secondaryFilteredCompanies, [
		'companyName',
	]);

	const stripSpecialCharacters = (str: string) => {
		return str.replace(/[^a-zA-ZøØåÅæÆ0-9 ]/g, '');
	};
	const onSearchValueChange = (e: any) => {
		let query = e?.target?.value || '';
		query = stripSpecialCharacters(query);
		setSearchValue(query);
		setSearchFilter(query);
	};

	const isFiltered =
		(searchValue !== undefined && searchValue !== '') ||
		Object.keys(filterChoices)
			.map((key) => filterChoices[key].length > 0)
			.some((x) => x);

	return {
		filter,
		filterOptions: options,
		invitationsCount,
		onInvitationFilterChange,
		invitationsFilterValue: invitationsFilter,
		quickFilterValue: quickFilter,
		onQuickFilterChange,
		searchValue,
		onSearchValueChange,
		isFiltered,
		searchResults,
	};
};

export default useFilters;
