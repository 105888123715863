import React, { FunctionComponent } from 'react';
import { ButtonSize, IconButton, IconMail } from '@montel/montelpro-shared-components-v2';
import { UserRole } from 'common/enums';
import { useAppSelector } from 'store/hooks';
import { selectUserRole } from 'store/user/userSelectors';

export interface KycButtonProps {
	emailAddresses: string[] | null;
	onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
	size?: ButtonSize;
}

const KycButton: FunctionComponent<KycButtonProps> = ({
	emailAddresses,
	onClick = (event: React.MouseEvent<HTMLButtonElement>) => event.stopPropagation(),
	size = 'md',
}) => {
	const userRole = useAppSelector(selectUserRole);
	if (!shouldSeeKyc(userRole) || !emailAddresses) return <></>;

	return (
		<IconButton
			as="a"
			size={size}
			icon={<IconMail />}
			href={`mailTo:${emailAddresses.join(';')}?subject=Montel Marketplace KYC Request`}
			onClick={onClick}
			title="Send KYC request"
		/>
	);
};

const shouldSeeKyc = (role: UserRole) => {
	return role && role !== UserRole.ReadOnly;
};

export default KycButton;
