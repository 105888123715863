import React, { FunctionComponent, useState } from 'react';
import { toast } from 'react-toastify';
import { DropdownPositioningContainer, DropdownSeparator } from '@montel/montelpro-shared-components-v2';
import { motion } from 'framer-motion';
import { AnimatePresence } from 'framer-motion';
import useRefreshPage from 'hooks/useRefreshPage';
import isCompanyIdValid from 'utils/activeCompanyUtils/isCompanyIdValid';
import saveActiveCompanyIdToLocalStorage from 'utils/activeCompanyUtils/saveActiveCompanyIdToLocalStorage';
import { useAppSelector } from '../../../store/hooks';
import {
	selectActiveCompanyId,
	selectFirstName,
	selectLastName,
	selectMarketPlaceRoles,
	selectUserCompanies,
	selectUserCompany,
	selectUserId,
} from '../../../store/user/userSelectors';
import ActiveCompanyItem from './ActiveCompanyItem';
import CompanyGroup from './CompanyGroup';
import MenuItem from './MenuItem';
import { StyledProfileContainer } from './Profile.style';
import ProfileButton from './ProfileButton';
import SignOutButton from './SignOutButton';

const Profile: FunctionComponent<{ showCompanyLinks: boolean }> = ({ showCompanyLinks }) => {
	const [profileOpen, setProfileOpen] = useState(false);
	const [companySwitchOpen, setCompanySwitchOpen] = useState(false);

	const userCompany = useAppSelector(selectUserCompany);
	const userCompanies = useAppSelector(selectUserCompanies);
	const activeCompanyId = useAppSelector(selectActiveCompanyId);
	const marketPlaceRoles = useAppSelector(selectMarketPlaceRoles);
	const userId = useAppSelector(selectUserId);
	const firstName = useAppSelector(selectFirstName);
	const lastName = useAppSelector(selectLastName);

	const moreThanOneCompany = activeCompanyId && userCompanies && userCompanies.length > 1;
	const refreshPage = useRefreshPage();

	const changeCompany = async (id: number) => {
		if (!isCompanyIdValid(id, marketPlaceRoles)) {
			toast.error("Can't change to this company right now. Try to reload page.");
			return;
		}

		saveActiveCompanyIdToLocalStorage(id, userId);
		refreshPage();
	};

	const onClose = () => {
		setProfileOpen(false);
		setCompanySwitchOpen(false);
	};

	return (
		<DropdownPositioningContainer>
			<ProfileButton
				firstName={firstName ?? null}
				lastName={lastName ?? null}
				company={userCompany ? userCompany : null}
				onCLick={() => setProfileOpen(!profileOpen)}
			/>
			{profileOpen && (
				<AnimatePresence>
					<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
						<StyledProfileContainer closeMenu={onClose} topPosition="3.5rem">
							{activeCompanyId && companySwitchOpen ? (
								<CompanyGroup
									userCompanies={userCompanies}
									activeCompanyId={activeCompanyId}
									onGoBack={() => setCompanySwitchOpen(false)}
									onClick={changeCompany}
								/>
							) : (
								<>
									{moreThanOneCompany && (
										<ActiveCompanyItem
											onClick={() => setCompanySwitchOpen(!companySwitchOpen)}
											active={userCompany ?? ''}
										/>
									)}
									{showCompanyLinks && (
										<>
											<MenuItem text={'Join company'} link={'/join-company'} />
											<MenuItem text={'Register company'} link={'/company/registration'} />
										</>
									)}
									<MenuItem text={'Notification center'} link={'/notification-settings'} />
									<DropdownSeparator />
									<SignOutButton />
								</>
							)}
						</StyledProfileContainer>
					</motion.div>
				</AnimatePresence>
			)}
		</DropdownPositioningContainer>
	);
};

export default Profile;
