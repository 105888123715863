import { RequestStatus } from '@montel/montelpro-shared-components/enums';
import { Text, VStack } from '@montel/montelpro-shared-components-v2';
import { AxiosError } from 'axios';
import moment from 'moment';
import { INotification } from '../INotification';
import NotificationsGroup from './NotificationsGroup';
import { StyledCenter } from './NotificationsStack.style';

type Props = {
	notifications: INotification[];
	onNotificationClick: (notification: INotification) => void;
	status: RequestStatus;
	error: AxiosError<any, any> | undefined;
};

const NotificationsStack = ({ notifications, onNotificationClick }: Props) => {
	const today = moment();
	const oneWeekAgo = moment().subtract(1, 'weeks');

	const todayArray = notifications.filter(({ created }) => moment(created).isSame(today, 'day'));
	const oneWeekOldArray = notifications.filter(({ created }) =>
		moment(created).isBetween(oneWeekAgo, today.startOf('day'), null),
	);
	const olderThanOneWeekArray = notifications.filter(({ created }) => moment(created).isBefore(oneWeekAgo));

	return (
		<>
			{notifications && notifications.length > 0 ? (
				<VStack fullWidth>
					{todayArray.length > 0 && (
						<NotificationsGroup
							notifications={todayArray}
							onNotificationClick={onNotificationClick}
							groupName="TODAY"
						/>
					)}
					{oneWeekOldArray.length > 0 && (
						<NotificationsGroup
							notifications={oneWeekOldArray}
							onNotificationClick={onNotificationClick}
							groupName="THIS WEEK"
							groupNameColor="secondaryText"
						/>
					)}
					{olderThanOneWeekArray.length > 0 && (
						<NotificationsGroup
							notifications={olderThanOneWeekArray}
							onNotificationClick={onNotificationClick}
							groupName="OLDER"
							groupNameColor="secondaryText"
						/>
					)}
				</VStack>
			) : (
				<StyledCenter>
					<Text size="sm" color="secondaryText">
						You have no notifications
					</Text>
				</StyledCenter>
			)}
		</>
	);
};

export default NotificationsStack;
