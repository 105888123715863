import robotoBlackTTF from 'assets/fonts/Roboto-Black.ttf';
import robotoBlackItalicTTF from 'assets/fonts/Roboto-BlackItalic.ttf';
import robotoBoldTTF from 'assets/fonts/Roboto-Bold.ttf';
import robotoBoldItalicTTF from 'assets/fonts/Roboto-BoldItalic.ttf';
import robotoItalicTTF from 'assets/fonts/Roboto-Italic.ttf';
import robotoLightTTF from 'assets/fonts/Roboto-Light.ttf';
import robotoLightItalicTTF from 'assets/fonts/Roboto-LightItalic.ttf';
import robotoMediumTTF from 'assets/fonts/Roboto-Medium.ttf';
import robotoMediumItalicTTF from 'assets/fonts/Roboto-MediumItalic.ttf';
import robotoRegularTTF from 'assets/fonts/Roboto-Regular.ttf';
import robotoThinTTF from 'assets/fonts/Roboto-Thin.ttf';
import robotoThinItalicTTF from 'assets/fonts/Roboto-ThinItalic.ttf';
import { createGlobalStyle } from 'styled-components';

const FontStyles = createGlobalStyle`

@font-face {
	font-family: 'Roboto';
	src: url(${robotoRegularTTF}) format('truetype');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'Roboto';
	src: url(${robotoItalicTTF}) format('truetype');
	font-style: italic;
}
@font-face {
	font-family: 'Roboto';
	src: url(${robotoBoldTTF}) format('truetype');
	font-weight: 700;
}
@font-face {
	font-family: 'Roboto';
	src: url(${robotoBoldItalicTTF}) format('truetype');
	font-weight: 700;
	font-style: italic;
}
@font-face {
	font-family: 'Roboto';
	src: url(${robotoBlackTTF}) format('truetype');
	font-weight: 900;
}
@font-face {
	font-family: 'Roboto';
	src: url(${robotoBlackItalicTTF}) format('truetype');
	font-weight: 900;
	font-style: italic;
}
@font-face {
	font-family: 'Roboto';
	src: url(${robotoThinTTF}) format('truetype');
	font-weight: 100;
}
@font-face {
	font-family: 'Roboto';
	src: url(${robotoThinItalicTTF}) format('truetype');
	font-weight: 100;
	font-style: italic;
}
@font-face {
	font-family: 'Roboto';
	src: url(${robotoLightTTF}) format('truetype');
	font-weight: 300;
}
@font-face {
	font-family: 'Roboto';
	src: url(${robotoLightItalicTTF}) format('truetype');
	font-weight: 300;
	font-style: italic;
}
@font-face {
	font-family: 'Roboto';
	src: url(${robotoMediumTTF}) format('truetype');
	font-weight: 500;
}
@font-face {
	font-family: 'Roboto';
	src: url(${robotoMediumItalicTTF}) format('truetype');
	font-weight: 500;
	font-style: italic;
}
`;

export default FontStyles;
