import { FunctionComponent } from 'react';
import { toast } from 'react-toastify';
import { ApiErrorDescription, ErrorAlert, HStack, VStack } from '@montel/montelpro-shared-components-v2';
import { ORDER_API_ROUTE } from 'apiRoutes';
import { OrderLifecycleEnum } from 'common/enums/OrderEnums';
import useSubmit, { POST, PUT } from 'hooks/useSubmit';
import { styled } from 'styled-components';
import { Notice } from 'components/Notice';
import FormFooter from '../../Footer';
import { useOrderFormContext } from '../../OrderFormProvider';
import { NewOrderSchema } from '../../types';
import getPayload from '../../utils/getPayload';
import CommentReview from './CommentReview';
import DeliveryReview from './DeliveryReview';
import PriceReview from './PriceReview';
import ProductReview from './ProductReview';

interface OverviewStepProps {
	onStepChange: (step: number) => void;
	onPrev: () => void;
}

const OverviewStep: FunctionComponent<OverviewStepProps> = ({ onStepChange, onPrev }) => {
	const { formState, validate, isSubmitted, setIsSubmitted } = useOrderFormContext();
	const isEdit = formState.type === OrderLifecycleEnum.Edit;
	const route = isEdit
		? `${ORDER_API_ROUTE}?orderId=${formState.id}&expectedVersion=${formState.version}`
		: ORDER_API_ROUTE;

	const [postData, postStatus, error] = useSubmit(route, isEdit ? PUT : POST);

	const onSubmit = async () => {
		const validationResult = validate(NewOrderSchema, formState);

		if (!validationResult.success) {
			return;
		}

		const result = await postData(getPayload(formState));

		if (result.success) {
			setIsSubmitted(true);
			toast.success('Your order has been submitted', { autoClose: 2000 });
		} else toast.error('An error occurred', { autoClose: 2000 });
	};

	const showCounterOrderNotice =
		(formState.type === OrderLifecycleEnum.Counterbid || formState.type === OrderLifecycleEnum.Counteroffer) &&
		!isSubmitted;

	return (
		<VStack gap="md" align="stretch" fullWidth>
			<StyledBoxContainer wrap="nowrap" gap="md">
				<ProductReview onStepChange={onStepChange} isSubmitted={isSubmitted} />
				<DeliveryReview onStepChange={onStepChange} isSubmitted={isSubmitted} />
			</StyledBoxContainer>
			<CommentReview onStepChange={onStepChange} isSubmitted={isSubmitted} />
			<PriceReview onStepChange={onStepChange} isSubmitted={isSubmitted} />

			<>
				{showCounterOrderNotice && (
					<Notice>
						A {OrderLifecycleEnum[formState.type].toLowerCase()} will create a new order and not replace the
						existing one. Please make necessary changes before submitting.
					</Notice>
				)}
			</>
			<>{isSubmitted && <Notice>Your order has been submitted</Notice>}</>

			<FormFooter
				isSubmit
				submitStatus={postStatus}
				onNext={onSubmit}
				onPrev={onPrev}
				showPrev
				justify="flex-end"
			/>

			<>
				{error && (
					<ErrorAlert text="Oh no, an error has occurred">
						<ApiErrorDescription error={error} />
					</ErrorAlert>
				)}
			</>
		</VStack>
	);
};

const StyledBoxContainer = styled(HStack)`
	align-items: stretch;
`;

export default OverviewStep;
