import { OrderStatus } from '../OrderEnums';

export const statusNames: { [key in keyof typeof OrderStatus]: string } = {
	Default: 'Default',
	Active: 'Active',
	Deleted: 'Deleted',
	Expired: 'Expired',
	Accepted: 'Accepted',
	Exhausted: 'Completed',
};

const OrderStatusNames = {
	[OrderStatus.Default]: 'Default',
	[OrderStatus.Active]: 'Active',
	[OrderStatus.Deleted]: 'Deleted',
	[OrderStatus.Expired]: 'Expired',
	[OrderStatus.Accepted]: 'Accepted',
	[OrderStatus.Exhausted]: 'Completed',
};

export const getOrderStatusName = (orderStatus: OrderStatus) => {
	if (orderStatus === undefined || orderStatus === null) {
		return OrderStatusNames[OrderStatus.Default];
	}
	return OrderStatusNames[orderStatus];
};
