import { IconCompanyTrades, IconMyTrades, IconSync } from '@montel/montelpro-shared-components-v2';
import { TMenuItem } from '../../components/Menu';

export const tradesMenuConfig: TMenuItem[] = [
	{
		type: 'Separator',
	},
	{
		type: 'Clickable',
		text: `All`,
		link: '/trades',
		icon: IconSync,
		isEnd: true,
	},
	{
		type: 'Clickable',
		text: `My trades`,
		link: '/trades/my-trades',
		icon: IconMyTrades,
		isEnd: true,
	},
	{
		type: 'Clickable',
		text: `Company trades`,
		link: '/trades/company-trades',
		icon: IconCompanyTrades,
		isEnd: true,
	},
];
