import { FunctionComponent, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { RequestStatus } from '@montel/montelpro-shared-components/enums';
import {
	ApiErrorDescription,
	Button,
	ErrorAlert,
	IconSave,
	Input,
	Modal,
	ModalBody,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
} from '@montel/montelpro-shared-components-v2';
import { ORDER_PRESETS_API_ROUTE } from 'apiRoutes';
import { AxiosError } from 'axios';
import useSubmit, { POST, PUT } from 'hooks/useSubmit';
import { styled } from 'styled-components';
import { usePresetsContext } from 'components/NewOrder/PresetsProvider';
import { OrderFormState } from 'components/NewOrder/types';
import convertOrderStateToPreset from './convertOrderStateToPreset';

interface SavePresetProps {
	fetchPresets: () => Promise<any>;
	orderState: OrderFormState;
}

const StyledModalContent = styled(ModalContent)`
	section {
		min-height: unset;
	}
`;

const SavePreset: FunctionComponent<SavePresetProps> = ({ fetchPresets, orderState }) => {
	const { selectedPreset, setSelectedPreset } = usePresetsContext();
	const [presetName, setPresetName] = useState(selectedPreset?.presetName ?? '');
	const [modalIsOpen, setIsOpen] = useState(false);
	const disableSubmit = !presetName || presetName === '';

	const [post, postStatus, errorPost] = useSubmit(ORDER_PRESETS_API_ROUTE, POST);
	const [update, updateStatus, errorUpdate] = useSubmit(ORDER_PRESETS_API_ROUTE, PUT);
	const [error, setError] = useState<AxiosError<any> | undefined>(undefined);

	useEffect(() => {
		setError(errorPost || errorUpdate);
	}, [errorPost, errorUpdate]);

	useEffect(() => {
		selectedPreset && setPresetName(selectedPreset.presetName);
	}, [selectedPreset]);

	const onSaveNew = async () => {
		const parsedPreset = convertOrderStateToPreset(orderState, presetName);

		const { success, data } = await post(parsedPreset);
		if (success) {
			setSelectedPreset({ ...parsedPreset, presetId: data.presetId });
			toast.success('New preset is created', { autoClose: 2000 });
			setIsOpen(false);
			fetchPresets();
		} else {
			toast.error('Error while saving new preset', { autoClose: 2000 });
		}
	};

	const onSaveChanges = async () => {
		const parsedState = convertOrderStateToPreset(orderState, presetName);
		const updateState = {
			...parsedState,
			presetId: selectedPreset?.presetId,
		};
		const { success } = await update(updateState);
		if (success) {
			setSelectedPreset(updateState);
			toast.success('Preset changes is saved', { autoClose: 2000 });
			setIsOpen(false);
			fetchPresets();
		} else {
			toast.error('Error while saving preset changes', { autoClose: 2000 });
		}
	};

	const onCloseSavePreset = () => {
		setPresetName(selectedPreset?.presetName ?? '');
		setError(undefined);
		setIsOpen(false);
	};

	const onSavePresetClick = () => {
		setIsOpen(true);
	};

	const onChangePresetName = (e: any) => {
		setPresetName(e.target.value);
	};

	return (
		<>
			<Button text={'Save as preset'} variant="secondary" leftIcon={<IconSave />} onClick={onSavePresetClick} />
			<Modal isOpen={modalIsOpen} onClose={() => setIsOpen(false)}>
				<ModalOverlay />
				<StyledModalContent>
					<ModalHeader>Save preset</ModalHeader>
					<ModalBody>
						<Input
							label="Preset name"
							required
							value={presetName}
							onChange={onChangePresetName}
							maxLength={60}
						/>
					</ModalBody>
					{error && (
						<ErrorAlert>
							<ApiErrorDescription error={error} />
						</ErrorAlert>
					)}
					<ModalFooter>
						{selectedPreset ? (
							<>
								<Button onClick={onCloseSavePreset} variant="secondary" text="Cancel" />
								<Button
									onClick={onSaveChanges}
									variant="primary"
									text={'Save changes'}
									isDisabled={disableSubmit}
									type="submit"
									isLoading={updateStatus === RequestStatus.LOADING}
									loadingText="Saving"
								/>
								<Button
									onClick={onSaveNew}
									text="Create new"
									variant="primary"
									isDisabled={disableSubmit}
									type="submit"
									isLoading={postStatus === RequestStatus.LOADING}
									loadingText="Saving"
								/>
							</>
						) : (
							<>
								<Button onClick={onCloseSavePreset} variant="secondary" text="Cancel" />
								<Button
									onClick={onSaveNew}
									variant="primary"
									text="Save"
									isDisabled={disableSubmit}
									type="submit"
									isLoading={postStatus === RequestStatus.LOADING}
									loadingText="Saving"
								/>
							</>
						)}
					</ModalFooter>
				</StyledModalContent>
			</Modal>
		</>
	);
};
export default SavePreset;
