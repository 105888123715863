import { PayloadAction } from '@reduxjs/toolkit';
import { getCountries } from 'store/lists/listsThunks';
import setDefaultCompanyIdHeader from 'utils/requestHeaders/setDefaultCompanyIdHeader';
import { getCompanies } from '../companies/companiesThunks';
import getActiveCompanyId from './utils/getActiveCompanyId';
import { getUserInfo } from './userThunks';

export const userMiddleware = (store: any) => (next: any) => async (action: any) => {
	const { type: actionType } = action as PayloadAction<string>;

	if (actionType === getUserInfo.fulfilled.type) {
		const { marketPlaceRoles, id } = action.payload;
		const activeCompanyId = getActiveCompanyId(id, marketPlaceRoles);
		setDefaultCompanyIdHeader(activeCompanyId);

		store.dispatch(getCountries());

		if (!window.location.pathname.includes('/embedded')) {
			store.dispatch(getCompanies());
		}

		return next(action);
	}

	return next(action);
};
