import { FunctionComponent } from 'react';
import { TypeOptions } from 'react-toastify';
import { IconCheckCircle, IconHelp, IconInfo } from '@montel/montelpro-shared-components-v2';

export const ToastIcon: FunctionComponent<{ type: TypeOptions }> = ({ type }) => {
	switch (type) {
		case 'success':
			return <IconCheckCircle boxSize="25px" />;
		case 'info':
			return <IconHelp boxSize="25px" />;
		case 'error':
		case 'warning':
		default:
			return <IconInfo boxSize="25px" />;
	}
};
