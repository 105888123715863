import { FunctionComponent, useEffect, useState } from 'react';
import { Countdown, HStack, TSizes } from '@montel/montelpro-shared-components-v2';
import { getMsAsMinutes, getRemainingTime, ProgressCircle } from '../../../Progress';

export interface CountdownWithProgressPops {
	/**
	 * Expiration date timestamp in milliseconds, UTC.
	 */
	expiryTimestamp: number;
	/**
	 * Callback function that is called when the countdown expires.
	 */
	onExpiry?: () => void;
	/**
	 * Font size of the countdown text.
	 */
	countdownFontSize?: TSizes;
	[otherProps: string]: any;
}

const CountdownWithProgress: FunctionComponent<CountdownWithProgressPops> = ({
	expiryTimestamp,
	onExpiry,
	countdownFontSize = 'sm',
	...otherProps
}) => {
	const [remaining, setRemaining] = useState(getRemainingTime(expiryTimestamp));

	useEffect(() => {
		const updateRemainingTime = setInterval(() => {
			if (expiryTimestamp <= Date.now()) {
				clearInterval(updateRemainingTime);
				return;
			}
			setRemaining(getRemainingTime(expiryTimestamp));
		}, 10000);

		return () => updateRemainingTime && clearInterval(updateRemainingTime);
	}, [expiryTimestamp]);

	const minutesUntilExpiry = getMsAsMinutes(remaining);

	return (
		<HStack wrap="nowrap" {...otherProps} fullWidth align="center">
			<ProgressCircle size="12px" minutesUntilExpiry={minutesUntilExpiry} />
			<Countdown size={countdownFontSize} expiration={expiryTimestamp} onExpiry={onExpiry} />
		</HStack>
	);
};

export default CountdownWithProgress;
