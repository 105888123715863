import { useEffect } from 'react';
import { HubConnection } from '@microsoft/signalr';
import { useAppDispatch } from 'store/hooks';
import { getUserInfo } from 'store/user/userThunks';

export interface IuseSubscribeToUserInfo {
	hubConnection?: HubConnection | null;
}

const useSubscribeToUserInfo = ({ hubConnection }: IuseSubscribeToUserInfo) => {
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (hubConnection) {
			hubConnection.on('user_updated', async () => {
				await dispatch(getUserInfo());
			});

			return () => {
				hubConnection.off('user_updated');
			};
		}
	}, [hubConnection]);
};

export default useSubscribeToUserInfo;
