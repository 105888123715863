export enum FilterCategoryEnum {
	Default = '',
	Technology = 'Tech',
	Region = 'Region',
	Label = 'Label',
	Currency = 'Currency',
	ProductionYear = 'ProductionYear',
	Country = 'Country',
	TradingPosition = 'TradingPosition',
	Relation = 'Relation',
}
