import { FunctionComponent } from 'react';
import { RequestStatus } from '@montel/montelpro-shared-components/enums';
import { Button, HStack, Text } from '@montel/montelpro-shared-components-v2';
import { Quote } from 'common/interfaces/Quote';
import { getTypeOfQuote } from 'components/OrderDetails/PlaceQuoteForm/getTypeOfQuote';
import Prompt from '../../../Prompt';
import apiClient from '../apiClient';

export interface QuoteCancelPromptProps {
	quote?: Quote | null;
	isOpen: boolean;
	onClose: () => void;
}

const QuoteCancelPrompt: FunctionComponent<QuoteCancelPromptProps> = ({ quote, isOpen, onClose }) => {
	const quoteType = quote ? getTypeOfQuote(quote.side) : undefined;
	const apiParameters = {
		orderId: quote?.orderId ?? '',
		quoteId: quote?.id ?? '',
		expectedVersion: quote?.version ?? -1,
		quoteType,
	};

	const [deleteData, deleteStatus] = apiClient.useCancelQuote(apiParameters);

	const onCancel = async () => {
		if (!quote) return;
		const success = await deleteData();
		if (success) {
			onClose();
		}
	};

	return (
		<Prompt
			headerText={`Cancel ${quoteType ?? 'quote'}`}
			animateFromHeight={0}
			animateToHeight={isOpen ? 200 : 0}
			onClosePrompt={onClose}
			body={<Text>Are you sure you want to cancel this {quoteType ?? 'quote'}?</Text>}
			footer={
				<HStack fullWidth justify="flex-end">
					<Button text="Cancel" variant="secondary" onClick={onClose} />
					<Button text="Submit" onClick={onCancel} isLoading={deleteStatus === RequestStatus.LOADING} />
				</HStack>
			}
		/>
	);
};

export default QuoteCancelPrompt;
