import { IconCompany, IconMyCompany } from '@montel/montelpro-shared-components-v2';

export default {
	approved: IconCompany,
	deactivated: IconCompany,
	joinCompanyInviteReceived: IconMyCompany,
	joinRequestAccepted: IconMyCompany,
	joinRequestDeclined: IconMyCompany,
	joinRequestReceived: IconMyCompany,
	userRemoved: IconMyCompany,
	roleChanged: IconMyCompany,
};
