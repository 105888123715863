import { RequestStatus } from '@montel/montelpro-shared-components/enums';
import { createSlice } from '@reduxjs/toolkit';
import { Order } from '../../../common/interfaces';
import { getUserUntradedOrderHistory } from '../ordersThunks';
import { translateApiOrders } from '../utils/translateApiOrder';

export interface IUserUntradedOrderHistoryState {
	orders: Order[];
	status: RequestStatus;
	error?: any;
}

export const initialState: IUserUntradedOrderHistoryState = {
	orders: [],
	status: RequestStatus.UNLOADED,
	error: undefined,
};

export const userUntradedOrderHistorySlice = createSlice({
	name: 'userUntradedOrderHistory',
	initialState,
	reducers: {
		userOrderExpired: (state, { payload: order }) => {
			state.orders.push(order);
		},
		userOrderCancelled: (state, { payload: order }) => {
			state.orders.push(order);
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getUserUntradedOrderHistory.pending, (state) => {
				state.status = RequestStatus.LOADING;
				state.error = undefined;
			})
			.addCase(getUserUntradedOrderHistory.rejected, (state, { error }) => {
				state.status = RequestStatus.ERROR;
				state.error = error;
			})
			.addCase(getUserUntradedOrderHistory.fulfilled, (state, { payload }) => {
				state.orders = translateApiOrders(payload);
				state.status = RequestStatus.SUCCESS;
				state.error = undefined;
			});
	},
});

export const { userOrderExpired, userOrderCancelled } = userUntradedOrderHistorySlice.actions;

export default userUntradedOrderHistorySlice.reducer;
