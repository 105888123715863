import { FunctionComponent } from 'react';
import { IconInfo, Text, Tooltip, TSizes } from '@montel/montelpro-shared-components-v2';
import { EllipsisText } from 'primitives/EllipsisText';
import { StyledCell, StyledHStack } from './styles';
import { CellProps } from './types';

export interface NumericCellProps extends CellProps {
	value: number;
	unit?: string;
	decimalPlaces?: number;
	textFontSize?: TSizes;
	unitFontSize?: TSizes;
	tooltipText?: string;
	tooltipSide?: string;
	orderType?: string;
}

const NumericCell: FunctionComponent<NumericCellProps> = ({
	value,
	unit,
	decimalPlaces = 4,
	disabled,
	justify = 'flex-start',
	align = 'flex-start',
	textFontSize = 'sm',
	unitFontSize = 'xxs',
	tooltipText,
	tooltipSide = 'left',
	orderType,
}) => {
	const cellColor = disabled ? 'subElement' : 'primaryText';
	const unitCellColor = disabled ? 'subElement' : 'secondaryText';

	return (
		<StyledCell fullWidth gap="xxs" justify={justify} align={align}>
			<StyledHStack $tooltipSide={tooltipSide} wrap="nowrap">
				{orderType === 'RFQ' && tooltipText && (
					<Tooltip $justify={justify} component={<IconInfo color={cellColor} />}>
						{tooltipText}
					</Tooltip>
				)}
				<EllipsisText size={textFontSize} color={cellColor}>
					{value !== 0 ? value.toDefaultNumberFormat(decimalPlaces) : '-'}
				</EllipsisText>
			</StyledHStack>

			{unit && (
				<Text color={unitCellColor} size={unitFontSize}>
					{unit}
				</Text>
			)}
		</StyledCell>
	);
};

export default NumericCell;
