import { TNoCompaniesConfig } from './NoCompaniesTypes';

export const noCompaniesConfig: TNoCompaniesConfig = {
	all: {
		title: 'No companies found',
		description: 'Try adjusting your search criteria or add a new company.',
		showInviteButton: true,
		showBackButton: false,
	},
	invitations: {
		title: 'No invitations pending',
		description: 'Go back to "All companies" and start sending out invites to build your network.',
		showInviteButton: false,
		showBackButton: true,
	},
	counterparties: {
		title: `You do not have any counterparties yet`,
		description: 'You can either become a counterparty with existing companies or invite new ones to join.',
		showInviteButton: true,
		showBackButton: false,
	},
	blocked: {
		title: 'No blocked or paused counterparties',
		description: `You have not blocked or paused any counterparties. You are all set to continue your transactions smoothly. `,
		showInviteButton: false,
		showBackButton: false,
	},
};
