import styled from 'styled-components';

export const StyledHeaderBar = styled.div`
	position: fixed;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: row;
	top: 0;
	height: 5rem;
	width: 100%;
	padding-left: ${({ theme }) => theme.spacing.md};
	padding-right: ${({ theme }) => theme.spacing.md};
	z-index: ${({ theme }) => theme.zIndices.docked};
	background-color: ${({ theme }) => theme.colors.widget};

	a {
		text-decoration: none;
	}
`;

export const StyledDivider = styled.div`
	width: 0.0625rem;
	height: 3rem;
	background-color: ${({ theme }) => theme.colors.subElement};
	margin-right: ${({ theme }) => theme.spacing.xs};
`;
