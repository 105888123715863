import { FunctionComponent } from 'react';
import { HStack, Text } from '@montel/montelpro-shared-components-v2';
import IconDown from './IconDown';
import IconUp from './IconUp';
import NoChange from './NoChange';

export interface ChangeValueIconProps {
	currentValue: number | null | undefined;
	previousValue: number | null | undefined;
	changeValue?: number;
}

const Index: FunctionComponent<ChangeValueIconProps> = ({ currentValue, previousValue, changeValue }) => {
	let change = changeValue || 0;
	const currentVal = currentValue || 0;
	const previousVal = previousValue || 0;
	change = currentVal - previousVal;

	if (change > 0) {
		return (
			<HStack align="center">
				<IconUp />
				<Text $color={'success'}>{change.toDefaultNumberFormat()}</Text>
			</HStack>
		);
	}
	if (change < 0) {
		return (
			<HStack align="center">
				<IconDown />
				<Text $color={'error'}>{change.toDefaultNumberFormat()}</Text>
			</HStack>
		);
	}

	return <NoChange />;
};

export default Index;
