import React, { FunctionComponent } from 'react';
import { H2 } from '@montel/montelpro-shared-components-v2';
import { HeadCell, TableData } from '@montel/montelpro-shared-components-v2';
import { IconProps } from '@montel/montelpro-shared-components-v2';
import { Box } from '@montel/montelpro-shared-components-v2';
import useWindowSize from 'hooks/useWindowSize';
import { StyledEndOfDayContainer, StyledTable } from './styles';

export interface EndOfDayProps {
	techName: string;
	techIcon: FunctionComponent<IconProps>;
	columns: HeadCell[];
	rows: TableData[];
}

export const EndOfDayTable: FunctionComponent<EndOfDayProps> = ({ techName, techIcon, columns = [], rows = [] }) => {
	const TechIcon = techIcon;
	const [width] = useWindowSize();

	return (
		<Box bg="widget" padding="xl">
			<StyledEndOfDayContainer>
				<div className="end-of-day-header">
					<H2>{techName}</H2>
					<TechIcon boxSize="1.5rem" color="white" />
				</div>
				<StyledTable columns={columns} data={rows} $windowWidth={width} />
			</StyledEndOfDayContainer>
		</Box>
	);
};

export default EndOfDayTable;
