import { orderTableColumns } from 'components/OrderTables/Columns/orderTableColumns';

const columns = [
	orderTableColumns.productionPeriod({ headCellProps: { text: 'Prod.' } }),
	orderTableColumns.tech({ cellProps: { numberOfTechToShow: 2 } }),
	orderTableColumns.region(),
	orderTableColumns.type(),
	orderTableColumns.quantity(),
];

export const orderColumns = [
	...columns,
	orderTableColumns.price({
		columnDefProps: { meta: { width: '140px' } },
		headCellProps: { tooltipText: 'For RFQ orders, we display the best quoted price.' },
	}),
];

export const tradeColumns = [...columns, orderTableColumns.price()];
