import PeriodTypeEnum from 'common/enums/PeriodTypeEnum';
import { Production, ProductionPeriod } from 'common/interfaces';
import { getMonthOptions, getQuarterOptions, getYearOptions } from 'components/NewOrder/options';
import { ProductionPeriodOption } from 'components/NewOrder/Steps/ProductStep/ProductStep.types';

const getProductionPeriodOptionIfValid = (
	period: ProductionPeriod,
	periodType: PeriodTypeEnum,
): ProductionPeriodOption | undefined => {
	const productionMonth_0_11 = period.month ? period.month - 1 : undefined;
	const today = new Date();

	let option: ProductionPeriodOption | undefined;
	if (periodType === PeriodTypeEnum.YEAR) {
		return (option = getYearOptions(today).find((option) => option.year === period.year));
	}

	if (periodType === PeriodTypeEnum.QUARTER) {
		return getQuarterOptions(today).find(
			(option) => option.year === period.year && option.quarter === period.quarter,
		);
	}

	option = getMonthOptions(today).find(
		(option) => option.year === period.year && option.month === productionMonth_0_11,
	);

	return option;
};

export const filterValidProductionPeriodOptions = (prod: Production): ProductionPeriodOption[] =>
	prod.periods
		.map((period) => getProductionPeriodOptionIfValid(period, prod.type))
		.filter((period): period is ProductionPeriodOption => Boolean(period));
