import { PeriodTypeEnum } from '../../../../common/enums';

export const getMultiText = (type: PeriodTypeEnum) => {
	switch (type) {
		case PeriodTypeEnum.YEAR:
			return 'Multiple years';
		case PeriodTypeEnum.QUARTER:
			return 'Multiple quarters';
		case PeriodTypeEnum.MONTH:
			return 'Multiple months';
		default:
			return 'Multiple';
	}
};
