import { FunctionComponent, useState } from 'react';
import { Outlet, useLocation, useOutletContext } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useOnMount } from '@montel/montelpro-shared-components-v2';
import { Order } from 'common/interfaces/Order';
import useSetPageTitle from 'hooks/useSetPageTitle';
import PageHeader from 'primitives/PageHeader';
import { selectMyActiveOrders, selectOrderById } from 'store/orders/activeOrders/activeOrdersSelectors';
import { getActiveOrders } from 'store/orders/ordersThunks';
import FilterDropDown from 'components/Filter/FilterDropdown';
import SelectedFilters from 'components/Filter/SelectedFilters';
import { OrderOption } from 'components/Filter/types';
import newOrderState from 'components/NewOrder/newOrderState';
import OrderFormProvider from 'components/NewOrder/OrderFormProvider';
import { OrderDetailsModal } from 'components/OrderDetails';
import { useProductFilter } from '../../components/Filter/useProductFilter';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { DeleteAllOrdersModal } from './MyOrders/Modals/DeleteAllOrdersModal';
import { DeleteAllOrdersAction } from './DeleteAllOrdersAction';
import { ordersMenuConfig } from './ordersMenuConfig';
import { useOrderPages } from './useOrderPages';

type OrdersContext = {
	filterChoices: any;
	filterOrders: (items: any, filters: any) => any;
	onOrderClicked: (order: Order) => void;
	setOrderFilterOptions: (filterOptions: OrderOption[]) => void;
	showDeleteAllOrdersModal: boolean;
	setShowDeleteAllOrdersModal: (show: boolean) => void;
};

export const useOrdersOutletContext = () => useOutletContext<OrdersContext>();

const Orders: FunctionComponent = () => {
	useSetPageTitle('Market Orders');
	const [searchParams, setSearchParams] = useSearchParams();
	const orderId = searchParams.get('orderId');

	const selectedOrder = useAppSelector((state) => selectOrderById(state, orderId ?? ''));

	const isModalOpen = orderId !== null;

	const [orderFilterOptions, setOrderFilterOptions] = useState<OrderOption[]>();

	const [showDeleteAllOrdersModal, setShowDeleteAllOrdersModal] = useState<boolean>(false);

	const orderFilters = useProductFilter();
	const { filterChoices, filterOrders, handleFilterClick } = orderFilters;

	const onOrderClicked = (row: Order) => {
		setSearchParams({ orderId: row.id });
	};

	const onModalClose = () => {
		setSearchParams((params) => {
			params.delete('orderId');
			return params;
		});
	};

	const dispatch = useAppDispatch();

	useOnMount(() => {
		dispatch(getActiveOrders());
	});

	const { isEndOfDayPage, isMyOrdersPage, isCompanyOrdersPage } = useOrderPages(useLocation().pathname);

	const myActiveOrders = useAppSelector(selectMyActiveOrders);
	const showFilter = !isEndOfDayPage;
	const showDeleteAllOrdersAction = (isMyOrdersPage || isCompanyOrdersPage) && myActiveOrders.length > 0;

	return (
		<OrderFormProvider initialState={newOrderState}>
			<PageHeader title="Marketplace" topMenuConfig={ordersMenuConfig}>
				{showFilter && (
					<FilterDropDown
						options={orderFilterOptions}
						onClick={handleFilterClick}
						selectedOptions={filterChoices}
					/>
				)}
				{showDeleteAllOrdersAction && (
					<DeleteAllOrdersAction onClick={() => setShowDeleteAllOrdersModal(true)} />
				)}
			</PageHeader>
			<SelectedFilters filterChoices={filterChoices} handleDeleteFilterChoices={handleFilterClick} />

			<Outlet
				context={{
					filterChoices,
					filterOrders,
					onOrderClicked,
					setOrderFilterOptions,
					showDeleteAllOrdersModal,
					setShowDeleteAllOrdersModal,
				}}
			/>
			<OrderDetailsModal order={selectedOrder} isOpen={isModalOpen} onClose={onModalClose} />
			{showDeleteAllOrdersAction && (
				<DeleteAllOrdersModal
					isOpen={showDeleteAllOrdersModal}
					closeModal={() => setShowDeleteAllOrdersModal(false)}
					orders={myActiveOrders}
					onSuccess={() => {
						setShowDeleteAllOrdersModal(false);
					}}
				/>
			)}
		</OrderFormProvider>
	);
};

export default Orders;
