import { useState } from 'react';

type useNewPendingCompaniesType = {
	newPendingCompanies: number[];
	addNewPendingCompany: (id: number) => void;
	removeNewPendingCompany: (id: number) => void;
};
const useNewPendingCompanies = (): useNewPendingCompaniesType => {
	const [newPendingCompanies, setNewPendingCompanies] = useState<number[]>([]);
	const addNewPendingCompany = (id: number) => {
		setNewPendingCompanies([...newPendingCompanies, id]);
	};
	const removeNewPendingCompany = (id: number) => {
		setNewPendingCompanies(newPendingCompanies.filter((newPendingCompanyId) => newPendingCompanyId !== id));
	};
	return {
		newPendingCompanies,
		addNewPendingCompany,
		removeNewPendingCompany,
	};
};

export default useNewPendingCompanies;
