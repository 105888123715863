import { BaseNotification, Text, TNotificationVariant } from '@montel/montelpro-shared-components-v2';
import { ENotificationStatus, INotification } from '../INotification';
import { getNotificationIcon, getNotificationText } from '../notificationContent';
import { StyledVStack } from './NotificationsStack.style';

type Props = {
	notifications: INotification[];
	onNotificationClick: (notification: INotification) => void;
	groupName: string;
	groupNameColor?: string;
};

const NotificationsGroup = ({ notifications, onNotificationClick, groupName, groupNameColor }: Props) => {
	return (
		<StyledVStack fullWidth>
			<Text size="xxs" weight="semiBold" color={groupNameColor ? groupNameColor : ''}>
				{groupName}
			</Text>
			{notifications.map((item) => {
				const { status, created, type, id } = item;

				return (
					<BaseNotification
						text={getNotificationText(item)}
						variant={ENotificationStatus[status]?.toLowerCase() as TNotificationVariant}
						date={created}
						key={id}
						icon={getNotificationIcon(type)}
						onClick={() => onNotificationClick(item)}
					/>
				);
			})}
		</StyledVStack>
	);
};

export default NotificationsGroup;
