import { FunctionComponent, ReactNode } from 'react';
import {
	CountIndicator,
	H1,
	HStack,
	IconCompany,
	Skeleton,
	Text,
	themes,
	VStack,
} from '@montel/montelpro-shared-components-v2';
import { motion } from 'framer-motion';
import { StyledTopMenuGroup } from 'components/Menu/TopMenu/TopMenuGroup.styles';
import { StyledListItem, StyledTopMenuItem, StyledUnderline } from 'components/Menu/TopMenu/TopMenuItem.styles';
import { TopMenuSeparator } from 'components/Menu/TopMenu/TopMenuSeparator';
import { isDataLoaded } from '../../components/Suspense/utils';
import { selectStatus } from '../../store/companies/companiesSelectors';
import { useAppSelector } from '../../store/hooks';

export interface InvitationsFilterProps {
	value?: string;
	onChange: (value: string) => void;
	invitationsCount?: number;
}

const CompaniesMenu: FunctionComponent<InvitationsFilterProps> = ({
	value = 'all',
	onChange,
	invitationsCount = 0,
}) => {
	const { colors } = themes.dark;
	const isSelected = (val: string) => val === value;
	const status = useAppSelector(selectStatus);

	return (
		<StyledTopMenuGroup>
			<H1>Companies</H1>
			<TopMenuSeparator />

			<StyledListItem>
				<StyledTopMenuItem
					key={'all'}
					onClick={() => onChange('all')}
					tabIndex={1}
					$isActive={isSelected('all')}
					to={''}
				>
					<Skeleton fitContent isLoaded={isDataLoaded(status)}>
						<ItemWrapper isSelected={isSelected('all')}>
							<IconCompany color="white" boxSize="1rem" />
							<Text as="span" size="sm" color={isSelected('all') ? 'white' : 'secondaryText'}>
								All Companies
							</Text>
						</ItemWrapper>
					</Skeleton>
				</StyledTopMenuItem>
			</StyledListItem>
			<TopMenuSeparator />
			<StyledListItem>
				<StyledTopMenuItem
					key={'invitations'}
					tabIndex={2}
					onClick={() => onChange('invitations')}
					$isActive={isSelected('invitations')}
					to={''}
				>
					<Skeleton fitContent isLoaded={isDataLoaded(status)}>
						<ItemWrapper isSelected={isSelected('invitations')}>
							<Text as="span" size="sm" color={isSelected('invitations') ? 'white' : 'secondaryText'}>
								Counterparty invitations
							</Text>
							<CountIndicator bg={colors.red} size="sm" value={invitationsCount} capValue={9} />
						</ItemWrapper>
					</Skeleton>
				</StyledTopMenuItem>
			</StyledListItem>
		</StyledTopMenuGroup>
	);
};

const ItemWrapper: FunctionComponent<{ children: ReactNode; isSelected: boolean }> = ({ children, isSelected }) => (
	<VStack justify="center">
		<HStack align="center">{children}</HStack>

		<StyledUnderline as={motion.div} layout $active={isSelected} />
	</VStack>
);

export default CompaniesMenu;
