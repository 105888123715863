import { RequestStatus } from '@montel/montelpro-shared-components/enums';
import { createSlice } from '@reduxjs/toolkit';
import { PublicCompanyInformation } from '../../common/interfaces';
import { translateApiCompanies } from './utils/translateApiCompany';
import { getCompanies } from './companiesThunks';

export interface ICompaniesState {
	data: PublicCompanyInformation[];
	status: RequestStatus;
	error?: any;
	isInitiated: boolean;
}

export const initialState: ICompaniesState = {
	data: [],
	status: RequestStatus.UNLOADED,
	error: undefined,
	isInitiated: false,
};

export const companiesSlice = createSlice({
	name: 'companies',
	initialState,
	reducers: {
		companiesUpdated: (state, { payload }) => {
			state.data = payload;
		},
		companyUpdated: (state, { payload }) => {
			const index = state.data.findIndex((c) => c.id === payload.id);
			if (index === -1) return;
			state.data[index] = payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getCompanies.pending, (state) => {
				if (!state.isInitiated) {
					state.status = RequestStatus.LOADING;
				}
				state.error = undefined;
			})
			.addCase(getCompanies.rejected, (state, { error }) => {
				state.status = RequestStatus.ERROR;
				state.error = error;
			})
			.addCase(getCompanies.fulfilled, (state, { payload }) => {
				state.data = translateApiCompanies(payload);
				state.status = RequestStatus.SUCCESS;
				state.error = undefined;
				state.isInitiated = true;
			});
	},
});

export const { companiesUpdated, companyUpdated } = companiesSlice.actions;

export default companiesSlice.reducer;
