import { Order } from 'common/interfaces/Order';
import { Quote } from 'common/interfaces/Quote';

export const getOrderOwner = (order: Order) => {
	if (order.isMyOrder) return 'Mine';
	if (order.isMyCompanysOrder) return 'Coworker';

	return order.isCounterpartysOrder ? 'Counterparty' : 'Public';
};

export const getQuoteOwner = (quote: Quote) => {
	if (quote.isMyQuote) return 'Mine';
	if (quote.isMyCompanyQuote) return 'Coworker';

	return 'N/A';
};
