import { FunctionComponent } from 'react';
import { Switch } from '@montel/montelpro-shared-components-v2';

export interface StatusCellProps {
	active: boolean;
	onToggleActive: () => void;
	isDisabled?: boolean;
}

const StatusCell: FunctionComponent<StatusCellProps> = ({ active, onToggleActive, isDisabled = false }) => {
	return <Switch isChecked={active} onChange={onToggleActive} isDisabled={isDisabled} />;
};

export default StatusCell;
