import { FunctionComponent } from 'react';
import { Select, SelectOptionType } from '@montel/montelpro-shared-components-v2';
import { styled } from 'styled-components';
import SavePreset from 'components/OrderPresets';
import { selectCounterPartiesOptions } from '../../store/companies/companiesSelectors';
import { useAppSelector } from '../../store/hooks';
import { convertPresetToOrderState } from './utils/convertAPIDataUtils';
import { useOrderFormContext } from './OrderFormProvider';
import { usePresetsContext } from './PresetsProvider';

const StyledPresets = styled.div`
	font-size: 16px;
	width: 12rem;
	margin-top: -4px;
`;

const Presets: FunctionComponent<{
	isLastStep: boolean;
	onPresetChange: () => void;
}> = ({ isLastStep, onPresetChange }) => {
	const { formState, setFormState } = useOrderFormContext();
	const counterpartyOptions = useAppSelector(selectCounterPartiesOptions);
	const { setSelectedPreset, fetchPresets, presets } = usePresetsContext();

	if (isLastStep) {
		return <SavePreset fetchPresets={fetchPresets} orderState={formState} />;
	}

	if (!presets || !presets.length) return null;

	const onPresetChanged = (preset: SelectOptionType) => {
		const selectedPreset = presets.find((p) => p.presetId === preset.value);

		if (!selectedPreset) return;

		setSelectedPreset(selectedPreset);
		setFormState(convertPresetToOrderState(selectedPreset, counterpartyOptions));
		onPresetChange();
	};

	const presetOptions = presets.map((p) => {
		return {
			label: p.presetName,
			value: p.presetId,
		};
	});

	return (
		<StyledPresets>
			<Select options={presetOptions} hideLabel label="" onChange={onPresetChanged} placeholder="My presets" />
		</StyledPresets>
	);
};

export default Presets;
