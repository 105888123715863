import { TMenuItem } from './menuTypes';

type TLocationProvider = () => { pathname: string; [key: string]: any };

export interface UseMenuProps {
	items: TMenuItem[];
	/**
	 * Function that returns the current window location of the user. It must include at least the pathname.
	 * This is useful when using a router that doesn't use the window.location.pathname to store the current location.
	 * In our case, we use the useLocation hook from react-router-dom.
	 */
	locationProvider?: TLocationProvider;
}

export const useMenu = ({ items = [] as TMenuItem[], locationProvider: locationProviderProp }: UseMenuProps) => {
	let locationProvider: TLocationProvider = () => ({
		pathname: window.location.pathname,
	});

	if (locationProviderProp !== undefined) {
		locationProvider = locationProviderProp as TLocationProvider;
	}

	const location = locationProvider();

	const getActiveItemIndex = () => {
		const { pathname } = location;

		return items.findIndex((item) => {
			if (item.type !== 'Clickable') return false;
			return item.isEnd ? item.link === pathname : pathname.startsWith(item.link);
		});
	};

	const isActiveItemByLink = (link: string) => {
		const { pathname } = location;
		return link === pathname;
	};

	const isActiveItemByIndex = (index: number) => {
		return getActiveItemIndex() === index;
	};

	return {
		items,
		isActiveItemByLink,
		isActiveItemByIndex,
		getActiveItemIndex,
	};
};
