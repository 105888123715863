import styled from 'styled-components';

export const StyledDropdownGroupWithBack = styled.ul`
	list-style: none;
	padding: 0;
	position: relative;

	.back {
		position: sticky;
		top: 0;
		z-index: ${({ theme }) => theme.zIndices.dropdown};
		width: 100%;
		background-color: ${({ theme }) => theme.colors.element};

		.backButton {
			cursor: pointer;
			display: flex;
			align-items: center;
			border: none;

			width: 100%;
			padding: 15px;
			color: white;
			background-color: transparent;

			&:focus {
				background: ${({ theme }) => theme.colors.elementHighlight};
				outline: none;
			}

			&:hover {
				background: ${({ theme }) => theme.colors.elementHighlight};
				outline: none;
			}

			.backText {
				padding-left: 15px;
			}
		}
	}
`;
