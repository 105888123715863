import React, { ReactNode } from 'react';
import { motion } from 'framer-motion';
import { StyledContainer } from './AnimateHeight.style';

interface AnimateHeightProps {
	children: ReactNode;
	/**
	 * This is the start height of the content.
	 */
	from: number;
	/**
	 * Set this height to > 0 to show the content. The value will also influence the actual height of the content.
	 */
	to: number;
	onAnimationComplete?: () => void;
}

const AnimateHeight: React.FC<AnimateHeightProps> = ({ children, from, to, onAnimationComplete }) => {
	return (
		<StyledContainer
			as={motion.div}
			layout
			initial={{
				height: from,
			}}
			animate={{
				height: to,
				display: 'block',
				transitionEnd: {
					display: to > 0 ? 'block' : 'none',
				},
			}}
			onAnimationComplete={() => {
				onAnimationComplete && onAnimationComplete();
			}}
		>
			{children}
		</StyledContainer>
	);
};

export default AnimateHeight;
