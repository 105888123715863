import React, { FunctionComponent } from 'react';
import { HStack, Text, TextProps } from '@montel/montelpro-shared-components-v2';
import { TradingPositionEnum } from '../../../common/enums/TradingPositionEnum';
import { StyledDashDivider } from '../style';

interface TradingPositionProps {
	tradingPosition: TradingPositionEnum;
	countryCode: string;
}
const TradingPositionAndCountry: FunctionComponent<TradingPositionProps> = ({ tradingPosition, countryCode }) => {
	const formattingProps: Partial<TextProps> = {
		as: 'span',
		size: 'xs',
		weight: 'semiBold',
		color: 'secondaryText',
	};

	const isBuyer = tradingPosition === TradingPositionEnum.Buying || tradingPosition === TradingPositionEnum.Both;
	const isSeller = tradingPosition === TradingPositionEnum.Selling || tradingPosition === TradingPositionEnum.Both;

	return (
		<HStack divider={<StyledDashDivider {...formattingProps}>-</StyledDashDivider>}>
			<HStack gap="0px">
				{isBuyer ? <Text {...formattingProps}>BUYER</Text> : <></>}
				{isBuyer && isSeller ? <Text {...formattingProps}>/</Text> : <></>}
				{isSeller ? <Text {...formattingProps}>SELLER</Text> : <></>}
			</HStack>
			<Text {...formattingProps}>{countryCode}</Text>
		</HStack>
	);
};

export default TradingPositionAndCountry;
