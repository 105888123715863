import { FunctionComponent } from 'react';
import { HStack } from '@montel/montelpro-shared-components-v2';
import { css, styled } from 'styled-components';

const StyledHr = styled.hr`
	width: 100%;
	border-left: none;
	border-right: none;
	border-top: none;

	${({ theme }) => {
		const { colors } = theme;
		return css`
			border-bottom: 1px solid ${colors.subElement};
		`;
	}};
`;

const ActionLine: FunctionComponent<{ children: any }> = ({ children }) => {
	return (
		<HStack justify="space-between" align="center" gap="md" direction="row" wrap="nowrap">
			<StyledHr />
			{children}
		</HStack>
	);
};

export default ActionLine;
