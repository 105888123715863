import { createContext } from 'hooks/createContext';

export interface MenuContext {
	/**
	 * Indicates if the menu item is active (selected).
	 * @type boolean
	 */
	isActive: boolean;
	/**
	 * Index of the menu item.
	 */
	index: number;
}

export const [MenuContextProvider, useMenuContext] = createContext<MenuContext>({
	name: 'MenuContext',
	hookName: 'useMenuContext',
	providerName: 'MenuContextProvider',
});
