import { FunctionComponent, useEffect, useMemo } from 'react';
import { HStack, VStack } from '@montel/montelpro-shared-components-v2';
import getOrderFilterOptions from 'common/options/orderFilterOptions';
import useSetPageTitle from 'hooks/useSetPageTitle';
import { getAllProductionYears } from 'utils/orderUtils/getAllProductionYears';
import { getYearGroups } from 'components/OrderTables/YearGroupedOrderTables/getYearGroups';
import YearGroupedOrderTable from 'components/OrderTables/YearGroupedOrderTables/YearGroupedOrderTable';
import { Order } from '../../../common/interfaces';
import { TableSkeleton } from '../../../components/Skeletons';
import { useAppSelector } from '../../../store/hooks';
import {
	selectActiveAskStrips,
	selectActiveBidStrips,
	selectActiveBuyOrders,
	selectActiveOrders,
	selectActiveOrdersStatus,
	selectActiveSellOrders,
} from '../../../store/orders/activeOrders/activeOrdersSelectors';
import { useOrdersOutletContext } from '../Orders';

const CurrentOrders: FunctionComponent = () => {
	useSetPageTitle('Market Orders');

	const { onOrderClicked, setOrderFilterOptions, filterOrders, filterChoices } = useOrdersOutletContext();

	const orderStatus = useAppSelector(selectActiveOrdersStatus);
	const orders = useAppSelector(selectActiveOrders);
	const ordersFiltered: Order[] = filterOrders(orders, filterChoices);
	const buyOrders = useAppSelector(selectActiveBuyOrders);
	const buyOrdersFiltered: Order[] = filterOrders(buyOrders, filterChoices);
	const sellOrders = useAppSelector(selectActiveSellOrders);
	const sellOrdersFiltered: Order[] = filterOrders(sellOrders, filterChoices);

	const askStrips = useAppSelector(selectActiveAskStrips);
	const bidStrips = useAppSelector(selectActiveBidStrips);
	const nrOfRows = bidStrips.length > askStrips.length ? bidStrips.length : askStrips.length;

	const activeProductionYears = getAllProductionYears(ordersFiltered);
	useEffect(() => setOrderFilterOptions(getOrderFilterOptions(activeProductionYears)), []);

	const productionYears = useMemo(() => {
		return getYearGroups(activeProductionYears, sellOrdersFiltered, buyOrdersFiltered);
	}, [ordersFiltered]);

	const initialSorting = [{ id: 'productionPeriod', desc: false }];

	return (
		<TableSkeleton
			status={orderStatus}
			error="Failed to load orders"
			config={[{ numberOfTables: 2, numberOfItems: 6 }]}
		>
			<HStack fullWidth gap="md" wrap="nowrap">
				<VStack fullWidth gap="md">
					<YearGroupedOrderTable
						header="Buy orders"
						orders={buyOrdersFiltered}
						nrOfStripsRows={nrOfRows}
						productionYears={productionYears}
						onRowClicked={onOrderClicked}
						noDataText="No active buy orders"
						initialSorting={initialSorting}
					/>
				</VStack>
				<VStack fullWidth gap="md">
					<YearGroupedOrderTable
						header="Sell orders"
						orders={sellOrdersFiltered}
						nrOfStripsRows={nrOfRows}
						productionYears={productionYears}
						reverse
						onRowClicked={onOrderClicked}
						noDataText="No active sell orders"
						initialSorting={initialSorting}
					/>
				</VStack>
			</HStack>
		</TableSkeleton>
	);
};

export default CurrentOrders;
