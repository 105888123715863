import { styled } from 'styled-components';

const StyledTable = styled.table`
	border-collapse: collapse;
	width: 100%;
	& > tbody > tr {
		line-height: 3rem;
		&:hover {
			background-color: ${({ theme }) => theme.colors.element};
		}
	}
	& > thead > tr {
		height: ${({ theme }) => theme.spacing.xxl};
	}

	td:first-child,
	th:first-child {
		padding-left: ${({ theme }) => theme.spacing.xl};
	}

	td:last-child,
	th:last-child {
		padding-right: ${({ theme }) => theme.spacing.xl};
	}
`;

export default StyledTable;
