import { createContext, FunctionComponent, ReactNode, useContext, useState } from 'react';
import { RequestStatus } from '@montel/montelpro-shared-components/enums';
import { useGet } from '@montel/montelpro-shared-components/hooks';
import { ORDER_PRESETS_API_ROUTE } from 'apiRoutes';
import { OrderPreset } from 'common/interfaces/OrderPreset';

export type PresetsContextType = {
	selectedPreset: OrderPreset | null | undefined;
	setSelectedPreset: React.Dispatch<OrderPreset | null>;
	presets: OrderPreset[];
	fetchPresets: () => Promise<any>;
	presetsStatus: RequestStatus;
};

export const PresetsContext = createContext<PresetsContextType | null>(null);

const PresetsProvider: FunctionComponent<{ children: ReactNode }> = ({ children }) => {
	const [selectedPreset, setSelectedPreset] = useState<OrderPreset | null | undefined>();
	const { data: presets, getData: fetchPresets, status: presetsStatus } = useGet(ORDER_PRESETS_API_ROUTE);

	return (
		<PresetsContext.Provider
			value={{
				selectedPreset,
				setSelectedPreset,
				presets,
				fetchPresets,
				presetsStatus,
			}}
		>
			{children}
		</PresetsContext.Provider>
	);
};

export function usePresetsContext() {
	const context = useContext(PresetsContext);
	if (!context) {
		throw new Error('usePresetsContext must be used within the PresetsProvider');
	}
	return context;
}

export default PresetsProvider;
