import React, { createContext, FunctionComponent, useEffect, useState } from 'react';
import { getValueFromLocalStorage, saveToLocalStorage } from '@montel/montelpro-shared-components/utils';
interface IMenuContext {
	menuOpen: boolean;
	subOpen: string;
	toggleMenu(menuValue: boolean): void;
	toggleSubMenu(subTitle: string): void;
}

export const MenuContext = createContext<IMenuContext>({
	menuOpen: true,
	subOpen: '',
	toggleMenu: () => {},
	toggleSubMenu: () => {},
});

export const MenuProvider: FunctionComponent<{ children: React.ReactNode }> = ({ children }) => {
	const [menuOpen, setMenuOpen] = useState(true);
	const [subOpen, setSubOpen] = useState('');

	useEffect(() => {
		const localMenuValue = getValueFromLocalStorage('montelProMenu', 'true');
		const isTrueSet = localMenuValue === 'true';
		if (localMenuValue) {
			setMenuOpen(isTrueSet);
		}
	}, []);

	const toggleMenu = (menuValue: boolean) => {
		saveToLocalStorage(menuValue.toString(), 'montelProMenu');
		setMenuOpen(menuValue);
	};

	const toggleSubMenu = (subTitle: string) => {
		setSubOpen(subTitle);
	};

	return (
		<MenuContext.Provider
			value={{
				menuOpen,
				subOpen,
				toggleMenu,
				toggleSubMenu,
			}}
		>
			{children}
		</MenuContext.Provider>
	);
};
