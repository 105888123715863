import { Label } from '../OrderEnums';

export const labelNames: { [key in keyof typeof Label]: string } = {
	Default: 'Default',
	Any: 'Any',
	AlpineHydropower: 'Alpine Hydropower',
	BraMiljovalMedAvgifterOchFondering: 'Bra Miljöval med avgifter och fondering',
	BraMiljovalUtenAvgifterOchFondering: 'Bra Miljöval utan avgifter och fondering',
	CfDUK: 'CfD (UK)',
	EKOenergy: 'EKOenergy',
	FiTUKOFGEM: 'FiT (UK) OFGEM',
	SEGUK: 'SEG (UK)',
	GeprufterOkostromTUVNord: 'Geprüfter Ôkostrom (TÜV Nord)',
	GrunerStromLabel: 'Grüner Strom Label',
	Naturemadebasic: 'Naturemade basic',
	Naturemadestar: 'Naturemade star',
	NTA8080: 'NTA 8080',
	OKPower: 'OK-Power',
	TUVSUDEE: 'TÜV SÜD EE',
	TUVSUDEE01: 'TÜV SÜD EE01',
	TUVSUDEE02: 'TÜV SÜD EE02',
	WELOVEENERGY: 'WE LOVE ENERGY',
	TUVNORD: 'TUV Nord',
	REGOS: 'REGOS',
};

const labelNamesV2 = {
	[Label.Default]: 'Default',
	[Label.Any]: 'Any',
	[Label.AlpineHydropower]: 'Alpine Hydropower',
	[Label.BraMiljovalMedAvgifterOchFondering]: 'Bra Miljöval med avgifter och fondering',
	[Label.BraMiljovalUtenAvgifterOchFondering]: 'Bra Miljöval utan avgifter och fondering',
	[Label.CfDUK]: 'CfD (UK)',
	[Label.EKOenergy]: 'EKOenergy',
	[Label.FiTUKOFGEM]: 'FiT (UK) OFGEM',
	[Label.SEGUK]: 'SEG (UK)',
	[Label.GeprufterOkostromTUVNord]: 'Geprüfter Ôkostrom (TÜV Nord)',
	[Label.GrunerStromLabel]: 'Grüner Strom Label',
	[Label.Naturemadebasic]: 'Naturemade basic',
	[Label.Naturemadestar]: 'Naturemade star',
	[Label.NTA8080]: 'NTA 8080',
	[Label.OKPower]: 'OK-Power',
	[Label.TUVSUDEE]: 'TÜV SÜD EE',
	[Label.TUVSUDEE01]: 'TÜV SÜD EE01',
	[Label.TUVSUDEE02]: 'TÜV SÜD EE02',
	[Label.WELOVEENERGY]: 'WE LOVE ENERGY',
	[Label.TUVNORD]: 'TUV Nord',
	[Label.REGOS]: 'REGOS',
};

export const getLabelName = (label: Label) => labelNamesV2[label];

//These labels have been deprecated and should not be available as options in the UI
export const deprecatedLabels = [Label.CfDUK, Label.FiTUKOFGEM];
