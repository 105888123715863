import { FunctionComponent, ReactNode } from 'react';
import { VStack } from '@montel/montelpro-shared-components-v2';
import { css, styled } from 'styled-components';

const StyledContainer = styled(VStack)<{ $bgImageUrl?: string }>`
	height: 100vh;

	${({ $bgImageUrl }) =>
		$bgImageUrl &&
		css`
			background-image: url(${$bgImageUrl});
			background-size: cover;
		`}
`;

const EmptyPageContainer: FunctionComponent<{ children: ReactNode; bgImageUrl?: string }> = ({
	children,
	bgImageUrl,
}) => (
	<StyledContainer fullWidth $bgImageUrl={bgImageUrl}>
		{children}
	</StyledContainer>
);

export default EmptyPageContainer;
