import { AvailableTo, Currency, OrderTypeEnum } from 'common/enums';
import { commonFields, expiryPresetIsSpecifyAndExpiresIsNotSet } from 'common/forms/commonFields';
import moment from 'moment';
import { z } from 'zod';
import { fields as deliveryFields } from '../DeliveryStep/DeliveryStep.types';
import { fields as productFields } from '../ProductStep/ProductStep.types';

const counterpartyOption = z.object({
	label: z.string(),
	value: z.number(),
});

export const fields = {
	quantity: commonFields.quantity,
	pricePerUnit: commonFields.pricePerUnit,
	expiryPreset: commonFields.expiryPreset,
	expires: commonFields.expires,
	orderType: z.nativeEnum(OrderTypeEnum),
	currency: z.nativeEnum(Currency),
	availableTo: z.nativeEnum(AvailableTo),
	orderCounterparties: z.array(counterpartyOption).optional(),
};

export const priceStep = z.object(fields);

export const PriceSchema = z
	.object({ ...productFields, ...deliveryFields, ...fields })
	.refine((fields) => !typeIsFixedPriceAndPricePerUnitIsNotSet(fields.orderType, fields.pricePerUnit), {
		message: 'Price per unit is required',
		path: ['pricePerUnit'],
	})
	.refine(
		(fields) =>
			!(
				fields.orderType === OrderTypeEnum.FixedPrice &&
				fields.pricePerUnit &&
				fields.pricePerUnit.toFloat() > 999999.9999
			),
		{
			message: 'Price per unit should be less than 999999.9999',
			path: ['pricePerUnit'],
		},
	)
	.refine(
		(fields) =>
			!(
				fields.orderType === OrderTypeEnum.FixedPrice &&
				fields.pricePerUnit &&
				fields.pricePerUnit.toFloat() < 0.00009
			),
		{
			message: 'Price per unit should be at least 0.0001',
			path: ['pricePerUnit'],
		},
	)
	.refine((fields) => !expiryPresetIsSpecifyAndExpiresIsNotSet(fields.expiryPreset, fields.expires), {
		message: 'Please specify expiry time',
		path: ['expires'],
	})
	.refine((fields) => validateExpiryTimeIsBeforeDeliveryDate(fields.expires, fields.deliveryDate), {
		message: 'Expiry time should be before delivery date',
		path: ['expires'],
	})
	.refine((val) => (val.availableTo === AvailableTo.Selected ? val.orderCounterparties?.length : true), {
		path: ['orderCounterparties'],
		message: 'Please select at least one counterparty',
	});

export type PriceState = z.infer<typeof PriceSchema>;

const typeIsFixedPriceAndPricePerUnitIsNotSet = (orderType: OrderTypeEnum, pricePerUnit: string | undefined) =>
	orderType === OrderTypeEnum.FixedPrice && !pricePerUnit;

const validateExpiryTimeIsBeforeDeliveryDate = (expires: Date | null, deliveryDate: Date | null) =>
	!expires || !deliveryDate || moment(expires, 'DD/MM/YYYY HH:mm').isBefore(deliveryDate);
