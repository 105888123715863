import { HStack, VStack } from '@montel/montelpro-shared-components-v2';
import { styled } from 'styled-components';

export const StyledIconCell = styled(VStack)`
	height: 50px;
	padding-top: 3px;
	justify-content: flex-start;
	padding-left: ${({ theme }) => theme.spacing.xs};
`;

export const StyledCell = styled(VStack)<{
	$offsetLeft?: string;
	$offsetRight?: string;
}>`
	height: 50px;
	${({ $offsetLeft }) => $offsetLeft && `padding-left: ${$offsetLeft};`}
	${({ $offsetRight }) => $offsetRight && `padding-right: ${$offsetRight};`}
`;

export const StyledHStack = styled(HStack)<{
	$tooltipSide?: string;
}>`
	${({ $tooltipSide }) => ($tooltipSide === 'right' ? `flex-direction: row-reverse;` : `flex-direction: row;`)}
`;
