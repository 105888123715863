import { Box, VStack } from '@montel/montelpro-shared-components-v2';
import styled, { css } from 'styled-components';

export const Container = styled.div`
	width: 100%;
	min-width: 90rem;
`;

export const Separator = styled.hr`
	${({ theme }) => {
		const { spacing, colors } = theme;

		return css`
			margin-left: ${spacing.md};
			margin-right: ${spacing.md};
			height: ${spacing.lg};
			border: 1px solid ${colors.subElement};
		`;
	}};
`;

export const StyledSwitchBox = styled<any>(Box).attrs({
	variant: 'outlined',
	bg: 'element',
})`
	width: 100%;
	max-width: 27rem;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: space-between;
	gap: 2rem;
`;

export const StyledBrowserSwitchBox = styled<any>(Box).attrs({
	variant: 'outlined',
	bg: 'element',
})`
	width: 100%;
	max-width: 27rem;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: space-between;
	gap: 0.5rem;
`;

export const InnerContainer = styled(VStack).attrs({ gap: 'xl' })`
	width: 83rem;
`;
