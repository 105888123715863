import { FunctionComponent } from 'react';
import { Button, IconCrossCancel } from '@montel/montelpro-shared-components-v2';

export const CancelTradeAction: FunctionComponent<{ onClick: () => void }> = ({ onClick }) => (
	<Button
		variant="negative"
		text={'Cancel trade'}
		size="sm"
		onClick={onClick}
		leftIcon={<IconCrossCancel boxSize="8px" />}
	/>
);
