import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import companiesReducer from './companies/companiesSlice';
import listsReducer from './lists/listsSlice';
import ordersReducer from './orders/ordersSlice';
import { userMiddleware } from './user/userMiddleware';
import userReducer from './user/userSlice';

export const store = configureStore({
	reducer: {
		user: userReducer,
		lists: listsReducer,
		companies: companiesReducer,
		orders: ordersReducer,
	},
	middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(userMiddleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, unknown, unknown, Action<string>>;
