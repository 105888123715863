import PeriodTypeEnum from '../PeriodTypeEnum';

const periodTypeNames = {
	[PeriodTypeEnum.DEFAULT]: 'period',
	[PeriodTypeEnum.YEAR]: 'year',
	[PeriodTypeEnum.QUARTER]: 'quarter',
	[PeriodTypeEnum.MONTH]: 'month',
};

export const getPeriodTypeName = (periodType: PeriodTypeEnum | undefined) =>
	periodType ? periodTypeNames[periodType] : periodTypeNames[PeriodTypeEnum.DEFAULT];
