import {
	IconTechnologyBio,
	IconTechnologyGeoThermal,
	IconTechnologyHydro,
	IconTechnologyNuclear,
	IconTechnologySolar,
	IconTechnologyWind,
} from '@montel/montelpro-shared-components-v2';
import { Tech } from '../../../common/enums/OrderEnums';

const iconSize = '1.5rem';
const getTechnologyOptions = () => [
	{
		label: <IconTechnologyBio boxSize={iconSize} />,
		value: Tech.Biomass,
		caption: 'BIOMASS',
	},
	{
		label: <IconTechnologyGeoThermal boxSize={iconSize} />,
		value: Tech.Geothermal,
		caption: 'GEO',
	},
	{
		label: <IconTechnologyHydro boxSize={iconSize} />,
		value: Tech.Hydro,
		caption: 'HYDRO',
	},
	{
		label: <IconTechnologySolar boxSize={iconSize} />,
		value: Tech.Solar,
		caption: 'SOLAR',
	},
	{
		label: <IconTechnologyWind boxSize={iconSize} />,
		value: Tech.Wind,
		caption: 'WIND',
	},
	{
		label: <IconTechnologyNuclear boxSize={iconSize} />,
		value: Tech.Nuclear,
		caption: 'NUCLEAR',
	},
];
export default getTechnologyOptions;
