import { ProductionPeriod } from 'common/interfaces';
import { getProductionPeriodString } from './getProductionPeriodString';

export const getProductionPeriodsString = (periods: ProductionPeriod[]): string => {
	let year: number;
	const productionPeriods = periods?.map((period) => {
		const periodString = getProductionPeriodString(period, period.year !== year);
		year = period.year;

		return periodString;
	});

	return productionPeriods?.join(', ');
};
