import { createContext, FunctionComponent, useContext, useState } from 'react';
import { RequestStatus } from '@montel/montelpro-shared-components/enums';
import { useGetOnMount, useUpdate } from '@montel/montelpro-shared-components/hooks';
import { MARKETPLACE_ALERTS_API_ROUTE } from 'apiRoutes';
import useDelete from 'hooks/useDelete';
import useSubmit, { POST, PUT } from 'hooks/useSubmit';
import { AlertType } from '../pages/Alerts/types';

export interface BrowserToken {
	name: string;
	token: string;
}

export interface AlertSettings {
	browserNotificationsEnabled: boolean;
	emailNotificationsEnabled: boolean;
	allTradesEnabled: boolean;
	allAskOrdersEnabled: boolean;
	allBidOrdersEnabled: boolean;
}

export interface IAlertSettingsContext {
	browserNotificationsEnabled: boolean;
	emailNotificationsEnabled: boolean;
	allTradesEnabled: boolean;
	allAskOrdersEnabled: boolean;
	allBidOrdersEnabled: boolean;
	enabledAlertTypes: AlertType[];
	updateBrowserEnabled: (enabled: boolean) => Promise<void>;
	updateEmailEnabled: (enabled: boolean) => Promise<void>;
	updateAllAskOrdersEnabled: (enabled: boolean) => Promise<void>;
	updateAllBidOrdersEnabled: (enabled: boolean) => Promise<void>;
	updateAllTradesEnabled: (enabled: boolean) => Promise<void>;
	browsers: BrowserToken[];
	settingsStatus: RequestStatus;
	browsersStatus: RequestStatus;
	addBrowser: (name: string, token: string) => Promise<void>;
	removeBrowser: (token: string) => Promise<void>;
}

const alertSettingsContext = createContext<any>({});

const AlertSettingsProvider: FunctionComponent<{
	children: any;
}> = ({ children }) => {
	const {
		data: settings,
		getData: getSettings,
		status: settingsStatus,
	} = useGetOnMount(`${MARKETPLACE_ALERTS_API_ROUTE}/GetSettings`);

	const {
		data: browserTokens,
		getData: getBrowserTokens,
		status: browsersStatus,
	} = useGetOnMount(`${MARKETPLACE_ALERTS_API_ROUTE}/GetBrowserTokens`);

	const unexpectedErrorMessage = 'An error has occured, please try again.';
	const [postSettings, , settingsError] = useSubmit(`${MARKETPLACE_ALERTS_API_ROUTE}/UpdateSettings`, PUT, {
		messageOnError: unexpectedErrorMessage,
	});

	const [postBrowser, , postBrowserError] = useSubmit(`${MARKETPLACE_ALERTS_API_ROUTE}/AddBrowserToken`, POST, {
		messageOnError: unexpectedErrorMessage,
	});

	const { deleteItem: deleteBrowserToken, deleteError } = useDelete({
		messageOnError: unexpectedErrorMessage,
	});

	const [alertSettings, setAlertSettings] = useState<AlertSettings>(settings);
	const [alertBrowsers, setAlertBrowsers] = useState<BrowserToken[]>(browserTokens);

	const [enabledAlertTypes, setEnabledAlertTypes] = useState<AlertType[]>([]);
	useUpdate(() => {
		const alertTypes = [];
		if (alertSettings?.allAskOrdersEnabled) {
			alertTypes.push(AlertType.Ask);
		}
		if (alertSettings?.allBidOrdersEnabled) {
			alertTypes.push(AlertType.Bid);
		}
		if (alertSettings?.allTradesEnabled) {
			alertTypes.push(AlertType.Trade);
		}
		setEnabledAlertTypes(alertTypes);
	}, [alertSettings]);

	useUpdate(() => setAlertSettings(settings), [settings]);
	useUpdate(() => setAlertBrowsers(browserTokens), [browserTokens]);
	useUpdate(async () => await getSettings(), [settingsError]);
	useUpdate(async () => await getBrowserTokens(), [postBrowserError, deleteError]);

	const updateBrowserEnabled = async (enabled: boolean) =>
		await updateSetting('browserNotificationsEnabled', enabled);
	const updateEmailEnabled = async (enabled: boolean) => await updateSetting('emailNotificationsEnabled', enabled);
	const updateAllAskOrdersEnabled = async (enabled: boolean) => await updateSetting('allAskOrdersEnabled', enabled);
	const updateAllBidOrdersEnabled = async (enabled: boolean) => await updateSetting('allBidOrdersEnabled', enabled);
	const updateAllTradesEnabled = async (enabled: boolean) => await updateSetting('allTradesEnabled', enabled);

	const addBrowser = async (name: string, token: string) => {
		setAlertBrowsers([...alertBrowsers, { name, token }]);

		await postBrowser({ name, token });
	};

	const removeBrowser = async (token: string) => {
		const browsers = alertBrowsers?.filter((browser) => browser.token !== token);

		setAlertBrowsers(browsers);

		await deleteBrowserToken(`${MARKETPLACE_ALERTS_API_ROUTE}/DeleteBrowserToken`, {
			params: {
				token: token,
			},
		});
	};

	const updateSetting = async (settingName: keyof AlertSettings, enabled: boolean) => {
		const newSettings = {
			...alertSettings,
			[settingName]: enabled,
		};

		setAlertSettings(newSettings);
		await postSettings(newSettings);
	};

	return (
		<alertSettingsContext.Provider
			value={{
				browserNotificationsEnabled: alertSettings?.browserNotificationsEnabled,
				browsers: alertBrowsers,
				emailNotificationsEnabled: alertSettings?.emailNotificationsEnabled,
				allAskOrdersEnabled: alertSettings?.allAskOrdersEnabled,
				allBidOrdersEnabled: alertSettings?.allBidOrdersEnabled,
				allTradesEnabled: alertSettings?.allTradesEnabled,
				updateBrowserEnabled,
				updateEmailEnabled,
				updateAllAskOrdersEnabled,
				updateAllBidOrdersEnabled,
				updateAllTradesEnabled,
				addBrowser,
				removeBrowser,
				settingsStatus,
				browsersStatus,
				enabledAlertTypes,
			}}
		>
			{children}
		</alertSettingsContext.Provider>
	);
};

const useAlertSettingsContext = () => {
	const context: IAlertSettingsContext = useContext(alertSettingsContext);
	if (context === undefined) {
		throw new Error(`useAlertsContext must be used within an AlertsProvider`);
	}
	return context;
};

export { AlertSettingsProvider, useAlertSettingsContext };
