import { FunctionComponent } from 'react';
import { useLocation } from 'react-router-dom';
import { H2 } from '@montel/montelpro-shared-components-v2';
import { ColumnDef, SortingState } from '@tanstack/react-table';
import { Order } from 'common/interfaces/Order';
import { useOrderPages } from 'pages/Orders/useOrderPages';
import { useAppSelector } from 'store/hooks';
import { selectActiveCompanyId } from 'store/user/userSelectors';
import NewOrderDropdown from 'components/NewOrderDropdown';
import { NoData } from 'components/Tables/NoData';
import Th from 'components/Tables/Th';
import useSortedTable from 'components/Tables/useSortedTable';
import { useMarketOrdersColumns } from '../Columns/orderTableColumns';
import { StyledBox, StyledHeadRow, StyledTable, TableWrapper } from '../OrderTable.styles';
import StripsGrouping from './StripsGrouping';
import YearGrouping, { YearGroup } from './YearGrouping';

interface OrderTableProps {
	orders: Order[];
	nrOfStripsRows: number;
	header: string;
	initialSorting?: SortingState;
	onRowClicked?: (order: Order) => void;
	noDataText?: string;
	reverse?: boolean;
	productionYears: YearGroup[];
	columns?: ColumnDef<Order, any>[];
}

const YearGroupedOrderTable: FunctionComponent<OrderTableProps> = ({
	orders,
	nrOfStripsRows,
	header,
	initialSorting,
	onRowClicked,
	noDataText = 'There is currently nothing to show here.',
	reverse,
	productionYears,
	columns,
}) => {
	const { isEndOfDayPage } = useOrderPages(useLocation().pathname);
	const activeCompanyId = useAppSelector(selectActiveCompanyId);
	const showNewButton = !isEndOfDayPage;

	const selectedColumns = columns || useMarketOrdersColumns(reverse);

	const orderType = header === 'Buy orders' ? 'Buy' : 'Sell';

	const table = useSortedTable({ data: orders, columns: selectedColumns, initialSorting });

	const rowModel = table.getSortedRowModel();
	const noOrders = rowModel.rows.length === 0 && productionYears?.length === 0;

	return (
		<TableWrapper fullWidth gap="md">
			<StyledBox>
				<H2>{header}</H2>
				{activeCompanyId && showNewButton && <NewOrderDropdown orderType={orderType} />}
			</StyledBox>
			<StyledTable>
				<thead>
					{table.getHeaderGroups().map((headerGroup) => (
						<StyledHeadRow key={headerGroup.id}>
							{headerGroup.headers.map((header) => (
								<Th key={header.id} header={header} />
							))}
						</StyledHeadRow>
					))}
				</thead>
				{productionYears.map((prodYear, i) => (
					<YearGrouping
						key={prodYear.year}
						productionYear={prodYear}
						columns={selectedColumns}
						index={i}
						rowModel={rowModel}
						noDataText={noDataText}
						onRowClicked={onRowClicked}
						reverse={reverse}
					/>
				))}
				{Boolean(nrOfStripsRows) && (
					<StripsGrouping
						nrOfRows={nrOfStripsRows}
						columns={selectedColumns}
						rowModel={rowModel}
						noDataText={noDataText}
						onRowClicked={onRowClicked}
						reverse={reverse}
					/>
				)}
			</StyledTable>
			{noOrders && <NoData>{noDataText}</NoData>}
		</TableWrapper>
	);
};

export default YearGroupedOrderTable;
