import { Stepper, VStack } from '@montel/montelpro-shared-components-v2';
import styled from 'styled-components';

export const StyledStepper = styled(Stepper)`
	width: 100%;
	max-width: 500px;
	margin: 0 auto;
	//TODO: remove when old library is removed
	& p {
		font-family: 'Roboto', sans-serif;
		font-size: ${({ theme }) => theme.typography.size.xs};
		line-height: unset;
		font-weight: unset;
	}
`;

export const Container = styled.div`
	max-width: 634px;
	width: 100%;
	min-height: 750px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	justify-content: stretch;
	gap: ${(props) => props.theme.spacing.xl};
	padding: ${(props) => props.theme.spacing.xxl};
	background-color: ${(props) => props.theme.colors.widget};
	border-radius: ${(props) => props.theme.borderRadii.lg};
`;

export const ButtonsContainer = styled.div`
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	flex-grow: 1;
`;

export const StepContentContainer = styled(VStack)`
	flex-grow: 1;
`;
