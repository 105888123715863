import { INotificationLibraryAsset } from '../types';
import orderTranslators from './order';

export const translators: INotificationLibraryAsset = {
	type: 'translator',
	order: { ...orderTranslators },
	company: {},
	systemNotification: {},
};

export default translators;
