import { SelectOptionType } from '@montel/montelpro-shared-components';
import DeliveryDatePresetEnum from 'common/enums/DeliveryDatePresetEnum';
import isStandardDeliveryValid from './isStandardDeliveryValid';

export const getDeliveryDatePresetOptions = (year?: number): SelectOptionType[] => [
	{
		label: 'Standard',
		value: DeliveryDatePresetEnum.STANDARD,
		disabled: !isStandardDeliveryValid(year),
	},
	{ label: 'Spot', value: DeliveryDatePresetEnum.SPOT },
	{ label: 'Date', value: DeliveryDatePresetEnum.DATE },
];
