import { RequestStatus } from '@montel/montelpro-shared-components/enums';
import { createSlice } from '@reduxjs/toolkit';
import getActiveCompanyId from './utils/getActiveCompanyId';
import { getUserInfo } from './userThunks';
import { IMarketPlaceRole, IUserCompany } from './userTypes';

export interface IUserState {
	token: string | null;
	userId: string;
	firstName: string;
	lastName: string;
	activeCompanyId: number | null;
	marketPlaceRoles: IMarketPlaceRole[];
	userCompanies: IUserCompany[];
	userCompany: string | null;
	email: string;
	isEmailVerified: boolean;
	entity: string;
	status: RequestStatus;
	error?: any;
	isInitialised: boolean;
}

export const initialState: IUserState = {
	token: null,
	userId: '',
	firstName: '',
	lastName: '',
	activeCompanyId: null,
	marketPlaceRoles: [],
	userCompanies: [],
	userCompany: '',
	email: '',
	isEmailVerified: false,
	entity: '',
	status: RequestStatus.UNLOADED,
	error: undefined,
	isInitialised: false,
};

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setToken(state, action) {
			state.token = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getUserInfo.fulfilled, (state, { payload: userInfo }) => {
			state.userId = userInfo?.id || '';
			state.firstName = userInfo?.firstName || '';
			state.lastName = userInfo?.lastName || '';
			state.marketPlaceRoles = userInfo?.marketPlaceRoles || [];
			state.email = userInfo?.email || '';
			state.isEmailVerified = userInfo?.verified || false;
			state.activeCompanyId = state.userId ? getActiveCompanyId(state.userId, state.marketPlaceRoles) : null;
			state.userCompanies = userInfo?.marketPlaceRoles;
			state.userCompany =
				state.userCompanies.find((company) => company.companyId === state.activeCompanyId)?.companyName ?? '';

			state.status = RequestStatus.SUCCESS;
			state.error = undefined;
			state.isInitialised = true;
		});
		builder.addCase(getUserInfo.pending, (state) => {
			state.status = RequestStatus.LOADING;
			state.error = undefined;
		});
		builder.addCase(getUserInfo.rejected, (state, { payload }) => {
			state.status = RequestStatus.ERROR;
			state.error = payload;
		});
	},
});

export const { setToken } = authSlice.actions;

export default authSlice.reducer;
