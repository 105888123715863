import { FunctionComponent } from 'react';
import { HStack, IconCheck } from '@montel/montelpro-shared-components-v2';
import { Text } from '@montel/montelpro-shared-components-v2';
import { useTheme } from 'styled-components';

export const TradeCompleted: FunctionComponent = () => {
	const theme = useTheme();

	return (
		<HStack align="center">
			<IconCheck color={theme.colors.success} boxSize="9px" />
			<Text size="xs" color={theme.colors.success}>
				Completed
			</Text>
		</HStack>
	);
};
