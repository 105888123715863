import { FunctionComponent, useState } from 'react';
import { RequestStatus } from '@montel/montelpro-shared-components/enums';
import { Button, HStack, IconChevronLeft, Text, VStack } from '@montel/montelpro-shared-components-v2';
import { Currency } from 'common/enums';
import { QuoteStatusEnum } from 'common/enums/quoteStatusEnum';
import moment from 'moment';
import { getExpiryDate } from 'utils/getExpiryDate';
import getTotalPriceFormatted from 'components/NewOrder/utils/getTotalPriceFormatted';
import QuoteExpiryCell from 'components/QuotesTable/Cells/QuoteExpiryCell';
import { toTitleCase } from '../../../utils/string-extension';
import Prompt from '../../Prompt';
import { useOrderDetailsContext } from '../OrderDetailsContext';
import apiClient from '../QuoteDetails/apiClient';
import { getTypeOfQuote } from './getTypeOfQuote';
import { usePlaceQuoteFormContext } from './PlaceQuoteFormContext';
import { getSummaryStepPromptHeights } from './stepDefinitions';

interface SummaryStepProps {
	onPrevious: () => void;
}

export const toPayload = (formState: any) => {
	const { quantity, pricePerUnit, expiryPreset, expires } = formState;

	return {
		quantity: quantity.toFloat(),
		price: pricePerUnit.toFloat(),
		expiryTime: getExpiryDate(expiryPreset, expires),
	};
};

export const SummaryValue = ({ label, value }: { label: string; value: React.ReactNode }) => (
	<VStack>
		<Text size="xs">{label}</Text>
		<Text size="sm" weight="600">
			{value}
		</Text>
	</VStack>
);

const SummaryStep: FunctionComponent<SummaryStepProps> = ({ onPrevious }) => {
	const { order } = useOrderDetailsContext();
	const { setIsQuoteFormOpen, resetQuoteForm, formState, isCreateAction } = usePlaceQuoteFormContext();
	const [willClose, setWillClose] = useState(false);
	const quoteType = getTypeOfQuote(order.side);

	const [postCreate, statusCreate] = apiClient.useCreateQuote({
		orderId: order.id,
		expectedVersion: order.version,
		quoteType,
	});

	const [postUpdate, statusUpdate] = apiClient.useUpdateQuote({
		orderId: order.id,
		quoteId: formState.id ?? '',
		expectedVersion: order.version,
		quoteType,
	});

	const submit = isCreateAction ? postCreate : postUpdate;
	const status = isCreateAction ? statusCreate : statusUpdate;
	const actionName = isCreateAction ? 'place' : 'edit';

	const onSubmit = async () => {
		const result = await submit(toPayload(formState));

		if (result.success) {
			setWillClose(true);
		}
	};

	const animationHeights = getSummaryStepPromptHeights(order.quotes.length);

	return (
		<Prompt
			onClosePrompt={() => setWillClose(true)}
			animateFromHeight={animationHeights.from}
			animateToHeight={willClose ? 0 : animationHeights.to}
			onAnimationComplete={() => {
				if (willClose) {
					setWillClose(false);
					setIsQuoteFormOpen(false);
					resetQuoteForm();
				}
			}}
			headerText={`${toTitleCase(actionName)} ${quoteType}`}
			body={
				<VStack fullWidth gap="lg">
					<Text size="md">{`Do you want to ${actionName} this ${quoteType}?`}</Text>
					<HStack fullWidth gap="md" justify="space-between">
						<SummaryValue
							label={`${toTitleCase(quoteType)} expires`}
							value={
								<QuoteExpiryCell
									status={QuoteStatusEnum.Active}
									expiryTimestamp={Math.min(
										getExpiryDate(formState.expiryPreset, formState.expires).utc().unix() * 1000,
										moment(order.expiryTime).utc().unix() * 1000,
									)}
								/>
							}
						/>
						<SummaryValue label="Quantity" value={`${formState.quantity} MWh`} />
						<SummaryValue
							label="Unit Price"
							value={`${formState.pricePerUnit} ${Currency[order.cost.currency]}`}
						/>
						<SummaryValue
							label="Total Price"
							value={`${getTotalPriceFormatted(formState.quantity, formState.pricePerUnit ?? '0')} ${
								Currency[order.cost.currency]
							}`}
						/>
					</HStack>
				</VStack>
			}
			footer={
				<HStack fullWidth gap="md" justify="flex-end">
					<Button variant="secondary" text="Back" onClick={onPrevious} leftIcon={<IconChevronLeft />} />
					<Button
						type="submit"
						text={isCreateAction ? `Place ${quoteType}` : 'Submit'}
						loadingText="Submitting"
						isLoading={status === RequestStatus.LOADING}
						onClick={onSubmit}
					/>
				</HStack>
			}
		/>
	);
};

export default SummaryStep;
