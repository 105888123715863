import { RequestStatus } from '@montel/montelpro-shared-components/enums';

export const isError = (status: RequestStatus | RequestStatus[]) =>
	Array.isArray(status)
		? (status as RequestStatus[]).some((s) => s === RequestStatus.ERROR)
		: (status as RequestStatus) === RequestStatus.ERROR;

export const isSuccessful = (status: RequestStatus | RequestStatus[]) =>
	Array.isArray(status)
		? (status as RequestStatus[]).every((s) => s === RequestStatus.SUCCESS)
		: (status as RequestStatus) === RequestStatus.SUCCESS;

export const isForbidden = (status: RequestStatus | RequestStatus[]) =>
	Array.isArray(status)
		? (status as RequestStatus[]).some((s) => s === RequestStatus.FORBIDDEN)
		: (status as RequestStatus) === RequestStatus.FORBIDDEN;

export const isDataLoaded = (status: RequestStatus | RequestStatus[]) => isSuccessful(status) || isError(status);
