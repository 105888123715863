import { TAction } from './types';

export function getActionText(action: TAction) {
	switch (action) {
		case 'Block':
			return 'Block company';
		case 'Pause':
			return 'Pause counterparty';
		case 'Unblock':
			return 'Unblock company';
		case 'Unpause':
			return 'Unpause counterparty';
		case 'Remove':
			return 'Remove as counterparty';
		default:
			return '';
	}
}
