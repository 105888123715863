import { SelectOptionType } from '@montel/montelpro-shared-components';
import { CounterpartyRelationStatus } from 'common/enums/counterpartyStateEnum';
import { UserCompany } from 'common/interfaces/Company';

const getOptions = (allCompanies: UserCompany[], newPendingCompanies: number[]): SelectOptionType[] => {
	const possibleToJoinFilter = (company: UserCompany) => {
		const approvedOrPending = [CounterpartyRelationStatus.Approved, CounterpartyRelationStatus.Pending].includes(
			company.connectionStatus,
		);
		const justSent = newPendingCompanies.includes(company.id);
		return !approvedOrPending && !justSent;
	};

	return allCompanies
		.filter(possibleToJoinFilter)
		.map((company) => ({ label: company.companyName, value: company.id }));
};

export default getOptions;
