import { FunctionComponent } from 'react';
import { HStack, Input, Select, VStack } from '@montel/montelpro-shared-components-v2';
import { selectCountries } from 'store/lists/listsSelectors';
import { MultipleEmails } from 'components/MultipleEmails';
import { useAppSelector } from '../../../store/hooks';
import { MyCompanyState } from '../MyCompanyState';
import getContactErrors from './getContactErrors';

const Contact: FunctionComponent<{ state: MyCompanyState; setState: React.Dispatch<MyCompanyState> }> = ({
	state,
	setState,
}) => {
	const countryOptions = useAppSelector(selectCountries);
	const errors = getContactErrors(state);

	return (
		<VStack gap="md">
			<Input
				label={'Street'}
				required
				value={state.street}
				onChange={(event: any) => setState({ ...state, street: event.target.value })}
				errorMessage={errors.street}
			/>
			<HStack gap="xs" wrap="nowrap" fullWidth>
				<Input
					label={'Postal code'}
					required
					value={state.postalCode}
					onChange={(event: any) =>
						setState({
							...state,
							postalCode: event.target.value,
						})
					}
					errorMessage={errors.postalCode}
				/>

				<Input
					label={'City'}
					required
					value={state.city}
					onChange={(event: any) => setState({ ...state, city: event.target.value })}
					errorMessage={errors.city}
				/>
			</HStack>
			<Select
				label={'Country'}
				options={countryOptions}
				required
				value={state.countryCode}
				onChange={(value) => setState({ ...state, countryCode: value })}
				errorMessage={errors.countryCode}
			/>

			<MultipleEmails
				label={`Signer's email addresses`}
				value={state.signersEmails}
				setValue={(value: string[]) =>
					setState({
						...state,
						signersEmails: value,
					})
				}
				errorMessage={errors.signersEmails}
			/>
			<MultipleEmails
				label={`KYC contact email addresses`}
				value={state.kycContactEmails}
				setValue={(value: string[]) =>
					setState({
						...state,
						kycContactEmails: value,
					})
				}
				errorMessage={errors.kycContactEmails}
			/>

			<Input
				label="Website"
				value={state.companyWebPage}
				onChange={(event: any) => setState({ ...state, companyWebPage: event.target.value })}
			/>
			<Input
				label="Facebook"
				value={state.facebook}
				onChange={(event: any) => setState({ ...state, facebook: event.target.value })}
			/>
			<Input
				label="X/Twitter"
				value={state.twitter}
				onChange={(event: any) => setState({ ...state, twitter: event.target.value })}
			/>
			<Input
				label="LinkedIn"
				value={state.linkedIn}
				onChange={(event: any) => setState({ ...state, linkedIn: event.target.value })}
			/>
		</VStack>
	);
};

export default Contact;
