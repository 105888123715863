import { FunctionComponent, useState } from 'react';
import { OrderLifecycleEnum, OrderTypeEnum } from 'common/enums/OrderEnums';
import { useOrderFormContext } from 'components/NewOrder/OrderFormProvider';
import { convertOrderToOrderState } from 'components/NewOrder/utils/convertAPIDataUtils';
import { selectCounterPartiesOptions } from '../../../../store/companies/companiesSelectors';
import { useAppSelector } from '../../../../store/hooks';
import CancelOrderPrompt from '../../CancelOrderPrompt';
import { useOrderDetailsContext } from '../../OrderDetailsContext';
import FixedPriceOwnerActions from './FixedPriceOwnerActions';
import RFQOwnerActions from './RFQOwnerActions';

interface OwnerActionsProps {}

const OwnerActions: FunctionComponent<OwnerActionsProps> = () => {
	const { order, closeModal: closeOrderDetailsModal } = useOrderDetailsContext();
	const { setFormState, setModalIsOpen: setNewOrderModalIsOpen } = useOrderFormContext();
	const counterpartyOptions = useAppSelector(selectCounterPartiesOptions);
	const [showCancel, setShowCancel] = useState(false);

	const onShowCancelClose = () => {
		setShowCancel(false);
	};

	const onEditOrder = () => {
		const formData = convertOrderToOrderState(order, counterpartyOptions);

		setFormState({ ...formData, type: OrderLifecycleEnum.Edit });
		setNewOrderModalIsOpen(true);
		closeOrderDetailsModal();
	};

	const isRFQ = order.orderType === OrderTypeEnum.RequestForQuote;

	return (
		<>
			{isRFQ ? (
				<RFQOwnerActions setShowCancel={setShowCancel} />
			) : (
				<FixedPriceOwnerActions setShowCancel={setShowCancel} onEditOrder={onEditOrder} />
			)}
			<CancelOrderPrompt isOpen={showCancel} onClose={onShowCancelClose} order={order} />
		</>
	);
};

export default OwnerActions;
