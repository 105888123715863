import { FunctionComponent } from 'react';
import { Button, H4, HStack, IconAdd, IconDelete, Text, VStack } from '@montel/montelpro-shared-components-v2';

export interface CustomAlertsHeaderProps {
	someSelected: boolean;
	allSelected: boolean;
	onMassDelete: () => void;
	onAddAlert: () => void;
}

const CustomAlertsHeader: FunctionComponent<CustomAlertsHeaderProps> = ({
	someSelected,
	allSelected,
	onMassDelete,
	onAddAlert,
}) => {
	return (
		<VStack fullWidth>
			<H4>Custom alerts</H4>
			<HStack fullWidth justify="space-between" align="center">
				<Text color="secondaryText" size="xs">
					Create your custom alerts
				</Text>
				<HStack>
					{someSelected ? (
						<Button
							variant="secondary"
							text={allSelected ? 'Delete all' : 'Delete selected'}
							rightIcon={<IconDelete />}
							onClick={() => onMassDelete()}
						/>
					) : (
						<></>
					)}
					<Button text="Add alert" leftIcon={<IconAdd />} onClick={onAddAlert} />
				</HStack>
			</HStack>
		</VStack>
	);
};

export default CustomAlertsHeader;
