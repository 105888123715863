import { Box } from '@montel/montelpro-shared-components-v2';
import { css, styled } from 'styled-components';

export const StyledNotice = styled(Box).attrs({
	borderRadius: 'base',
	color: 'primaryText',
	bg: 'info',
})`
	${({ theme }) => css`
		display: flex;
		align-items: center;
		padding: ${theme.spacing.xs} ${theme.spacing.xl};
		font-size: ${theme.typography.size.xs};
		max-height: ${theme.spacing.xl};
	`}
`;

export const StyledIcon = styled.div`
	${({ theme }) => css`
		width: 19px;
		height: 19px;
		margin-right: ${theme.spacing.md};
	`}
`;
