import { useState } from 'react';
import {
	ColumnDef,
	getCoreRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	SortingState,
	useReactTable,
} from '@tanstack/react-table';

interface UsePaginatedTableProps {
	data: Array<any>;
	columns: ColumnDef<any, any>[];
	pageSize?: number;
	initialSorting?: SortingState;
	enableSortingRemoval?: boolean;
}

const usePaginatedTable = ({
	data,
	columns,
	pageSize,
	initialSorting,
	enableSortingRemoval = false,
}: UsePaginatedTableProps) => {
	const [sorting, setSorting] = useState<SortingState>(initialSorting ?? []);
	const table = useReactTable({
		data,
		columns,
		state: {
			sorting,
		},
		initialState: {
			pagination: {
				pageSize: pageSize || 10,
				pageIndex: 0,
			},
		},
		onSortingChange: setSorting,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		enableSortingRemoval: enableSortingRemoval,
	});
	return table;
};

export default usePaginatedTable;
