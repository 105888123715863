import { FunctionComponent } from 'react';
import { Button, Tooltip } from '@montel/montelpro-shared-components-v2';
import { Quote } from 'common/interfaces/Quote';
import { useOrderDetailsContext } from '../../../OrderDetails/OrderDetailsContext';
import { CellProps } from '../../../Tables/Cells';
import { StyledCell } from '../../../Tables/Cells/styles';

export interface QuoteActionCellProps extends CellProps {
	quote: Quote;
}
const QuoteActionCell: FunctionComponent<QuoteActionCellProps> = ({
	offsetLeft,
	offsetRight,
	quote,
	...otherProps
}) => {
	const { id } = quote;
	const { setAcceptQuoteWithId, order } = useOrderDetailsContext();
	const onClick = (e: any) => {
		e.stopPropagation();
		setAcceptQuoteWithId(id);
	};
	const quoteExceedsRemainingQuantity = quote.quantity > order.cost.remainingQuantity;

	return (
		<StyledCell $offsetLeft={offsetLeft} $offsetRight={offsetRight} {...otherProps}>
			{quoteExceedsRemainingQuantity ? (
				<Tooltip component={<Button variant="primary" size="sm" text="Accept" isDisabled />}>
					{"Quote cannot be accepted as it exceeds the order's remaining quantity"}
				</Tooltip>
			) : (
				<Button variant="primary" size="sm" text="Accept" onClick={onClick} />
			)}
		</StyledCell>
	);
};

export default QuoteActionCell;
