import { FunctionComponent, useState } from 'react';
import ProgressLine from '../../Progress/ProgressLine';
import { useOrderDetailsContext } from '../OrderDetailsContext';
import QuotePromptsRouter from './QuotePrompts/QuotePromptsRouter';
import QuoteDetailsBody from './QuoteDetailsBody';
import { QuoteDetailsProvider, TQuoteDetailsPrompt } from './quoteDetailsContext';
import QuoteDetailsHeader from './QuoteDetailsHeader';

const QuoteDetails: FunctionComponent = () => {
	const [prompt, setPrompt] = useState<TQuoteDetailsPrompt>(null);
	const { minutesUntilExpiry, selectedQuote } = useOrderDetailsContext();

	return (
		<QuoteDetailsProvider value={{ prompt, setPrompt }}>
			<ProgressLine minutesUntilExpiry={minutesUntilExpiry} />
			<QuoteDetailsHeader />
			<QuoteDetailsBody />
			<QuotePromptsRouter />
		</QuoteDetailsProvider>
	);
};

export default QuoteDetails;
