import { Steps } from '@montel/montelpro-shared-components-v2';
import Form from './FormStep';
import { usePlaceQuoteFormContext } from './PlaceQuoteFormContext';
import SummaryStep from './SummaryStep';

const PlaceQuoteForm = () => {
	const { isQuoteFormOpen, activeStep, goToNext, goToPrevious } = usePlaceQuoteFormContext();

	if (!isQuoteFormOpen) return null;
	return (
		<Steps currentStep={activeStep}>
			<Form onNext={goToNext} />
			<SummaryStep onPrevious={goToPrevious} />
		</Steps>
	);
};

export default PlaceQuoteForm;
