import { FunctionComponent } from 'react';
import { AvatarImage, H3, VCenter } from '@montel/montelpro-shared-components-v2';
import { styled } from 'styled-components';

const LogoAndName: FunctionComponent<{ src?: string; companyName: string }> = ({ src, companyName }) => (
	<VCenter gap="md">
		<AvatarImage variant={'company'} src={src} borderRadius={'sm'} />
		<StyledH3>{companyName}</StyledH3>
	</VCenter>
);

export default LogoAndName;

const StyledH3 = styled(H3)`
	font-size: ${({ theme }) => theme.typography.size.xxl};
	font-weight: ${({ theme }) => theme.typography.weight.normal};
`;
