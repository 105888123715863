import getAboutErrors from './formSections/getAboutErrors';
import getContactErrors from './formSections/getContactErrors';
import getTradingInterestsErrors from './formSections/getTradingInterestsErrors';
import { MyCompanyState } from './MyCompanyState';

const getErrors = (state: MyCompanyState) => ({
	...getAboutErrors(state),
	...getTradingInterestsErrors(state),
	...getContactErrors(state),
});

export default getErrors;
