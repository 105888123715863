import { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { Text } from '@montel/montelpro-shared-components-v2';
import { selectEmail } from 'store/user/userSelectors';
import EmailPage from './EmailPage';
import ResendVerificationLink from './ResendVerificationEmail';

const EmailVerification: FunctionComponent = () => {
	const email = useSelector(selectEmail);

	return (
		<EmailPage subHeading="Please verify your email address">
			<Text>
				We&apos;ve sent a verification link to your email address at{' '}
				<Text as={'span'} weight="bold">
					{email}
				</Text>
				. Click on the link to verify your account.
			</Text>
			<Text>
				Haven&apos;t received the email? Be sure to check your spam folder, or{' '}
				<ResendVerificationLink>click here to get a new verification email.</ResendVerificationLink>
			</Text>
		</EmailPage>
	);
};

export default EmailVerification;
