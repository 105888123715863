import { hasAtLeastOneItem } from '@montel/montelpro-shared-components/utils';
import isValidEmail from 'utils/validationUtils/isValidEmail';

const getErrorMessageEmails = (value: string[]): string | undefined => {
	if (!hasAtLeastOneItem(value)) {
		return 'Should be at least one email address';
	}
	for (const email of value) {
		if (!isValidEmail(email)) {
			return `\"${email}\" is not a valid email address`;
		}
	}
	return undefined;
};

export default getErrorMessageEmails;
