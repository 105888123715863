import { FunctionComponent, useEffect, useState } from 'react';
import {
	DropdownButton,
	DropdownGroup,
	DropdownMenu,
	DropdownPositioningContainer,
	DropdownSeparator,
	themes,
} from '@montel/montelpro-shared-components-v2';
import { Side } from 'common/enums/OrderEnums';
import { OrderPreset } from 'common/interfaces/OrderPreset';
import { selectCounterPartiesOptions } from 'store/companies/companiesSelectors';
import { useAppSelector } from 'store/hooks';
import { selectIsBackOfficeOrReadonlyRole } from 'store/user/userSelectors';
import { styled } from 'styled-components';
import NewOrder from 'components/NewOrder/NewOrder';
import { useOrderFormContext } from 'components/NewOrder/OrderFormProvider';
import { usePresetsContext } from 'components/NewOrder/PresetsProvider';
import { convertPresetToOrderState } from 'components/NewOrder/utils/convertAPIDataUtils';
import EditPresetsModal from './EditPresetsModal';
import NewOrderButton from './NewOrderButton';

interface NewOrderDropdownProps {
	orderType: 'Buy' | 'Sell';
}

const NewOrderDropdown: FunctionComponent<NewOrderDropdownProps> = ({ orderType }) => {
	const isBackOfficeOrReadonlyRole = useAppSelector(selectIsBackOfficeOrReadonlyRole);
	const [active, setActive] = useState(false);
	const [editPresetModalOpen, setEditPresetModalOpen] = useState(false);
	const { setModalIsOpen, formState, setFormState } = useOrderFormContext();
	const counterpartyOptions = useAppSelector(selectCounterPartiesOptions);
	const { fetchPresets, presets, setSelectedPreset } = usePresetsContext();

	const verticalOffset = themes.dark.spacing.lg as string;

	useEffect(() => {
		if (!isBackOfficeOrReadonlyRole) fetchPresets();
	}, []);

	const onOpenNewOrder = (side: Side) => {
		setFormState({ ...formState, side });
		setActive(false);
		setModalIsOpen(true);
	};

	const onOpenPreset = (preset: OrderPreset) => {
		setSelectedPreset(preset);
		setFormState(convertPresetToOrderState(preset, counterpartyOptions));
		setActive(false);
		setModalIsOpen(true);
	};

	const onOpenEditPreset = () => {
		setActive(false);
		setEditPresetModalOpen(true);
	};

	const ChosenNewOrderButton: FunctionComponent<NewOrderDropdownProps> = ({ orderType }) => {
		if (orderType === 'Buy') {
			return <DropdownButton onClick={() => onOpenNewOrder(Side.Bid)}>New buy order</DropdownButton>;
		}
		if (orderType === 'Sell') {
			return <DropdownButton onClick={() => onOpenNewOrder(Side.Ask)}>New sell order</DropdownButton>;
		}
		return null;
	};

	return (
		<DropdownPositioningContainer>
			<NewOrderButton
				active={active}
				disabled={isBackOfficeOrReadonlyRole}
				onClick={() => setActive(true)}
				text={`New ${orderType.toLowerCase()} order`}
			/>
			{active && (
				<StyledDropdownMenu topPosition={verticalOffset} closeMenu={() => setActive(false)}>
					<ChosenNewOrderButton orderType={orderType} />
					<DropdownGroup name="fromPreset" label="From preset" backLabel="Back">
						{presets?.map((preset) => (
							<DropdownButton key={preset.presetName} onClick={() => onOpenPreset(preset)}>
								{preset.presetName}
							</DropdownButton>
						))}
						<DropdownSeparator />
						<DropdownButton justifyContent="space-between" onClick={() => onOpenEditPreset()}>
							Edit presets
						</DropdownButton>
					</DropdownGroup>
				</StyledDropdownMenu>
			)}
			<NewOrder />
			<EditPresetsModal
				isOpen={editPresetModalOpen}
				onClose={() => setEditPresetModalOpen(false)}
				orderPresets={presets}
				onOpenPreset={onOpenPreset}
			/>
		</DropdownPositioningContainer>
	);
};

const StyledDropdownMenu = styled(DropdownMenu)`
	width: 200px;
`;

export default NewOrderDropdown;
