import { FunctionComponent, useEffect, useState } from 'react';
import { VStack } from '@montel/montelpro-shared-components-v2';
import { Trade, TradeStatusEnum } from 'common/interfaces/Trade';
import useSetPageTitle from 'hooks/useSetPageTitle';
import ServerSideTable from 'components/OrderTables/ServerSideTable';
import { useServerSideTableContext } from 'components/Tables/Pagination/ServerSideTableContext';
import { TableSkeleton } from '../../components/Skeletons';
import { TradeQueryParams } from './TradeQueryParams';
import { useTradesContext } from './Trades';
import { useGetTrades } from './useGetTrades';

interface TradePageParams {
	pageTitle: string;
	tableHeader: string;
	statuses: TradeStatusEnum[];
	columns: any[];
	url: string;
}

const TradePage: FunctionComponent<TradePageParams> = ({ pageTitle, tableHeader, statuses, columns, url }) => {
	useSetPageTitle(pageTitle);

	const [trades, setTrades] = useState<Trade[]>([]);
	const { filterChoices } = useTradesContext();
	const { status, getTrades } = useGetTrades(url);
	const { pageSize, pageIndex, onTotalCountChanged, sortBy, sortDirection } = useServerSideTableContext();

	useEffect(() => {
		const fetchTrades = async () => {
			const params = new TradeQueryParams();
			params.addFilter(filterChoices);

			params.tradeStatuses = statuses;
			params.limit = pageSize;
			params.offset = pageIndex * pageSize;
			params.sortBy = sortBy;
			params.sortDirection = sortDirection;

			const trades = await getTrades(params);
			if (!trades) return;
			onTotalCountChanged(trades.totalCount);
			setTrades(trades.items);
		};

		fetchTrades();
	}, [filterChoices, pageIndex, pageSize, sortBy, sortDirection]);

	return (
		<TableSkeleton status={status} error="Failed to load trades">
			<VStack fullWidth>
				<ServerSideTable<Trade> header={tableHeader} data={trades} columns={columns} />
			</VStack>
		</TableSkeleton>
	);
};

export default TradePage;
