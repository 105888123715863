import { ReactElement, useState } from 'react';
import { toast } from 'react-toastify';
import { ApiErrorDescription } from '@montel/montelpro-shared-components';
import { RequestStatus } from '@montel/montelpro-shared-components/enums';
import axios, { AxiosError } from 'axios';

export const POST = 'post';
export const PUT = 'put';
export type Method = 'post' | 'put';

const useSubmit = (
	apiRoute: string,
	method: Method = POST,
	toastOptions?: {
		messageOnSuccess?: React.ReactElement | string | undefined;
		messageOnError?: React.ReactElement | string | undefined;
		showApiError?: boolean | undefined;
	},
	queryParams?: { [name: string]: string | undefined },
	isBodyParam?: boolean | undefined,
): [
	postData: (payload: any) => Promise<{ success: boolean; data: any; statusCode: number | undefined }>,
	status: RequestStatus,
	error: AxiosError<any> | undefined,
	resetSubmitState: () => void,
] => {
	const [status, setStatus] = useState(RequestStatus.UNLOADED);
	const [error, setError] = useState<AxiosError<any> | undefined>(undefined);
	const { messageOnSuccess, messageOnError, showApiError } = toastOptions || {};
	const postData = async (payload: any) => {
		setError(undefined);
		setStatus(RequestStatus.LOADING);
		let success = false;
		let result;
		try {
			const axiosMethod = method === PUT ? axios.put : axios.post;

			const payloadObj = isBodyParam ? { ...payload, ...queryParams } : payload;
			const paramObj = isBodyParam ? undefined : { params: queryParams };
			result = await axiosMethod(apiRoute, payloadObj, paramObj);
			setStatus(RequestStatus.SUCCESS);
			success = true;
			messageOnSuccess && toast.success(messageOnSuccess, { autoClose: 3000 });
		} catch (error) {
			setStatus(RequestStatus.ERROR);
			const axiosError = error as AxiosError<any>;
			setError(axiosError);
			messageOnError && toast.error(getErrorContent(messageOnError, showApiError, axiosError));
			console.error(error);
		}
		return {
			success,
			data: result?.data,
			statusCode: result?.status,
		};
	};
	const resetSubmitState = () => {
		setStatus(RequestStatus.UNLOADED);
		setError(undefined);
	};
	return [postData, status, error, resetSubmitState];
};

const getErrorContent = (
	messageOnError: React.ReactElement | string | undefined,
	showApiError: boolean | undefined,
	axiosError: AxiosError<any>,
): ReactElement => (
	<div>
		{messageOnError}
		{showApiError && (
			<div>
				<ApiErrorDescription error={axiosError} />
			</div>
		)}
	</div>
);
export default useSubmit;
