import { FunctionComponent, useEffect } from 'react';
import { Box, VStack } from '@montel/montelpro-shared-components-v2';
import { Order } from 'common/interfaces';
import { Quote } from 'common/interfaces/Quote';
import getOrderFilterOptions from 'common/options/orderFilterOptions';
import useSetPageTitle from 'hooks/useSetPageTitle';
import { selectActiveCompanyQuotes } from 'store/orders/companyActiveQuotes/activeCompanyQuotesSelectors';
import {
	selectCompanyUntradedOrderHistory,
	selectCompanyUntradedOrderHistoryStatus,
} from 'store/orders/companyUntradedOrderHistory/companyUntradedOrderHistorySelectors';
import { getActiveCompanyQuotes, getCompanyUntradedOrderHistory } from 'store/orders/ordersThunks';
import NewOrder from 'components/NewOrder';
import ExpiredOrdersTable from 'components/OrderTables/ExpiredOrdersTable';
import MarketOrdersTable from 'components/OrderTables/MarketOrdersTable';
import { quotesOverviewTableColumns } from 'components/QuotesTable/Columns/quotesOverviewTableColumns';
import QuoteOverviewTable from 'components/QuotesTable/QuotesOverviewTable';
import { TableSkeleton } from '../../../components/Skeletons';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
	selectActiveOrders,
	selectActiveOrdersStatus,
	selectMyCompanyProductionYears,
	selectMyCompanysActiveAskOrders,
	selectMyCompanysActiveBidOrders,
} from '../../../store/orders/activeOrders/activeOrdersSelectors';
import { useOrdersOutletContext } from '../Orders';
import { StyledContainer } from '../style';

const CompanyOrders: FunctionComponent = () => {
	useSetPageTitle('Company Orders');
	const dispatch = useAppDispatch();
	const { onOrderClicked, setOrderFilterOptions, filterOrders, filterChoices } = useOrdersOutletContext();
	const orderStatus = useAppSelector(selectActiveOrdersStatus);
	const myCompanyBidOrders = useAppSelector(selectMyCompanysActiveBidOrders);
	const filteredMyCompanyBidOrders = filterOrders(myCompanyBidOrders, filterChoices);
	const myCompanyAskOrders = useAppSelector(selectMyCompanysActiveAskOrders);
	const filteredMyCompanyAskOrders = filterOrders(myCompanyAskOrders, filterChoices);
	const myCompanyQuotes = useAppSelector(selectActiveCompanyQuotes);
	const myCompanyQuotesFiltered = filterOrders(myCompanyQuotes, filterChoices);
	const activeOrders = useAppSelector(selectActiveOrders);
	const companyOrderHistory = useAppSelector(selectCompanyUntradedOrderHistory);
	const companyOrderHistoryStatus = useAppSelector(selectCompanyUntradedOrderHistoryStatus);

	const productionYears = useAppSelector(selectMyCompanyProductionYears);
	useEffect(() => {
		setOrderFilterOptions(getOrderFilterOptions(productionYears));
		dispatch(getCompanyUntradedOrderHistory());
		dispatch(getActiveCompanyQuotes());
	}, []);

	return (
		<>
			<VStack gap="lg" fullWidth>
				<TableSkeleton
					status={orderStatus}
					config={[
						{ numberOfTables: 2, numberOfItems: 4 },
						{ numberOfTables: 1, numberOfItems: 4 },
					]}
				>
					<StyledContainer>
						<Box px="0" py="0" bg="widget">
							<MarketOrdersTable
								header="Buy orders"
								orders={filteredMyCompanyBidOrders}
								onRowClicked={onOrderClicked}
								noDataText="No active buy orders"
							/>
						</Box>
						<Box px="0" py="0" bg="widget">
							<MarketOrdersTable
								header="Sell orders"
								reverse
								orders={filteredMyCompanyAskOrders}
								onRowClicked={onOrderClicked}
								noDataText="No active sell orders"
							/>
						</Box>
					</StyledContainer>
					<Box px="0" py="0" bg="widget">
						<QuoteOverviewTable
							header={'Quotes'}
							quotes={myCompanyQuotesFiltered}
							columns={quotesOverviewTableColumns}
							initialSorting={[{ id: 'delivery', desc: true }]}
							withPagination={false}
							onRowClicked={(quote: Quote) => {
								const order = activeOrders.find((order: Order) => order.id === quote.orderId);
								if (order) onOrderClicked(order);
							}}
							noDataText={'No active quotes'}
						/>
					</Box>
				</TableSkeleton>
				<TableSkeleton status={companyOrderHistoryStatus} config={[{ numberOfTables: 1, numberOfItems: 6 }]}>
					<VStack wrap="nowrap" fullWidth>
						<ExpiredOrdersTable orders={companyOrderHistory} />
					</VStack>
				</TableSkeleton>
			</VStack>
			<NewOrder />
		</>
	);
};

export default CompanyOrders;
