import { SelectOptionType } from '@montel/montelpro-shared-components';

const getTradingFrequencyOptions = (): SelectOptionType[] => [
	{ label: 'Daily', value: 1 },
	{ label: 'Weekly', value: 2 },
	{ label: 'Monthly', value: 3 },
	{ label: 'Yearly', value: 4 },
];

export default getTradingFrequencyOptions;
