import { styled } from 'styled-components';

export const StyledProductStep = styled.div`
	width: 100%;
`;

export const EqualColumnsContainer = styled.div`
	width: 100%;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: ${({ theme }) => theme.spacing.md};
	.react-select__single-value {
		min-width: 0;
	}
`;
