export enum Tech {
	Default = 0,
	AnyRenewable = 1,
	Hydro = 2,
	Wind = 3,
	Solar = 4,
	Geothermal = 5,
	Biomass = 6,
	Nuclear = 7,
}

export enum RegistryOperator {
	Default = 0,
	Any = 1,
	Borzen = 2,
	CancellationStatement = 3,
	VertiCer = 4,
	Cecar = 5,
	CecarWithOutEECS = 6,
	CMODenmark = 7,
	Deprecated_CMOGrexel = 8,
	CNMC = 9,
	CREG = 10,
	EControl = 11,
	ECwape = 12,
	Elering = 13,
	Finextra = 14,
	GSE = 15,
	NECS = 16,
	Ofgem = 17,
	OKTE = 18,
	OTE = 19,
	Portugal = 20,
	Powernext = 21,
	Pronovo = 22,
	TGE = 23,
	TSOCyprus = 24,
	UBA = 25,
	VREG = 26,
	GREX = 27,
	SEDABulgaria = 28,
	HROTE = 29,
}
export enum Region {
	Default = 0,
	AIB = 1,
	AIBGridConnected = 2,
	Alpine = 3,
	Nordic = 4,
	NordicGridConnected = 5,
	EU = 6,
	Albania = 7,
	Andorra = 8,
	Armenia = 9,
	Austria = 10,
	Azerbaijan = 11,
	Belarus = 12,
	Belgium = 13,
	BosniaAndHerzegovina = 14,
	Bulgaria = 15,
	Croatia = 16,
	Cyprus = 17,
	CzechRepublic = 18,
	Denmark = 19,
	Estonia = 20,
	Finland = 21,
	France = 22,
	Georgia = 23,
	Germany = 24,
	Greece = 25,
	Hungary = 26,
	Iceland = 27,
	Ireland = 28,
	Italy = 29,
	Kazakhstan = 30,
	Latvia = 31,
	Liechtenstein = 32,
	Lithuania = 33,
	Luxembourg = 34,
	Macedonia = 35,
	Malta = 36,
	Moldova = 37,
	Monaco = 38,
	Montenegro = 39,
	Netherlands = 40,
	Norway = 41,
	Poland = 42,
	Portugal = 43,
	Romania = 44,
	Russia = 45,
	SanMarino = 46,
	Serbia = 47,
	Slovakia = 48,
	Slovenia = 49,
	Spain = 50,
	Sweden = 51,
	Switzerland = 52,
	Turkey = 53,
	Ukraine = 54,
	UnitedKingdom = 55,
	VaticanCity = 56,
}
export enum Label {
	Default = 0,
	Any = 1,
	AlpineHydropower = 2,
	BraMiljovalMedAvgifterOchFondering = 3,
	BraMiljovalUtenAvgifterOchFondering = 4,
	CfDUK = 5,
	EKOenergy = 6,
	FiTUKOFGEM = 7,
	SEGUK = 8,
	GeprufterOkostromTUVNord = 9,
	GrunerStromLabel = 10,
	Naturemadebasic = 11,
	Naturemadestar = 12,
	NTA8080 = 13,
	OKPower = 14,
	TUVSUDEE = 15,
	TUVSUDEE01 = 16,
	TUVSUDEE02 = 17,
	WELOVEENERGY = 18,
	TUVNORD = 19,
	REGOS = 20,
}

export enum Side {
	Default = 0,
	Bid = 1,
	Ask = 2,
}

export enum OrderStatus {
	Default = 0,
	Active = 1,
	Deleted = 2,
	Accepted = 3,
	Expired = 4,
	Exhausted = 5,
}

export enum OrderLifecycleEnum {
	Default = 0,
	New = 1,
	Counterbid = 2,
	Counteroffer = 3,
	Edit = 4,
	Preset = 5,
}

export enum OrderTypeEnum {
	Default = 0,
	FixedPrice,
	RequestForQuote,
}
