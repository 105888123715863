import { OrderTypeEnum, Side } from 'common/enums';
import { Cost } from './Order';
import { Product } from './Product';

export enum TradeStatusEnum {
	Default = 0,
	Pending = 1,
	Signed = 2,
	Cancelled = 3,
}

export interface Trade {
	version: number;
	orderId: string;
	quoteId: string;
	orderType: OrderTypeEnum;
	tradeStatus: TradeStatusEnum;
	tradeTime: string;
	product: Product;
	cost: Cost;
	isMyTrade: boolean;
	isMyCompanysOrder: boolean;
	isCounterpartysTrade: boolean;
	isMyCompanyPartOfTrade: boolean;
	myCompanyTradersName?: string;
	counterpartyCompanyName?: string;
	side: Side;
}
