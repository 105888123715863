import styled from 'styled-components';

export const StyledNoAlerts = styled.div`
	cursor: pointer;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: ${({ theme }) => theme.spacing.lg};
	border: 1px dashed ${({ theme }) => theme.colors.pineGreen};
`;
