import { FunctionComponent } from 'react';
import Error from '../Error';

const NotFound: FunctionComponent = () => (
	<Error
		heading="404"
		subHeading="Oops, you've ventured off the grid!"
		bodyText="We can't find the page you're looking for. Please double-check the URL, or take a step back to our homepage."
	/>
);

export default NotFound;
