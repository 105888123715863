import hasAtLeastOneItem from 'utils/validationUtils/hasAtLeastOneItem';
import { MyCompanyState } from '../MyCompanyState';

const getAboutErrors = (state: MyCompanyState) => ({
	marketRole: !state.marketRole && 'This field is required',
	currencies: !hasAtLeastOneItem(state.currencies) && 'At least one required',
	vat: !state.vat && 'This field is required',
});

export default getAboutErrors;
