import { SelectOptionType } from '@montel/montelpro-shared-components';
import { createSelector } from '@reduxjs/toolkit';
import { CounterpartyRelationStatus } from '../../common/enums';
import { PublicCompanyInformation } from '../../common/interfaces';
import { RootState } from '../store';

export const selectCompaniesState = (state: RootState) => state.companies;

export const selectCompanies = (state: RootState) => selectCompaniesState(state).data;

export const selectStatus = (state: RootState) => selectCompaniesState(state).status;

export const selectError = (state: RootState) => selectCompaniesState(state).error;

export const selectCounterParties = createSelector([selectCompanies], (companies) => {
	return companies.filter((company) => company.counterpartyStatus === CounterpartyRelationStatus.Approved);
});

export const selectBlockedCompanies = createSelector([selectCompanies], (companies) => {
	return companies.filter((company) => company.isBlockedByMyCompany);
});

export const selectCompanyInvitations = createSelector([selectCompanies], (companies) => {
	return companies.filter((company) => company.counterpartyStatus === CounterpartyRelationStatus.Pending);
});

export const selectCompanyInvitationsCount = createSelector([selectCompanyInvitations], (companies) => {
	return companies.length;
});

export const selectCompanyInvitationsRecieved = createSelector([selectCompanies], (companies) => {
	return companies.filter(
		(company) => company.counterpartyStatus === CounterpartyRelationStatus.Pending && !company.isInvitedByMyCompany,
	);
});

export const selectCompanyInvitationsRecievedCount = createSelector([selectCompanyInvitationsRecieved], (companies) => {
	return companies.length;
});

export const selectCounterPartiesOptions = createSelector([selectCounterParties], (counterparties) => {
	return counterparties.map(
		({ companyName, id }: PublicCompanyInformation): SelectOptionType => ({
			label: companyName,
			value: id,
		}),
	);
});
