import { FunctionComponent } from 'react';
import { VStack } from '@montel/montelpro-shared-components-v2';
import { Side } from 'common/enums/OrderEnums';
import { getLabelName, getRegionName, getTechName } from 'common/enums/valueLookups';
import { ProductionPeriod } from 'common/interfaces';
import { getProductionPeriodsString } from 'utils/orderUtils/getProductionPeriodsString';
import { useOrderFormContext } from '../../OrderFormProvider';
import ReviewBox from './ReviewBox';
import Value from './Value';

interface ProductReviewProps {
	onStepChange: (step: number) => void;
	isSubmitted?: boolean;
}

const ProductReview: FunctionComponent<ProductReviewProps> = ({ onStepChange, isSubmitted }) => {
	const { formState } = useOrderFormContext();
	const { side } = formState;
	const { technologies, region, productionPeriods, label, plantCommissioningYear, plantMaxAge, subsidized } =
		formState.product;

	return (
		<ReviewBox onStepChange={onStepChange} isSubmitted={isSubmitted} gotoStep={0} header="Product">
			<VStack gap="xxs">
				<Value label="Technologies">{technologies.map(getTechName).join(', ')}</Value>
				<Value label="Region">{region ? getRegionName(region) : 'N/A'}</Value>
				<Value label="Label">{getLabelName(label)}</Value>
				<Value label="Production period">
					{getProductionPeriodsString(productionPeriods as ProductionPeriod[])}
				</Value>
				<Value label="Subsidised">{subsidized}</Value>
				{side === Side.Ask ? (
					<Value label="Plant commissioning year">{plantCommissioningYear}</Value>
				) : (
					<Value label="Max plant age (years)">{plantMaxAge}</Value>
				)}
			</VStack>
		</ReviewBox>
	);
};

export default ProductReview;
