import { FunctionComponent, useState } from 'react';
import { toast } from 'react-toastify';
import {
	Button,
	HStack,
	IconButton,
	IconDelete,
	IconEdit,
	Modal,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
	VStack,
} from '@montel/montelpro-shared-components-v2';
import { DELETE_ORDER_PRESET_API_ROUTE } from 'apiRoutes';
import useDelete from 'hooks/useDelete';
import { useOrderFormContext } from 'components/NewOrder/OrderFormProvider';
import { usePresetsContext } from 'components/NewOrder/PresetsProvider';
import { convertPresetToOrderState } from 'components/NewOrder/utils/convertAPIDataUtils';
import { OrderPreset } from '../../common/interfaces';
import { selectCounterPartiesOptions } from '../../store/companies/companiesSelectors';
import { useAppSelector } from '../../store/hooks';
import { EditPresetsModalRow, StyledModalBody } from './EditPresetsModal.style';

export interface EditPresetsModalProps {
	isOpen: boolean;
	onClose: () => void;
	onOpenPreset: (preset: OrderPreset) => void;
	orderPresets?: OrderPreset[];
}

const EditPresetsModal: FunctionComponent<EditPresetsModalProps> = ({
	isOpen,
	onClose,
	onOpenPreset,
	orderPresets,
}) => {
	const { fetchPresets, setSelectedPreset } = usePresetsContext();
	const { setFormState } = useOrderFormContext();
	const counterpartyOptions = useAppSelector(selectCounterPartiesOptions);
	const [deletePresetId, setDeletePresetId] = useState<number | null>();
	const { deleteItem } = useDelete();

	const onClickDeletePreset = (preset: OrderPreset) => {
		if (!preset.presetId) {
			return;
		}
		setDeletePresetId(preset.presetId);
	};

	const deletePreset = async () => {
		if (!deletePresetId) return;
		const success = await deleteItem(DELETE_ORDER_PRESET_API_ROUTE.replace(':presetId', deletePresetId.toString()));
		if (success) {
			setDeletePresetId(null);
			fetchPresets();
			toast.success('Preset is deleted', { autoClose: 2000 });
		} else {
			toast.error('Could not delete preset', { autoClose: 2000 });
		}
	};

	const openNewOrderFromPreset = (presetId: number | null | undefined) => {
		const selectedPreset = orderPresets?.find((p) => p.presetId === presetId);

		if (!selectedPreset) return;

		setSelectedPreset(selectedPreset);
		setFormState(convertPresetToOrderState(selectedPreset, counterpartyOptions));
		onOpenPreset(selectedPreset);
		onClose();
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent size={4}>
				<ModalHeader>Edit presets</ModalHeader>
				<StyledModalBody>
					<VStack gap="md">
						{orderPresets ? (
							orderPresets.map((preset: OrderPreset, idx: number) => (
								<EditPresetsModalRow key={idx}>
									<Text size="xs">{preset.presetName}</Text>
									<HStack align="center">
										{deletePresetId === preset.presetId ? (
											<>
												<Button
													variant="secondary"
													text="Cancel"
													onClick={() => setDeletePresetId(null)}
												/>
												<Button
													variant="secondary"
													text="Delete"
													onClick={() => deletePreset()}
												/>
											</>
										) : (
											<HStack align="center">
												<IconButton
													icon={<IconEdit />}
													variant="tertiary"
													onClick={() => openNewOrderFromPreset(preset.presetId)}
												/>
												<IconButton
													icon={<IconDelete />}
													variant="tertiary"
													onClick={() => onClickDeletePreset(preset)}
												/>
											</HStack>
										)}
									</HStack>
								</EditPresetsModalRow>
							))
						) : (
							<></>
						)}
					</VStack>
				</StyledModalBody>
				<ModalFooter />
			</ModalContent>
		</Modal>
	);
};

export default EditPresetsModal;
