import { ProductEnum, Region, Tech } from '@montel/montelpro-shared-components/enums';
import { AlertType } from '../../types';
import { TAlertEditState } from '../types';

export interface CreateAlertRequest {
	technology: Tech;
	region: Region;
	productionYear: number;
	productionQuarter?: number | null;
	productionMonth?: number | null;
	type: AlertType;
	product: ProductEnum.GO;
	includeUnderlyingContracts: boolean;
}

export const toCreateAlertRequest = (data: TAlertEditState): CreateAlertRequest => {
	const { technology, region, productionYear, productionQuarter, productionMonth, type, includeUnderlyingContracts } =
		data;
	return {
		technology: technology,
		region: region as Region,
		productionYear: productionYear as number,
		productionQuarter: productionQuarter ?? null,
		productionMonth: productionMonth === null ? null : productionMonth + 1,
		type: type,
		product: ProductEnum.GO,
		includeUnderlyingContracts: includeUnderlyingContracts,
	};
};
