import { QuoteStatusEnum } from '../../../../common/enums/quoteStatusEnum';
import ActionLine from '../../OrderDetailsBody/ActionLine';
import { useOrderDetailsContext } from '../../OrderDetailsContext';
import OrderOwnerActions from './OrderOwnerActions';
import QuoteOwnerActions from './QuoteOwnerActions';
import QuoteStatus from './QuoteStatus';

const QuoteActions = () => {
	const { selectedQuote, order } = useOrderDetailsContext();
	const { status, isMyQuote } = selectedQuote || {};
	const isActive = status === QuoteStatusEnum.Active;
	const { isMyOrder } = order || {};

	const showQuoteOwnerActions = isActive && isMyQuote;
	const showOrderOwnerActions = isActive && isMyOrder;
	const showQuoteStatus = !showQuoteOwnerActions && !showOrderOwnerActions;

	return (
		<ActionLine>
			{showOrderOwnerActions && <OrderOwnerActions />}
			{showQuoteOwnerActions && <QuoteOwnerActions />}
			{showQuoteStatus && <QuoteStatus />}
		</ActionLine>
	);
};

export default QuoteActions;
