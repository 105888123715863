import { FunctionComponent } from 'react';
import { AvailableTo, OrderLifecycleEnum, Side } from 'common/enums';
import { useOrderFormContext } from 'components/NewOrder/OrderFormProvider';
import { convertOrderToOrderState } from 'components/NewOrder/utils/convertAPIDataUtils';
import { selectCounterPartiesOptions } from '../../../../store/companies/companiesSelectors';
import { useAppSelector } from '../../../../store/hooks';
import { useOrderDetailsContext } from '../../OrderDetailsContext';
import { ActionButton } from '../OrderDetailsBody.styles';

interface FixedPriceTraderActionsProps {}

const FixedPriceTraderActions: FunctionComponent<FixedPriceTraderActionsProps> = () => {
	const { order, isExpired, closeModal: closeOrderDetailsModal, openTradePrompt } = useOrderDetailsContext();
	const { setFormState, setModalIsOpen: setNewOrderModalIsOpen } = useOrderFormContext();
	const counterpartyOptions = useAppSelector(selectCounterPartiesOptions);

	const onCreateCounterOrder = () => {
		const formData = convertOrderToOrderState(order, counterpartyOptions);
		const { side } = order;

		setFormState({
			...formData,
			side: side === Side.Ask ? Side.Bid : Side.Ask,
			type: side === Side.Ask ? OrderLifecycleEnum.Counterbid : OrderLifecycleEnum.Counteroffer,
			product: {
				...formData.product,
				plantCommissioningYear: side === Side.Bid ? formData.product.plantCommissioningYear : undefined,
				plantMaxAge: side === Side.Ask ? formData.product.plantMaxAge : undefined,
			},
			delivery: {
				...formData.delivery,
				comment: '',
			},
			price: {
				...formData.price,
				availableTo: AvailableTo.Public,
				orderCounterparties: [],
			},
		});
		setNewOrderModalIsOpen(true);
		closeOrderDetailsModal();
	};

	return (
		<>
			<ActionButton
				isDisabled={isExpired}
				variant="secondary"
				text={order.side === Side.Ask ? 'Counterbid' : 'Counteroffer'}
				onClick={onCreateCounterOrder}
			/>
			<ActionButton
				isDisabled={isExpired}
				variant="primary"
				text={order.side === Side.Ask ? 'Buy unit' : 'Sell unit'}
				onClick={() => openTradePrompt()}
			/>
		</>
	);
};

export default FixedPriceTraderActions;
