import { ExpiresPresetEnum } from 'common/enums';
import moment, { Moment } from 'moment';
import { now } from 'utils/dateUtil';
import { z } from 'zod';

export const commonFields = {
	quantity: z
		.string()
		.refine((val) => Boolean(val), 'Quantity is required')
		.refine((val) => val && val.toFloat() < 10000000, 'Max quantity is 10TWh')
		.refine((val) => val && val.toFloat() >= 1, 'Quantity should be at least 1'),
	pricePerUnit: z.string().optional(),
	expiryPreset: z.string().nullable(),
	expires: z
		.date()
		.nullable()
		.refine(
			(val) => !val || moment(val, 'DD/MM/YYYY HH:mm').isValid(),
			'Please choose a valid date (dd/mm/yyyy hh:mm)',
		)
		.refine(
			(val) => !val || isAtLeast10MinutesFromNow(moment(val, 'DD/MM/YYYY HH:mm')),
			'Expiry time should be at least 10 min in future',
		),
};

export const isAtLeast10MinutesFromNow = (value: Moment) => value.isSameOrAfter(now().add(10, 'minute'), 'minute');

export const expiryPresetIsSpecifyAndExpiresIsNotSet = (expiryPreset: string | null, expires: Date | null) =>
	expiryPreset === ExpiresPresetEnum.SPECIFY && !expires;
