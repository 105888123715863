import { FunctionComponent } from 'react';
import { Button, HStack, IconEdit } from '@montel/montelpro-shared-components-v2';
import { getTypeOfQuote } from 'components/OrderDetails/PlaceQuoteForm/getTypeOfQuote';
import { useOrderDetailsContext } from '../../OrderDetailsContext';
import { initialFormState, usePlaceQuoteFormContext } from '../../PlaceQuoteForm/PlaceQuoteFormContext';
import { useQuoteDetailsContext } from '../quoteDetailsContext';

const QuoteOwnerActions: FunctionComponent = () => {
	const { setPrompt } = useQuoteDetailsContext();
	const { selectedQuote } = useOrderDetailsContext();
	const quoteType = selectedQuote ? getTypeOfQuote(selectedQuote.side) : undefined;

	const { setFormState, setIsQuoteFormOpen } = usePlaceQuoteFormContext();
	const formState = {
		...initialFormState,
		id: selectedQuote?.id,
		quantity: selectedQuote?.quantity.toString(),
		pricePerUnit: selectedQuote?.price.toString(),
		expires: new Date(selectedQuote?.expiryTime ?? ''),
	};
	const onEdit = () => {
		setFormState(formState);
		setIsQuoteFormOpen(true);
	};

	return (
		<HStack wrap="nowrap" gap="md">
			<Button text={`Edit ${quoteType}`} variant="secondary" leftIcon={<IconEdit />} onClick={onEdit} />
			<Button text={`Cancel ${quoteType}`} variant="negative" onClick={() => setPrompt('cancelPrompt')} />
		</HStack>
	);
};

export default QuoteOwnerActions;
