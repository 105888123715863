import { TradeStatusEnum } from 'common/interfaces/Trade';
import { SortBy, SortDirection } from 'components/Tables/Pagination/ServerSideTableContext';

export interface IOrderPropertyFilter {
	Tech: string[];
	Region: string[];
	Label: string[];
	Currency: string[];
	ProductionYear: string[];
}

export class TradeQueryParams {
	currencies?: string[];
	labels?: string[];
	regions?: string[];
	techs?: string[];
	productionYears?: string[];
	limit: number = 10;
	offset?: number = 0;
	tradeStatuses?: TradeStatusEnum[];
	sortBy?: SortBy;
	sortDirection?: SortDirection;

	addFilter = (filter: IOrderPropertyFilter): void => {
		const { Currency, Label, Region, Tech, ProductionYear } = filter;

		if (Currency.length) {
			this.currencies = Currency;
		}
		if (Label.length) {
			this.labels = Label;
		}
		if (Region.length) {
			this.regions = Region;
		}
		if (Tech.length) {
			this.techs = Tech;
		}
		if (ProductionYear.length) {
			this.productionYears = ProductionYear;
		}
	};
}
