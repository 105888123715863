import { FunctionComponent, useEffect, useRef, useState } from 'react';
import Datetime from 'react-datetime';
import { DeliveryDatePresetEnum } from '@montel/montelpro-shared-components/enums';
import { DateTimePicker, HStack, RadioButtonList } from '@montel/montelpro-shared-components-v2';
import { ProductionPeriod } from 'common/interfaces';
import moment, { Moment } from 'moment';
import { getDeliveryDatePresetOptions } from 'utils/orderUtils/getDeliveryOptions';
import getFirstDateAfterProdPeriodUTC from 'components/NewOrder/utils/getFirstDateAfterProdPeriodUTC';
import { useOrderFormContext } from '../../OrderFormProvider';
import { getDeliveryDateTooltip } from '../../tooltips';
import { fields } from './DeliveryStep.types';

const DeliveryPeriod: FunctionComponent<{ productionPeriod: ProductionPeriod | undefined }> = ({
	productionPeriod,
}) => {
	const { formState, setFormState, errors, validateSingle } = useOrderFormContext();
	const { deliveryDatePreset, deliveryDate } = formState.delivery;
	const [deliveryDatePresetChangedByUser, setDeliveryDatePresetChangedByUser] = useState(false);

	useEffect(() => {
		if (deliveryDatePreset) {
			datePickerRef?.current?.setState((prevState) => {
				return {
					...prevState,
					open: deliveryDatePreset === DeliveryDatePresetEnum.DATE && deliveryDatePresetChangedByUser,
				};
			});
		}
	}, [deliveryDatePreset]);

	const datePickerRef = useRef<Datetime>(null);

	const firstDateAfterProdPeriodUTC = getFirstDateAfterProdPeriodUTC(productionPeriod);
	const nowUTC = moment.utc();
	const tomorrowUTC = nowUTC.startOf('day').add(1, 'day');
	const firstValidDeliveryDateUTC = nowUTC.isAfter(firstDateAfterProdPeriodUTC)
		? tomorrowUTC
		: firstDateAfterProdPeriodUTC;
	const [date, setDate] = useState(deliveryDate ? deliveryDate : firstValidDeliveryDateUTC?.format('DD/MM/YYYY'));

	const onDeliveryDateChange = (date: string) => {
		const momentDate = moment(date).toDate();

		validateSingle(fields.deliveryDate, momentDate, 'deliveryDate');

		setDate(date);

		setFormState({
			...formState,
			delivery: { ...formState.delivery, deliveryDate: momentDate },
		});
	};

	const onDeliveryDatePresetChange = (value: DeliveryDatePresetEnum) => {
		setDeliveryDatePresetChangedByUser(true);

		setFormState({
			...formState,
			delivery: { ...formState.delivery, deliveryDatePreset: value, deliveryDate: null },
		});
	};

	return (
		<HStack gap="md" wrap="nowrap" fullWidth>
			<RadioButtonList
				label="Delivery period"
				required
				options={getDeliveryDatePresetOptions(productionPeriod?.year)}
				errorMessage={errors?.deliveryPeriod && errors.deliveryPeriod}
				onChange={onDeliveryDatePresetChange}
				value={deliveryDatePreset}
				tooltip={getDeliveryDateTooltip()}
				tooltipContentSize="lg"
			/>
			<DateTimePicker
				label="Delivery Time"
				hideLabel
				keepLabelHeightWhenHidden
				utc
				required={false}
				isValidDate={(current: Moment) => current.isSameOrAfter(firstValidDeliveryDateUTC)}
				initialViewDate={firstValidDeliveryDateUTC}
				errorMessage={errors?.deliveryDate && errors.deliveryDate}
				onChange={onDeliveryDateChange}
				disabled={deliveryDatePreset !== DeliveryDatePresetEnum.DATE}
				value={date}
				ref={datePickerRef}
			/>
		</HStack>
	);
};

export default DeliveryPeriod;
