import { Box } from '@montel/montelpro-shared-components-v2';
import styled from 'styled-components';

export const Container = styled(Box).attrs({ bg: 'widget', py: '3rem' })`
	display: flex;
	justify-content: center;
	min-width: 666px;
	margin-bottom: 3rem;
	.content {
		display: flex;
		flex-direction: column;
		gap: ${({ theme }) => theme.spacing.lg};
		width: 602px;
		min-width: 602px;
	}
`;
