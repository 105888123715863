import { Center, VStack } from '@montel/montelpro-shared-components-v2';
import styled from 'styled-components';

export const StyledVStack = styled(VStack)`
	margin-bottom: ${({ theme }) => theme.spacing.lg};
`;

export const StyledCenter = styled(Center)`
	flex: 1;
	margin-bottom: ${({ theme }) => theme.spacing.lg};
`;
