import { FunctionComponent, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, H2, VCenter, VStack } from '@montel/montelpro-shared-components-v2';
import { selectCanInviteCompanyOrUser } from 'store/user/userSelectors';
import { Suspense } from 'components/Suspense';
import { useUsersContext } from '../UsersProvider';
import DataTable from './DataTable';
import { InviteUserButton } from './InviteUserButton';
import InviteUserModal from './InviteUserModal';

const RequestsToJoin: FunctionComponent = () => {
	const { data, status, error } = useUsersContext().requestsToJoin;
	const [isModalOpen, setIsModalOpen] = useState(false);
	const canInviteUser = useSelector(selectCanInviteCompanyOrUser);

	return (
		<Box bg="widget" px="0" py="lg">
			<VStack gap="lg">
				<Box bg="widget" px="xl" py="0">
					<VCenter gap="xl">
						<H2>Requests to join</H2>
						<InviteUserButton onClick={() => setIsModalOpen(true)} disabled={!canInviteUser} />
						{isModalOpen && <InviteUserModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />}
					</VCenter>
				</Box>
				<Suspense status={status} error={error}>
					<DataTable data={data} />
				</Suspense>
			</VStack>
		</Box>
	);
};

export default RequestsToJoin;
