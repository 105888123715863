import { AvailableTo } from '../availableTo';

export const availableToNames = {
	[AvailableTo.Default]: 'Default',
	[AvailableTo.Public]: 'Public',
	[AvailableTo.Counterparties]: 'Counterparties',
	[AvailableTo.Selected]: 'Selected counterparties',
};

export const getAvailableToName = (availableTo: AvailableTo) => availableToNames[availableTo];
