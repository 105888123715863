import { FunctionComponent } from 'react';
import { HStack, VStack } from '@montel/montelpro-shared-components-v2';
import LabelAndValue from 'primitives/LabelAndValue';
import { MyCompanyState } from '../MyCompanyState';

const AboutOverview: FunctionComponent<{ state: MyCompanyState }> = ({ state }) => {
	return (
		<VStack gap="xl">
			<LabelAndValue label="Market role" value={state.marketRole?.label} size="sm" />
			<LabelAndValue
				label="Currencies"
				value={state.currencies?.map((currency) => currency?.label).join(', ')}
				size="sm"
			/>
			<LabelAndValue label="VAT / Registration No." value={state.vat} size="sm" />
			<HStack gap="xl" wrap="nowrap" fullWidth>
				<LabelAndValue label="Bank name" value={state.bankName} size="sm" />
				<LabelAndValue label="Bank account No." value={state.bankAccountNo} size="sm" />
			</HStack>
			<LabelAndValue label="BIC/Swift-code" value={state.bic} size="sm" />
			<LabelAndValue label="IBAN" value={state.iban} size="sm" />
			<LabelAndValue label="Description" value={state.companyText} size="sm" />
		</VStack>
	);
};

export default AboutOverview;
