import React, { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';
import { Text } from '@montel/montelpro-shared-components-v2';
import { StyledProfileMenuItem } from './Profile.style';

export interface MenuItemProps {
	text: string;
	link: string;
}

const MenuItem: FunctionComponent<MenuItemProps> = ({ text, link }) => {
	return (
		<StyledProfileMenuItem>
			<NavLink to={link}>
				<Text size="xs">{text}</Text>
			</NavLink>
		</StyledProfileMenuItem>
	);
};

export default MenuItem;
