import { Text, Tooltip } from '@montel/montelpro-shared-components-v2';
import styled from 'styled-components';

export const StyledTableHeadCellContent = styled.div<{
	$isSortable: boolean;
	$justify: 'flex-start' | 'flex-end';
	$offsetLeft?: string;
	$offsetRight?: string;
}>`
	display: flex;
	align-items: center;
	gap: 0.25rem;
	cursor: ${({ $isSortable }) => ($isSortable ? 'pointer' : 'default')};
	flex-direction: ${({ $justify }) => ($justify === 'flex-start' ? 'row' : 'row-reverse')};
	${({ $offsetLeft }) => $offsetLeft && `padding-left: ${$offsetLeft};`};
	${({ $offsetRight }) => $offsetRight && `padding-right: ${$offsetRight};`};
`;

export const StyledTooltip = styled(Tooltip)<{
	$justify: 'flex-start' | 'flex-end';
}>`
	margin-left: 0.1225rem;
	order: ${({ $justify }) => ($justify === 'flex-start' ? 1 : 0)};
	font-weight: 400;
`;

export const StyledText = styled(Text)`
	text-wrap: nowrap;
`;
