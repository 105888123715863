import styled, { css } from 'styled-components';

export const StyledContainer = styled.nav`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap: ${({ theme }) => theme.spacing.xs};
`;

export const StyledButton = styled.button<{
	$checked: boolean;
	[otherProps: string]: any;
}>`
	display: flex;
	align-items: center;
	justify-content: center;
	transition: linear 75ms;
	box-sizing: border-box;
	width: 100%;

	${({ $checked, theme }) => {
		const { subElement, pineGreen, element, heritageOrange } = theme.colors;
		const { spacing, borderRadii } = theme;
		const borderColor = $checked ? pineGreen : subElement;
		const backgroundColor = $checked ? pineGreen : 'transparent';
		return css`
			height: ${spacing.xxl};
			cursor: ${$checked ? 'default' : 'pointer'};
			pointer-events: ${$checked ? 'none' : 'inherit'};
			padding: 0 ${spacing.md};
			border: 1px solid ${borderColor};
			border-radius: ${borderRadii.base};
			background-color: ${backgroundColor};
			height: ${spacing.xl};
			&:hover {
				background-color: ${element};
			}
			&:focus-visible {
				outline: 2px solid ${heritageOrange};
			}
		`;
	}};
`;
