import { CompanyRegistrationState } from './types';

export const initialState: CompanyRegistrationState = {
	companyName: '',
	vat: '',
	street: '',
	postalCode: '',
	city: '',
	countryCode: null,
	signersEmails: [],
	kycContactEmails: [],
	marketRole: null,
	technologies: [],
	products: [{ label: "GO'", value: 1 }],
	tradingPosition: null,
	tradingFrequency: null,
	numberOfCertificatesPerYear: 0,
	labels: [{ label: 'Any', value: 1 }],
	registryOperators: [],
	currencies: [{ label: 'EUR', value: 1 }],
	eecsRegistry: true,
	subsidized: false,
	facebook: '',
	twitter: '',
	linkedIn: '',
	companyWebPage: '',
	companyText: '',
	termsAccepted: false,
};
