export default {
	invite: {
		en: {
			version_1: 'You have received a counterparty invitation from {{companyName}} on Marketplace',
		},
	},
	confirmation: {
		en: {
			version_1: '{{actingCompanyName}} and {{otherCompanyName}} are now approved counterparties on Marketplace',
		},
	},
};
