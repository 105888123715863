import { FunctionComponent } from 'react';
import { OrderStatus } from 'common/enums';
import { Order } from 'common/interfaces/Order';
import { CellProps } from 'components/Tables/Cells';
import { StyledCell } from 'components/Tables/Cells/styles';
import { NotAvailable, OrderDeleted, OrderExpired } from './CellContent';

export interface OrderStatusCellProps extends CellProps {
	order: Order;
}

const OrderStatusCell: FunctionComponent<OrderStatusCellProps> = ({ order, offsetLeft }) => {
	const { status: orderStatus } = order;

	return (
		<>
			<StyledCell $offsetLeft={offsetLeft}>
				<OrderStatusCellContent orderStatus={orderStatus} />
			</StyledCell>
		</>
	);
};

const OrderStatusCellContent: FunctionComponent<{
	orderStatus: OrderStatus;
}> = ({ orderStatus }) => {
	switch (orderStatus) {
		case OrderStatus.Deleted:
			return <OrderDeleted />;

		case OrderStatus.Expired:
			return <OrderExpired />;

		default:
			return <NotAvailable />;
	}
};

export default OrderStatusCell;
