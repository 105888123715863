import { FunctionComponent } from 'react';
import { isMediaStep, isVerificationStep } from '../stepsDefinition';
import { CompanyRegistrationState } from '../types';
import CompleteButton from './CompleteButton';
import NextButton from './NextButton';
import OKButton from './OKButton';

const RightButtonPicker: FunctionComponent<{
	setIsNextPressed: React.Dispatch<boolean>;
	activeStep: number;
	goToNext: () => void;
	state: CompanyRegistrationState;
	logo?: File;
}> = ({ setIsNextPressed, activeStep, goToNext, state, logo }) => {
	if (isMediaStep(activeStep)) {
		return (
			<CompleteButton
				setIsNextPressed={setIsNextPressed}
				activeStep={activeStep}
				goToNext={goToNext}
				state={state}
				logo={logo}
			/>
		);
	}

	if (isVerificationStep(activeStep)) {
		return <OKButton />;
	}

	return <NextButton setIsNextPressed={setIsNextPressed} activeStep={activeStep} goToNext={goToNext} state={state} />;
};

export default RightButtonPicker;
