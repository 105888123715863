import React, { FC, ReactNode } from 'react';
import { motion } from 'framer-motion';
import { StyledContainer } from './AnimateWidth.style';

interface AnimateWidthProps {
	children: ReactNode;
	/**
	 * Starting x coordinate of the content.
	 */
	xFrom: number;
	/**
	 * Final x coordinate of the content.
	 */
	xTo: number;
	/**
	 * Starting width of the content.
	 */
	widthFrom?: string;
	/**
	 * Final width of the content.
	 */
	widthTo?: string;
	onAnimationComplete?: () => void;
}

const AnimateHeight: FC<AnimateWidthProps> = ({
	children,
	xFrom,
	xTo,
	widthFrom = '100%',
	widthTo = '100%',
	onAnimationComplete,
}) => {
	return (
		<StyledContainer
			as={motion.div}
			layout
			initial={{
				width: widthFrom,
				x: xFrom,
			}}
			animate={{
				width: widthTo,
				x: xTo,
			}}
			transition={{ duration: 0.4 }}
			onAnimationComplete={() => {
				onAnimationComplete && onAnimationComplete();
			}}
		>
			{children}
		</StyledContainer>
	);
};

export default AnimateHeight;
