import { FunctionComponent } from 'react';
import { Button, IconChevronLeft } from '@montel/montelpro-shared-components-v2';
import { isFirstStep, isVerificationStep } from '../stepsDefinition';

const BackButton: FunctionComponent<{
	activeStep: number;
	goToPrevious: () => void;
}> = ({ activeStep, goToPrevious }) => {
	if (isFirstStep(activeStep) || isVerificationStep(activeStep)) {
		//empty div to keep the layout
		return <div />;
	}
	return <Button text="Back" variant="secondary" onClick={goToPrevious} leftIcon={<IconChevronLeft />} />;
};

export default BackButton;
