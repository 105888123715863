import {
	IconTechnologyAny,
	IconTechnologyBio,
	IconTechnologyGeoThermal,
	IconTechnologyHydro,
	IconTechnologyNuclear,
	IconTechnologySolar,
	IconTechnologyWind,
} from '@montel/montelpro-shared-components-v2';
import { Tech } from 'common/enums/OrderEnums';

export const technologyOptions = [
	{
		label: <IconTechnologyBio boxSize="18px" />,
		value: Tech.Biomass,
		caption: 'BIOMASS',
	},
	{
		label: <IconTechnologyGeoThermal boxSize="18px" />,
		value: Tech.Geothermal,
		caption: 'GEO',
	},
	{
		label: <IconTechnologyHydro boxSize="18px" />,
		value: Tech.Hydro,
		caption: 'HYDRO',
	},
	{
		label: <IconTechnologySolar boxSize="18px" />,
		value: Tech.Solar,
		caption: 'SOLAR',
	},
	{
		label: <IconTechnologyWind boxSize="18px" />,
		value: Tech.Wind,
		caption: 'WIND',
	},
	{
		label: <IconTechnologyAny boxSize="18px" />,
		value: Tech.AnyRenewable,
		caption: 'ANY',
	},
];

export const techOptionNuclear = [
	{
		label: <IconTechnologyNuclear boxSize="18px" />,
		value: Tech.Nuclear,
		caption: 'NUCLEAR',
	},
];
