import { Production, ProductionPeriod } from 'common/interfaces';
import { Order } from 'common/interfaces/Order';
import { filterFirstProductionPeriod } from 'utils/orderUtils/filterFirstProductionPeriod';

type SignedNumber = 1 | -1 | 0;

export const sortingProductionOrder = (a: Production, b: Production): SignedNumber =>
	sortingProductionOrderByPeriod(filterFirstProductionPeriod(a.periods), filterFirstProductionPeriod(b.periods));

export const sortingProductionOrderByPeriod = (a: ProductionPeriod, b: ProductionPeriod): SignedNumber => {
	if (a.year !== b.year) {
		return Math.sign(a.year - b.year) as SignedNumber;
	}

	if (a.month && b.month) {
		return Math.sign(a.month - b.month) as SignedNumber;
	} else if (a.month) {
		return -1;
	} else if (b.month) {
		return 1;
	}

	if (a.quarter && b.quarter) {
		return Math.sign(a.quarter - b.quarter) as SignedNumber;
	} else if (a.quarter) {
		return -1;
	} else if (b.quarter) {
		return 1;
	}

	return 0;
};
