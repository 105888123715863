import React, { FunctionComponent, useCallback, useState } from 'react';
import { RequestStatus } from '@montel/montelpro-shared-components/enums';
import { useGet, useIntervalAsync, useUpdate } from '@montel/montelpro-shared-components/hooks';
import { PRICEINDEX_API_ROUTE } from '../../apiRoutes';
import { TableSkeleton } from '../Skeletons';
import EndOfDayTables from './EndOfDayTables';
import { getColumns } from './getColumns';
import { IEndOfDayTables } from './types';

const Container: FunctionComponent = () => {
	const updateIntervalSec = 60;
	const { data, getData, status } = useGet(PRICEINDEX_API_ROUTE);
	const [tables, setTables] = useState<IEndOfDayTables>();
	const [isDataLoaded, setDataLoaded] = useState(false);

	const updateData = useCallback(async () => {
		await getData();
	}, []);

	useIntervalAsync(updateData, updateIntervalSec * 1000);

	useUpdate(() => {
		setTables(data as IEndOfDayTables);
		setDataLoaded(true);
	}, [data]);

	const { previousDate, currentDate } = (tables || {}) as IEndOfDayTables;
	const columns = getColumns(previousDate, currentDate);

	return (
		<TableSkeleton
			status={status}
			error="Failed to load data"
			config={[{ numberOfTables: 3, numberOfItems: 6 }]}
			disabled={isDataLoaded && status !== RequestStatus.ERROR}
		>
			<EndOfDayTables data={tables} columns={columns} />
		</TableSkeleton>
	);
};

export default Container;
