import { FunctionComponent } from 'react';
import { ModalBody } from '@montel/montelpro-shared-components-v2';
import { styled } from 'styled-components';
import QuoteActions from './QuoteActions';
import QuoteTable from './QuoteTable';

export const StyledBody = styled(ModalBody)`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 25px;
	min-height: 600px;
`;

const QuoteDetailsBody: FunctionComponent = () => {
	return (
		<StyledBody>
			<QuoteTable />
			<QuoteActions />
		</StyledBody>
	);
};

export default QuoteDetailsBody;
