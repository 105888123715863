import { FunctionComponent } from 'react';
import { AuthStatus, useAuth } from '@montel/montelpro-auth';
import { FullPageLoader } from '@montel/montelpro-shared-components';
import { RequestStatus } from '@montel/montelpro-shared-components/enums';
import { EmailVerification } from 'pages/EmailVerification';
import { Forbidden } from 'pages/Error';
import GenericError from 'pages/Error/GenericError';
import Unauthorised from 'pages/Unauthorised';
import WelcomePage from 'pages/WelcomePage';
import { shouldRegister } from 'pages/WelcomePage/registerCookie';
import {
	selectActiveCompanyId,
	selectIsInitialised,
	selectStatus,
	selectUserRole,
	selectUserState,
} from 'store/user/userSelectors';
import getTokenPayload from 'utils/tokenUtils/getTokenPayload';
import getUserIsLocked from 'utils/tokenUtils/getUserIsLocked';
import { useAppSelector } from '../../store/hooks';
import allowedToEnter from './allowedToEnter';

export interface PrivateRouteProps {
	restrictedTo?: 'admin' | 'traders' | 'company';
	children: any;
}

const PrivateRoute: FunctionComponent<PrivateRouteProps> = ({ restrictedTo, children }) => {
	const { authStatus } = useAuth();
	const userStatus = useAppSelector(selectStatus);
	const isUserInitialized = useAppSelector(selectIsInitialised);
	const { isEmailVerified, userId, marketPlaceRoles, token } = useAppSelector(selectUserState);
	const userRole = useAppSelector(selectUserRole);
	const activeCompanyId = useAppSelector(selectActiveCompanyId);
	const isUserLocked = getUserIsLocked(token);
	const { email } = getTokenPayload(token);

	if (authStatus === AuthStatus.SUCCESS && isUserInitialized) {
		if (isUserLocked) return <Unauthorised email={email} />;
		if (!isEmailVerified) return <EmailVerification />;
		if (shouldRegister(userId, marketPlaceRoles)) return <WelcomePage />;
		if (!allowedToEnter(userRole, restrictedTo, activeCompanyId)) return <Forbidden />;

		return children;
	}

	if (authStatus === AuthStatus.ERROR || userStatus === RequestStatus.ERROR) {
		if (isUserLocked) return <Unauthorised email={email} />;
		return <GenericError />;
	}

	return <FullPageLoader />;
};

export default PrivateRoute;
