import { FunctionComponent } from 'react';
import { HStack, Text } from '@montel/montelpro-shared-components-v2';
import { IconPending } from '../Icons/IconPending';
import { StatusLabelProps } from '../types';

const LabelAccepted: FunctionComponent<StatusLabelProps> = ({ color = 'white', boxSize, size }) => {
	return (
		<HStack align="center" gap="xxs" wrap="nowrap">
			<IconPending color={color} boxSize={boxSize} />
			<Text size={size} color={color} weight="medium" lineHeight="none">
				Pending
			</Text>
		</HStack>
	);
};

export default LabelAccepted;
