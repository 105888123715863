import React, { ReactNode } from 'react';
import { StyledAnimateOpacity } from './AnimateOpacity.style';

interface AnimateOpacityProps {
	children: ReactNode;
}

const AnimateOpacity: React.FC<AnimateOpacityProps> = ({ children }) => {
	return (
		<StyledAnimateOpacity initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
			{children}
		</StyledAnimateOpacity>
	);
};

export default AnimateOpacity;
