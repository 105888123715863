import { FunctionComponent } from 'react';
import { HStack } from '@montel/montelpro-shared-components-v2';
import { Currency } from 'common/enums/CurrencyEnum';
import { useOrderDetailsContext } from '../../OrderDetailsContext';
import Detail from './Detail';

const FixedPriceTopDetails: FunctionComponent = () => {
	const { order } = useOrderDetailsContext();
	const currency = Currency[order.cost.currency];
	const totalPrice = order.cost.price * order.cost.quantity;

	return (
		<HStack align="center" justify="space-evenly">
			<Detail header={`${order.cost?.quantity?.toDefaultNumberFormat(0)} MWh`} subheader={'Fixed quantity'} />
			<Detail
				header={`${totalPrice?.toDefaultNumberFormat(2)} ${currency}`}
				subheader={`${order.cost?.price?.toDefaultNumberFormat()} ${currency} per unit`}
			/>
		</HStack>
	);
};

export default FixedPriceTopDetails;
