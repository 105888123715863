import { FunctionComponent } from 'react';
import { Box, H2, VStack } from '@montel/montelpro-shared-components-v2';
import { Suspense } from 'components/Suspense';
import { useUsersContext } from '../UsersProvider';
import DataTable from './DataTable';

const AllCompanyUsers: FunctionComponent = () => {
	const { data, status, error } = useUsersContext().allCompanyUsers;

	return (
		<Box bg="widget" px="0" py="lg">
			<VStack gap="lg">
				<Box bg="widget" px="xl" py="0">
					<H2>All company users</H2>
				</Box>
				<Suspense status={status} error={error}>
					<DataTable data={data} />
				</Suspense>
			</VStack>
		</Box>
	);
};

export default AllCompanyUsers;
