import { FunctionComponent } from 'react';
import { AvailableTo, OrderStatus } from 'common/enums';
import { Order } from 'common/interfaces/Order';
import { useMarketOrdersColumns } from 'components/OrderTables/Columns/orderTableColumns';
import OrderTable from 'components/OrderTables/OrderTable';

interface MarketOrdersTableProps {
	orders: Order[];
	header: string;
	reverse?: boolean;
	onRowClicked?: (row: Order) => void;
	noDataText?: string;
}

export const disableRow = (row: Order) => {
	if (row.status !== OrderStatus.Active) return false;

	const isCoworkersOrder = !row.isMyOrder && row.isMyCompanysOrder;
	const isCounterpartyRestricted = row.availability.availableTo !== AvailableTo.Public && !row.isCounterpartysOrder;
	const isUnavailableToTrade = !row.isMyOrder && (isCoworkersOrder || isCounterpartyRestricted);
	const disableRow = isUnavailableToTrade && row.status === OrderStatus.Active;

	return disableRow;
};

const MarketOrdersTable: FunctionComponent<MarketOrdersTableProps> = ({
	orders,
	reverse,
	header,
	onRowClicked,
	noDataText,
}) => {
	const columns = useMarketOrdersColumns(reverse);

	return (
		<OrderTable
			header={header}
			orders={orders}
			columns={columns}
			initialSorting={[{ id: 'productionPeriod', desc: true }]}
			withPagination={false}
			onRowClicked={onRowClicked}
			noDataText={noDataText}
		/>
	);
};

export default MarketOrdersTable;
