import React, { FunctionComponent, useState } from 'react';
import { useGet, useUpdate } from '@montel/montelpro-shared-components/hooks';
import {
	Modal,
	ModalBody,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
} from '@montel/montelpro-shared-components-v2';
import { LazyLoadComponent } from 'primitives/LazyLoad';
import { COUNTERPARTY_COMPANY_API_ROUTE } from '../../apiRoutes';
import { PublicCompanyInformation } from '../../common/interfaces/Company';
import CompanyDetailsExtended from './CompanyDetails/CompanyDetailsExtended';
import CompanyDetailsShort from './CompanyDetails/CompanyDetailsShort';
import SocialMedia from './SocialMedia';
import { StyledCompanyBox, VStackFullWidth } from './style';

interface CompanyCardProps extends LazyLoadComponent {
	companyInformation: PublicCompanyInformation;
	/** Update single company in memory. Optimistic update. */
	setCompany: (company: PublicCompanyInformation) => void;
}

const CompanyCard: FunctionComponent<CompanyCardProps> = ({
	companyInformation,
	setCompany,
	isInView = true,
	targetElement,
}) => {
	const [isOpen, setIsOpen] = useState(false);

	const onOpen = () => {
		setIsOpen(true);
	};
	const onClose = () => {
		setIsOpen(false);
	};

	const { getData: getCompanyData, data: companyData } = useGet(COUNTERPARTY_COMPANY_API_ROUTE, {
		params: {
			companyId: companyInformation.id,
		},
	});

	useUpdate(() => {
		if (companyData) setCompany(companyData);
	}, [companyData]);

	const companyDetailsShortProps = {
		companyInformation: companyInformation,
		refreshContent: getCompanyData,
	};

	return (
		<div ref={targetElement}>
			<StyledCompanyBox w="428px" h="216px" bg={'widget'} onClick={onOpen}>
				{isInView && <CompanyDetailsShort {...companyDetailsShortProps} />}
			</StyledCompanyBox>
			{isInView && (
				<Modal isOpen={isOpen} onClose={onClose}>
					<ModalOverlay />
					<ModalContent size={6}>
						<ModalHeader />
						<ModalBody>
							<VStackFullWidth gap="lg">
								<CompanyDetailsShort {...{ ...companyDetailsShortProps, showSecondaryActions: true }} />
								<CompanyDetailsExtended companyInformation={companyInformation} />
							</VStackFullWidth>
						</ModalBody>
						<ModalFooter>
							<SocialMedia companyInformation={companyInformation} />
						</ModalFooter>
					</ModalContent>
				</Modal>
			)}
		</div>
	);
};

export default CompanyCard;
