import { FunctionComponent } from 'react';
import { IconProps } from '@montel/montelpro-shared-components-v2';
import { styled } from 'styled-components';

const StyledNavigationButton = styled.span<{ $disabled: boolean }>`
	${({ $disabled, theme }) => `
    display: flex;
    cursor: ${$disabled ? 'default' : 'pointer'};
		pointer-events: ${$disabled ? 'none' : 'auto'};
		color: ${$disabled ? theme.colors.disabledText : theme.colors.primaryText};
	`};
`;

export const PaginationButton: FunctionComponent<{
	disabled: boolean;
	onClick: () => void;
	icon: React.ReactElement<IconProps>;
}> = ({ disabled, onClick, icon }) => (
	<StyledNavigationButton $disabled={disabled} onClick={onClick}>
		{icon}
	</StyledNavigationButton>
);
