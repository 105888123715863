import { CellProps } from '../types';
import { StatusCellProps } from './types';

export function getCellProps<T extends CellProps>(props: T): CellProps {
	const { align, justify, disabled, offsetLeft, offsetRight, width } = props;
	return { align, justify, disabled, offsetLeft, offsetRight, width };
}

export function getStatusCellProps(props: StatusCellProps) {
	const { size, boxSize } = props;
	return { size, boxSize };
}
