import { FunctionComponent } from 'react';
import { Avatar, HStack, ModalHeader, Tag, Text } from '@montel/montelpro-shared-components-v2';
import { Side } from 'common/enums/OrderEnums';
import styled from 'styled-components';
import ActiveTech from 'components/ActiveTech';
import OrderCountDown from './OrderCountDown';
import { useOrderDetailsContext } from './OrderDetailsContext';

const StyledHeader = styled(ModalHeader)`
	gap: 1rem;
	.title {
		max-width: none;
		flex-grow: 1;
	}
`;

const OrderDetailsHeader: FunctionComponent = () => {
	const { order } = useOrderDetailsContext();
	const { isMyOrder } = order;
	const { tech } = order.product || {};

	const isBuy = order.side === Side.Bid;
	const tagColor = isBuy ? 'blue' : 'pink';
	const tagText = isBuy ? 'BUY' : 'SELL';

	return (
		<StyledHeader>
			<HStack align="center" justify="space-between">
				<HStack align="center" justify="flex-start" gap="xs">
					{isMyOrder && <Avatar variant="person" size="lg" />}
					<Tag bg={tagColor} px="sm" py="xxs" borderRadius="md">
						<Text size="md">{tagText}</Text>
					</Tag>
					<ActiveTech tech={tech} tooltipPosition="bottom" numberOfTechToShow={3} />
				</HStack>
				<OrderCountDown />
			</HStack>
		</StyledHeader>
	);
};

export default OrderDetailsHeader;
