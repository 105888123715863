import { FunctionComponent, ReactNode } from 'react';
import { StyledPromptFooter } from './PromptFooter.style';

interface PromptFooterProps {
	children: ReactNode;
}

export const PromptFooter: FunctionComponent<PromptFooterProps> = ({ children }) => {
	return <StyledPromptFooter>{children}</StyledPromptFooter>;
};
