import React, { FunctionComponent, useState } from 'react';
import { Falsy, Input } from '@montel/montelpro-shared-components-v2';
import { arrayToString, stringToArray } from './stringToArray';

const MultipleEmails: FunctionComponent<{
	label: string;
	value: string[];
	setValue: (value: string[]) => void;
	tooltip?: string;
	errorMessage: string | Falsy;
}> = ({ label, value, setValue, tooltip, errorMessage }) => {
	const [tempStringValue, setTempStringValue] = useState(arrayToString(value));
	return (
		<Input
			label={label}
			value={tempStringValue}
			tooltip={tooltip}
			required
			placeholder="Separate with comma"
			onChange={(event: any) => setTempStringValue(event.target.value)}
			onBlur={() => {
				const emailsArray = stringToArray(tempStringValue);
				setValue(emailsArray);
				setTempStringValue(arrayToString(emailsArray));
			}}
			errorMessage={errorMessage}
		/>
	);
};

export default MultipleEmails;
