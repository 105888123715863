import { Quote } from 'common/interfaces/Quote';
import getTechnologyOptions from 'common/options/getTechnologyOptions';
import sortBySortingOrder from 'utils/sorting/sortBySortingOrder';

const techSortingOrder = getTechnologyOptions(true, true).map((item) => item.value);

const translateApiQuote = (quote: Quote): Quote => ({
	...quote,
	tech: sortBySortingOrder(quote.tech, techSortingOrder),
});

export const translateApiQuotes = (quotes: Quote[]): Quote[] => quotes.map(translateApiQuote);

export default translateApiQuotes;
