import { FunctionComponent } from 'react';
import { QuoteStatusEnum } from '../../../../common/enums/quoteStatusEnum';
import LabelAccepted from '../../../Tables/Cells/StatusCell/Labels/LabelAccepted';
import LabelCancelled from '../../../Tables/Cells/StatusCell/Labels/LabelCancelled';
import LabelExpired from '../../../Tables/Cells/StatusCell/Labels/LabelExpired';
import LabelPending from '../../../Tables/Cells/StatusCell/Labels/LabelPending';
import { useOrderDetailsContext } from '../../OrderDetailsContext';

const QuoteStatus: FunctionComponent = () => {
	const { selectedQuote } = useOrderDetailsContext();
	const { status } = selectedQuote || {};
	const labelProps = { size: 'xs' };

	switch (status) {
		case QuoteStatusEnum.Accepted:
			return <LabelAccepted {...labelProps} />;
		case QuoteStatusEnum.Cancelled:
			return <LabelCancelled {...labelProps} />;
		case QuoteStatusEnum.Expired:
			return <LabelExpired {...labelProps} />;
		case QuoteStatusEnum.Active:
			return <LabelPending {...labelProps} />;
		default:
			return <></>;
	}
};

export default QuoteStatus;
