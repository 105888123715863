import { cloneElement, FunctionComponent, MutableRefObject, ReactElement, useEffect, useState } from 'react';
import useLazyLoad from './useLazyLoad';

/**
 * __NOTE__: Any component whose prop type extends this type need to ensure that the component takes up
 * the same amount of space (height AND width) in the viewport on load as it does when fully lazy-loaded.
 * This is because the IntersectionObserver needs to be able to track whether the component is
 * within the viewport (a collection of components with unset height and width would always be
 * within the viewport on load, as they take up no space).
 */
export interface LazyLoadComponent {
	id?: string;
	targetElement?: MutableRefObject<any>;
	isInView?: boolean;
}

export interface LazyLoadProps {
	children: ReactElement<LazyLoadComponent>;
	observe: (ref: Element, onIntersect: () => void) => void;
	unobserve: (ref: Element) => void;
}

const LazyLoad: FunctionComponent<LazyLoadProps> = ({ children, observe, unobserve }) => {
	const { targetElement, isInView } = useLazyLoad(observe, unobserve);
	const [clonedChild, setClonedChild] = useState(
		cloneElement(children, {
			targetElement,
			isInView,
		}),
	);
	useEffect(
		() =>
			setClonedChild(
				cloneElement(children, {
					targetElement,
					isInView,
				}),
			),
		[children, targetElement, isInView],
	);

	return <>{clonedChild}</>;
};

export default LazyLoad;
