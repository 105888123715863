import { Order } from 'common/interfaces';
import { filterFirstProductionPeriod } from 'utils/orderUtils/filterFirstProductionPeriod';
import { isStrip } from 'utils/orderUtils/isStrip';
import { YearGroup } from './YearGrouping';

export const getYearGroups = (activeProductionYears: number[], activeAskOrders: Order[], activeBidOrders: Order[]) => {
	const result: YearGroup[] = [];

	if (!activeProductionYears) return [];

	for (const year of activeProductionYears) {
		const sellOrdersLength =
			activeAskOrders.filter(
				(order) =>
					filterFirstProductionPeriod(order.product.production?.periods)?.year === year && !isStrip(order),
			)?.length ?? 0;
		const buyOrdersLength =
			activeBidOrders.filter(
				(order) =>
					filterFirstProductionPeriod(order.product.production?.periods)?.year === year && !isStrip(order),
			)?.length ?? 0;

		result.push({
			year: year,
			nrOfRows: sellOrdersLength > buyOrdersLength ? sellOrdersLength : buyOrdersLength,
		});
	}

	return result?.sort((a, b) => a.year - b.year);
};
