import { getErrorMessageEmails } from 'components/MultipleEmails';
import { CompanyRegistrationState } from '../types';

const getValidationErrorsInfoStep = (state: CompanyRegistrationState) => ({
	companyName: !state.companyName && 'This field is required',
	vat: !state.vat && 'This field is required',
	street: !state.street && 'This field is required',
	postalCode: !state.postalCode && 'This field is required',
	city: !state.city && 'This field is required',
	countryCode: !state.countryCode && 'This field is required',
	signersEmails: getErrorMessageEmails(state.signersEmails),
	kycContactEmails: getErrorMessageEmails(state.kycContactEmails),
	marketRole: !state.marketRole && 'This field is required',
});

export default getValidationErrorsInfoStep;
