import { SelectOptionType } from '@montel/montelpro-shared-components';
import { RequestStatus } from '@montel/montelpro-shared-components/enums';
import { createSlice } from '@reduxjs/toolkit';
import { translateCountriesApi } from './utils/traslateCountryApi';
import { getCountries } from './listsThunks';

export interface IListsSlice {
	countries: SelectOptionType[];
	countriesStatus: RequestStatus;
	countriesError?: any;
}

export const defaultState: IListsSlice = {
	countries: [],
	countriesStatus: RequestStatus.UNLOADED,
	countriesError: undefined,
};

export const listsSlice = createSlice({
	name: 'lists',
	initialState: defaultState,
	reducers: {
		countriesUpdated: (state, { payload }) => {
			state.countries = payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getCountries.pending, (state) => {
				state.countriesStatus = RequestStatus.LOADING;
				state.countriesError = undefined;
			})
			.addCase(getCountries.rejected, (state, { error }) => {
				state.countriesStatus = RequestStatus.ERROR;
				state.countriesError = error;
			})
			.addCase(getCountries.fulfilled, (state, { payload }) => {
				state.countries = translateCountriesApi(payload);
				state.countriesStatus = RequestStatus.SUCCESS;
				state.countriesError = undefined;
			});
	},
});

export const { countriesUpdated } = listsSlice.actions;

export default listsSlice.reducer;
