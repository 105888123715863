import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const StyledTopMenuItem = styled(NavLink)<{
	$isActive: boolean;
}>`
	display: flex;
	flex-grow: 0;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 0;
	position: static;
	text-decoration: none;
	color: inherit;
	&::before {
		content: '';
		cursor: inherit;
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 0;
		width: 100%;
		height: 100%;
	}

	${({ $isActive, theme }) => {
		const { typography, spacing, colors, borderRadii } = theme;

		return css`
			color: ${$isActive ? colors.white : colors.secondaryText};
			font-size: ${typography.size.sm};
			gap: ${spacing.xs};
			cursor: ${$isActive ? 'default' : 'pointer'};
			pointer-events: ${$isActive ? 'none' : 'default'};
			padding: ${spacing.xs} 0;

			&:focus-visible {
				outline: 2px solid ${colors.heritageOrange};
				border-radius: ${borderRadii.base};
				outline-offset: 2px;
			}

			svg {
				width: ${typography.size.lg};
				height: ${typography.size.lg};
			}

			&:hover {
				color: ${colors.white};
			}
		`;
	}}
`;

export const StyledUnderline = styled.div<{ $active: boolean }>`
	height: 2px;
	margin-top: -2px;

	${({ theme, $active }) => css`
		background-color: ${theme.colors.lightPine};
		width: ${$active ? '100%' : '0%'};
	`}
`;

export const StyledListItem = styled.li`
	position: relative;
	list-style-type: none;
`;
