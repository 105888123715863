import { FunctionComponent } from 'react';
import { FileUpload, HStack, Input, Select, TextArea, VStack } from '@montel/montelpro-shared-components-v2';
import { LOGOS_API_ROUTE } from 'apiRoutes';
import getCurrencyOptions from 'common/options/getCurrencyOptions';
import { getMarketRoleOptions } from 'common/options/getMarketRoleOptions';
import { styled } from 'styled-components';
import LogoAndName from '../LogoAndName';
import { MyCompanyState } from '../MyCompanyState';
import getAboutErrors from './getAboutErrors';

const About: FunctionComponent<{
	state: MyCompanyState;
	setState: React.Dispatch<MyCompanyState>;
	newLogo?: File;
	setNewLogo: React.Dispatch<File>;
}> = ({ state, setState, newLogo, setNewLogo }) => {
	const errors = getAboutErrors(state);
	return (
		<VStack gap="md">
			<StyledContainer>
				<LogoAndName src={getLogoUrl(newLogo, state.companyLogo)} companyName={state.companyName} />
				<StyledFileUpload
					accept=".png, .svg, .jpeg, .jpg"
					onChange={(event) => {
						const files = (event?.target as HTMLInputElement)?.files;
						files && setNewLogo(files[0]);
					}}
				/>
			</StyledContainer>
			<Select
				label="Market role"
				required
				options={getMarketRoleOptions()}
				value={state.marketRole}
				onChange={(value) => setState({ ...state, marketRole: value })}
				errorMessage={errors.marketRole && 'This field is required'}
			/>
			<Select
				label="Currencies"
				required
				options={getCurrencyOptions()}
				value={state.currencies}
				onChange={(value) => setState({ ...state, currencies: value })}
				isMulti
				errorMessage={errors.currencies && 'At least one required'}
			/>

			<Input
				label="VAT / Registration number"
				required
				value={state.vat}
				onChange={(event) => setState({ ...state, vat: event.target.value })}
				errorMessage={errors.vat && 'This field is required'}
			/>
			<HStack gap="xs" wrap="nowrap" fullWidth>
				<Input
					label="Bank Name"
					value={state.bankName}
					onChange={(event) => setState({ ...state, bankName: event.target.value })}
				/>
				<Input
					label="Bank Account No"
					value={state.bankAccountNo}
					onChange={(event) => setState({ ...state, bankAccountNo: event.target.value })}
				/>
			</HStack>
			<HStack gap="xs" wrap="nowrap" fullWidth>
				<Input
					label="BIC/Swift-code"
					value={state.bic}
					onChange={(event) => setState({ ...state, bic: event.target.value })}
				/>
				<Input
					label="IBAN"
					value={state.iban}
					onChange={(event) => setState({ ...state, iban: event.target.value })}
				/>
			</HStack>
			<TextArea
				label="Description"
				value={state.companyText}
				onChange={(event) => setState({ ...state, companyText: event.target.value })}
				rows={5}
				resize="vertical"
			/>
		</VStack>
	);
};

const getLogoUrl = (newLogo: File | undefined, existingLogoFileName: string | undefined): string | undefined => {
	if (newLogo) return URL.createObjectURL(newLogo);
	return existingLogoFileName && LOGOS_API_ROUTE.replace(':filename', existingLogoFileName);
};

const StyledFileUpload = styled(FileUpload)`
	position: absolute;
	left: 73px;
	top: 68px;
`;

const StyledContainer = styled.div`
	position: relative;
`;

export default About;
