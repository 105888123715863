import { CompleteEmailVerification, EmailVerification } from 'pages/EmailVerification';
import Embedded from 'pages/Embedded';

const routesForUnverifiedUsers = [
	{
		path: 'verification-email-sent',
		element: <EmailVerification />,
	},
	{
		path: 'complete-email-verification',
		element: <CompleteEmailVerification />,
	},
	{
		path: 'embedded',
		element: <Embedded />,
	},
];

export default routesForUnverifiedUsers;
