import { FunctionComponent } from 'react';
import { Box, VStack } from '@montel/montelpro-shared-components-v2';
import PageHeader from 'primitives/PageHeader';
import useSetPageTitle from '../../hooks/useSetPageTitle';
import { AlertSettingsProvider } from '../../providers/AlertSettingsProvider';
import AlertSettings from './AlertSettings';
import { alertsMenuConfig } from './alertsMenuConfig';
import CustomAlerts from './CustomAlerts';
import { Container } from './style';

const Alerts: FunctionComponent = () => {
	const pageTitle = 'My alerts';
	useSetPageTitle(pageTitle);
	return (
		<>
			<PageHeader title="My Account" topMenuConfig={alertsMenuConfig} />
			<Container>
				<VStack gap="xl">
					<Box bg="widget" py="2rem">
						<VStack gap="md" align="center">
							<AlertSettingsProvider>
								<AlertSettings />
								<CustomAlerts />
							</AlertSettingsProvider>
						</VStack>
					</Box>
				</VStack>
			</Container>
		</>
	);
};

export default Alerts;
