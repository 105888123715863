import { Box, H2 } from '@montel/montelpro-shared-components-v2';
import { ColumnDef } from '@tanstack/react-table';
import { NoData } from 'components/Tables/NoData';
import ServerSidePagination from 'components/Tables/Pagination/ServerSidePagination';
import Th from 'components/Tables/Th';
import useServerSideTable from 'components/Tables/useServerSideTable';
import { StyledHeadRow, StyledTable, StyledTableData, StyledTableRow, TableWrapper } from './OrderTable.styles';

interface ServerSideTableProps<T> {
	data: T[];
	columns: ColumnDef<T, any>[];
	header: string;
	noDataText?: string;
}

function ServerSideTable<T>(props: ServerSideTableProps<T>) {
	const { data, columns, header, noDataText = 'There is currently nothing to show here.' } = props;
	const table = useServerSideTable<T>({
		data,
		columns,
	});

	return (
		<>
			<TableWrapper fullWidth gap="md">
				<Box bg="widget" px="xl" py="0">
					<H2>{header}</H2>
				</Box>
				<StyledTable>
					<thead>
						{table.getHeaderGroups().map((headerGroup) => (
							<StyledHeadRow key={headerGroup.id}>
								{headerGroup.headers.map((header) => (
									<Th key={header.id} header={header} />
								))}
							</StyledHeadRow>
						))}
					</thead>
					<tbody>
						{table.getRowModel().rows.map((row) => (
							<StyledTableRow key={row.id}>
								{row.getVisibleCells().map((cell) => (
									<StyledTableData
										$width={(cell.column.columnDef as any).width}
										key={cell.id}
										cell={cell}
									/>
								))}
							</StyledTableRow>
						))}
					</tbody>
				</StyledTable>
				{data.length === 0 && <NoData>{noDataText}</NoData>}
			</TableWrapper>
			<ServerSidePagination />
		</>
	);
}

export default ServerSideTable;
