import hasAtLeastOneItem from 'utils/validationUtils/hasAtLeastOneItem';
import isUnderMaxValue from 'utils/validationUtils/isUnderMaxValue';
import { CompanyRegistrationState } from '../types';

const INT_MAX_VALUE: number = 2147483647;

const getValidationErrorsPositionStep = (state: CompanyRegistrationState) => ({
	technologies: !hasAtLeastOneItem(state.technologies) && 'At least one technology should be chosen',
	tradingPosition: !state.tradingPosition && 'This field is required',
	tradingFrequency: !state.tradingFrequency && 'This field is required',
	numberOfCertificatesPerYear:
		!isUnderMaxValue(state.numberOfCertificatesPerYear, INT_MAX_VALUE) && 'Max value exceeded',
	labels: !hasAtLeastOneItem(state.labels) && 'At least one required',
	currencies: !hasAtLeastOneItem(state.currencies) && 'At least one required',
});

export default getValidationErrorsPositionStep;
