import { FunctionComponent } from 'react';
import { Button, HStack, Text } from '@montel/montelpro-shared-components-v2';
import { Quote } from 'common/interfaces/Quote';
import { getTypeOfQuote } from 'components/OrderDetails/PlaceQuoteForm/getTypeOfQuote';
import Prompt from '../../../Prompt';
import apiClient from '../apiClient';

export interface QuoteCancelPromptProps {
	quote?: Quote | null;
	isOpen: boolean;
	onClose: () => void;
}

const QuoteAcceptPrompt: FunctionComponent<QuoteCancelPromptProps> = ({ quote, isOpen, onClose }) => {
	const quoteType = quote ? getTypeOfQuote(quote.side) : undefined;
	const quoteTypeText = quoteType ?? 'quote';

	const apiParameters = {
		orderId: quote?.orderId ?? '',
		quoteId: quote?.id ?? '',
		expectedVersion: quote?.version ?? -1,
	};

	const [postData] = apiClient.useAcceptQuote(apiParameters);

	const onAccept = async () => {
		if (!quote) return;
		const success = await postData({});
		if (success) {
			onClose();
		}
	};

	return (
		<Prompt
			headerText={`Accept ${quoteTypeText}`}
			animateFromHeight={0}
			animateToHeight={isOpen ? 200 : 0}
			onClosePrompt={onClose}
			body={<Text>Accepting the {quoteTypeText} will complete the trade</Text>}
			footer={
				<HStack fullWidth justify="flex-end">
					<Button text="Cancel" variant="secondary" onClick={onClose} />
					<Button text={`Accept ${quoteTypeText}`} onClick={onAccept} />
				</HStack>
			}
		/>
	);
};

export default QuoteAcceptPrompt;
