import { FunctionComponent } from 'react';
import { useRouteError } from 'react-router';
import GenericError from './GenericError';

const ErrorBoundary: FunctionComponent = () => {
	const error = useRouteError();
	console.error(error);

	return <GenericError />;
};

export default ErrorBoundary;
