import { UserRole } from '@montel/montelpro-shared-components/enums';
import { createColumnHelper } from '@tanstack/react-table';
import { userRoleNames } from 'common/enums/valueLookups/UserRoleLookup';
import SortableHeadCell from 'components/Tables/Cells/HeadCell/SortableHeadCell';
import DropdownButton from './DropdownButton';
import { CompanyUser } from './types';

const columnHelper = createColumnHelper<CompanyUser>();
const columnDefinitions = [
	columnHelper.accessor('name', {
		id: 'name',
		enableSorting: true,
		header: ({ header }) => <SortableHeadCell text="Name" header={header} />,
	}),
	columnHelper.accessor('email', {
		id: 'email',
		enableSorting: true,
		header: ({ header }) => <SortableHeadCell text="Email" header={header} />,
	}),
	columnHelper.accessor('role', {
		id: 'role',
		enableSorting: true,
		header: ({ header }) => <SortableHeadCell text="Role" header={header} />,
		cell: ({ cell }) => userRoleNames[cell.getValue() as UserRole],
	}),
	columnHelper.display({
		id: 'actions',
		cell: ({ row }) => (
			<DropdownButton userId={row.original.userId} userName={row.getValue('name')} role={row.getValue('role')} />
		),
	}),
];

export default columnDefinitions;
