import { FunctionComponent, ReactNode } from 'react';
import { AuthProvider } from '@montel/montelpro-auth';
import { FullPageLoader } from '@montel/montelpro-shared-components';
import { FirebaseProvider } from './FirebaseProvider';
import { HubProvider } from './HubProvider';
import { MenuProvider } from './MenuProvider';
import UserProvider from './UserProvider';

const AppProvider: FunctionComponent<{ children: ReactNode }> = ({ children }) => {
	return (
		<AuthProvider
			authServerUri={process.env.REACT_APP_AUTH_SERVER ?? ''}
			applicationId={process.env.REACT_APP_FA_APPLICATION_ID ?? ''}
			returnUrl={process.env.REACT_APP_AUTH_RETURN_URL ?? ''}
			loaderElement={<FullPageLoader />}
			onNewAccessToken={async (token) => {
				// TODO: BONNY FIX THIS
			}}
		>
			<UserProvider>
				<HubProvider>
					<FirebaseProvider
						config={{
							apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
							authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
							projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
							storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
							messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
							appId: process.env.REACT_APP_FIREBASE_APP_ID,
						}}
						vapidKey={process.env.REACT_FIREBASE_VAPIDKEY}
					>
						<MenuProvider>{children}</MenuProvider>
					</FirebaseProvider>
				</HubProvider>
			</UserProvider>
		</AuthProvider>
	);
};

export default AppProvider;
