import { FunctionComponent } from 'react';
import { Avatar } from '@montel/montelpro-shared-components-v2';
import { Order } from 'common/interfaces/Order';
import { Quote } from 'common/interfaces/Quote';
import { EllipsisText } from 'primitives/EllipsisText';
import { StyledCell } from 'components/Tables/Cells/styles';
import { CellProps } from 'components/Tables/Cells/types';

const renderOwner = (order: Order | Quote, color: string) => {
	const isMine = (order as Order).isMyOrder || (order as Quote).isMyQuote;
	if (isMine) {
		return (
			<>
				<Avatar variant="person" />
				<EllipsisText color={color}>Mine</EllipsisText>
			</>
		);
	}

	const isMyCompanys = (order as Order).isMyCompanysOrder || (order as Quote).isMyCompanyQuote;
	if (isMyCompanys) {
		return (
			<>
				<Avatar variant="company" />
				<EllipsisText color={color}>Coworker</EllipsisText>
			</>
		);
	}

	return <EllipsisText color={color}>{order.isCounterpartysOrder ? 'Counterparty' : 'Any'}</EllipsisText>;
};

export interface OwnerCellProps extends CellProps {
	order: Order | Quote;
}

const OwnerCell: FunctionComponent<OwnerCellProps> = ({
	order,
	disabled,
	align = 'flex-start',
	justify = 'flex-start',
}) => {
	const cellColor = disabled ? 'subElement' : 'primaryText';

	return (
		<StyledCell direction="row" wrap="nowrap" align={align} justify={justify}>
			{renderOwner(order, cellColor)}
		</StyledCell>
	);
};

export default OwnerCell;
