import React, { FunctionComponent } from 'react';
import { IconArrowDropDown, IconArrowDropUp, ITheme } from '@montel/montelpro-shared-components-v2';
import { useTheme } from 'styled-components';
import { StyledTableSortingIndicator } from './TableSortingIndicator.style';

export interface TableSortingButtonProps {
	order: 'asc' | 'desc';
}

const TableSortingIndicator: FunctionComponent<TableSortingButtonProps> = ({ order }) => {
	const { colors } = useTheme() as ITheme;
	const arrowUpColor = order === 'asc' ? colors.white : colors.element;
	const arrowDownColor = order === 'desc' ? colors.white : colors.element;

	return (
		<StyledTableSortingIndicator>
			<IconArrowDropUp color={arrowUpColor} style={{ marginBottom: '-2px' }} boxSize="8px" />
			<IconArrowDropDown color={arrowDownColor} boxSize="8px" />
		</StyledTableSortingIndicator>
	);
};

export default TableSortingIndicator;
