import { FunctionComponent } from 'react';
import { HStack, VStack } from '@montel/montelpro-shared-components-v2';
import { Currency, OrderTypeEnum } from 'common/enums';
import ExpiresPresetEnum from 'common/enums/ExpiresPresetEnum';
import { getAvailableToName } from 'common/enums/valueLookups';
import moment, { Moment } from 'moment';
import { styled } from 'styled-components';
import getLabelByValue from 'utils/getOptionLabelByValue';
import { getExpiresOptions } from 'utils/orderUtils/getExpiresOptions';
import { useOrderFormContext } from '../../OrderFormProvider';
import { TotalPrice } from '../PriceStep/TotalPrice';
import ReviewBox from './ReviewBox';
import Value from './Value';

interface PriceReviewProps {
	onStepChange: (step: number) => void;
	isSubmitted?: boolean;
}

const Values = styled(VStack)`
	min-width: 40%;
`;

const PriceReview: FunctionComponent<PriceReviewProps> = ({ onStepChange, isSubmitted }) => {
	const { formState } = useOrderFormContext();
	const { quantity, pricePerUnit, currency, availableTo, expiryPreset, expires, orderType } = formState.price;

	return (
		<ReviewBox onStepChange={onStepChange} isSubmitted={isSubmitted} gotoStep={2} header="Price">
			<HStack wrap="nowrap">
				<Values gap="xxs">
					<Value label="Quantity">{quantity?.toFloat().toDefaultNumberFormat(0)} MWh</Value>
					{orderType === OrderTypeEnum.FixedPrice && (
						<Value label="Price per unit">{pricePerUnit?.toFloat().toDefaultNumberFormat()}</Value>
					)}
					<Value label="Currency">{Currency[currency]}</Value>
					<Value label="Expires">{getExpires(expiryPreset, expires)}</Value>
					<Value label="Available to">{getAvailableToName(availableTo)}</Value>
				</Values>
				<TotalPrice
					quantity={quantity}
					pricePerUnit={pricePerUnit}
					currency={currency}
					smaller
					isRfq={orderType === OrderTypeEnum.RequestForQuote}
				/>
			</HStack>
		</ReviewBox>
	);
};

const getExpires = (expiryPreset: string | null, expires: Date | Moment | null) => {
	if (expiryPreset !== ExpiresPresetEnum.SPECIFY) return getLabelByValue(getExpiresOptions(), expiryPreset);
	return moment(expires).format('DD/MM/YYYY HH:mm');
};

export default PriceReview;
