import { FunctionComponent } from 'react';
import { QuoteStatusEnum } from 'common/enums/quoteStatusEnum';
import { getTypeOfQuote } from 'components/OrderDetails/PlaceQuoteForm/getTypeOfQuote';
import { usePlaceQuoteFormContext } from 'components/OrderDetails/PlaceQuoteForm/PlaceQuoteFormContext';
import { useOrderDetailsContext } from '../../OrderDetailsContext';
import { ActionButton } from '../OrderDetailsBody.styles';

interface RFQTraderActionsProps {}

const RFQTraderActions: FunctionComponent<RFQTraderActionsProps> = () => {
	const { order, isExpired } = useOrderDetailsContext();
	const { setIsQuoteFormOpen } = usePlaceQuoteFormContext();
	const hasActiveQuote = order.quotes.some((quote) => quote.status === QuoteStatusEnum.Active && quote.isMyQuote);

	return hasActiveQuote ? null : (
		<ActionButton
			isDisabled={isExpired}
			variant="primary"
			text={`Place ${getTypeOfQuote(order.side)}`}
			onClick={() => setIsQuoteFormOpen(true)}
		/>
	);
};

export default RFQTraderActions;
