import { MutableRefObject, useEffect, useRef, useState } from 'react';

interface useLazyLoadReturn {
	targetElement: MutableRefObject<any>;
	isInView: boolean;
}

const useLazyLoad = (
	observe: (ref: Element, onIntersect: () => void) => void = () => {},
	unobserve: (ref: Element) => void = () => {},
): useLazyLoadReturn => {
	const targetElement = useRef(null);
	const hasBeenObserved = useRef(false);
	const [isInView, setIsInView] = useState(false);

	useEffect(() => {
		if (targetElement?.current && !hasBeenObserved.current) {
			observe(targetElement.current, () => setIsInView(true));
			hasBeenObserved.current = true;
		}

		return () => {
			if (targetElement.current) unobserve(targetElement.current);
		};
	}, [targetElement]);

	useEffect(() => {
		if (isInView && targetElement?.current) {
			unobserve(targetElement.current);
		}
	}, [isInView]);

	return {
		targetElement,
		isInView,
	};
};

export default useLazyLoad;
