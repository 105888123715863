import { Production } from 'common/interfaces';
import { Order } from 'common/interfaces/Order';
import { Trade } from 'common/interfaces/Trade';
import { filterFirstProductionPeriod } from './filterFirstProductionPeriod';
import { isStrip } from './isStrip';

export const getAllProductionYears = (orderList: any[]): number[] => {
	const productions = orderList
		.filter((order: Order | Trade) => !isStrip(order))
		.map((order: Order | Trade) => order.product.production);
	const productionYears = productions.flatMap((prod: Production) => filterFirstProductionPeriod(prod.periods).year);

	return Array.from(new Set(productionYears));
};
