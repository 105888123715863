import { FunctionComponent } from 'react';
import EmptyPageContainer from 'primitives/EmptyPageContainer';
import Error from '../Error';

const GenericError: FunctionComponent = () => (
	<EmptyPageContainer>
		<Error
			heading="Error"
			subHeading="An unexpected error occured"
			bodyText="Please refresh the page, or try again later."
		/>
	</EmptyPageContainer>
);

export default GenericError;
