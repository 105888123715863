import React, { FunctionComponent } from 'react';
import { useLocation } from 'react-router-dom';
import { MenuContextProvider } from '../menuContext';
import { MenuProps } from '../menuTypes';
import { useMenu } from '../useMenu';
import { Sidebar } from './Sidebar';
import SideMenuGroup from './SideMenuGroup';
import SideMenuItem from './SideMenuItem';
import { SideMenuSeparator } from './SideMenuSeparator';

const Index: FunctionComponent<MenuProps> = ({
	menuConfig = [],
	w = '40px',
	bg = 'widget',
	className,
	...otherProps
}) => {
	const { items, isActiveItemByIndex } = useMenu({ items: menuConfig, locationProvider: useLocation });

	return (
		<Sidebar $w={w} $bg={bg} className={className} {...otherProps}>
			<SideMenuGroup>
				{items.map((item, index) => {
					if (item.type === 'Clickable') {
						const { text, link, icon } = item;
						return (
							<MenuContextProvider
								key={link}
								value={{
									index,
									isActive: isActiveItemByIndex(index),
								}}
							>
								<SideMenuItem text={text} link={link} icon={icon} h={w} tabIndex={-1} />
							</MenuContextProvider>
						);
					}
					return <SideMenuSeparator as="li" key={`separator-${menuConfig[index - 1]}`} />;
				})}
			</SideMenuGroup>
		</Sidebar>
	);
};

export default Index;
