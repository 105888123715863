import React, { FunctionComponent } from 'react';
import { MenuGroupProps } from '../menuTypes';
import { StyledTopMenuGroup } from './TopMenuGroup.styles';

/**
 * The component represents a group of menu items in the top menu. (Might become a category-like container in future)
 */
const SideMenuGroup: FunctionComponent<MenuGroupProps> = ({ children, className, ...rest }) => (
	<StyledTopMenuGroup className={className} {...rest}>
		{children}
	</StyledTopMenuGroup>
);

export default SideMenuGroup;
