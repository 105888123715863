import { FunctionComponent } from 'react';
import { HStack, VStack } from '@montel/montelpro-shared-components-v2';
import LabelAndValue from 'primitives/LabelAndValue';
import ActiveTech from 'components/ActiveTech';
import { MyCompanyState } from '../MyCompanyState';

const TradingInterestsOverview: FunctionComponent<{ state: MyCompanyState }> = ({ state }) => {
	return (
		<VStack gap="xl">
			<LabelAndValue label="Technologies" value={<ActiveTech tech={state.technologies} />} size="sm" />
			<LabelAndValue
				label="Products"
				value={state.products?.map((product) => product?.label).join(', ')}
				size="sm"
			/>
			<LabelAndValue
				label="Trading position"
				value={formatTradingPosition(state.tradingPosition?.label)}
				size="sm"
			/>
			<LabelAndValue
				label="Registry operators"
				value={state.registryOperators?.map((operator) => operator?.label).join(', ')}
				size="sm"
			/>
			<LabelAndValue label="Labels" value={state.labels?.map((label) => label?.label).join(', ')} size="sm" />
			<HStack gap="xl" wrap="nowrap" fullWidth>
				<LabelAndValue label="Certificates per year" value={state.numberOfCertificatesPerYear} size="sm" />
				<LabelAndValue label="Trading frequency" value={state.tradingFrequency?.label} size="sm" />
			</HStack>
			<HStack gap="xl" wrap="nowrap" fullWidth>
				<LabelAndValue label="EECS registry" value={state.eecsRegistry?.label} size="sm" />
				<LabelAndValue label="Subsidised" value={state.subsidized?.label} size="sm" />
			</HStack>
		</VStack>
	);
};

const formatTradingPosition = (tradingPosition?: string) => {
	if (tradingPosition === 'Both') {
		return 'Buying, Selling';
	}
	return tradingPosition;
};

export default TradingInterestsOverview;
