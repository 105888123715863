import { FunctionComponent, useEffect, useRef, useState } from 'react';
import DateTime from 'react-datetime';
import { DateTimePicker, HStack, RadioButtonList } from '@montel/montelpro-shared-components-v2';
import ExpiresPresetEnum from 'common/enums/ExpiresPresetEnum';
import { Moment } from 'moment';
import { yesterday } from 'utils/dateUtil';
import { getExpiresOptions } from 'utils/orderUtils/getExpiresOptions';
import { toTitleCase } from '../../../../utils/string-extension';

interface ExpiresGroupProps {
	expiryPreset: string | null;
	expires: Date | Moment | null;
	deliveryDate: Date | null;
	onExpiryPresetChange: (value: string) => void;
	onExpiryDateChange: (date: Moment) => void;
	displayInline?: boolean;
	disabled?: boolean;
	errors?: any;
	dropCalendarUpward?: boolean;
	lastValidDate?: Moment;
	type: string;
}

const ExpiresGroup: FunctionComponent<ExpiresGroupProps> = ({
	expiryPreset,
	expires,
	deliveryDate,
	onExpiryPresetChange,
	onExpiryDateChange,
	errors,
	displayInline = true,
	disabled = false,
	dropCalendarUpward = false,
	lastValidDate,
	type,
}) => {
	const datePickerRef = useRef<DateTime>();
	const [expiryPresetChangedByUser, setExpiryPresetChangedByUser] = useState(false);

	useEffect(() => {
		if (expiryPreset) {
			datePickerRef?.current?.setState((prevState) => {
				return {
					...prevState,
					open: expiryPreset === ExpiresPresetEnum.SPECIFY && expiryPresetChangedByUser,
				};
			});
		}
	}, [expiryPreset]);

	const isValidDate = (current: Moment) => {
		const isAfterYesterday = current.isAfter(yesterday());
		const isBeforeDeliveryDate = !deliveryDate || current.isBefore(deliveryDate);
		const isBeforeLastValidDate = !lastValidDate || current.isBefore(lastValidDate);

		return isAfterYesterday && isBeforeDeliveryDate && isBeforeLastValidDate;
	};

	const onPresetChange = (value: string) => {
		setExpiryPresetChangedByUser(true);
		onExpiryPresetChange(value);
	};

	return (
		<HStack fullWidth gap="md" wrap="nowrap">
			<RadioButtonList
				label={`${toTitleCase(type)} expires in`}
				required
				options={getExpiresOptions()}
				errorMessage={errors?.expiryPreset && 'You should choose expiry time'}
				isDisabled={disabled}
				onChange={onPresetChange}
				value={expiryPreset}
			/>
			<DateTimePicker
				ref={datePickerRef}
				label="Expiry time"
				withTime
				hideLabel
				keepLabelHeightWhenHidden
				required={false}
				timeFormat="HH:mm"
				isValidDate={isValidDate}
				dropCalendarUpward={!displayInline || dropCalendarUpward}
				disabled={disabled || expiryPreset !== ExpiresPresetEnum.SPECIFY}
				errorMessage={errors?.expires && errors.expires}
				onChange={onExpiryDateChange}
				value={expires}
				placeholder="dd/mm/yyyy - hh:mm"
			/>
		</HStack>
	);
};

export default ExpiresGroup;
