import React, { FunctionComponent } from 'react';
import { AvatarImage, FileUpload, Text, VStack } from '@montel/montelpro-shared-components-v2';
import styled, { css } from 'styled-components';

const LogoUpload: FunctionComponent<{
	logo: File | undefined;
	setLogo: (logo: File | undefined) => void;
}> = ({ logo, setLogo }) => {
	const imageUrl = logo && URL.createObjectURL(logo);
	return (
		<StyledUploadContainer>
			<AvatarImage variant={'company'} src={imageUrl} borderRadius={'sm'} />
			<VStack justify={'space-between'}>
				<Text color="secondaryText">
					Adding a company logo will help other Marketplace users recognise your company.
				</Text>
				<FileUpload
					text={'Upload'}
					accept=".png, .svg, .jpeg, .jpg"
					onChange={(event) => {
						const files = (event?.target as HTMLInputElement)?.files;
						files && setLogo(files[0]);
					}}
				/>
			</VStack>
		</StyledUploadContainer>
	);
};

export const StyledUploadContainer = styled.div`
	${({ theme }) => {
		const { colors, spacing } = theme;
		return css`
			border: 1px solid ${colors.subElement};
			border-radius: ${spacing.xs};
			display: flex;
			gap: ${spacing.lg};
			padding: 1rem;
		`;
	}}
`;

export default LogoUpload;
