import { FunctionComponent } from 'react';
import { ButtonsContainer } from '../CompanyRegistration.styles';
import { CompanyRegistrationState } from '../types';
import BackButton from './BackButton';
import RightButtonPicker from './RightButtonPicker';

const NavigationBar: FunctionComponent<{
	setIsNextPressed: React.Dispatch<boolean>;
	activeStep: number;
	goToNext: () => void;
	goToPrevious: () => void;
	state: CompanyRegistrationState;
	logo?: File;
}> = ({ setIsNextPressed, activeStep, state, goToNext, goToPrevious, logo }) => {
	return (
		<ButtonsContainer>
			<BackButton goToPrevious={goToPrevious} activeStep={activeStep} />
			<RightButtonPicker
				goToNext={goToNext}
				activeStep={activeStep}
				state={state}
				logo={logo}
				setIsNextPressed={setIsNextPressed}
			/>
		</ButtonsContainer>
	);
};

export default NavigationBar;
