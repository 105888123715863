import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { OrderStatus } from 'common/enums';
import { Order } from 'common/interfaces';
import { Quote } from 'common/interfaces/Quote';
import { RootState } from 'store/store';
import { selectActiveOrders } from './activeOrders/activeOrdersSelectors';
import { orderCreated, orderRemoved, orderUpdated } from './activeOrders/activeOrdersSlice';
import {
	companyOrderCancelled,
	companyOrderExpired,
} from './companyUntradedOrderHistory/companyUntradedOrderHistorySlice';
import { userOrderCancelled, userOrderExpired } from './userUntradedOrderHistory/userUntradedOrderHistorySlice';
import translateApiOrder from './utils/translateApiOrder';
import { OrderEventEnum } from './OrderStateEnum';

interface IGetOrder {
	orderId: string;
	event: string;
}

export const getActiveOrders = createAsyncThunk('orders/getActiveOrders', async () => {
	const response = await axios.get(`${process.env.REACT_APP_MARKEDPLACE_API}/api/Order/GetActiveOrders`);
	return (response.data || []) as Order[];
});

export const getUserUntradedOrderHistory = createAsyncThunk('orders/getUserUntradedOrderHistory', async () => {
	const response = await axios.get(`${process.env.REACT_APP_MARKEDPLACE_API}/api/Order/getUserUntradedOrderHistory`);
	return (response.data || []) as Order[];
});

export const getCompanyUntradedOrderHistory = createAsyncThunk('orders/getCompanyUntradedOrderHistory', async () => {
	const response = await axios.get(
		`${process.env.REACT_APP_MARKEDPLACE_API}/api/Order/getCompanyUntradedOrderHistory`,
	);
	return (response.data || []) as Order[];
});

export const getActiveUserQuotes = createAsyncThunk('orders/getUserQuotes', async () => {
	const response = await axios.get(`${process.env.REACT_APP_MARKEDPLACE_API}/api/Quote/GetUserQuotes`);
	return (response.data || []) as Quote[];
});

export const getActiveCompanyQuotes = createAsyncThunk('orders/getCompanyQuotes', async () => {
	const response = await axios.get(`${process.env.REACT_APP_MARKEDPLACE_API}/api/Quote/GetCompanyQuotes`);
	return (response.data || []) as Quote[];
});

export const getOrder = createAsyncThunk(
	'orders/getOrder',
	async ({ orderId, event }: IGetOrder, { dispatch, getState }) => {
		const response = await axios.get(
			`${process.env.REACT_APP_MARKEDPLACE_API}/api/Order/GetOrder?orderId=${orderId}`,
		);

		if (!response.data) return;

		const order = translateApiOrder(response.data);
		const activeOrders = selectActiveOrders(getState() as RootState);

		const existingActiveOrderIdx = activeOrders.findIndex((o: Order) => o.id === order.id);
		const activeOrderExists = existingActiveOrderIdx >= 0;

		switch (event) {
			case OrderEventEnum.ORDER_CREATED:
				if (!activeOrderExists && order.status === OrderStatus.Active) {
					dispatch(orderCreated(order));
				}
				break;

			case OrderEventEnum.ORDER_UPDATED:
				if (activeOrderExists && order.status === OrderStatus.Active) {
					dispatch(orderUpdated({ order, index: existingActiveOrderIdx }));
				}
				if (activeOrderExists && order.status === OrderStatus.Exhausted) {
					dispatch(orderRemoved(existingActiveOrderIdx));
				}
				break;

			case OrderEventEnum.ORDER_REMOVED:
				if (activeOrderExists) {
					dispatch(orderRemoved(existingActiveOrderIdx));
				}

				if (order.status === OrderStatus.Deleted) {
					if (order.isMyOrder) dispatch(userOrderCancelled(order));
					if (order.isMyCompanysOrder) dispatch(companyOrderCancelled(order));
				}

				if (order.status === OrderStatus.Expired) {
					if (order.isMyOrder) dispatch(userOrderExpired(order));
					if (order.isMyCompanysOrder) dispatch(companyOrderExpired(order));
				}
				break;
		}
	},
);
