import { NavLink } from 'react-router-dom';
import { Text } from '@montel/montelpro-shared-components-v2';
import EmailPage from './EmailPage';

const UserDeactivated = () => (
	<EmailPage subHeading="User deactivated">
		<Text>
			Your user has been deactivated. Please contact our support team, or{' '}
			<NavLink to="/">click here to proceed to the login page.</NavLink>
		</Text>
	</EmailPage>
);

export default UserDeactivated;
