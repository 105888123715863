import { FunctionComponent, ReactNode } from 'react';
import { VStack } from '@montel/montelpro-shared-components-v2';
import { css, styled } from 'styled-components';

interface PageContainerProps {
	children: ReactNode;
}

const StyledContainer = styled(VStack)`
	min-height: calc(100vh - 5rem);
	margin-top: 5rem;
	margin-left: 40px;
	width: calc(100% - 40px);

	${({ theme }) => css`
		padding: ${theme.spacing.xl} ${theme.spacing.xl};
	`}
`;

const PageContainer: FunctionComponent<PageContainerProps> = ({ children }) => (
	<StyledContainer gap="xxl" fullWidth>
		{children}
	</StyledContainer>
);

export default PageContainer;
