import styled from 'styled-components';

export const StyledJoinCompany = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 50%;
	max-width: 540px;
	min-height: 70vh;
	margin-left: auto;
	margin-right: auto;
`;
