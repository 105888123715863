import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@montel/montelpro-shared-components-v2';

const OKButton: FunctionComponent = () => {
	const navigate = useNavigate();
	return <Button text={'OK'} onClick={() => navigate('/orders')} />;
};

export default OKButton;
