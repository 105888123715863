import React, { ReactElement } from 'react';
import { Region } from '../../../../common/enums/OrderEnums';
import { regionCountries } from '../../../../common/enums/valueLookups/RegionLookup';

export const getRegionTooltip = (region: Region | null | undefined): ReactElement | undefined => {
	const countries = region ? regionCountries[Region[region as number] as keyof typeof Region] : [];
	if (countries.length === 0) return undefined;
	return (
		<p>
			<strong>Selected region includes:</strong> <br />
			<span>{countries.reduce((p, c) => p + `, ${c}`)}</span>
		</p>
	);
};
