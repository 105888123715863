import styled from 'styled-components';

export { ReactComponent as AscIcon } from 'assets/icons/icons_navigation/expand_less_24dp.svg';
export { ReactComponent as DescIcon } from 'assets/icons/icons_navigation/expand_more_24dp.svg';

export const Container = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	border-radius: 8px;
	max-height: 100%;

	/* Chrome, Safari and Opera */
	&::-webkit-scrollbar {
		display: none;
	}
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
`;

export const SubContainer = styled.div<{ $bottomMargin?: number }>`
	height: fit-content;
	margin-bottom: ${({ $bottomMargin }) => ($bottomMargin ? `${$bottomMargin}px` : 'default')};
	background: #23323a;
	border-radius: 8px;
	padding: 0 16px 16px 16px;
`;

export const TableContainer = styled.table`
	width: 100%;
	border-collapse: collapse;
	table-layout: fixed;
`;

export const THead = styled.thead`
	width: 100%;
	background: #23323a;
	color: #acb5b9;
	text-transform: capitalize;
	position: sticky;
	top: 0;
	z-index: 1;
	padding: 0;
`;

export const THWrapper = styled.div<{ $isSortable: boolean }>`
	height: 24px;
	display: flex;
	flex-direction: row;
	align-items: center;
	cursor: ${({ $isSortable }) => ($isSortable ? 'pointer' : 'default')};
`;

export const TH = styled.th`
	text-align: left;
	padding-top: 16px;
	padding-bottom: 4px;
	padding-left: 0;
	padding-right: 0;
	font-size: 14px;
`;

export const TBody = styled.tbody`
	width: 100%;
`;

export const TR = styled.tr`
	width: 100%;
	border-bottom: 1px solid #acb5b9;
`;

export const TD = styled.td`
	color: #f2f2f2;
	width: 1px;
	font-size: 16px;
	font-weight: 400;
	line-height: 130%;
	padding-top: 8px;
`;
