import { FunctionComponent } from 'react';
import { Text } from '@montel/montelpro-shared-components-v2';
import { StyledNoAlerts } from './style';

export interface NoAlertsProps {
	onClick: () => void;
	className?: string;
	[otherProps: string]: any;
}

const NoAlerts: FunctionComponent<NoAlertsProps> = ({ onClick, className, ...otherProps }) => {
	return (
		<StyledNoAlerts onClick={onClick} className={className} {...otherProps}>
			<Text>You do not have any custom alerts yet. Click here to add one.</Text>
		</StyledNoAlerts>
	);
};

export default NoAlerts;
