import { FunctionComponent } from 'react';
import { Box, H2 } from '@montel/montelpro-shared-components-v2';
import { ColumnDef, SortingState } from '@tanstack/react-table';
import { Quote } from 'common/interfaces/Quote';
import { NoData } from 'components/Tables/NoData';
import TanStackPagination from 'components/Tables/Pagination/TanStackPagination';
import Th from 'components/Tables/Th';
import usePaginatedTable from 'components/Tables/usePaginatedTable';
import {
	StyledHeadRow,
	StyledTable,
	StyledTableData,
	StyledTableRow,
	TableWrapper,
} from '../OrderTables/OrderTable.styles';

interface QuoteTableProps {
	quotes: Quote[];
	columns: ColumnDef<Quote, any>[];
	header: string;
	initialSorting?: SortingState;
	withPagination?: boolean;
	onRowClicked?: (row: Quote) => void;
	noDataText?: string;
	pageSize?: number;
}

const QuoteOverviewTable: FunctionComponent<QuoteTableProps> = ({
	quotes,
	columns,
	header,
	initialSorting,
	withPagination = true,
	onRowClicked,
	pageSize = 10,
	noDataText = 'There is currently nothing to show here.',
}) => {
	const table = usePaginatedTable({ data: quotes, columns, pageSize, initialSorting });

	const rowModel = withPagination ? table.getPaginationRowModel() : table.getSortedRowModel();

	return (
		<>
			<TableWrapper fullWidth gap="md">
				<Box bg="widget" px="xl" py="0">
					<H2>{header}</H2>
				</Box>
				<StyledTable>
					<thead>
						{table.getHeaderGroups().map((headerGroup) => (
							<StyledHeadRow key={headerGroup.id}>
								{headerGroup.headers.map((header) => (
									<Th key={header.id} header={header} />
								))}
							</StyledHeadRow>
						))}
					</thead>
					<tbody>
						{rowModel.rows.map((row) => (
							<StyledTableRow
								key={row.id}
								onClick={() => {
									onRowClicked && onRowClicked(row.original);
								}}
								$clickable={Boolean(onRowClicked)}
							>
								{row.getVisibleCells().map((cell) => (
									<StyledTableData
										$width={(cell.column.columnDef as any).width}
										key={cell.id}
										cell={cell}
									/>
								))}
							</StyledTableRow>
						))}
					</tbody>
				</StyledTable>
				{rowModel.rows.length === 0 && <NoData>{noDataText}</NoData>}
			</TableWrapper>
			{withPagination && rowModel.rows.length > 0 && <TanStackPagination table={table} />}
		</>
	);
};

export default QuoteOverviewTable;
