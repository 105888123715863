import { FunctionComponent } from 'react';
import { Avatar } from '@montel/montelpro-shared-components-v2';
import { Quote } from '../../../../common/interfaces/Quote';
import { CellProps } from '../../../Tables/Cells';
import { StyledCell } from '../../../Tables/Cells/styles';

export interface QuoteOwnerCellProps extends Partial<CellProps>, Pick<Quote, 'isMyQuote'> {}

const QuoteOwnerCell: FunctionComponent<QuoteOwnerCellProps> = ({
	isMyQuote,
	align,
	justify,
	offsetLeft,
	offsetRight,
}) => {
	if (!isMyQuote) return <></>;

	return (
		<StyledCell $offsetRight={offsetRight} $offsetLeft={offsetLeft} align={align} justify={justify}>
			<Avatar variant="person" />
		</StyledCell>
	);
};

export default QuoteOwnerCell;
