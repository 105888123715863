/** v2 Icons */
import {
	IconTechnologyAny,
	IconTechnologyBio,
	IconTechnologyGeoThermal,
	IconTechnologyHydro,
	IconTechnologyNuclear,
	IconTechnologySolar,
	IconTechnologyWind,
} from '@montel/montelpro-shared-components-v2';
import { Tech } from '../OrderEnums';

const techIconsV2 = {
	[Tech.Default]: IconTechnologyAny,
	[Tech.AnyRenewable]: IconTechnologyAny,
	[Tech.Hydro]: IconTechnologyHydro,
	[Tech.Wind]: IconTechnologyWind,
	[Tech.Solar]: IconTechnologySolar,
	[Tech.Geothermal]: IconTechnologyGeoThermal,
	[Tech.Biomass]: IconTechnologyBio,
	[Tech.Nuclear]: IconTechnologyNuclear,
};
export const getTechIcon = (tech: Tech) => techIconsV2[tech];

export const techNames = {
	[Tech.Default]: 'Default',
	[Tech.AnyRenewable]: 'Any',
	[Tech.Hydro]: 'Hydro',
	[Tech.Wind]: 'Wind',
	[Tech.Solar]: 'Solar',
	[Tech.Geothermal]: 'Geothermal',
	[Tech.Biomass]: 'Biomass',
	[Tech.Nuclear]: 'Nuclear',
};
export const getTechName = (tech: Tech) => techNames[tech];
