import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetOnMount } from '@montel/montelpro-shared-components/hooks';
import {
	DropdownPositioningContainer,
	IconButton,
	IconThreeDots,
	InfiniteScrollContainer,
	Text,
} from '@montel/montelpro-shared-components-v2';
import { NOTIFICATIONS_API_ROUTE, NOTIFICATIONS_UPDATE_LAST_SEEN_API_ROUTE } from 'apiRoutes';
import { AnimatePresence, motion } from 'framer-motion';
import useSubmit, { PUT } from 'hooks/useSubmit';
import { INotification } from './INotification';
import KebabMenu from './KebabMenu';
import { getNotificationLink } from './notificationContent';
import NotificationsButton from './NotificationsButton';
import { StyledHStack, StyledNotificationsContainer } from './NotificationsCenter.style';
import { NotificationsStack } from './NotificationsStack';
import useNotifications, { TUseNotificationsReturn } from './useNotifications';

const FETCH_LIMIT = 10;

const NotificationsCenter = () => {
	const navigate = useNavigate();
	const [notificationsOpen, setNotificationsOpen] = useState(false);
	const [kebabOpen, setKebabOpen] = useState(false);
	const [offset, setOffset] = useState(0);
	const [hasMore, setHasMore] = useState(true);
	const {
		notifications,
		unseenNotificationsCount,
		addNotifications,
		setLastSeen,
		markNotificationAsSeen,
		markAllNotificationsAsRead,
	}: TUseNotificationsReturn = useNotifications();

	const { data, status, getData, error } = useGetOnMount(NOTIFICATIONS_API_ROUTE, {
		params: {
			limit: FETCH_LIMIT.toString(),
			offset: offset.toString(),
		},
	});

	useEffect(() => {
		if (!data) {
			setHasMore(false);
			return;
		}

		addNotifications(data.items as INotification[]);
		setLastSeen(data.lastSeen);
		setOffset(data.nextOffset);
		setHasMore(data.hasMore);
	}, [data]);
	const [updateLastSeen] = useSubmit(NOTIFICATIONS_UPDATE_LAST_SEEN_API_ROUTE, PUT);

	const handleNotificationOpen = async () => {
		setNotificationsOpen(!notificationsOpen);
		const { data, success } = await updateLastSeen(undefined);
		if (success) setLastSeen(data.lastSeen);
	};

	function onNotificationClick(notification: INotification) {
		markNotificationAsSeen(notification);
		const notificationLink = getNotificationLink(notification);
		if (notificationLink) {
			navigate(notificationLink);
		}
	}

	const onClose = () => {
		setNotificationsOpen(false);
		setKebabOpen(false);
	};

	return (
		<DropdownPositioningContainer>
			<NotificationsButton
				handleNotificationOpen={handleNotificationOpen}
				unseenNotificationsCount={unseenNotificationsCount}
			/>
			{notificationsOpen && (
				<AnimatePresence>
					<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
						<StyledNotificationsContainer closeMenu={onClose} topPosition="1.8rem">
							<StyledHStack align="center" justify="space-between" fullWidth>
								<Text size="sm" weight="semiBold">
									Notifications
								</Text>
								<DropdownPositioningContainer>
									<IconButton
										icon={<IconThreeDots />}
										onClick={() => setKebabOpen(!kebabOpen)}
										variant="tertiary"
									/>
									{kebabOpen && (
										<KebabMenu
											setKebabOpen={setKebabOpen}
											markAllNotificationsAsSeen={markAllNotificationsAsRead}
											closeNotificationMenu={onClose}
										/>
									)}
								</DropdownPositioningContainer>
							</StyledHStack>
							<InfiniteScrollContainer
								getData={getData}
								status={status}
								hasMore={hasMore}
								maxHeight="62.5rem"
								allFetchedMessage="All notifications fetched"
							>
								<NotificationsStack
									notifications={notifications}
									status={status}
									error={error}
									onNotificationClick={onNotificationClick}
								/>
							</InfiniteScrollContainer>
						</StyledNotificationsContainer>
					</motion.div>
				</AnimatePresence>
			)}
		</DropdownPositioningContainer>
	);
};

export default NotificationsCenter;
