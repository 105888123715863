import { FunctionComponent, ReactElement } from 'react';
import { RequestStatus } from '@montel/montelpro-shared-components/enums';
import { ApiErrorDescription, ErrorAlert, FullPageLoader, RoundSpinner } from '@montel/montelpro-shared-components-v2';
import { isError, isForbidden, isSuccessful } from './utils';

type LoaderTypes = 'lg' | 'sm' | 'full';

export interface SuspenseProps {
	status: RequestStatus | RequestStatus[];
	error?: any; // This is an AxiosError<any>, but we dont want to have a dep to axios just for this declaration.
	children?: any;
	disabled?: boolean;
	loaderType?: LoaderTypes;
	customError?: ReactElement;
	customForbidden?: ReactElement;
	classNameLoading?: string;
	classNameError?: string;
}

const Suspense: FunctionComponent<SuspenseProps> = ({
	status,
	error,
	children,
	loaderType = 'lg',
	disabled = false,
	customError,
	customForbidden,
	classNameLoading,
	classNameError,
}) => {
	if (isSuccessful(status) || disabled) {
		return children;
	}
	if (isError(status)) {
		return (
			customError || (
				<ErrorAlert className={classNameError} text="Try to reload the page">
					<ApiErrorDescription error={error} />
				</ErrorAlert>
			)
		);
	}
	if (isForbidden(status)) {
		return (
			customForbidden || <ErrorAlert className={classNameError} text="You do not have access to this content." />
		);
	}
	return loaderType === 'full' ? <FullPageLoader /> : <RoundSpinner size={loaderType} className={classNameLoading} />;
};

export default Suspense;
