import { OrderLifecycleEnum, Side } from 'common/enums/OrderEnums';
import { z } from 'zod';
import { deliveryStep } from './Steps/DeliveryStep/DeliveryStep.types';
import { priceStep } from './Steps/PriceStep/PriceStep.types';
import { ProductSchema } from './Steps/ProductStep/ProductStep.types';

export const NewOrderSchema = z.object({
	product: ProductSchema,
	delivery: deliveryStep,
	price: priceStep,
	id: z.string().optional(),
	version: z.number().optional(),
	presetId: z.number().nullable().optional(),
	side: z.nativeEnum(Side),
	type: z.nativeEnum(OrderLifecycleEnum),
});

export type IOrderFormStep = {
	title: string;
	canBeNavigatedTo: boolean;
};

export type OrderFormState = z.infer<typeof NewOrderSchema>;
