import { UserRole } from 'common/enums/UserRoleEnum';

const allowedToEnter = (
	role: UserRole,
	restrictedTo: 'admin' | 'traders' | 'company' | undefined,
	activeCompany: number | null | undefined,
): boolean => {
	const readOnlyWithCompany = role === UserRole.ReadOnly && activeCompany;
	const trader = role === UserRole.Trader && activeCompany;
	const tradeManager = role === UserRole.TradeManager && activeCompany;
	const backOffice = role === UserRole.BackOffice && activeCompany;

	const adminUser = tradeManager || backOffice;
	const traderUser = tradeManager || trader;
	const companyUser = tradeManager || trader || backOffice || readOnlyWithCompany;

	if (restrictedTo) {
		if (adminUser && restrictedTo === 'admin') return true;
		if (traderUser && restrictedTo === 'traders') return true;
		if (companyUser && restrictedTo === 'company') return true;
		return false;
	}
	return true;
};

export default allowedToEnter;
