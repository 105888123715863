import { SelectOptionType } from '@montel/montelpro-shared-components';
import { Tech } from '@montel/montelpro-shared-components/enums';
import getRelationOptions from 'common/options/getRelationOptions';
import { getTechnologyOptionsWithIcon } from 'common/options/technologyOptionsWithIcon';
import { IFilterOption } from 'components/Filter/types';
import { PublicCompanyInformation } from '../../common/interfaces/Company';
import getCurrencyOptions from '../../common/options/getCurrencyOptions';
import getTradingPositionOptions from '../../common/options/getTradingPositionOptions';

function getCompanyFilterOptions(
	companies: PublicCompanyInformation[],
	countryOptions: SelectOptionType[],
): IFilterOption[] {
	return [
		{
			category: 'Tech',
			type: 'Checkbox',
			checkboxOptions: getTechnologyOptionsWithIcon().filter(
				(o) => o.value !== Tech.AnyRenewable && companies.some((m: any) => m.technologies.includes(o.value)),
			),
		},
		{
			category: 'TradingPosition',
			type: 'Checkbox',
			checkboxOptions: getTradingPositionOptions().filter((o) =>
				companies.some((m: any) => m.tradingPosition === o.value),
			),
		},
		{
			category: 'Country',
			type: 'Checkbox',
			checkboxOptions: countryOptions.filter((o) => companies.some((m: any) => m.countryCode === o.value)),
		},
		{
			category: 'Currency',
			type: 'Checkbox',
			checkboxOptions: getCurrencyOptions().filter((o) =>
				companies.some((m: any) => m.currencies.includes(o.value)),
			),
		},
		{
			category: 'Relation',
			type: 'Checkbox',
			checkboxOptions: getRelationOptions(),
		},
	];
}

export default getCompanyFilterOptions;
