import { quoteTableColumns } from 'components/QuotesTable/Columns/quoteTableColumns';

export const quotesOverviewTableColumns = [
	quoteTableColumns.productionPeriod(),
	quoteTableColumns.tech(),
	quoteTableColumns.region(),
	quoteTableColumns.delivery(),
	quoteTableColumns.ownerIcon(),
	quoteTableColumns.type(),
	quoteTableColumns.quantity({
		cellProps: { align: 'flex-end' },
		headCellProps: { justify: 'flex-end' },
	}),
	quoteTableColumns.price({
		cellProps: { align: 'flex-end' },
		headCellProps: { text: 'My unit price', justify: 'flex-end' },
	}),
];
