import { Link, Text } from '@montel/montelpro-shared-components-v2';
import EmailPage from './EmailPage';

const EmailVerificationCompleted = () => (
	<EmailPage subHeading="Email verified!" iconColor="success">
		<Text>
			Your email address has been verified. <Link href="/">Click here to go to Marketplace.</Link>
		</Text>
	</EmailPage>
);

export default EmailVerificationCompleted;
