import { IconBellOutlined } from '@montel/montelpro-shared-components-v2';
import { AnimatePresence, motion } from 'framer-motion';
import { StyledContainer, StyledDot, StyledIconButton } from './NotificationsButton.style';

type Props = {
	handleNotificationOpen: () => void;
	unseenNotificationsCount: number;
};

const NotificationsButton = ({ handleNotificationOpen, unseenNotificationsCount }: Props) => (
	<StyledContainer>
		<StyledIconButton
			size="lg"
			icon={<IconBellOutlined />}
			variant="tertiary"
			onClick={handleNotificationOpen}
			title="Open notifications"
		/>
		<AnimatePresence>
			{unseenNotificationsCount > 0 && (
				<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
					<StyledDot>{unseenNotificationsCount > 9 ? '9+' : unseenNotificationsCount}</StyledDot>
				</motion.div>
			)}
		</AnimatePresence>
	</StyledContainer>
);

export default NotificationsButton;
