const MY_COMPANY = '/my-company';

export default {
	approved: MY_COMPANY,
	deactivated: MY_COMPANY,
	joinCompanyInviteReceived: MY_COMPANY,
	joinRequestAccepted: MY_COMPANY,
	joinRequestDeclined: MY_COMPANY,
	joinRequestReceived: MY_COMPANY,
	userRemoved: MY_COMPANY,
	roleChanged: MY_COMPANY,
};
