import { useState } from 'react';
import {
	Button,
	HStack,
	IconChevronLeft,
	IconChevronRight,
	Text,
	VStack,
} from '@montel/montelpro-shared-components-v2';
import moment, { Moment } from 'moment';
import { setToAtLeastTenMinFromNow } from 'utils/setToAtLeastTinMinFromNow';
import ExpiresGroup from 'components/NewOrder/Steps/PriceStep/ExpiresGroup';
import PricePerUnit from 'components/NewOrder/Steps/PriceStep/PricePerUnit';
import Quantity from 'components/NewOrder/Steps/PriceStep/Quantity';
import { useZodValidation } from 'components/NewOrder/utils/useZodValidation';
import Prompt from '../../Prompt';
import { useOrderDetailsContext } from '../OrderDetailsContext';
import { getTypeOfQuote } from './getTypeOfQuote';
import { fields, QuoteFormSchema } from './PlaceQuoteForm.types';
import { usePlaceQuoteFormContext } from './PlaceQuoteFormContext';
import { getFormStepPromptHeights } from './stepDefinitions';
import { TotalPrice } from './TotalPrice';

interface FormStepProps {
	onNext: () => void;
}

const FormStep: React.FunctionComponent<FormStepProps> = ({ onNext }) => {
	const { order } = useOrderDetailsContext();
	const { validate, errors, validateSingle } = useZodValidation();
	const { setIsQuoteFormOpen, previousStep, formState, setFormState, isCreateAction } = usePlaceQuoteFormContext();
	const [willClose, setWillClose] = useState(false);
	const quoteType = getTypeOfQuote(order.side);

	const validateAndProgress = () => {
		const result = validate(QuoteFormSchema, { ...formState });

		if (result.success) {
			onNext();
		}
	};

	const { quantity, pricePerUnit } = formState;

	const onQuantityChange = (quantity: string, validate: boolean = false) => {
		validate && validateSingle(fields.quantity, quantity, 'quantity');

		setFormState({ ...formState, quantity });
	};

	const onPricePerUnitChange = (pricePerUnit: string, validate: boolean = false) => {
		validate && validateSingle(fields.pricePerUnit, pricePerUnit, 'pricePerUnit');

		setFormState({ ...formState, pricePerUnit });
	};

	const onExpiryPresetChange = (value: string) => {
		setFormState({
			...formState,
			expiryPreset: value,
			expires: null,
		});

		if (errors?.expires) errors.expires = null;
	};

	const onExpiryDateChange = (date: Moment) => {
		const newDate = setToAtLeastTenMinFromNow(date);

		validateSingle(fields.expires, newDate.toDate(), 'expires');

		setFormState({
			...formState,
			expires: newDate ? newDate.toDate() : null,
		});
	};

	const animationHeights = getFormStepPromptHeights(order.quotes.length, previousStep);
	const expiryTimeIsAfterOrderExpiry = moment(order.expiryTime).isBefore(formState.expires);

	return (
		<Prompt
			onClosePrompt={() => setWillClose(true)}
			animateFromHeight={animationHeights.from}
			animateToHeight={willClose ? 0 : animationHeights.to}
			headerText={`${isCreateAction ? 'Place' : 'Edit'} ${quoteType}`}
			onAnimationComplete={() => {
				if (willClose) {
					setWillClose(false);
					setIsQuoteFormOpen(false);
				}
			}}
			body={
				<VStack fullWidth gap="md" align="flex-start">
					<HStack fullWidth justify="space-between" wrap="nowrap">
						<Quantity
							value={quantity}
							onChange={onQuantityChange}
							error={errors?.quantity && errors.quantity}
							maxValue={order.cost.remainingQuantity}
						/>
						<PricePerUnit
							value={pricePerUnit ?? ''}
							onChange={onPricePerUnitChange}
							error={errors?.pricePerUnit && errors.pricePerUnit}
						/>
						<TotalPrice quantity={quantity} pricePerUnit={pricePerUnit} currency={order.cost.currency} />
					</HStack>
					<VStack fullWidth gap="sm">
						<ExpiresGroup
							onExpiryDateChange={onExpiryDateChange}
							onExpiryPresetChange={onExpiryPresetChange}
							deliveryDate={order.product.deliveryDate ? new Date(order.product.deliveryDate) : null}
							expires={formState.expires}
							expiryPreset={formState.expiryPreset}
							dropCalendarUpward={true}
							errors={{ ...errors }}
							lastValidDate={moment(order.expiryTime)}
							type={quoteType}
						/>
						{expiryTimeIsAfterOrderExpiry && (
							<Text color="secondaryText" size="xs">
								Note: You have set your quote to expire after the order expires. It will be set to
								expire when the order does.
							</Text>
						)}
					</VStack>
				</VStack>
			}
			footer={
				<HStack fullWidth gap="md" justify="flex-end">
					<Button
						variant="secondary"
						text="Back"
						onClick={() => setWillClose(true)}
						leftIcon={<IconChevronLeft />}
					/>
					<Button
						text="Next"
						variant="primary"
						loadingText="Submitting"
						onClick={validateAndProgress}
						rightIcon={<IconChevronRight />}
					/>
				</HStack>
			}
		/>
	);
};

export default FormStep;
