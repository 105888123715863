import { getValueFromLocalStorage, removeFromLocalStorage } from '@montel/montelpro-shared-components/utils';
import isCompanyIdValid from '../../../utils/activeCompanyUtils/isCompanyIdValid';
import { IMarketPlaceRole } from '../userTypes';

export const ACTIVE_COMPANY_AND_USER_ID = 'MontelProActiveCompany';

const getActiveCompanyId = (userId: string, marketPlaceRoles: IMarketPlaceRole[]): number | null => {
	const companyIdFromLocalStorage = userId ? getActiveCompanyIdFromLocalStorage(userId) : null;
	const isCompanyIdFromLocalStorageValid =
		companyIdFromLocalStorage && isCompanyIdValid(companyIdFromLocalStorage, marketPlaceRoles);

	if (isCompanyIdFromLocalStorageValid === false) {
		removeFromLocalStorage(ACTIVE_COMPANY_AND_USER_ID);
	}

	const firstCompanyFromToken = marketPlaceRoles.length ? marketPlaceRoles[0].companyId : null;
	return isCompanyIdFromLocalStorageValid ? companyIdFromLocalStorage : firstCompanyFromToken;
};

const getActiveCompanyIdFromLocalStorage = (userId: string): number | null => {
	const activeCompanyAndUserId = getValueFromLocalStorage(ACTIVE_COMPANY_AND_USER_ID, null);
	if (activeCompanyAndUserId === null) {
		return null;
	}
	return userId === activeCompanyAndUserId.userId ? activeCompanyAndUserId.companyId : null;
};

export default getActiveCompanyId;
