import { FunctionComponent } from 'react';
import { Button, IconChevronRight } from '@montel/montelpro-shared-components-v2';
import { CompanyRegistrationState } from '../types';
import isStepValid from './isStepValid';

const NextButton: FunctionComponent<{
	setIsNextPressed: React.Dispatch<boolean>;
	activeStep: number;
	goToNext: () => void;
	state: CompanyRegistrationState;
}> = ({ setIsNextPressed, activeStep, state, goToNext }) => {
	return (
		<Button
			text={'Next'}
			onClick={() => {
				setIsNextPressed(true);
				if (isStepValid(activeStep, state)) {
					setIsNextPressed(false);
					goToNext();
				}
			}}
			rightIcon={<IconChevronRight />}
		/>
	);
};

export default NextButton;
