import { FunctionComponent } from 'react';
import { HStack, IconDelete, IconEdit } from '@montel/montelpro-shared-components-v2';
import { Separator } from '../style';
import { StyledTooltip } from './ManageCell.style';

interface ManageCellProps {
	onEdit: () => void;
	onDelete: () => void;
	className?: string;
	[otherProps: string]: any;
}

const ManageCell: FunctionComponent<ManageCellProps> = ({ onEdit, onDelete, className, ...otherProps }) => {
	return (
		<HStack gap="0px" justify="flex-start" align="center" className={className} {...otherProps}>
			<StyledTooltip component={<IconEdit onClick={onEdit} />}>Edit</StyledTooltip>
			<Separator />
			<StyledTooltip component={<IconDelete onClick={onDelete} />}>Delete</StyledTooltip>
		</HStack>
	);
};

export default ManageCell;
