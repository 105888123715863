import { getTechnologyOptionsWithIcon } from 'common/options/technologyOptionsWithIcon';
import { CheckboxOption, OrderOption } from 'components/Filter/types';
import { getRegionOptions } from 'components/NewOrder/options';
import getCurrencyOptions from './getCurrencyOptions';
import getLabelOptions from './getLabelOptions';

function getOrderFilterOptions(productionYears: number[]): OrderOption[] {
	return [
		{
			category: 'Tech',
			type: 'Checkbox',
			checkboxOptions: getTechnologyOptionsWithIcon(),
		},
		{
			category: 'Region',
			type: 'Checkbox',
			checkboxOptions: getRegionOptions(),
		},
		{
			category: 'Label',
			type: 'Checkbox',
			checkboxOptions: getLabelOptions(),
		},
		{
			category: 'Currency',
			type: 'Checkbox',
			checkboxOptions: getCurrencyOptions(),
		},
		{
			category: 'ProductionYear',
			type: 'Checkbox',
			checkboxOptions: getProductionYearsOptions(productionYears),
		},
		// TODO: Probably needs to be reimplemented one API is updated
		/*		{
			category: 'Status',
			type: 'Checkbox',
			checkboxOptions: getOrderStatusOptions(),
		},*/
	];
}

const getProductionYearsOptions = (productionYears: number[]): CheckboxOption[] => {
	if (!productionYears.length) {
		return [];
	}

	return productionYears.sort().map((year) => {
		return { label: year.toString(), value: year };
	});
};

export default getOrderFilterOptions;
