/* eslint-disable react/no-unescaped-entities */
import { FunctionComponent } from 'react';
import { H1, H2, Link, Text, VStack } from '@montel/montelpro-shared-components-v2';
import useSetPageTitle from 'hooks/useSetPageTitle';
import Header from '../../components/Header';
import EmptyPageContainer from '../../primitives/EmptyPageContainer';
import PageContainer from '../../primitives/PageContainer';
import { Container } from './TermsAndConditions.style';

const TermsAndConditions: FunctionComponent = () => {
	useSetPageTitle('Terms And Conditions');
	const subheaderProps = {
		size: 'sm',
		weight: 'semiBold',
		color: 'secondaryText',
	};

	return (
		<EmptyPageContainer>
			<Header showCompanyLinks={false} showProfile={false} />
			<PageContainer>
				<VStack fullWidth wrap="nowrap" gap="xxl">
					<H1>Terms of Use for Montel Marketplace</H1>
					<Container>
						<div className="content">
							<H2>Montel AS</H2>
							<Text>
								These Terms of Use apply to the trading platform Montel Marketplace (the "Marketplace")
								which is made available by Montel AS. The Terms of Use are effective as of the date you
								(the "User") click to accept the Terms of Use and shall govern your access to and use of
								the Marketplace.
							</Text>
							<Text as="h3" {...subheaderProps}>
								1. Definitions
							</Text>
							<Text>The following definitions apply to these Terms of Use:</Text>
							<Text>
								"Authorised User" shall mean any physical person that has access to the Marketplace,
								regardless of actual use, and that has been assigned a role and/or granted permissions
								by a Member of the Marketplace in connection with the Member's Marketplace account.
							</Text>
							<Text>
								"Company Credentials" shall mean the company details required by Montel from time to
								time, including, but not limited to, information reasonably required to identify the
								Member and assess financial risks associated with the Member using the Marketplace.
							</Text>
							<Text>
								"Guarantees of Origin" or "GOs" shall mean the tradable EU certificates which form the
								recognised method of proving that a given amount of electricity was generated by a
								renewable source.
							</Text>
							<Text>
								"Intellectual Property Rights" shall mean any intellectual property rights, whether
								registered, applied or non-registered, including but not limited to patents, designs,
								trademarks, service marks, copyright, copyright-related rights (neighbouring rights) and
								all similar rights, including those subsisting in inventions, performances, computer
								programs, trade secrets, know-how, business names, goodwill, and the layout, style and
								presentation of goods and services.
							</Text>
							<Text>
								"Marketplace" shall mean the online platform made available by Montel which relates to
								and facilitates the trade of Guarantees of Origin, as well as any products and services
								offered through such platform.
							</Text>
							<Text>
								"Member" shall mean a legal entity with a registered account to access and use the
								Marketplace and the services and products provided therein.
							</Text>
							<Text>
								"Montel" shall mean Montel AS, with business address Holbergs gate 1, 0166 Oslo, Norway.
							</Text>
							<Text>
								"Privacy Policy" shall mean Montel's privacy policy as made available on the Website.
							</Text>
							<Text>
								"Terms of Use" shall mean these terms of use as amended by Montel from time to time, as
								well as any appendices hereto and any other terms referred to herein.
							</Text>
							<Text>
								"User" shall mean any physical person that has access to the Marketplace, regardless of
								actual use and whether or not the person has been assigned a role and/or granted
								permissions by a Member.
							</Text>
							<Text>
								"Website" shall mean the site/domain (currently{' '}
								<Link href="https://montelgroup.com/services/montel-marketplace" external>
									https://montelgroup.com/services/montel-marketplace
								</Link>
								) on which Montel makes available the Marketplace, and where further information about
								the Marketplace and the products and services offered therein may be found.
							</Text>
							<Text as="h3" {...subheaderProps}>
								2. The Marketplace
							</Text>
							<Text>
								The Marketplace is made available to the Member through the Website, subject to these
								Terms of Use. The Marketplace is further described in the user manual available{' '}
								<Link
									href="https://info.montelgroup.com/hubfs/Montel%20Marketplace%20Files/Marketplace%20user%20manual%202023%20updated.pdf?_gl=1*qfd4br*_gcl_aw*R0NMLjE2OTI2ODc4ODguQ2owS0NRand1WkduQmhEMUFSSXNBQ3hiQVZqZGQxak5YTlM3SkZYQ3F1akVocXhJcDM5SURDUzA2clpsZ2c4TU52c0thb3dPdkEtbVMxMGFBZ2NhRUFMd193Y0I.*_gcl_au*MjAzODg3OTI0Mi4xNjkzMjkzMDg3*_ga*MTc3MTg2MjkxLjE2ODQ4MjY1MzE.*_ga_BD6V9B2Y7Z*MTY5NzgwMzkxMC45MS4xLjE2OTc4MDQyNTYuMC4wLjA.*_ga_3GYHW3KP9J*MTY5NzgwMzkxMC45MS4xLjE2OTc4MDQyNTYuMC4wLjA.*_ga_9MVDDS9KQE*MTY5NzgwMzkxMC45MS4xLjE2OTc4MDQyNTYuNjAuMC4w"
									external
								>
									here
								</Link>
								.
							</Text>
							<Text>
								It is the responsibility of the Member to read and understand relevant instructions and
								information relating to the Marketplace which are made available on the Website or as
								otherwise communicated to the Member.
							</Text>
							<Text>
								Montel will use reasonable efforts to maintain the availability of the Marketplace,
								however Montel does not guarantee that it will be available, accurate, complete,
								current, uninterrupted, error free or free of harmful components. Montel reserves the
								right to change or discontinue the Marketplace and/or any features or functionality
								therein at any time without prior notice. Montel waives any obligation or liability
								accruing from such changes and discontinuances. Montel will endeavour to notify the
								Member by email in case of any material changes to the Marketplace which will have a
								significant negative impact on the Member.
							</Text>
							<Text>
								Unless otherwise agreed in writing, Montel does not undertake any obligation to provide
								maintenance or support, or to update or upgrade the Marketplace.
							</Text>

							<Text as="h3" {...subheaderProps}>
								3. User rights and responsibility for Authorised Users
							</Text>
							<Text>
								The Member shall procure that its Authorised Users comply with these Terms of Use, and
								the Member is responsible to Montel for any breach thereof by any Authorised User.
							</Text>
							<Text>
								The Member is solely responsible for managing the roles and responsibilities of the
								Authorised Users and all other aspects of its Marketplace account, including but not
								limited to maintaining a correct and up-to-date list of counterparties and Authorised
								Users. The rights of an Authorised User are subject to the roles assigned and
								permissions granted by a Member.
							</Text>
							<Text>
								To the extent a User accesses and/or uses the Marketplace other than as an Authorised
								User, these Terms of Use shall apply accordingly to such User, and for such purpose any
								references to "Member" set out herein shall be read as "User" (unless the context
								clearly implies otherwise). Notwithstanding the foregoing, the User acknowledges and
								accepts that certain parts and functionalities of the Marketplace are restricted to
								Authorised Users, and that the access and use of the Marketplace by a User who is not an
								Authorised User will be restricted. Such User may inter alia not participate in the
								trading of GOs.
							</Text>
							<Text as="h3" {...subheaderProps}>
								4. Fees
							</Text>
							<Text>
								Access to and use of the Marketplace is subject to the payment of the service fees (if
								any) as presented on the Website or as otherwise communicated prior to the registration
								of a Marketplace account.
							</Text>
							<Text>
								If the Marketplace is provided free of charge, Montel reserves the right to introduce a
								fee for the use of the Marketplace, and if the Marketplace is subject to payment, Montel
								reserves the right to make changes to the service fees at any time, subject to Montel's
								prior written notice of such to the Member.
							</Text>
							<Text>
								Montel provides the platform which facilitates the trade of GOs, but does not receive or
								in any way handle payments made between Members in connection with the trade of GOs on
								the Marketplace.
							</Text>
							<Text as="h3" {...subheaderProps}>
								5. Right to use the Marketplace
							</Text>
							<Text>
								Subject to these Terms of Use, the Member is granted a non-exclusive, non-transferable,
								non-assignable, limited right to access and use the Marketplace.
							</Text>
							<Text>
								To access and use the Marketplace the Member is required to create a Marketplace account
								by following the instructions on the Website and/or any other instructions provided by
								Montel. The Member shall provide correct and up-to-date information and any
								documentation requested by Montel and shall safeguard its account and log-in details.
								The Member shall provide and maintain complete and accurate Company Credentials at all
								times.
							</Text>
							<Text>
								The use of the Marketplace or parts thereof may be subject to additional terms and
								conditions, including payment of fees, discounts, limitation of number of Authorised
								Users, or other usage limitations. The Member will be informed of any such additional
								terms and conditions on the Marketplace upon registering an account, or before accessing
								functionality, features or content that are subject to such additional terms and
								conditions. Additional terms and conditions may also be communicated by email to the
								Member.
							</Text>
							<Text>
								Montel reserves the right to, without any obligation or liability accruing therefrom,
								immediately exclude the Member or any Authorised User from accessing the Marketplace if
								Montel is unable to verify the Company Credentials, or if Montel reasonably suspects any
								form of misuse or abuse of the Marketplace, any violation of applicable laws and
								regulations or any breach of these Terms of Use or other applicable terms and
								conditions.
							</Text>
							<Text as="h3" {...subheaderProps}>
								6. Montel's messaging service
							</Text>
							<Text>
								Montel provides a messaging service within the Marketplace which allows Members to send
								and receive messages relating to active buy and sell interests. The messaging service is
								intended for open and non-committing inquiries and dialogue between Members of the
								Marketplace.
							</Text>
							<Text>
								For this reason and for the convenience of the Members, all Members acknowledge and
								agree that messages exchanged through the messaging service shall not be considered an
								initiated trade or a final agreement of trade unless otherwise is explicitly agreed by
								the parties.
							</Text>
							<Text>
								Further, all Members acknowledge and agree that Montel is merely a facilitator of trades
								through the Marketplace, and that no legal obligation or liability relating to specific
								trades shall accrue from Montel engaging in any form of communication with a Member
								and/or an Authorised User.
							</Text>
							<Text as="h3" {...subheaderProps}>
								7. Visibility of memberships and activity
							</Text>
							<Text>
								Membership to the Marketplace will be visible to all other Members and its Authorised
								Users.
							</Text>
							<Text>
								Montel will not publish the identities of Members or specific Authorised Users in
								connection with individual buy and sell interests or matched interests on the
								Marketplace. However, each party to any matched interests will be made aware of the
								other party's identity and will be put in contact with each other in order to enable the
								parties to enter into final agreements of trade.
							</Text>
							<Text as="h3" {...subheaderProps}>
								8. Privacy and data
							</Text>
							<Text>
								Montel will, as data controller, collect and process personal data about the Users in
								connection with the provision of the Marketplace. Montel will at all times treat such
								personal data in accordance with its Privacy Policy, available{' '}
								<Link href="https://www.montelnews.com/privacy" external>
									here
								</Link>
								.
							</Text>
							<Text>
								The Member is responsible for ensuring the legality, reliability, integrity, accuracy
								and quality of all data introduced into the Marketplace by such Member or any of its
								Authorised Users, including for ensuring that the use of such data does not infringe any
								third party right.
							</Text>
							<Text>
								Montel may use anonymised data (price, volume, etc.) from buying and selling interests,
								including matched interests and initiated trades, to develop market data products.
								Montel may transfer anonymised data to third parties at its sole discretion.
							</Text>
							<Text as="h3" {...subheaderProps}>
								9. Intellectual Property Rights
							</Text>
							<Text>
								Nothing in these Terms of Use shall be construed to transfer or assign any Intellectual
								Property Rights between the parties. All right, title, and interest in and to the
								Marketplace, the content therein, and all related Intellectual Property Rights, are and
								shall remain the exclusive property of Montel and/or its licensors. The Member
								acknowledges that it is only granted a limited right to use the Marketplace as described
								in these Terms of Use.
							</Text>
							<Text as="h3" {...subheaderProps}>
								10. Restrictions of Use
							</Text>
							<Text>
								The Marketplace may be used solely for the purposes permitted by these Terms of Use, and
								applicable laws and regulations. The Member shall not:
							</Text>
							<ol type="a">
								<li>
									attempt to copy, adapt, amend, revise, compile or decompile, reverse engineer,
									modify, or create derivative works based on, the Marketplace or any part thereof,
									provided however that this point (a) shall not prevent the Member from utilising the
									functionality in the Marketplace in accordance with its intended use;
								</li>
								<li>
									disclose or distribute, or allow disclosure or distribution of, the Marketplace or
									any part thereof to any third party, including without limitation by uploading any
									information accessed through the Marketplace to the internet and/or an intranet,
									without Montel's explicit prior written consent;
								</li>
								<li>
									provide services or products to third parties based on the Marketplace or any part
									thereof;
								</li>
								<li>allow third parties to use or access the Marketplace or any part thereof;</li>
								<li>
									alter or remove any copyright and other proprietary rights notices from the
									Marketplace or any information accessed through the Marketplace;
								</li>
								<li>
									change the content, context or original language of any information accessed through
									the Marketplace;
								</li>
								<li>
									introduce or permit the introduction of any computer virus, harmful code or similar
									into the Marketplace; and/or
								</li>
								<li>
									access all or any part of the Marketplace in order to build a product or service
									which competes with the Marketplace.
								</li>
							</ol>
							<Text as="h3" {...subheaderProps}>
								11. Disclaimers and limitation on liability
							</Text>
							<Text>
								The Member acknowledges and accepts that the Marketplace is provided “as is". Montel
								(which for the purpose of this article 11 includes, but is not limited to, affiliated
								companies and their contractors, officers, directors, licensors, agents, subcontractors
								and employees) makes no representations or warranties of any kind, whether express,
								implied, statutory or otherwise, including but not limited to warranty of
								merchantability or fitness for a particular purpose.
							</Text>
							<Text>
								Use of the Marketplace, including for the avoidance of doubt the Member's use of and/or
								reliance on information accessed or generated through the use of the Marketplace, is
								solely at the risk of such Member, and the Member is solely responsible for any damage
								resulting therefrom. Montel is not responsible for the correctness or completeness of
								any such information, including for the avoidance of doubt any market data products.
							</Text>
							<Text>
								The Member hereby waives any claim against Montel arising from or in connection with the
								Member's access to or use of the Marketplace, whether arising under law, contract,
								warranty, indemnification, tort or otherwise, including, without limitation, incidental
								and consequential damages, loss of profit or business opportunities, or damages
								resulting from loss of data or loss of access to the Marketplace. Subject to the
								foregoing and to this clause 11, the maximum cumulative liability of Montel for any
								liability arising out of or related to these Terms of Use and the Marketplace shall in
								any event be limited to NOK 5,000.
							</Text>
							<Text>
								The Marketplace is not intended for, nor does it allow the facilitation of trading in
								financial instruments as defined in Directive 2014/65/EU (MiFID II) Annex I.
							</Text>
							<Text>
								The Member understands that laws regarding investments, hereunder in GOs, vary
								throughout the world. It is the Member’s sole responsibility to ensure that it fully
								complies with any applicable law, regulation, or directive with regards to the use of
								the Marketplace.
							</Text>
							<Text>
								Montel is not a licensed financial advisor, investment firm or registered broker-dealer,
								and is not under supervision by any financial supervisory authority. No information
								provided by Montel is intended as securities brokerage, investment, tax, accounting, or
								legal advice, or as an endorsement, recommendation or advice tailored to the Member’s
								particular situation.
							</Text>
							<Text>
								Montel does not provide services with regards to the final conclusion of contracts to
								buy and/or sell, clearing or settlement, with the exception of the provision of an
								automated email with a suggested trade contract related to matched interests on the
								Marketplace. Montel will seek to keep such standard contracts updated with well-known
								industry standards. However, Montel assumes no responsibility with regards to such
								standard contracts, including but not limited to their accuracy, fit-for-purpose,
								completeness, or lawfulness.
							</Text>
							<Text>
								The risk of trading on the Marketplace can be substantial and such trading is not
								suitable for all investors. The Member must consider all relevant risk factors,
								including its financial situation, before trading. The Member assumes the risk of any
								and all financial investments it makes. In no event shall Montel be liable to the Member
								or any third party or anyone else for any decision made or action taken by the Member in
								reliance on content from the Marketplace or the Marketplace itself.
							</Text>
							<Text>
								Montel does not verify or warrant that any Member of the Marketplace is financially able
								or willing to complete trades initiated on the Marketplace, nor that it has any
								necessary licenses to perform such trades.
							</Text>
							<Text>
								Montel reserves the right to cancel a transaction in the event of a significant
								deviation in price or volume. Montel does not report initiated trades to any financial
								supervisory authority, registers, or similar entities.
							</Text>
							<Text as="h3" {...subheaderProps}>
								12. Indemnity
							</Text>
							<Text>
								The Member agrees to indemnify, defend, and hold harmless Montel (including, but not
								limited to, affiliated companies and their contractors, officers, directors, licensors,
								agents, subcontractors and employees) from all claims, liabilities and expenses
								(including reasonable attorney's fees) that arise from the use of the Marketplace in
								breach of these Terms of Use or applicable law or regulation.
							</Text>
							<Text as="h3" {...subheaderProps}>
								13. Suspension and termination
							</Text>
							<Text>
								These Terms of Use will apply for as long as the Member or any of its Authorised Users
								use the Marketplace.
							</Text>
							<Text>
								Montel may terminate the access to the Marketplace (in full or in part) with immediate
								effect if Montel reasonably believes that the Member or any of its Authorised Users are
								in breach of these Terms of Use, or use the Marketplace in a way that has a significant
								detrimental effect to Montel. Montel may also terminate such access immediately if the
								Member fails to pay any relevant fees within 30 days after due date.
							</Text>
							<Text>
								Upon termination of the Terms of Use, the license granted hereunder will immediately
								terminate and all rights of the Member and any Authorised User to access and use the
								Marketplace shall immediately cease.
							</Text>
							<Text>
								Provisions in these Terms of Use which by their nature are intended to remain in effect
								after the expiry or termination of these Terms of Use, including but not limited to
								clause 9 Intellectual Property Rights, clause 11 Disclaimers and limitations of
								liability, clause 12 Indemnity and clause 15 Confidentiality, shall survive expiry or
								termination.
							</Text>
							<Text as="h3" {...subheaderProps}>
								14. Business conduct
							</Text>
							<Text>
								The Member is expected to comply with established practices of good business conduct
								when expressing buying and selling interests and concluding final agreements of trade.
								If Montel is notified, or otherwise deems that the Member does not comply with Montel’s
								understanding of such business conduct, Montel may terminate or suspend the Member’s or
								any Authorised User's access to the Marketplace.
							</Text>
							<Text>
								Each party represents and warrants that neither the party nor, to the knowledge of the
								party, any director, officer, agent, employee, affiliate of or person acting on behalf
								of the party is engaged in any activity or conduct which would violate any applicable
								anti-bribery-, money laundering-, sanction- or anti-corruption law or regulation and
								which activity or conduct would be material in the context of the services
								provided/trades performed under the Terms of Use. In addition, each party represents and
								warrants that it has instituted and maintains policies and procedures designed to
								prevent any sanction, bribery, corruption and money laundering violations by the party,
								the group of companies it belongs to, persons associated to it, its responsible persons,
								its beneficial owners, and ultimate beneficial owners. Responsible persons referred to
								in this clause are members of the board or a similar, highest decision-making body of
								the company as well as its senior executives.
							</Text>
							<Text as="h3" {...subheaderProps}>
								15. Confidentiality
							</Text>
							<Text>
								Each party shall treat all non-public information exchanged or received in connection
								with the Marketplace and these Terms of Use as confidential and shall not disclose any
								such information to any third party (other than those of its employees, affiliates,
								subsidiaries, subcontractors, suppliers or professional advisers who are under
								confidentiality obligations reflecting the principles hereunder) without the prior
								written consent of the other party except to the extent required by applicable law. In
								particular, the Member acknowledges and accepts that any information made available to
								the Member through the Marketplace is confidential information of Montel and/or its
								licensors.
							</Text>
							<Text>
								Each party agrees to take reasonable precautions (no less rigorous than the receiving
								party takes with respect to its own comparable confidential information) to prevent
								unauthorised disclosure of the other party's confidential information.
							</Text>
							<Text as="h3" {...subheaderProps}>
								16. Assignment
							</Text>
							<Text>
								Montel is entitled to assign its rights and obligations under the Terms of Use to a
								third party at its own discretion. The Member may not assign its account, individual
								User accounts, or any rights or obligations under the Terms of Use to a third party
								without the prior written consent of Montel.
							</Text>
							<Text as="h3" {...subheaderProps}>
								17. Force majeure
							</Text>
							<Text>
								If either party is prevented from or delayed in performing its obligations under the
								Terms of Use or from carrying on its business due to circumstances outside its control,
								which under Norwegian law would be classified as force majeure, the other party shall be
								notified of this as soon as possible and the affected party's obligations shall be
								suspended to the extent the circumstances are relevant and for as long as such relevant
								circumstances last. The corresponding obligation of the other party shall be suspended
								for the same period.
							</Text>
							<Text as="h3" {...subheaderProps}>
								18. Changes to the Terms of Use
							</Text>
							<Text>
								Montel reserves the right to amend, remove, or add to these Terms of Use at any time.
								Montel shall provide written notification to the Member of any changes that will
								negatively impact the Member in a significant manner. Following such notice, the Member
								shall be bound by the amended Terms of Use upon its continued use of the Marketplace.
							</Text>
							<Text as="h3" {...subheaderProps}>
								19. Governing laws and jurisdiction
							</Text>
							<Text>
								These Terms of Use shall be governed by and construed in accordance with the laws of
								Norway, without regard to conflict of law principles. Norwegian law shall apply
								regardless of whether the Member resides or transacts business with Montel anywhere else
								in the world.
							</Text>
							<Text>
								Any dispute, controversy or claim arising out of or in connection with the Terms of Use,
								and which is not resolved amicably, shall be finally settled by the courts of Norway,
								and the venue shall be Oslo District Court.
							</Text>
						</div>
					</Container>
				</VStack>
			</PageContainer>
		</EmptyPageContainer>
	);
};
export default TermsAndConditions;
