import { SelectOptionType } from '@montel/montelpro-shared-components';

export const getMarketRoleOptions = (): SelectOptionType[] => [
	{ label: 'Corporate', value: 1 },
	{ label: 'Portfolio management', value: 2 },
	{ label: 'Trading', value: 3 },
	{ label: 'Power generator', value: 4 },
	{ label: 'Broker', value: 5 },
	{ label: 'Bank', value: 6 },
	{ label: 'Investment', value: 7 },
	{ label: 'Distribution', value: 8 },
	{ label: 'Other', value: 9 },
];
