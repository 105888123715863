import { useState } from 'react';
import { getValueFromLocalStorage, saveToLocalStorage } from '@montel/montelpro-shared-components/utils';
import { toCamelCase } from 'utils/string-extension';

export interface CustomFilter {
	(item: any, filters: any): any;
}

const useFilter = ({
	defaultOptions,
	customFilter,
	localStorageKey,
}: {
	defaultOptions: any;
	customFilter?: CustomFilter;
	localStorageKey?: string;
}): {
	filterChoices: any;
	filterItems: (items: any, filters: any) => any;
	handleFilterClick: (value: number, type: string) => void;
} => {
	const [filterChoices, setFilter] = useState<any>(
		localStorageKey ? getValueFromLocalStorage(localStorageKey, defaultOptions) : defaultOptions,
	);

	const getNewFilterChoices = (value: number, type: string) => {
		const filterChoicesIncludesValue = filterChoices[type].includes(value);

		if (filterChoicesIncludesValue) {
			const filterValuesWithoutDeleted = filterChoices[type]?.filter(
				(filterValue: number) => filterValue !== value,
			);
			return {
				...filterChoices,
				[type]: filterValuesWithoutDeleted ? [...filterValuesWithoutDeleted] : undefined,
			};
		}

		return {
			...filterChoices,
			[type]: filterChoices[type] ? [...filterChoices[type].concat(value)] : undefined,
		};
	};

	const handleFilterClick = (value: number, type: string) => {
		if (!type) return;

		const newFilterChoices = getNewFilterChoices(value, type);

		setFilter(newFilterChoices);

		if (localStorageKey) {
			saveToLocalStorage(newFilterChoices, localStorageKey);
		}
	};

	const filter = (item: any, filters: any, key: string) => {
		if (!filters[key].length) return true;

		const selectedFilters = filters[key];
		const itemValue = item[toCamelCase(key)];

		return Array.isArray(itemValue)
			? itemValue.some((value: any) => selectedFilters.includes(value))
			: selectedFilters.includes(itemValue);
	};

	const filterItems = (items: any, filters: any) => {
		if (customFilter) return customFilter(items, filters);

		const filterKeys = Object.keys(filters);
		const filteredByChoices = items.filter((item: any) => {
			return filterKeys.every((key) => filter(item, filters, key));
		});

		return filteredByChoices;
	};

	return { filterChoices, filterItems, handleFilterClick };
};

export default useFilter;
