import React, { FunctionComponent, useEffect, useState } from 'react';
import { RequestStatus } from '@montel/montelpro-shared-components/enums';
import {
	Button,
	Checkbox,
	HStack,
	Modal,
	ModalBody,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	RadioButtonList,
	VStack,
} from '@montel/montelpro-shared-components-v2';
import { Alert } from 'common/interfaces/Alert';
import { getProductionPeriodOptions, getRegionOptions } from 'components/NewOrder/options';
import PeriodTypeEnum from '../../../common/enums/PeriodTypeEnum';
import getOptionByValue from '../../../utils/getOptionByValue';
import { alertTypeOptions } from './options/alertTypeOptions';
import { productionPeriodTypeOptions } from './options/productionPeriodTypeOptions';
import { technologyOptions, techOptionNuclear } from './options/technologyOptions';
import { getProductionPeriodValue } from './utils/getProductionPeriodValue';
import { getRegionTooltip } from './utils/getRegionTooltip';
import defaultState from './defaultState';
import { StyledNuclearSelector, StyledPeriodSelector, StyledRegionSelector } from './style';
import { TAlertEditState } from './types';
import useSaveAlert from './useSaveAlert';

interface EditAlertModalProps {
	isOpen: boolean;
	onClose: () => void;
	alert: Alert | null;
	onSaveAlert: (alert: Alert) => void;
}

const EditAlertModal: FunctionComponent<EditAlertModalProps> = ({ isOpen, onClose, alert, onSaveAlert }) => {
	const onAlertPropChange = () => {
		const newState = { ...defaultState };
		if (!alert) return newState;

		const { productionQuarter, productionMonth } = alert;
		return {
			...newState,
			...alert,
			productionPeriod:
				(productionQuarter !== null ? PeriodTypeEnum.QUARTER : null) ||
				(productionMonth !== null ? PeriodTypeEnum.MONTH : null) ||
				PeriodTypeEnum.YEAR,
		};
	};
	const [state, setState] = useState<TAlertEditState>(onAlertPropChange);
	const { onSubmit, isSubmitPressed, setIsSubmitPressed, status, errors } = useSaveAlert({
		alert,
		state,
		onSaveAlert,
		onClose,
	});

	useEffect(() => {
		if (isOpen) {
			setState(onAlertPropChange());
			setIsSubmitPressed(false);
		}
	}, [isOpen]);

	const regionsOptions = getRegionOptions();
	const productionPeriodOptions = getProductionPeriodOptions(state.productionPeriod);
	const productionPeriodValue =
		getOptionByValue(
			productionPeriodOptions,
			getProductionPeriodValue(state.productionMonth, state.productionQuarter, state.productionYear),
		) || '';

	const onProductionPeriodChange = (value: PeriodTypeEnum | null) => {
		const newState = {
			...state,
			productionPeriod: value,
			productionMonth: null,
			productionQuarter: null,
			productionYear: null,
		};
		if (value === PeriodTypeEnum.MONTH) {
			newState.includeUnderlyingContracts = false;
		}
		setState(newState);
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent size={6}>
				<ModalHeader>{alert ? 'Edit alert' : 'New alert'}</ModalHeader>
				<ModalBody>
					<VStack align="stretch" gap="lg">
						<RadioButtonList
							required
							label="Type"
							options={alertTypeOptions}
							onChange={(value) => {
								setState({ ...state, type: value });
							}}
							value={state.type}
						/>
						<HStack wrap="nowrap" align="flex-end" justify="flex-start" gap="lg">
							<RadioButtonList
								required
								label="Technologies"
								options={technologyOptions}
								onChange={(value) => {
									setState({ ...state, technology: value });
								}}
								value={state.technology}
							/>

							<StyledNuclearSelector
								required
								label="Technologies"
								hideLabel
								options={techOptionNuclear}
								onChange={(value: any) => {
									setState({ ...state, technology: value });
								}}
								value={state.technology}
							/>
						</HStack>

						<StyledRegionSelector
							required
							label="Region"
							tooltip={getRegionTooltip(state.region)}
							options={regionsOptions}
							onChange={({ value }: { value: any }) => {
								setState({ ...state, region: value });
							}}
							value={getOptionByValue(regionsOptions, state.region) || ''}
							errorMessage={isSubmitPressed && errors.region}
						/>
						<HStack fullWidth wrap="nowrap" align="flex-start" gap="lg">
							<RadioButtonList
								required
								label="Production period"
								tooltip="Select production period"
								options={productionPeriodTypeOptions}
								onChange={onProductionPeriodChange}
								value={state.productionPeriod}
							/>
							<StyledPeriodSelector
								required
								hideLabel
								disabled={!state.productionPeriod}
								label="Select production period"
								placeholder="Select period"
								options={productionPeriodOptions}
								onChange={({ month, quarter, year }: any) => {
									setState({
										...state,
										productionMonth: month || null,
										productionQuarter: quarter || null,
										productionYear: year,
									});
								}}
								value={productionPeriodValue}
								errorMessage={isSubmitPressed && errors.productionPeriod}
							/>
						</HStack>

						{state.productionPeriod !== PeriodTypeEnum.MONTH ? (
							<Checkbox
								label="Include all underlying contracts"
								checked={state.includeUnderlyingContracts}
								onChange={() => {
									setState({
										...state,
										includeUnderlyingContracts: !state.includeUnderlyingContracts,
									});
								}}
							/>
						) : (
							<></>
						)}
					</VStack>
				</ModalBody>
				<ModalFooter>
					<Button text="Cancel" variant="secondary" onClick={onClose} />
					<Button
						text={alert ? 'Update alert' : 'Add alert'}
						isLoading={status === RequestStatus.LOADING}
						onClick={onSubmit}
					/>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default EditAlertModal;
