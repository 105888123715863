import { FunctionComponent } from 'react';
import { Center, Text } from '@montel/montelpro-shared-components-v2';

interface HeaderCellProps {
	text?: string;
}

const HeaderCell: FunctionComponent<HeaderCellProps> = ({ text = '' }) => {
	const headerTextProps = { color: 'secondaryText', size: 'xs' };
	return (
		<th>
			<Center>
				<Text {...headerTextProps}>{text}</Text>
			</Center>
		</th>
	);
};

export default HeaderCell;
