import { FunctionComponent } from 'react';
import { HStack } from '@montel/montelpro-shared-components-v2';
import useSetPageTitle from 'hooks/useSetPageTitle';
import { EndOfDayPriceTables } from 'components/EndOfDay';

const EndOfDay: FunctionComponent = () => {
	useSetPageTitle('End-of-Day prices');

	return (
		<HStack direction="column" fullWidth gap="md" wrap="nowrap">
			<EndOfDayPriceTables />
		</HStack>
	);
};

export default EndOfDay;
