import { FunctionComponent } from 'react';
import { Text, VStack } from '@montel/montelpro-shared-components-v2';
import { Label as LabelEnum } from 'common/enums/OrderEnums';
import { ProductEnum } from '../../../common/enums/ProductEnum';
import { labelNames } from '../../../common/enums/valueLookups/LabelLookup';
import { productNames } from '../../../common/enums/valueLookups/ProductLookup';
import { PublicCompanyInformation } from '../../../common/interfaces/Company';
import getTradingFrequencyOptions from '../../../common/options/getTradingFrequencyOptions';
import { HStackFullWidth, VStackFullWidth } from '../style';

export interface CompanyDetailsExtendedProps {
	companyInformation: PublicCompanyInformation;
}

const CompanyDetailsExtended: FunctionComponent<CompanyDetailsExtendedProps> = ({ companyInformation }) => {
	const {
		companyText,
		vat,
		subsidised,
		products,
		numberOfCertificatesPerYear,
		tradingFrequency,
		eecsRegistry,
		labels,
	} = companyInformation;
	const tradingFrequencyOptions = getTradingFrequencyOptions();

	const textCommonProps = {
		color: 'white',
		size: 'xs',
	};

	const labelCommonProps = {
		color: 'secondaryText',
		size: 'xs',
	};

	return (
		<VStackFullWidth gap="lg">
			<div>
				<Text {...labelCommonProps}>Description</Text>
				<Text {...textCommonProps}>{companyText}</Text>
			</div>
			<HStackFullWidth justify="space-between">
				<VStack gap="lg">
					<div>
						<Text {...labelCommonProps}>Products</Text>
						<div>
							{products.map((product) => (
								<Text key={product} {...textCommonProps}>
									{productNames[ProductEnum[product] as keyof typeof ProductEnum]}
								</Text>
							))}
						</div>
					</div>
					<div>
						<Text {...labelCommonProps}>Trading frequency</Text>
						<Text {...textCommonProps}>
							{tradingFrequencyOptions.find(({ value }) => value === tradingFrequency)?.label}
						</Text>
					</div>
				</VStack>
				<VStack gap="lg">
					<div>
						<Text {...labelCommonProps}>Certificates per year</Text>
						<Text {...textCommonProps}>{numberOfCertificatesPerYear}</Text>
					</div>
					<div>
						<Text {...labelCommonProps}>EECS registry</Text>
						<Text {...textCommonProps}>{eecsRegistry ? 'Yes' : 'No'}</Text>
					</div>
				</VStack>
				<VStack gap="lg">
					<div>
						<Text {...labelCommonProps}>VAT / Registration No.</Text>
						<Text {...textCommonProps}>{vat}</Text>
					</div>
					<div>
						<Text {...labelCommonProps}>Subsidised</Text>
						<Text {...textCommonProps}>{subsidised ? 'Yes' : 'No'}</Text>
					</div>
				</VStack>
			</HStackFullWidth>
			<div>
				<Text {...labelCommonProps}>Labels</Text>
				{labels.map((label) => (
					<Text key={label} {...textCommonProps}>
						{labelNames[LabelEnum[label] as keyof typeof LabelEnum]}
					</Text>
				))}
			</div>
		</VStackFullWidth>
	);
};

export default CompanyDetailsExtended;
