import { createContext, FunctionComponent, ReactNode, useEffect } from 'react';
import { useAuth } from '@montel/montelpro-auth';
import { useAppDispatch } from 'store/hooks';
import { setToken } from 'store/user/userSlice';
import { getUserInfo } from 'store/user/userThunks';
import getActiveCompanyId from 'store/user/utils/getActiveCompanyId';
import setDefaultAuthorizationHeader from 'utils/requestHeaders/setDefaultAuthorizationHeader';
import setDefaultCompanyIdHeader from 'utils/requestHeaders/setDefaultCompanyIdHeader';

const userContext = createContext<null>(null);

const UserProvider: FunctionComponent<{ children: ReactNode }> = ({ children }) => {
	const dispatch = useAppDispatch();
	const { accessToken } = useAuth();

	useEffect(() => {
		(async () => {
			setDefaultAuthorizationHeader(accessToken);
			dispatch(setToken(accessToken));
			const { payload: userInfo } = await dispatch(getUserInfo());
			if (!userInfo) return;

			const { id, marketPlaceRoles } = userInfo;
			if (!id || !marketPlaceRoles) return;

			setDefaultCompanyIdHeader(getActiveCompanyId(id, marketPlaceRoles));
		})();
	}, [accessToken]);

	return <userContext.Provider value={null}>{children}</userContext.Provider>;
};

export default UserProvider;
