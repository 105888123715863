import { FunctionComponent } from 'react';
import { HStack, IconStackPlus, Text, Tooltip } from '@montel/montelpro-shared-components-v2';
import { Alert } from 'common/interfaces/Alert';
import convertMonthToString from 'utils/convertMonthToString';
import convertQuarterToString from 'utils/convertQuarterToString';

const ProductionCell: FunctionComponent<{ row: Alert }> = ({ row }) => {
	return (
		<HStack align="center">
			<Text size="md">{getProductionPeriod(row.productionYear, row.productionQuarter, row.productionMonth)}</Text>
			{row.includeUnderlyingContracts ? (
				<Tooltip component={<IconStackPlus />}>All underlying contracts</Tooltip>
			) : (
				<></>
			)}
		</HStack>
	);
};

const getProductionPeriod = (year: number, quarter: number | null, month: number | null) =>
	`${year} ${quarter ? convertQuarterToString(quarter) : ''} ${month ? convertMonthToString(month) : ''}`;

export default ProductionCell;
