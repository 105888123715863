import { FunctionComponent } from 'react';
import { Box, IconButton, IconEdit, Text } from '@montel/montelpro-shared-components-v2';
import { styled } from 'styled-components';

interface ReviewBoxProps {
	onStepChange: (step: number) => void;
	isSubmitted?: boolean;
	children: React.ReactNode;
	gotoStep: number;
	header?: string;
	minHeight?: string;
}

const Edit = styled(IconButton)`
	border: none;
	position: absolute;
	top: 0.5rem;
	right: 0.5rem;

	&:hover {
		background-color: transparent;
		border: none;
	}
`;

const StyledReviewBox = styled(Box).attrs({ borderRadius: 'base', h: 'unset' })<{ $minHeight?: string }>`
	position: relative;
	min-height: ${({ $minHeight }) => $minHeight || '10rem'};
	padding-right: ${({ theme }) => theme.spacing.xl};
`;

const Header = styled(Text)`
	margin-bottom: 1rem;
	font-weight: ${({ theme }) => theme.typography.weight.semiBold};
`;

const ReviewBox: FunctionComponent<ReviewBoxProps> = ({
	children,
	onStepChange,
	isSubmitted,
	gotoStep,
	header,
	minHeight,
}) => {
	return (
		<StyledReviewBox $minHeight={minHeight}>
			{!isSubmitted && <Edit icon={<IconEdit />} onClick={() => onStepChange(gotoStep)} variant="secondary" />}
			{header && <Header size="sm">{header}</Header>}
			{children}
		</StyledReviewBox>
	);
};

export default ReviewBox;
