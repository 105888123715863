import { FunctionComponent } from 'react';
import { RequestStatus } from '@montel/montelpro-shared-components/enums';
import {
	Button,
	Modal,
	ModalBody,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
} from '@montel/montelpro-shared-components-v2';
import { useAppSelector } from '../../../store/hooks';
import { selectUserCompany } from '../../../store/user/userSelectors';
import apiClient from '../apiClient';
import { useUsersContext } from '../UsersProvider';

const RemoveUserModal: FunctionComponent<{
	isOpen: boolean;
	onClose: () => void;
	userName: string;
	userId: string;
}> = ({ isOpen, onClose, userName, userId }) => {
	const [deleteData, deleteStatus] = apiClient.useRemoveCompanyUser(userId);
	const userCompany = useAppSelector(selectUserCompany);

	const { allCompanyUsers } = useUsersContext();

	const onSend = async () => {
		const success = await deleteData();
		if (success) {
			allCompanyUsers.refetch();
			onClose();
		}
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent size={6}>
				<ModalHeader>Remove user</ModalHeader>
				<ModalBody>
					<Text size="md">
						{userName}´s access to {userCompany} on Marketplace will be removed
					</Text>
				</ModalBody>
				<ModalFooter>
					<Button text="Cancel" variant="secondary" onClick={onClose} />
					<Button
						text="Remove"
						onClick={onSend}
						isLoading={deleteStatus === RequestStatus.LOADING}
						loadingText="Removing"
					/>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default RemoveUserModal;
