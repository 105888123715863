import { FunctionComponent, useState } from 'react';
import { HStack, RadioButtonList, Select, SelectOptionType, VStack } from '@montel/montelpro-shared-components-v2';
import { OrderTypeEnum } from 'common/enums';
import getCurrencyOptions from 'common/options/getCurrencyOptions';
import getOrderTypeOptions from 'common/options/getOrderTypeOptions';
import { Moment } from 'moment';
import getOptionByValue from 'utils/getOptionByValue';
import { setToAtLeastTenMinFromNow } from 'utils/setToAtLeastTinMinFromNow';
import FormFooter from '../../Footer';
import { useOrderFormContext } from '../../OrderFormProvider';
import AvailableToGroup from './AvailableToGroup';
import ExpiresGroup from './ExpiresGroup';
import PricePerUnit from './PricePerUnit';
import { fields, PriceSchema } from './PriceStep.types';
import Quantity from './Quantity';
import { TotalPrice } from './TotalPrice';

const PriceStep: FunctionComponent<{ onProgress: () => void; onBack: () => void }> = ({ onProgress, onBack }) => {
	const { formState, setFormState, errors, validateSingle, validate } = useOrderFormContext();
	const { price, product, delivery } = formState;
	const { currency, quantity, pricePerUnit, orderType } = price;
	const [expiryDate, setExpiryDate] = useState(price.expires || setToAtLeastTenMinFromNow());

	const currencyOptions = getCurrencyOptions();

	const validateAndProgress = () => {
		const result = validate(PriceSchema, { ...product, ...delivery, ...price });

		if (result.success) {
			onProgress();
		}
	};

	const onCurrencyChanged = (currency: SelectOptionType) => {
		validateSingle(fields.currency, currency, 'currency');

		setFormState({
			...formState,
			price: { ...formState.price, currency: currency.value },
		});
	};

	const onOrderTypeChanged = (orderType: OrderTypeEnum) => {
		setFormState({
			...formState,
			price: { ...formState.price, orderType: orderType, pricePerUnit: '' },
		});
	};

	const onQuantityChanged = (quantity: string, validate: boolean = false) => {
		validate && validateSingle(fields.quantity, quantity, 'quantity');

		setFormState({ ...formState, price: { ...price, quantity } });
	};

	const onPricePerUnitChange = (pricePerUnit: string, validate: boolean = false) => {
		validate && validateSingle(fields.pricePerUnit, pricePerUnit, 'pricePerUnit');

		setFormState({ ...formState, price: { ...price, pricePerUnit } });
	};

	const onExpiryPresetChange = (value: string) => {
		validateSingle(fields.expiryPreset, value, 'expiryPreset');
		setFormState({
			...formState,
			price: { ...price, expiryPreset: value, expires: null },
		});
	};

	const onExpiryDateChange = (date: Moment) => {
		const newDate = setToAtLeastTenMinFromNow(date);

		setExpiryDate(date);
		validateSingle(fields.expires, newDate.toDate(), 'expires');

		setFormState({
			...formState,
			price: { ...price, expires: newDate ? newDate.toDate() : null },
		});
	};

	return (
		<VStack gap="lg" fullWidth>
			<HStack gap="md" wrap="nowrap" fullWidth>
				<RadioButtonList
					label="Price"
					options={getOrderTypeOptions()}
					value={orderType}
					onChange={onOrderTypeChanged}
				/>
			</HStack>
			<HStack gap="md" wrap="nowrap" fullWidth>
				<Quantity value={quantity} onChange={onQuantityChanged} error={errors?.quantity} />
				{orderType === OrderTypeEnum.FixedPrice && (
					<PricePerUnit
						value={pricePerUnit ?? ''}
						onChange={onPricePerUnitChange}
						error={errors?.pricePerUnit}
					/>
				)}
				<Select
					label="Currency"
					options={currencyOptions}
					value={getOptionByValue(currencyOptions, currency)}
					onChange={onCurrencyChanged}
					error={errors?.currency && errors.currency}
				/>
			</HStack>
			{orderType === OrderTypeEnum.FixedPrice && (
				<TotalPrice quantity={quantity} pricePerUnit={pricePerUnit} currency={currency} />
			)}
			<ExpiresGroup
				expires={expiryDate}
				expiryPreset={price.expiryPreset}
				onExpiryDateChange={onExpiryDateChange}
				onExpiryPresetChange={onExpiryPresetChange}
				deliveryDate={delivery.deliveryDate}
				errors={{ ...errors }}
				type="order"
			/>
			<AvailableToGroup />
			<FormFooter onPrev={onBack} onNext={validateAndProgress} showPrev />
		</VStack>
	);
};

PriceStep.displayName = 'Price';
export default PriceStep;
