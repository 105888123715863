export const MARKEDPLACE_API = process.env.REACT_APP_MARKEDPLACE_API + '/api';
export const CDN = process.env.REACT_APP_CDN;
export const NOTIFICATION_API = process.env.REACT_APP_NOTIFICATION_API;

export const COMPANY_API_ROUTE = MARKEDPLACE_API + '/Company';
export const USER_COMPANY_API_ROUTE = MARKEDPLACE_API + '/UserCompany';
export const ORDER_API_ROUTE = MARKEDPLACE_API + '/Order';
export const ORDER_DELETE_API_ROUTE = ORDER_API_ROUTE + '?orderId={orderId}&expectedVersion={orderVersion}';
export const ORDER_PRESETS_API_ROUTE = MARKEDPLACE_API + '/OrderPreset';
export const DELETE_ORDER_PRESET_API_ROUTE = ORDER_PRESETS_API_ROUTE + '?presetId=:presetId';
export const LOGOS_API_ROUTE = CDN + '/marketplace-images/company-logos/:filename';

export const COUNTERPARTY_API_ROUTE = MARKEDPLACE_API + '/Counterparty';
export const COUNTERPARTY_COMPANY_API_ROUTE = COUNTERPARTY_API_ROUTE + '/CompanyInformation';
export const DELETE_CONNECTED_COUNTERPARTY_ROUTE = COUNTERPARTY_API_ROUTE + '/RemoveCounterparty';

export const COMPANY_RELATIONS_ROUTE = MARKEDPLACE_API + '/CompanyRelations';
export const BLOCK_COMPANY_ROUTE = COMPANY_RELATIONS_ROUTE + '/BlockCompany';
export const UNBLOCK_COMPANY_ROUTE = COMPANY_RELATIONS_ROUTE + '/UnblockCompany';

export const SEND_INVITE_COMPANY_API_ROUTE = MARKEDPLACE_API + '/Email/SendInviteCompany';
export const SEND_INVITE_USER_API_ROUTE = MARKEDPLACE_API + '/Email/SendInviteUser';

export const COUNTRY_OPTIONS_API_ROUTE = COMPANY_API_ROUTE + '/GetCountries';

export const MARKETPLACE_ALERTS_API_ROUTE = MARKEDPLACE_API + '/Alerts';

export const PRICEINDEX_API_ROUTE = MARKEDPLACE_API + '/EndOfDayPrices';

export const JOIN_COMPANY_API_ROUTE = USER_COMPANY_API_ROUTE + '/JoinCompany?companyId=:companyId';
export const RESPOND_TO_JOIN_COMPANY_API_ROUTE = USER_COMPANY_API_ROUTE + '/RespondToJoinCompanyRequest';
export const ALL_COMPANY_USERS_API_ROUTE = USER_COMPANY_API_ROUTE + '/Company/ApprovedUsers';
export const REQUESTS_TO_JOIN_API_ROUTE = USER_COMPANY_API_ROUTE + '/Company/PendingRequests';
export const CHANGE_ROLE_COMPANY_USER_API_ROUTE = USER_COMPANY_API_ROUTE + '/ChangeRole';
export const REMOVE_COMPANY_USER_API_ROUTE = USER_COMPANY_API_ROUTE + '/Company/RemoveUser?userId={userId}';

export const GET_ALL_TRADES_API_ROUTE = MARKEDPLACE_API + '/Trade/GetTrades';
export const GET_USER_TRADES_API_ROUTE = MARKEDPLACE_API + '/Trade/GetUserTrades';
export const GET_COMPANY_TRADES_API_ROUTE = MARKEDPLACE_API + '/Trade/GetCompanyTrades';
export const GET_TRADE_PRODUCTION_YEARS = MARKEDPLACE_API + '/Trade/GetProductionYears';

export const CREATE_QUOTE_API_ROUTE = ({ orderId, expectedVersion }: { [props: string]: string }) =>
	`${ORDER_API_ROUTE}/AddQuote?orderId=${orderId}&expectedVersion=${expectedVersion}`;

export const CANCEL_QUOTE_API_ROUTE = ({ orderId, quoteId, expectedVersion }: { [props: string]: string }) =>
	`${ORDER_API_ROUTE}/CancelQuote?orderId=${orderId}&quoteId=${quoteId}&expectedVersion=${expectedVersion}`;

export const UPDATE_QUOTE_API_ROUTE = ({ orderId, quoteId, expectedVersion }: { [props: string]: string }) =>
	`${ORDER_API_ROUTE}/UpdateQuote?orderId=${orderId}&quoteId=${quoteId}&expectedVersion=${expectedVersion}`;

export const ACCEPT_QUOTE_API_ROUTE = ({ orderId, quoteId, expectedVersion }: { [props: string]: string }) =>
	`${ORDER_API_ROUTE}/AcceptQuote?orderId=${orderId}&quoteId=${quoteId}&expectedVersion=${expectedVersion}`;

export const NOTIFICATIONS_API_ROUTE = NOTIFICATION_API + '/Notifications';

export const NOTIFICATIONS_MARK_ALL_READ_API_ROUTE = NOTIFICATIONS_API_ROUTE + '/MarkAllRead';
export const NOTIFICATIONS_UPDATE_LAST_SEEN_API_ROUTE = NOTIFICATIONS_API_ROUTE + '/UpdateLastSeen';

export const NOTIFICATIONS_MARK_READ_API_ROUTE = ({ notificationId }: { [props: string]: string }) =>
	`${NOTIFICATIONS_API_ROUTE}/${notificationId}/MarkRead`;
