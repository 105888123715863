import { FC } from 'react';
import { RequestStatus } from '@montel/montelpro-shared-components/enums';
import { ApiErrorDescription, ErrorAlert, HStack, Skeleton, TSizes } from '@montel/montelpro-shared-components-v2';
import { isError, isSuccessful } from '../../../components/Suspense/utils';

export interface CompanyCardsSkeletonProps {
	status: RequestStatus;
	error: any;
	children: any;
	w?: string;
	h?: string;
	borderRadius?: TSizes;
	numberOfItems?: number;
}
const CompanyCardsSkeleton: FC<CompanyCardsSkeletonProps> = ({
	status,
	error,
	children,
	w = '428px',
	h = '216px',
	borderRadius = 'lg',
	numberOfItems = 12,
}) => {
	if (isSuccessful(status)) {
		return children;
	}
	if (isError(status)) {
		return (
			<ErrorAlert text="Try to reload the page">
				<ApiErrorDescription error={error} />
			</ErrorAlert>
		);
	}
	return (
		<HStack fullWidth wrap="wrap" gap="1rem">
			{Array.from(Array(numberOfItems).keys()).map((idx) => (
				<Skeleton key={idx} w={w} h={h} borderRadius={borderRadius} />
			))}
		</HStack>
	);
};

export default CompanyCardsSkeleton;
