import { DeliveryDatePresetEnum, ExpiresPresetEnum, OrderTypeEnum, SubsidizedEnum } from 'common/enums';
import { ProductionPeriod } from 'common/interfaces';
import { OrderPreset } from 'common/interfaces/OrderPreset';
import { OrderFormState } from 'components/NewOrder/types';
import { getProductionMonth_1_12 } from 'components/NewOrder/utils/getPayload';

const convertOrderStateToPreset = (state: OrderFormState, presetName: string): OrderPreset =>
	({
		presetName,
		availableTo: state.price.availableTo,
		orderCounterparties: state.price.orderCounterparties?.map((counterparty) => counterparty.value) || [],
		product: {
			tech: state.product.technologies,
			label: state.product.label,
			region: state.product.region,
			deliveryDate: state.delivery.deliveryDate,
			subsidized:
				state.product.subsidized === SubsidizedEnum.YES
					? true
					: state.product.subsidized === SubsidizedEnum.NO
					? false
					: null,
			production: {
				type: state.product.periodType,
				periods: (() => {
					state.product.productionPeriods?.forEach(({ month }) => (month = getProductionMonth_1_12(month)));

					return state.product.productionPeriods as ProductionPeriod[];
				})(),
			},
			registryOperator: state.delivery.registryOperator,
			plantMaxAge: state.product.plantMaxAge,
			plantCommissioningYear: state.product.plantCommissioningYear,
		},
		side: state.side,
		quantity: state.price.quantity?.toFloat(),
		price: state.price.orderType === OrderTypeEnum.FixedPrice ? state.price.pricePerUnit?.toFloat() : 0,
		currency: state.price.currency,
		expiryPreset: state.price.expiryPreset === ExpiresPresetEnum.SPECIFY ? null : state.price.expiryPreset,
		expiryTime: state.price.expires,
		deliveryPreset:
			state.delivery.deliveryDatePreset === DeliveryDatePresetEnum.DATE
				? null
				: state.delivery.deliveryDatePreset,
		minimumValidity: state.delivery.minimumValidity,
		comments: state.delivery.comment,
		orderType: state.price.orderType,
	} as unknown as OrderPreset);

export default convertOrderStateToPreset;
