import { FunctionComponent, ReactNode } from 'react';
import { IconMail, Text, VStack } from '@montel/montelpro-shared-components-v2';
import EmptyPageContainer from 'primitives/EmptyPageContainer';
import styled, { css } from 'styled-components';
import Header from 'components/Header';

interface EmailPageProps {
	children: ReactNode;
	subHeading: string;
	iconColor?: string;
}

const EmailPage: FunctionComponent<EmailPageProps> = ({ children, subHeading, iconColor = 'primaryText' }) => (
	<EmptyPageContainer>
		<Header showCompanyLinks={false} />
		<StyledContent align="center" justify="center" gap="xl">
			<StyledCircle $iconColor={iconColor}>
				<IconMail w="75" h="75" />
			</StyledCircle>
			<Text size="xxxl">{subHeading}</Text>
			<VStack gap="md">{children}</VStack>
		</StyledContent>
	</EmptyPageContainer>
);

const StyledContent = styled(VStack)`
	width: 540px;
	margin: auto;
`;

const StyledCircle = styled.div<{ $iconColor: string }>`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 150px;
	height: 150px;

	${({ theme, $iconColor }) => css`
		border-radius: ${theme.borderRadii.full};
		border: 3px solid ${theme.colors[$iconColor]};
		color: ${theme.colors[$iconColor]};
	`};
`;

export default EmailPage;
