import { FunctionComponent } from 'react';
import { RequestStatus } from '@montel/montelpro-shared-components/enums';
import { Button } from '@montel/montelpro-shared-components-v2';
import { COMPANY_API_ROUTE } from 'apiRoutes';
import useSubmit, { PUT } from 'hooks/useSubmit';
import isAllObjectValuesFalsy from 'utils/validationUtils/isAllObjectValuesFalsy';
import getErrors from './getErrors';
import getPayload from './getPayload';
import { MyCompanyState } from './MyCompanyState';

const SaveChangesButton: FunctionComponent<{
	state: MyCompanyState;
	newLogo?: File;
	setIsEdit: (value: boolean) => void;
	fetchData: () => void;
}> = ({ state, newLogo, fetchData, setIsEdit }) => {
	const [postCompany, postStatus] = useSubmit(COMPANY_API_ROUTE, PUT, {
		messageOnSuccess: 'Changes are saved',
		messageOnError: 'Changes were not saved',
		showApiError: true,
	});
	const isFormValid = isAllObjectValuesFalsy(getErrors(state));

	const onSaveChanges = async () => {
		if (!isFormValid) return;
		const payload = await getPayload(state, newLogo);
		const result = await postCompany(payload);
		if (result.success) {
			await fetchData();
			setIsEdit(false);
		}
	};

	return (
		<Button
			text="Save changes"
			isLoading={postStatus === RequestStatus.LOADING}
			onClick={onSaveChanges}
			isDisabled={!isFormValid}
		/>
	);
};

export default SaveChangesButton;
