import { FunctionComponent } from 'react';
import { RequestStatus } from '@montel/montelpro-shared-components/enums';
import { Button } from '@montel/montelpro-shared-components-v2';
import { COMPANY_API_ROUTE } from 'apiRoutes';
import useSubmit, { POST } from 'hooks/useSubmit';
import { CompanyRegistrationState } from '../types';
import getPayload from './getPayload';
import isStepValid from './isStepValid';

const CompleteButton: FunctionComponent<{
	setIsNextPressed: React.Dispatch<boolean>;
	activeStep: number;
	goToNext: () => void;
	state: CompanyRegistrationState;
	logo?: File;
}> = ({ setIsNextPressed, activeStep, goToNext, state, logo }) => {
	const [postData, status] = useSubmit(COMPANY_API_ROUTE, POST, {
		messageOnError: 'Something went wrong while completing registration',
		showApiError: true,
	});

	const complete = async () => {
		setIsNextPressed(true);
		if (isStepValid(activeStep, state)) {
			const payload = await getPayload(state, logo);
			const result = await postData(payload);
			if (result.success) {
				goToNext();
			}
		}
	};
	const isCompleting = status === RequestStatus.LOADING;

	return <Button text={'Complete'} onClick={complete} isLoading={isCompleting} />;
};

export default CompleteButton;
