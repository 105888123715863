import { RadioButtonList, Select } from '@montel/montelpro-shared-components-v2';
import styled from 'styled-components';

export const StyledNuclearSelector = styled<any>(RadioButtonList)`
	width: 15%;
`;

export const StyledRegionSelector = styled<any>(Select)`
	margin-top: 1rem;
`;

export const StyledPeriodSelector = styled<any>(Select)`
	margin-top: 1.9rem;
`;
