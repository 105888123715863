import { combineSlices } from '@reduxjs/toolkit';
import activeOrdersSlice from './activeOrders/activeOrdersSlice';
import activeCompanyQuotesSlice from './companyActiveQuotes/activeCompanyQuotesSlice';
import companyUntradedOrderHistorySlice from './companyUntradedOrderHistory/companyUntradedOrderHistorySlice';
import activeUserQuotesSlice from './userActiveQuotes/activeUserQuotesSlice';
import userUntradedOrderHistorySlice from './userUntradedOrderHistory/userUntradedOrderHistorySlice';

export type OrdersState = ReturnType<typeof ordersSlice>;

export const ordersSlice = combineSlices({
	activeOrders: activeOrdersSlice,
	userUntradedOrderHistory: userUntradedOrderHistorySlice,
	companyUntradedOrderHistory: companyUntradedOrderHistorySlice,
	activeUserQuotes: activeUserQuotesSlice,
	activeCompanyQuotes: activeCompanyQuotesSlice,
});

export default ordersSlice;
