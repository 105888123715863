import { createContext } from '../../../hooks/createContext';

export type TQuoteDetailsPrompt = 'cancelPrompt' | 'editPrompt' | 'acceptPrompt' | null;

export interface IQuoteDetailsContext {
	prompt: TQuoteDetailsPrompt | null;
	setPrompt: (prompt: TQuoteDetailsPrompt | null) => void;
}

export const [QuoteDetailsProvider, useQuoteDetailsContext] = createContext<IQuoteDetailsContext>({
	name: 'QuoteDetailsContext',
	hookName: 'useQuoteDetailsContext',
	providerName: 'QuoteDetailsProvider',
	strict: true,
});
