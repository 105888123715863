import { FunctionComponent } from 'react';
import { EllipsisText } from 'primitives/EllipsisText';
import { getProductionPeriodString } from 'utils/orderUtils/getProductionPeriodString';
import { ProductionPeriod } from '../../../../common/interfaces';
import { StyledCell } from '../../../Tables/Cells/styles';

export interface SinglePeriodProps {
	/**
	 * Production period object.
	 * @type ProductionPeriod | undefined
	 */
	period: ProductionPeriod | undefined;
	/**
	 * If true, the cell will render as disabled - text color will be secondaryText (grayish).
	 * @type boolean
	 */
	disabled?: boolean;
	[otherProps: string]: any;
}

/**
 * The cell displays a single production period in order tables.
 */
const SinglePeriod: FunctionComponent<SinglePeriodProps> = ({ period, disabled, ...otherProps }) => {
	const cellText = period && getProductionPeriodString(period);
	const textColor = disabled ? 'subElement' : 'primaryText';

	return (
		<StyledCell fullWidth wrap="nowrap" gap="xxs" {...otherProps}>
			<EllipsisText size="sm" color={textColor}>
				{cellText}
			</EllipsisText>
		</StyledCell>
	);
};

export default SinglePeriod;
