import { FunctionComponent, useState } from 'react';
import { RequestStatus } from '@montel/montelpro-shared-components/enums';
import {
	Button,
	Modal,
	ModalBody,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
} from '@montel/montelpro-shared-components-v2';
import { getErrorMessageEmails, MultipleEmails } from 'components/MultipleEmails';
import apiClient from '../apiClient';

const InviteUserModal: FunctionComponent<{ isOpen: boolean; onClose: () => void }> = ({ isOpen, onClose }) => {
	const [emails, setEmails] = useState<string[]>([]);
	const errorMessage = getErrorMessageEmails(emails);
	const [showErrors, setShowErrors] = useState(false);

	const [postData, status] = apiClient.useSendInviteUser();

	const onSend = async () => {
		setShowErrors(true);
		if (errorMessage) {
			return;
		}
		const result = await postData(emails);
		if (result.success) {
			onClose();
		}
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent size={6}>
				<ModalHeader>Invite a user to join Montel Marketplace</ModalHeader>
				<ModalBody>
					<MultipleEmails
						label={'Enter one or more email addresses to send invites'}
						value={emails}
						setValue={(value: string[]) => setEmails(value)}
						errorMessage={showErrors && errorMessage}
					/>
				</ModalBody>
				<ModalFooter>
					<Button text="Cancel" variant="secondary" onClick={onClose} />
					<Button
						text="Send"
						onClick={onSend}
						isLoading={status === RequestStatus.LOADING}
						loadingText="Sending"
					/>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default InviteUserModal;
