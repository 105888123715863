import { Tag } from '@montel/montelpro-shared-components-v2';
import styled, { css } from 'styled-components';

export const Square = styled.div<{ $boxSize: string }>`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
	box-sizing: border-box;
	${({ $boxSize }) =>
		css`
			width: ${$boxSize};
			height: ${$boxSize};
		`}
`;

export const StyledSideMenuItem = styled.li<{
	$boxSize: string;
	$backgroundColor: string;
	$hoverBackgroundColor: string;
	$color: string;
	$isActive: boolean;
}>`
	position: relative;
	display: flex;
	flex-grow: 0;
	flex-shrink: 0;
	flex-direction: row;
	justify-content: flex-start;
	gap: 0.5rem;
	padding: 0;
	margin: 0;
	list-style-type: none;
	overflow: hidden;
	height: ${({ $boxSize }) => $boxSize};
	cursor: pointer;
	background-color: ${({ $backgroundColor }) => $backgroundColor};

	${({ $isActive, $boxSize, $hoverBackgroundColor, theme }) => {
		const { colors } = theme;
		return css`
			${$isActive &&
			css`
				width: ${$boxSize};
				> .square {
					background-color: ${colors[$hoverBackgroundColor] || $hoverBackgroundColor};
				}
			`}

			&:hover, &:focus {
				width: 200px;

				> .square {
					background-color: ${colors[$hoverBackgroundColor] || $hoverBackgroundColor};
				}
			}
		`;
	}};

	svg {
		color: ${({ $color, theme }) => theme.colors[$color] || $color};
	}

	a {
		position: static;
		text-decoration: none;
		color: inherit;

		&::before {
			content: '';
			cursor: inherit;
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 0;
			width: 200px;
			height: ${({ $boxSize }) => $boxSize};
		}
	}
`;

export const StyledSideMenuItemTag = styled(Tag)`
	height: inherit;
	font-size: 16px;
	font-weight: 400;
`;
