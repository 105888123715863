import { SelectOptionType } from '@montel/montelpro-shared-components';
import { Company } from 'common/interfaces/Company';
import getCurrencyOptions from 'common/options/getCurrencyOptions';
import getLabelOptions from 'common/options/getLabelOptions';
import { getMarketRoleOptions } from 'common/options/getMarketRoleOptions';
import getOptionsForBoolean from 'common/options/getOptionsForBoolean';
import getProductOptions from 'common/options/getProductOptions';
import getRegistryOperatorOptions from 'common/options/getRegistryOperatorOptions';
import getTradingFrequencyOptions from 'common/options/getTradingFrequencyOptions';
import getTradingPositionOptions from 'common/options/getTradingPositionOptions';
import getOptionByValue from 'utils/getOptionByValue';
import { MyCompanyState } from './MyCompanyState';

const convertAPIDataToState = (data: Company, countryOptions: SelectOptionType[]): MyCompanyState => {
	const currencyOptions = getCurrencyOptions();
	const labelOptions = getLabelOptions(false);
	const registryOperatorOptions = getRegistryOperatorOptions(true, true);
	const productOptions = getProductOptions();
	return {
		...data,
		marketRole: getOptionByValue(getMarketRoleOptions(), data.marketRole),
		currencies: data?.currencies?.map((value) => getOptionByValue(currencyOptions, value)),
		products: data?.products?.map((value) => getOptionByValue(productOptions, value)),
		tradingFrequency: getOptionByValue(getTradingFrequencyOptions(), data.tradingFrequency),
		tradingPosition: getOptionByValue(getTradingPositionOptions(), data.tradingPosition),
		labels: data?.labels?.map((value) => getOptionByValue(labelOptions, value)),
		registryOperators: data?.registryOperators?.map((value) => getOptionByValue(registryOperatorOptions, value)),
		eecsRegistry: getOptionByValue(getOptionsForBoolean(), data.eecsRegistry),
		subsidized: getOptionByValue(getOptionsForBoolean(), data.subsidised),
		countryCode: getOptionByValue(countryOptions, data.countryCode),
	};
};

export default convertAPIDataToState;
