import TermsAndConditions from 'pages/TermsAndConditions';

const publicRoutes = [
	{
		path: '/terms-and-conditions',
		element: <TermsAndConditions />,
	},
];

export default publicRoutes;
