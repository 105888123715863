import { FunctionComponent } from 'react';
import { IconChevronDropDown } from '@montel/montelpro-shared-components-v2';
import { styled } from 'styled-components';

interface SimpleSelectProps {
	value: number | string;
	onChange: (value: number | string) => void;
	options: number[] | string[];
}

const Wrapper = styled.div`
	color: ${({ theme }) => theme.colors.primaryText};
	position: relative;
`;

const StyledSimpleSelect = styled.select`
	box-sizing: border-box;
	font-size: ${({ theme }) => theme.typography.size.xs};
	height: 29px;
	appearance: none;
	border: solid 1px ${({ theme }) => theme.colors.primaryText};
	border-radius: ${({ theme }) => theme.borderRadii.sm};
	color: inherit;
	background-color: transparent;
	padding: 0.25rem 1.75rem 0.25rem 0.5rem;
	cursor: pointer;

	option {
		background-color: transparent;
		color: ${({ theme }) => theme.colors.background};
	}
`;

const StyledChevron = styled(IconChevronDropDown)`
	pointer-events: none;
	position: absolute;
	right: 8px;
	top: 8px;
`;

const SimpleSelect: FunctionComponent<SimpleSelectProps> = ({ value, onChange, options }) => {
	return (
		<Wrapper>
			<StyledSimpleSelect value={value} onChange={(e) => onChange(e.target.value)}>
				{options.map((option) => (
					<option key={option} value={option}>
						{option}
					</option>
				))}
			</StyledSimpleSelect>
			<StyledChevron boxSize="12px" />
		</Wrapper>
	);
};

export default SimpleSelect;
