const TRADE_LOG = '/trades';
const ORDER = '/orders?orderId={{id}}';

export default {
	created: ORDER,
	ownAccepted: TRADE_LOG,
	accepted: TRADE_LOG,
	tradeCancelled: TRADE_LOG,
	orderWithActiveQuotesClosed: TRADE_LOG,
	quoteCreated: ORDER,
	quoteUpdated: ORDER,
	partiallyTraded: ORDER,
	quoteAccepted: ORDER,
};
