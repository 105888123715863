import { FilterCategoryEnum } from '../../common/enums/FilterCategoryEnum';
import { createContext } from '../../hooks/createContext';
import { OrderOption } from './types';

export interface FilterContext {
	options: Array<OrderOption>;
	onClick: (value: number, type: string) => void;
	activeCategory: FilterCategoryEnum;
	setActiveCategory: (value: FilterCategoryEnum) => void;
	selectedOptions: any;
}

export const [FilterProvider, useFilterContext] = createContext<FilterContext>({
	name: 'FilterContext',
	hookName: 'useFilterContext',
	providerName: 'FilterProvider',
	strict: false,
});
