import { ProductionPeriodOption } from '@montel/montelpro-shared-components/utils';
import DeliveryDatePresetEnum from 'common/enums/DeliveryDatePresetEnum';
import moment from 'moment';
import { Moment } from 'moment';
import { filterLastProductionPeriod } from 'utils/orderUtils/filterLastProductionPeriod';
import getStandardDeliveryDate from 'utils/orderUtils/getStandardDeliveryDate';
import { OrderFormState } from '../types';

const getDeliveryDate = (state: OrderFormState): Moment | undefined => {
	const { deliveryDate, deliveryDatePreset } = state.delivery;
	if (deliveryDate) return moment(deliveryDate);
	if (deliveryDatePreset === DeliveryDatePresetEnum.STANDARD) {
		const lastProductionPeriod = filterLastProductionPeriod(
			state.product.productionPeriods as ProductionPeriodOption[],
		);

		if (lastProductionPeriod) return getStandardDeliveryDate(lastProductionPeriod?.year);
	}
	return undefined;
};

export default getDeliveryDate;
