import { RelationEnum } from 'common/enums/RelationEnum';

const getRelationOptions = (): { value: any; label: string }[] => {
	return [
		{
			value: RelationEnum.Blocked,
			label: 'Blocked / Paused',
		},
		{
			value: RelationEnum.Counterparty,
			label: 'Counterparty',
		},
	];
};

export default getRelationOptions;
