import {
	Icon,
	IconCompanyDisable,
	IconPaused,
	IconPersonChecked,
	IconPersonRemove,
	IconResume,
} from '@montel/montelpro-shared-components-v2';
import { TAction } from './types';

export function getIcon(action: TAction) {
	switch (action) {
		case 'Block':
			return IconCompanyDisable;
		case 'Pause':
			return IconPaused;
		case 'Unblock':
			return IconPersonChecked;
		case 'Unpause':
			return IconResume;
		case 'Remove':
			return IconPersonRemove;
		default:
			return Icon;
	}
}
