import { createContext, FunctionComponent, ReactNode, useContext, useState } from 'react';
import { useZodValidation, ValidationResult } from './utils/useZodValidation';
import newOrderState from './newOrderState';
import PresetsProvider from './PresetsProvider';
import { OrderFormState } from './types';

export type OrderFormContextType = {
	formState: OrderFormState;
	setFormState: (state: OrderFormState) => void;
	errors: any;
	hasErrors: boolean;
	validate: (schema: Zod.ZodSchema, value: any, dry?: boolean) => ValidationResult;
	validateSingle: (schema: Zod.ZodSchema, value: any, path: string) => Zod.SafeParseReturnType<any, any>;
	modalIsOpen: boolean;
	setModalIsOpen: React.Dispatch<boolean>;
	isSubmitted: boolean;
	setIsSubmitted: React.Dispatch<boolean>;
	reset: () => void;
};

export const OrderFormContext = createContext<OrderFormContextType | null>(null);

const OrderFormProvider: FunctionComponent<{ children: ReactNode; initialState: OrderFormState }> = ({
	children,
	initialState,
}) => {
	const [formState, setFormState] = useState<OrderFormState>(initialState);
	const { errors, setErrors, hasErrors, validate, validateSingle } = useZodValidation();
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [isSubmitted, setIsSubmitted] = useState(false);

	const reset = () => {
		setFormState(newOrderState);
		setErrors({});
		setIsSubmitted(false);
	};

	return (
		<OrderFormContext.Provider
			value={{
				formState,
				setFormState,
				errors,
				validate,
				validateSingle,
				hasErrors,
				modalIsOpen,
				setModalIsOpen,
				reset,
				isSubmitted,
				setIsSubmitted,
			}}
		>
			<PresetsProvider>{children}</PresetsProvider>
		</OrderFormContext.Provider>
	);
};

export function useOrderFormContext() {
	const context = useContext(OrderFormContext);
	if (!context) {
		throw new Error('useOrderFormState must be used within the OrderFormProvider');
	}
	return context;
}

export default OrderFormProvider;
