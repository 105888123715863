import { FunctionComponent } from 'react';
import { RequestStatus } from '@montel/montelpro-shared-components/enums';
import {
	Button,
	HStack,
	Modal,
	ModalBody,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
	VStack,
} from '@montel/montelpro-shared-components-v2';
import { Currency } from 'common/enums/CurrencyEnum';
import { getRegionName, getTechName } from 'common/enums/valueLookups';
import { Trade } from 'common/interfaces/Trade';
import LabelAndValue from 'primitives/LabelAndValue';
import { styled } from 'styled-components';
import { getProductionPeriodsStringFromOrder } from 'utils/orderUtils/getProductionPeriodsStringFromOrder';
import useSubmit, { POST } from '../../../hooks/useSubmit';

interface CancelTradeModalProps {
	trade: Trade;
	isOpen: boolean;
	onClose: () => void;
}

const Actions = styled(HStack)`
	button {
		min-width: 9rem;
	}
`;

const CancelTradeModal: FunctionComponent<CancelTradeModalProps> = ({ trade, isOpen, onClose }) => {
	const { orderId, quoteId, version } = trade;
	const [postData, status] = useSubmit(
		`${process.env.REACT_APP_MARKEDPLACE_API}/api/Order/CancelTrade`,
		POST,

		{
			messageOnSuccess: 'Your trade has been cancelled.',
			messageOnError: 'An error has occurred, please try again.',
		},
		{ orderId, expectedVersion: String(version), ...(quoteId && { quoteId }) },
	);

	const cancelTrade = async () => {
		if (!trade.orderId) return;
		const response = await postData('');
		if (response.success) {
			onClose();
		}
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent size={4}>
				<ModalHeader>Cancel Trade</ModalHeader>
				<ModalBody>
					<VStack gap="md">
						<HStack gap="xs" wrap="nowrap" fullWidth>
							<LabelAndValue label="Production" value={getProductionPeriodsStringFromOrder(trade)} />
							<LabelAndValue label="Region" value={getRegionName(trade.product.region)} />
						</HStack>
						<HStack gap="xs" wrap="nowrap" fullWidth>
							<LabelAndValue label="Tech" value={trade.product.tech.map(getTechName).join(', ')} />
							<LabelAndValue
								label="Quantity"
								value={`${trade.cost.quantity.toDefaultNumberFormat(0)} MWh`}
							/>
						</HStack>
						<HStack gap="xs" wrap="nowrap" fullWidth>
							<LabelAndValue label="Price" value={trade.cost.price.toDefaultNumberFormat()} />
							<LabelAndValue label="Currency" value={Currency[trade.cost.currency]} />
						</HStack>
					</VStack>
				</ModalBody>
				<ModalFooter>
					<VStack gap="md" fullWidth align="center">
						<Text lineHeight="300%">Are you sure you want to cancel this trade?</Text>
						<Actions gap="md" fullWidth justify="center">
							<Button
								onClick={cancelTrade}
								variant="primary"
								text="Yes"
								type="submit"
								isLoading={status === RequestStatus.LOADING}
								loadingText="Cancelling"
							/>
							<Button onClick={onClose} variant="secondary" text="No" />
						</Actions>
					</VStack>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default CancelTradeModal;
