import { Button, ModalBody } from '@montel/montelpro-shared-components-v2';
import { styled } from 'styled-components';

export const StyledBody = styled(ModalBody)`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 25px;
`;

export const ActionButton = styled(Button)`
	position: relative;
	width: auto;
`;
