import { FunctionComponent, useState } from 'react';
import { useGetOnMount } from '@montel/montelpro-shared-components/hooks';
import { Box } from '@montel/montelpro-shared-components-v2';
import { COMPANY_API_ROUTE } from 'apiRoutes';
import { Company } from 'common/interfaces/Company';
import useSetPageTitle from 'hooks/useSetPageTitle';
import { selectCountries } from 'store/lists/listsSelectors';
import { selectActiveCompanyId } from 'store/user/userSelectors';
import { Suspense } from 'components/Suspense';
import { useAppSelector } from '../../store/hooks';
import convertAPIDataToState from './convertAPIDataToState';
import Form from './Form';
import Overview from './Overview';

const MyCompany: FunctionComponent = () => {
	useSetPageTitle('My Company');
	const activeCompanyId = useAppSelector(selectActiveCompanyId);
	const { data, status, error, getData } = useGetOnMount(`${COMPANY_API_ROUTE}?companyId=${activeCompanyId}`);
	const countryOptions = useAppSelector(selectCountries);
	const myCompany = data && convertAPIDataToState(data as Company, countryOptions);

	const [isEdit, setIsEdit] = useState(false);

	return (
		<Suspense status={status} error={error} disabled={isEdit}>
			<Box bg="widget" px="xl" py="xl">
				{isEdit ? (
					<Form data={myCompany} setIsEdit={setIsEdit} fetchData={getData} />
				) : (
					<Overview state={myCompany} setIsEdit={setIsEdit} />
				)}
			</Box>
		</Suspense>
	);
};

export default MyCompany;
