import { FunctionComponent } from 'react';
import { ColumnDef, SortingState } from '@tanstack/react-table';
import { Quote } from 'common/interfaces/Quote';
import Th from '../Tables/Th';
import useSortedTable from '../Tables/useSortedTable';
import {
	ScrollableContainer,
	StyledHeadRow,
	StyledQuotesTable,
	StyledTableData,
	StyledTableRow,
} from './QuotesTable.style';

interface QuotesTableBaseProps {
	quotes: Quote[];
	columns: ColumnDef<Quote, any>[];
	onRowClicked?: (quote: Quote) => void;
	initialSorting?: SortingState;
	isScrollable?: boolean;
}
const QuotesTableBase: FunctionComponent<QuotesTableBaseProps> = ({
	quotes,
	columns,
	onRowClicked,
	initialSorting,
	isScrollable = true,
}) => {
	const table = useSortedTable({
		data: quotes,
		columns,
		initialSorting,
	});
	const rowModel = table.getSortedRowModel();
	const showScroll = isScrollable && quotes.length > 3;

	return (
		<ScrollableContainer $showScroll={showScroll} $maxHeight="220px">
			<StyledQuotesTable>
				<thead>
					{table.getHeaderGroups().map((headerGroup) => (
						<StyledHeadRow key={headerGroup.id}>
							{headerGroup.headers.map((header) => (
								<Th key={header.id} header={header} />
							))}
						</StyledHeadRow>
					))}
				</thead>
				<tbody>
					{rowModel.rows.map((row) => (
						<StyledTableRow
							key={row.id}
							onClick={() => {
								onRowClicked && onRowClicked(row.original);
							}}
							$clickable={Boolean(onRowClicked)}
						>
							{row.getVisibleCells().map((cell) => (
								<StyledTableData
									$width={(cell.column.columnDef as any).width}
									key={cell.id}
									cell={cell}
								/>
							))}
						</StyledTableRow>
					))}
				</tbody>
			</StyledQuotesTable>
		</ScrollableContainer>
	);
};

export default QuotesTableBase;
