import { FunctionComponent, useState } from 'react';
import {
	Button,
	DropdownGroup,
	DropdownPositioningContainer,
	IconFilterList,
} from '@montel/montelpro-shared-components-v2';
import { OrderOption } from 'components/Filter/types';
import { FilterCategoryEnum } from '../../../common/enums';
import { FilterProvider } from '../filterContext';
import CheckboxItem from './CheckboxItem';
import { StyledDropdownMenu } from './style';
import { NormalizeFilterCategory } from './utils';

export interface FilterDropDownProps {
	options?: Array<OrderOption>;
	onClick: (value: number, type: string) => void;
	selectedOptions: any;
	alignToRight?: boolean;
	className?: string;
}

const FilterDropDown: FunctionComponent<FilterDropDownProps> = ({
	options = [],
	onClick,
	selectedOptions,
	alignToRight = false,
	className,
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const [activeCategory, setActiveCategory] = useState(FilterCategoryEnum.Default);

	return (
		<FilterProvider
			value={{
				options,
				onClick,
				selectedOptions,
				activeCategory,
				setActiveCategory,
			}}
		>
			<DropdownPositioningContainer className={className}>
				<Button
					text="Filter"
					variant="secondary"
					leftIcon={<IconFilterList />}
					onClick={() => setIsOpen(true)}
				/>
				{isOpen && (
					<StyledDropdownMenu
						closeMenu={() => {
							setActiveCategory(FilterCategoryEnum.Default);
							setIsOpen(false);
						}}
						topPosition="2.5rem"
						alignToRight={alignToRight}
					>
						{options.map(({ category, checkboxOptions }, index) => (
							<DropdownGroup
								onClick={() => setActiveCategory(category as FilterCategoryEnum)}
								key={`category-${index}`}
								name={NormalizeFilterCategory(category)}
							>
								{checkboxOptions?.map(({ label, value }) => (
									<CheckboxItem
										onClick={onClick}
										label={label}
										value={value}
										key={`${label}-option-${value}`}
									/>
								))}
							</DropdownGroup>
						))}
					</StyledDropdownMenu>
				)}
			</DropdownPositioningContainer>
		</FilterProvider>
	);
};

export default FilterDropDown;
