import { FunctionComponent, useState } from 'react';
import { Button, H2, HStack, IconChevronBoldLeft, Text, VStack } from '@montel/montelpro-shared-components-v2';
import { useAppSelector } from 'store/hooks';
import { selectCanInviteCompanyOrUser } from 'store/user/userSelectors';
import { InviteCompanyButton } from '../InviteCompanyButton';
import InviteCompanyModal from './InviteCompanyModal';
import { StyledNoCompanies } from './NoCompanies.style';
import { noCompaniesConfig } from './noCompaniesConfig';
import { NoCompaniesType } from './NoCompaniesTypes';

export interface NoCompaniesProps {
	variant: NoCompaniesType;
	isFiltered: boolean;
	goBack: () => void;
}

const NoCompanies: FunctionComponent<NoCompaniesProps> = ({ variant, isFiltered, goBack }) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	if (!noCompaniesConfig[variant]) return null;

	const variantConfig = isFiltered ? 'all' : variant;
	const { title, description, showInviteButton, showBackButton } = noCompaniesConfig[variantConfig];
	const canInviteCompany = useAppSelector(selectCanInviteCompanyOrUser);

	return (
		<StyledNoCompanies>
			<VStack gap="md" align="center" justify="center">
				<VStack gap="md">
					<H2>{title}</H2>
					<Text>{description}</Text>
				</VStack>
				<HStack gap="md" justify="flex-end" fullWidth>
					{showInviteButton && (
						<InviteCompanyButton onClick={() => setIsModalOpen(true)} disabled={!canInviteCompany} />
					)}
					{showBackButton && (
						<Button text={'Go back'} onClick={() => goBack()} leftIcon={<IconChevronBoldLeft />} />
					)}
				</HStack>
			</VStack>
			<InviteCompanyModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
		</StyledNoCompanies>
	);
};

export default NoCompanies;
