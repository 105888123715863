import { FunctionComponent } from 'react';
import { Text } from '@montel/montelpro-shared-components-v2';
import { ColumnDef, RowModel } from '@tanstack/react-table';
import { Order } from 'common/interfaces/Order';
import { isStrip } from 'utils/orderUtils/isStrip';
import Td from 'components/Tables/Td';
import { StyledTableRow } from '../OrderTable.styles';
import EmptyRow from './EmptyRow';
import { StyledUnderline, StyledYearHeading } from './styles';

export interface YearGroup {
	year: number;
	nrOfRows: number;
}

interface StripsGroupingProps {
	nrOfRows: number;
	columns: ColumnDef<Order, any>[];
	onRowClicked?: (order: Order) => void;
	noDataText?: string;
	reverse?: boolean;
	rowModel: RowModel<Order>;
}

const StripsGrouping: FunctionComponent<StripsGroupingProps> = ({
	nrOfRows,
	columns,
	reverse,
	rowModel,
	onRowClicked,
	noDataText,
}) => {
	const nrOfActiveRows = rowModel.rows?.filter((row) => isStrip(row.original))?.length || 0;
	const nrOfEmptyRows = nrOfRows - nrOfActiveRows;

	const emptyRows = [];
	for (let i = 0; i < nrOfEmptyRows; i++) {
		emptyRows.push(
			<EmptyRow
				key={i}
				noDataText={noDataText}
				displayNoDataText={!nrOfActiveRows && i === 0}
				nrOfColumns={columns.length}
			/>,
		);
	}

	return (
		<tbody>
			<tr>
				<td colSpan={columns.length}>
					<StyledYearHeading align={reverse ? 'flex-end' : 'flex-start'} gap="xxs">
						<Text size="xxs" weight="semiBold">
							Strips
						</Text>
						<StyledUnderline />
					</StyledYearHeading>
				</td>
			</tr>
			{rowModel.rows
				.filter((row) => isStrip(row.original))
				.map((row) => (
					<StyledTableRow
						key={row.id}
						onClick={() => {
							onRowClicked && onRowClicked(row.original);
						}}
						$clickable={Boolean(onRowClicked)}
					>
						{row.getVisibleCells().map((cell) => (
							<Td cell={cell} key={cell.id} />
						))}
					</StyledTableRow>
				))}
			{emptyRows}
		</tbody>
	);
};

export default StripsGrouping;
