import { FunctionComponent } from 'react';
import { IconLock } from '@montel/montelpro-shared-components-v2';
import { Text, VStack } from '@montel/montelpro-shared-components-v2';
import EmptyPageContainer from 'primitives/EmptyPageContainer';
import { styled } from 'styled-components';
import Header from 'components/Header';

interface UnauthorisedProps {
	email: string;
}

const StyledContainer = styled(VStack)`
	margin: auto;
`;
const StyledTextContainer = styled(VStack)`
	width: 540px;
	text-align: left;
`;
const StyledIconLock = styled(IconLock)`
	width: 160px;
	height: 160px;
	padding: 32px;
	border: white 2px solid;
	border-radius: 999%;
`;
const Unauthorised: FunctionComponent<UnauthorisedProps> = ({ email }) => {
	return (
		<EmptyPageContainer>
			<Header showCompanyLinks={false} showProfile={true} />
			<StyledContainer align="center" justify="center" gap="xxl">
				<StyledIconLock />
				<StyledTextContainer align="center" justify="center" gap="sm">
					<Text size="xxxl">Unauthorised user</Text>
				</StyledTextContainer>
				<StyledTextContainer align="center" justify="center" gap="sm">
					<Text align="center" size="sm">
						{`Your account ${email} is not currently authorised to use this platform. Your
						subscription or trial may have expired.`}
					</Text>
					<Text align="center" size="sm">
						Reach out to us at support@montelgroup.com and we will be happy to help.
					</Text>
				</StyledTextContainer>
			</StyledContainer>
		</EmptyPageContainer>
	);
};
export default Unauthorised;
