import { AvailableTo, DeliveryDatePresetEnum, OrderLifecycleEnum, Side, SubsidizedEnum, Tech } from 'common/enums';
import getCurrencyOptions from 'common/options/getCurrencyOptions';
import getLabelOptions from 'common/options/getLabelOptions';
import getRegistryOperatorOptions from 'common/options/getRegistryOperatorOptions';
import { OrderFormState } from './types';

const newOrderState: OrderFormState = {
	product: {
		technologies: [Tech.AnyRenewable],
		label: getLabelOptions()[0].value,
		subsidized: SubsidizedEnum.NOT_REQUIRED,
		productionPeriods: [],
		periodType: undefined,
	},
	delivery: {
		registryOperator: getRegistryOperatorOptions()[0].value,
		deliveryDatePreset: DeliveryDatePresetEnum.STANDARD,
		deliveryDate: null,
		minimumValidity: 4,
		comment: '',
	},
	price: {
		orderType: 1,
		availableTo: AvailableTo.Public,
		currency: getCurrencyOptions()[0].value,
		expiryPreset: '17:00 CET',
		quantity: '',
		pricePerUnit: '',
		expires: null,
	},
	side: Side.Ask,
	type: OrderLifecycleEnum.New,
};

export default newOrderState;
