import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { RequestStatus } from '@montel/montelpro-shared-components/enums';
import { useGetOnMount } from '@montel/montelpro-shared-components/hooks';
import { MARKETPLACE_ALERTS_API_ROUTE } from '../../apiRoutes';
import { Alert } from '../../common/interfaces/Alert';
import { AlertType } from './types';

const useLoadAllAlerts = () => {
	const [alerts, setAlerts] = useState<Alert[]>([]);
	const [status, setStatus] = useState<RequestStatus>(RequestStatus.UNLOADED);

	const { data: dataBid, status: statusBid } = useGetOnMount(
		`${MARKETPLACE_ALERTS_API_ROUTE}/GetAlertsByType?type=${AlertType.Bid}`,
	);
	const { data: dataAsk, status: statusAsk } = useGetOnMount(
		`${MARKETPLACE_ALERTS_API_ROUTE}/GetAlertsByType?type=${AlertType.Ask}`,
	);
	const { data: dataTrade, status: statusTrade } = useGetOnMount(
		`${MARKETPLACE_ALERTS_API_ROUTE}/GetAlertsByType?type=${AlertType.Trade}`,
	);

	useEffect(() => {
		const statuses = [statusBid, statusAsk, statusTrade];
		if (statuses.every((s) => s === RequestStatus.SUCCESS)) {
			setStatus(RequestStatus.SUCCESS);
		}
		if (statuses.some((s) => s === RequestStatus.LOADING)) {
			setStatus(RequestStatus.LOADING);
		}
		if (statuses.some((s) => s === RequestStatus.ERROR)) {
			setStatus(RequestStatus.ERROR);
		}
	}, [statusBid, statusAsk, statusTrade]);

	useEffect(() => {
		if (dataBid && dataAsk && dataTrade) {
			setAlerts([...dataBid, ...dataAsk, ...dataTrade]);
		}
	}, [dataBid, dataAsk, dataTrade]);

	return [alerts, setAlerts, status] as [Alert[], Dispatch<SetStateAction<Alert[]>>, RequestStatus.UNLOADED];
};

export default useLoadAllAlerts;
