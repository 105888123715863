import { useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { OrderStatus } from 'common/enums';
import { Currency } from 'common/enums/CurrencyEnum';
import { getRegionName } from 'common/enums/valueLookups/RegionLookup';
import { Order } from 'common/interfaces/Order';
import moment from 'moment';
import { getOwner } from 'components/OrderTables/helpers';
import { sortingProductionOrder } from 'components/OrderTables/sorting';
import { NumericCell, SortableHeadCell, TextCell } from 'components/Tables/Cells';
import { getLabelName, getOrderTypeName } from '../../../common/enums/valueLookups';
import { OrderStatusCell, OwnerCell, TechCell } from '../Cells';
import ProductionCell from '../Cells/ProductionPeriodCell';
import sortingTech from '../sorting/sortingTech';
import {
	CreatedTimeColumnProps,
	DeliveryColumnProps,
	LabelColumnProps,
	OrderStatusColumnProps,
	OwnerColumnProps,
	PriceColumnProps,
	ProductionPeriodColumnProps,
	QuantityColumnProps,
	RegionColumnProps,
	SubsidizedColumnProps,
	TechColumnProps,
	TypeColumnProps,
} from './orderColumnTypes';

const columnHelper = createColumnHelper<Order>();

const isOrderRowDisabled = (row: Order) => {
	const isCoworkersOrder = !row.isMyOrder && row.isMyCompanysOrder;

	if (row.status !== OrderStatus.Active) return false;
	if (row.isMyOrder) return false;
	if (isCoworkersOrder) return true;

	return !row.isAvailableToTrade;
};

export const orderTableColumns = {
	productionPeriod: (overrideProps?: ProductionPeriodColumnProps) =>
		columnHelper.accessor((row) => row.product.production.periods.join(','), {
			id: 'productionPeriod',
			enableSorting: true,
			header: ({ header }) => (
				<SortableHeadCell text="Production" header={header} {...overrideProps?.headCellProps} />
			),
			cell: (info) => (
				<ProductionCell
					production={info.row.original.product.production}
					disabled={isOrderRowDisabled(info.row.original)}
					{...overrideProps?.cellProps}
				/>
			),
			sortingFn: (a, b) => sortingProductionOrder(a.original.product.production, b.original.product.production),
			...overrideProps?.columnDefProps,
		}),

	tech: (overrideProps?: TechColumnProps) =>
		columnHelper.accessor((row) => row.product.tech, {
			id: 'tech',
			enableSorting: true,
			sortingFn: (a, b) => sortingTech(a.original.product.tech, b.original.product.tech),
			header: ({ header }) => <SortableHeadCell text="Tech" header={header} {...overrideProps?.headCellProps} />,
			cell: (info) => (
				<TechCell
					tech={info.getValue()}
					numberOfTechToShow={3}
					disabled={isOrderRowDisabled(info.row.original)}
					{...overrideProps?.cellProps}
				/>
			),
			...overrideProps?.columnDefProps,
		}),

	owner: (overrideProps?: OwnerColumnProps) =>
		columnHelper.accessor((row) => getOwner(row), {
			id: 'owner',
			enableSorting: true,
			header: ({ header }) => <SortableHeadCell text="Owner" header={header} {...overrideProps?.headCellProps} />,
			cell: (info) => (
				<OwnerCell
					order={info.row.original}
					disabled={isOrderRowDisabled(info.row.original)}
					{...overrideProps?.cellProps}
				/>
			),
			...overrideProps?.columnDefProps,
		}),

	region: (overrideProps?: RegionColumnProps) =>
		columnHelper.accessor((row) => getRegionName(row.product.region), {
			id: 'region',
			enableSorting: true,
			header: ({ header }) => (
				<SortableHeadCell text="Region" header={header} {...overrideProps?.headCellProps} />
			),
			cell: (info) => (
				<TextCell
					text={info.getValue()}
					disabled={isOrderRowDisabled(info.row.original)}
					{...overrideProps?.cellProps}
				/>
			),
			...overrideProps?.columnDefProps,
		}),

	label: (overrideProps?: LabelColumnProps) =>
		columnHelper.accessor((row) => getLabelName(row.product.label), {
			id: 'label',
			enableSorting: true,
			header: ({ header }) => <SortableHeadCell text="Label" header={header} {...overrideProps?.headCellProps} />,
			cell: (info) => <TextCell truncateAt={20} text={info.getValue()} {...overrideProps?.cellProps} />,
			...overrideProps?.columnDefProps,
		}),

	subsidized: (overrideProps?: SubsidizedColumnProps) =>
		columnHelper.accessor((row) => row.product.subsidized, {
			id: 'subsidized',
			enableSorting: true,
			header: ({ header }) => (
				<SortableHeadCell text="Subsidised" header={header} {...overrideProps?.headCellProps} />
			),
			cell: (info) => (
				<TextCell
					text={info.getValue() === null ? 'Not required' : info.getValue() ? 'Yes' : 'No'}
					{...overrideProps?.cellProps}
				/>
			),
			...overrideProps?.columnDefProps,
		}),

	type: (overrideProps?: TypeColumnProps) =>
		columnHelper.accessor((row) => getOrderTypeName(row.orderType), {
			id: 'type',
			header: ({ header }) => <SortableHeadCell text="Type" header={header} {...overrideProps?.headCellProps} />,
			cell: (info) => (
				<TextCell
					text={info.getValue()}
					disabled={isOrderRowDisabled(info.row.original)}
					{...overrideProps?.cellProps}
				/>
			),
			...overrideProps?.columnDefProps,
		}),
	quantity: (overrideProps?: QuantityColumnProps) =>
		columnHelper.accessor((row) => row.cost.remainingQuantity, {
			id: 'quantity',
			enableSorting: true,
			header: ({ header }) => (
				<SortableHeadCell
					text="Quantity"
					header={header}
					justify="flex-end"
					{...overrideProps?.headCellProps}
				/>
			),
			cell: (info) => (
				<NumericCell
					value={info.getValue()}
					unit={'MWh'}
					decimalPlaces={0}
					align="flex-end"
					disabled={isOrderRowDisabled(info.row.original)}
					{...overrideProps?.cellProps}
				/>
			),
			...overrideProps?.columnDefProps,
		}),

	price: (overrideProps?: PriceColumnProps) =>
		columnHelper.accessor((row) => row.cost.price, {
			id: 'price',
			enableSorting: true,
			header: ({ header }) => (
				<SortableHeadCell
					text="Unit price"
					header={header}
					justify="flex-end"
					{...overrideProps?.headCellProps}
				/>
			),
			cell: (info) => (
				<NumericCell
					value={info.getValue()}
					unit={Currency[info.row.original.cost.currency]}
					align="flex-end"
					disabled={isOrderRowDisabled(info.row.original)}
					tooltipText="For RFQ orders, we display the best quoted price."
					tooltipSide="left"
					orderType={getOrderTypeName(info.row.original.orderType)}
					{...overrideProps?.cellProps}
				/>
			),
			...overrideProps?.columnDefProps,
		}),

	status: (overrideProps?: OrderStatusColumnProps) =>
		columnHelper.accessor('status', {
			id: 'status',
			enableSorting: true,
			header: ({ header }) => (
				<SortableHeadCell text="Status" header={header} {...overrideProps?.headCellProps} />
			),
			cell: (info) => <OrderStatusCell order={info.row.original} {...overrideProps?.cellProps} />,
			...overrideProps?.columnDefProps,
		}),

	delivery: (overrideProps?: DeliveryColumnProps) =>
		columnHelper.accessor((row) => row.product.deliveryDate, {
			id: 'delivery',
			enableSorting: true,
			header: ({ header }) => (
				<SortableHeadCell text="Delivery (UTC)" header={header} {...overrideProps?.headCellProps} />
			),
			cell: (info) => (
				<TextCell
					text={info.getValue() ? moment(info.getValue()).format('DD MMM YYYY') : 'Spot'}
					{...overrideProps?.cellProps}
				/>
			),
			...overrideProps?.columnDefProps,
		}),

	createdTime: (overrideProps?: CreatedTimeColumnProps) =>
		columnHelper.accessor('createdTime', {
			id: 'createdTime',
			enableSorting: true,
			header: ({ header }) => (
				<SortableHeadCell text="Created at" header={header} {...overrideProps?.headCellProps} />
			),
			cell: (info) => (
				<TextCell text={moment(info.getValue()).format('DD MMM YY HH:mm')} {...overrideProps?.cellProps} />
			),
			...overrideProps?.columnDefProps,
		}),
};

export const useMarketOrdersColumns = (reverse?: boolean) => {
	const columns = useMemo(() => {
		const result = [
			orderTableColumns.productionPeriod({
				cellProps: {
					align: !reverse ? 'flex-start' : 'flex-end',
				},
				headCellProps: { justify: !reverse ? 'flex-start' : 'flex-end', text: 'Prod.' },
				columnDefProps: {
					meta: {
						width: '20%',
					},
				},
			}),
			orderTableColumns.owner(),
			orderTableColumns.tech(),
			orderTableColumns.region(),
			orderTableColumns.type(),
			orderTableColumns.quantity({
				cellProps: { align: 'flex-end' },
				headCellProps: { justify: 'flex-end' },
			}),
			orderTableColumns.price({
				cellProps: { align: !reverse ? 'flex-end' : 'flex-start', tooltipSide: !reverse ? 'left' : 'right' },
				headCellProps: { justify: !reverse ? 'flex-end' : 'flex-start' },
				columnDefProps: {
					meta: {
						width: '8.25rem',
					},
				},
			}),
		];

		if (reverse) return result.reverse();

		return result;
	}, [reverse]);

	return columns;
};
