import jwt_decode from 'jwt-decode';
const getJwtPayload = (jwt?: string): any | undefined => {
	try {
		return jwt && jwt_decode(jwt);
	} catch (error) {
		console.error("Couldn't decode jwt token due to error: ", error);
		return undefined;
	}
};

export default getJwtPayload;
