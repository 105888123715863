import { FunctionComponent, useState } from 'react';
import { UserRole } from 'common/enums';
import { Trade, TradeStatusEnum } from 'common/interfaces/Trade';
import { useAppSelector } from 'store/hooks';
import { selectUserRole } from 'store/user/userSelectors';
import CancelTradeModal from 'components/OrderTables/CancelTradeModal/CancelTradeModal';
import { CellProps } from 'components/Tables/Cells';
import { StyledCell } from 'components/Tables/Cells/styles';
import { CancelTradeAction, NotAvailable, TradeCancelled, TradeCompleted } from './CellContent';

export interface TradeStatusCellProps extends CellProps {
	trade: Trade;
}

const TradeStatusCell: FunctionComponent<TradeStatusCellProps> = ({ trade, offsetLeft }) => {
	const { tradeStatus, isMyCompanyPartOfTrade } = trade;
	const [showCancelModal, setShowCancelModal] = useState(false);
	const userRole = useAppSelector(selectUserRole);

	const userAllowedToCancel = userRole === UserRole.TradeManager;
	const renderTradeModal = userAllowedToCancel;

	if (!isMyCompanyPartOfTrade)
		return (
			<StyledCell $offsetLeft={offsetLeft}>
				<NotAvailable />
			</StyledCell>
		);

	return (
		<>
			<StyledCell $offsetLeft={offsetLeft}>
				<TradeStatusCellContent
					tradeStatus={tradeStatus}
					onCancelTrade={setShowCancelModal}
					allowedToCancel={userAllowedToCancel}
				/>
			</StyledCell>
			{renderTradeModal && (
				<CancelTradeModal isOpen={showCancelModal} trade={trade} onClose={() => setShowCancelModal(false)} />
			)}
		</>
	);
};

const TradeStatusCellContent: FunctionComponent<{
	tradeStatus: TradeStatusEnum;
	onCancelTrade: React.Dispatch<boolean>;
	allowedToCancel: boolean;
}> = ({ tradeStatus, onCancelTrade, allowedToCancel }) => {
	switch (tradeStatus) {
		case TradeStatusEnum.Pending:
			return allowedToCancel ? <CancelTradeAction onClick={() => onCancelTrade(true)} /> : <NotAvailable />;

		case TradeStatusEnum.Signed:
			return <TradeCompleted />;

		case TradeStatusEnum.Cancelled:
			return <TradeCancelled />;

		default:
			return <NotAvailable />;
	}
};

export default TradeStatusCell;
