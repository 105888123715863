import { FunctionComponent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useAuth } from '@montel/montelpro-auth';
import { RoundSpinner } from '@montel/montelpro-shared-components';
import { RequestStatus } from '@montel/montelpro-shared-components/enums';
import useSetPageTitle from 'hooks/useSetPageTitle';
import useSubmit from 'hooks/useSubmit';
import EmailVerificationCompleted from 'pages/EmailVerification/EmailVerificationCompleted';
import EmptyPageContainer from 'primitives/EmptyPageContainer';
import { selectIsEmailVerified } from 'store/user/userSelectors';
import parseQueryString from 'utils/parseQueryString';
import EmailVerificationError from './EmailVerificationError';
import UserDeactivated from './UserDeactivated';

const CompleteEmailVerification: FunctionComponent = () => {
	const isEmailVerified = useSelector(selectIsEmailVerified);

	if (isEmailVerified) {
		return <EmailVerificationCompleted />;
	}

	const location = useLocation();
	const { verificationId, email } = parseQueryString(location.search);
	const { authRoutes, fetchUserInfo, refreshAccessToken } = useAuth();

	const [verifyEmail, verificationStatus] = useSubmit(authRoutes.verifyEmail(verificationId));
	const [userIsDeactivated, setUserIsDeactivated] = useState(false);
	const [verificationError, setVerificationError] = useState<any>();

	useSetPageTitle('Verifying your e-mail address');
	const completeVerification = async () => {
		await verifyEmail(undefined);
	};

	useEffect(() => {
		(async () => {
			try {
				const data = await fetchUserInfo(email);
				const userDeactivated = data?.user?.active === false;
				setUserIsDeactivated(userDeactivated);
				if (!userDeactivated) {
					await completeVerification();
					await refreshAccessToken();
				}
			} catch (e: any) {
				await refreshAccessToken();
				setVerificationError(e);
			}
		})();
	}, []);

	if (verificationError || verificationStatus === RequestStatus.ERROR) {
		return <EmailVerificationError />;
	}

	if (userIsDeactivated) {
		return <UserDeactivated />;
	}

	if (verificationStatus === RequestStatus.SUCCESS) {
		return <EmailVerificationCompleted />;
	}

	return (
		<EmptyPageContainer>
			<RoundSpinner text="Verifying your e-mail address..." size="large" />
		</EmptyPageContainer>
	);
};

export default CompleteEmailVerification;
