import { FunctionComponent, ReactElement } from 'react';
import {
	HStack,
	IconSocialFacebook,
	IconSocialLinkedIn,
	IconSocialTwitter,
	IconSocialWeb,
} from '@montel/montelpro-shared-components-v2';
import { PublicCompanyInformation } from '../../common/interfaces/Company';
import { StyledSocialLink } from './style';

export interface SocialMediaProps {
	companyInformation: PublicCompanyInformation;
}

const socialMediaConf = [
	{ value: 'facebook', label: 'Facebook', icon: IconSocialFacebook },
	{ value: 'twitter', label: 'X/Twitter', icon: IconSocialTwitter },
	{ value: 'linkedIn', label: 'LinkedIn', icon: IconSocialLinkedIn },
	{ value: 'companyWebPage', label: 'Web page', icon: IconSocialWeb },
];

const SocialMedia: FunctionComponent<SocialMediaProps> = ({ companyInformation }) => {
	return (
		<HStack gap="md">
			{
				socialMediaConf
					.filter((conf) => companyInformation[conf.value as keyof PublicCompanyInformation])
					.map((conf) => {
						const { value, icon: Icon } = conf;
						const path = companyInformation[value as keyof PublicCompanyInformation] as string;
						const isAbsolutePath = path?.startsWith('https://') || path?.startsWith('http://');
						return (
							<StyledSocialLink
								$isActive={!!path}
								key={conf.value}
								href={isAbsolutePath ? path : 'https://' + path}
								target="_blank"
								rel="noreferrer"
							>
								<Icon boxSize="24px" />
							</StyledSocialLink>
						);
					}) as ReactElement[]
			}
		</HStack>
	);
};

export default SocialMedia;
