import { FunctionComponent } from 'react';
import { Input } from '@montel/montelpro-shared-components-v2';

interface PricePerUnitProps {
	value: string;
	onChange: (pricePerUnit: string, validate?: boolean) => void;
	error?: string;
	disabled?: boolean;
}

const PricePerUnit: FunctionComponent<PricePerUnitProps> = ({ value, error, onChange, disabled = false }) => {
	const MAX_PRICE = 999999.9999;

	const onUnitPriceChange = (event: any) => {
		const pricePerUnit = event.target.value;

		if (pricePerUnit === null) return;

		if (pricePerUnit.endsWith(',')) {
			onChange(pricePerUnit.replace(/.$/, '.'));
			return;
		}

		if (pricePerUnit.endsWith('.')) {
			onChange(pricePerUnit);
			return;
		}

		if (pricePerUnit.startsWith('-')) {
			onChange(pricePerUnit.slice(1));
			return;
		}

		const numericValue = pricePerUnit.toFloat();
		const numberOfDecimals = pricePerUnit.split('.')[1]?.length ?? 0;

		if (numberOfDecimals > 4) {
			return;
		}

		if (isNaN(numericValue)) {
			onChange(pricePerUnit.slice(0, -1));
			return;
		}

		if (numericValue <= MAX_PRICE) {
			onChange(numericValue.toDefaultNumberFormat(numberOfDecimals), true);
		}
	};

	return (
		<Input
			label="Price per unit"
			value={value}
			disabled={disabled}
			onChange={onUnitPriceChange}
			errorMessage={error ?? ''}
			required
			placeholder="0.0000"
		/>
	);
};

export default PricePerUnit;
