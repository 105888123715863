import { Box, HStack, Text, VStack } from '@montel/montelpro-shared-components-v2';
import styled, { css } from 'styled-components';

export const HStackFullWidth = styled<any>(HStack)`
	width: 100%;
`;

export const VStackFullWidth = styled<any>(VStack)`
	width: 100%;
`;

export const StyledCompanyBox = styled<any>(Box)`
	${({ theme }) => {
		const { colors } = theme;
		return css`
			&:hover {
				cursor: pointer;
				background-color: ${colors.element};
			}
		`;
	}}
`;
export const StyledSocialLink = styled.a<any>`
	${({ $isActive, theme }) => {
		const { colors } = theme;
		return css`
			cursor: ${$isActive ? 'pointer' : 'default'};
			svg {
				color: ${$isActive ? colors.white : colors.subElement};
			}
			&:hover {
				pointer-events: ${$isActive ? 'inherit' : 'none'};
			}
		`;
	}};
`;

export const CompanyLogoWithOverlay = styled.div`
	position: relative;
	.company-logo-overlay {
		position: absolute;
		top: 0;
		left: 0;
	}
`;

export const StyledCompanyLogo = styled.div`
	position: relative;
	.company-logo-text {
		padding-top: 0.75rem;
		position: absolute;
		text-align: center;
		width: 100%;
	}
`;

export const StyledActionButtonsGroup = styled<any>(HStack)`
	margin-top: 1rem;
`;

export const StyledCompanyName = styled<any>(Text)`
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	max-width: 280px;
`;

export const StyledDashDivider = styled.span`
	${({ theme }) => css`
		color: ${theme.colors.white};
		font-size: ${theme.typography.size.xs};
		line-height: ${theme.typography.lineHeight.base};
		margin-left: ${theme.spacing.xxs};
		margin-right: ${theme.spacing.xxs};
	`};
`;
