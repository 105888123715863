import { Table } from '@tanstack/react-table';
import { StyledHeadRow } from 'components/OrderTables/OrderTable.styles';
import Th from 'components/Tables/Th';
import { THead } from './TableStyles';

function TableHead<T>(props: { table: Table<T> }) {
	const { table } = props;
	return (
		<THead>
			{table.getHeaderGroups().map((headerGroup) => (
				<StyledHeadRow key={headerGroup.id}>
					{headerGroup.headers.map((header) => (
						<Th key={header.id} header={header} />
					))}
				</StyledHeadRow>
			))}
		</THead>
	);
}

export default TableHead;
