import { FunctionComponent } from 'react';
import { Center, H2, HCenter, IconCheck, ITheme, Text } from '@montel/montelpro-shared-components-v2';
import styled, { useTheme } from 'styled-components';

const VerificationStep: FunctionComponent = () => {
	const { colors } = useTheme() as ITheme;

	return (
		<StyledContainer gap="xxl">
			<BackgroundCircle>
				<IconCheck color={colors.white} boxSize={'2rem'} />
			</BackgroundCircle>
			<StyledTextContainer gap="lg">
				<H2 align={'center'}>Registration completed!</H2>
				<Text align={'center'} color={colors.secondaryText}>
					Your company registration is now subject to a manual audit. You will be notified when this process
					is complete.
				</Text>
			</StyledTextContainer>
		</StyledContainer>
	);
};

const StyledContainer = styled(HCenter)`
	margin-top: 7.25rem;
`;
const StyledTextContainer = styled(HCenter)`
	max-width: 24.5rem;
`;

const BackgroundCircle = styled(Center)`
	border-radius: 100%;
	background-color: ${({ theme }) => theme.colors.lightPine};
	padding: 1.5rem;
`;
export default VerificationStep;
