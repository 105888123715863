import styled, { css } from 'styled-components';

export const StyledTableRow = styled.tr<{ $isDisabled: boolean }>`
	text-align: left;
	line-height: 3rem;
	vertical-align: middle;
	${({ $isDisabled }) => {
		if ($isDisabled) {
			return css`
				opacity: 0.5;
				pointer-events: none;
			`;
		}
	}}
`;
