export enum MarketRoleEnum {
	Default = 0,
	Corporate = 1,
	Portfolio = 2,
	Trading = 3,
	PowerGenerator = 4,
	Broker = 5,
	Bank = 6,
	Investment = 7,
	Distribution = 8,
	Other = 9,
}
