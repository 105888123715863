import React, { FunctionComponent } from 'react';
import { Checkbox, Input, Link, TextArea, VStack } from '@montel/montelpro-shared-components-v2';
import tooltips from '../tooltips';
import { CompanyRegistrationState } from '../types';
import getValidationErrorsMediaStep from './getValidationErrorsMediaStep';
import LogoUpload from './LogoUpload';

const MediaStep: FunctionComponent<{
	state: CompanyRegistrationState;
	setState: React.Dispatch<CompanyRegistrationState>;
	showErrors: boolean;
	setLogo: React.Dispatch<File | undefined>;
	logo?: File;
}> = ({ state, setState, setLogo, logo, showErrors }) => {
	const errors = getValidationErrorsMediaStep(state);
	return (
		<VStack gap={'lg'} align={'stretch'}>
			<Input
				label="Company web page"
				value={state.companyWebPage}
				onChange={(event: any) => setState({ ...state, companyWebPage: event.target.value })}
			/>
			<Input
				label="LinkedIn"
				value={state.linkedIn}
				onChange={(event: any) => setState({ ...state, linkedIn: event.target.value })}
			/>
			<Input
				label="X/Twitter"
				value={state.twitter}
				onChange={(event: any) => setState({ ...state, twitter: event.target.value })}
			/>
			<Input
				label="Facebook"
				value={state.facebook}
				onChange={(event: any) => setState({ ...state, facebook: event.target.value })}
			/>
			<TextArea
				label="Company description"
				placeholder="Short public company description ..."
				tooltip={tooltips.companyText}
				rows={4}
				value={state.companyText}
				onChange={(event: any) => setState({ ...state, companyText: event.target.value })}
			/>
			<LogoUpload logo={logo} setLogo={setLogo} />
			<Checkbox
				label={
					<span>
						I accept{' '}
						<Link href="/terms-and-conditions" external>
							Terms and Conditions
						</Link>
					</span>
				}
				checked={Boolean(state.termsAccepted)}
				onChange={() => setState({ ...state, termsAccepted: !state.termsAccepted })}
				errorMessage={showErrors && errors.termsAccepted}
			/>
		</VStack>
	);
};

export default MediaStep;
