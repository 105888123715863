import styled from 'styled-components';

export const Container = styled.div`
	width: 1316px;
	@media (min-width: 1760px) {
		width: 1760px;
	}
	@media (min-width: 2204px) {
		width: 2204px;
	}
`;

export const StyledSearchContainer = styled.div`
	width: 318px;
`;
