import { FunctionComponent, useMemo } from 'react';
import { Quote } from 'common/interfaces/Quote';
import { useOrderDetailsContext } from '../OrderDetails/OrderDetailsContext';
import { setWidth } from '../Tables/Cells/utils/setWidth';
import { quoteTableColumns } from './Columns/quoteTableColumns';
import QuotesTableBase from './QuotesTableBase';

const QuotesTable: FunctionComponent = () => {
	const { order, onOpenQuoteView } = useOrderDetailsContext();
	const { quotes } = order;

	const onRowClicked = (quote: Quote) => {
		onOpenQuoteView(quote);
	};

	const columns = useMemo(
		() => [
			quoteTableColumns.ownerIcon({
				...setWidth('5%'),
				cellProps: {
					justify: 'center',
				},
			}),
			quoteTableColumns.expiry({
				cellProps: {
					countdownFontSize: 'xs',
				},
			}),
			quoteTableColumns.quantity({
				cellProps: {
					textFontSize: 'xs',
					unitFontSize: 'xxs',
				},
			}),
			quoteTableColumns.price({
				cellProps: {
					textFontSize: 'xs',
					unitFontSize: 'xxs',
				},
			}),
			quoteTableColumns.totalPrice({
				cellProps: {
					textFontSize: 'xs',
					unitFontSize: 'xxs',
				},
			}),
			quoteTableColumns.status({
				cellProps: {
					align: 'center',
					justify: 'center',
					size: 'xs',
				},
				headCellProps: {
					offsetLeft: '1.5rem',
				},
			}),
		],
		[quotes],
	);

	const initialSorting = [
		{ id: 'status', desc: true },
		{ id: 'expiryTimestamp', desc: false },
	];

	if (!quotes.length) return <></>;

	return (
		<QuotesTableBase
			quotes={quotes}
			columns={columns}
			initialSorting={initialSorting}
			onRowClicked={onRowClicked}
		/>
	);
};

export default QuotesTable;
