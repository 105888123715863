import { FunctionComponent, useEffect, useState } from 'react';
import {
	Box,
	Button,
	HStack,
	IconButton,
	IconCrossCancel,
	Input,
	Text,
	VStack,
} from '@montel/montelpro-shared-components-v2';
import { getToken } from 'firebase/messaging';
import { BrowserToken } from 'providers/AlertSettingsProvider';
import { useFirebaseContext } from 'providers/FirebaseProvider';

const BrowserList: FunctionComponent<{
	browserToken: string;
	setBrowserToken: (token: string) => void;
	browsers: BrowserToken[];
	addBrowser: (name: string, token: string) => void;
	removeBrowser: (token: string) => void;
}> = ({ browserToken, setBrowserToken, browsers, addBrowser, removeBrowser }) => {
	const [addingBrowser, setAddingBrowser] = useState(false);
	const [newBrowserName, setNewBrowserName] = useState('');

	const { messaging, vapidKey, isSupported } = useFirebaseContext();

	const notificationsSupported = 'Notification' in window && isSupported;
	const notificationsDeniedOrUnsupported = !notificationsSupported || Notification.permission === 'denied';
	const noNewBrowserNameSpecified = addingBrowser && !newBrowserName;
	const currentBrowserActivated = browsers?.some((browser) => browser.token === browserToken);

	useEffect(() => {
		if (!browsers?.length) {
			setAddingBrowser(true);
		}
	}, [browsers]);

	const triggerAddButton = async () => {
		switch (Notification.permission) {
			case 'denied':
			// @ts-expect-error: NoFallthroughCaseInSwtich
			case 'default':
				/*
					If below permission request is granted,
					fall through to next switch case is intentional.
				*/
				const permission = await Notification.requestPermission();
				if (permission !== 'granted') {
					setAddingBrowser(false);
					break;
				}

			case 'granted':
				if (addingBrowser && newBrowserName) {
					await addBrowserToList(newBrowserName);
					setNewBrowserName('');
				}
				setAddingBrowser(!addingBrowser);
		}
	};

	const addBrowserToList = async (name: string) => {
		if (!messaging) return;

		const title = 'Enabled browser notifications';
		const body = `You have enabled browser notifications based on your configuration. You will be notified here whenever one of your Alerts are triggered.`;

		try {
			const token = await getToken(messaging, { vapidKey });
			await addBrowser(name, token);
			setBrowserToken(token);
		} catch (error: any) {
			console.error(error);
			new Notification('Unable to add browser for Marketplace Alerts', {
				body: 'An unexpected error occured and we were unable to register your browser for Marketplace Alerts. Please ensure that you have granted permission for browser notifications, or try again later.',
			});

			return;
		}

		new Notification(title, {
			body: body,
		});
	};

	return (
		<VStack fullWidth gap="lg">
			{!!browsers?.length && (
				<VStack fullWidth wrap="nowrap">
					<Text>Approved browsers: </Text>
					{browsers.map((browser: BrowserToken, i: number) => (
						<Box key={i}>
							<HStack justify="space-between" align="center">
								<HStack>
									<Text>{browser.name}</Text>
									{browser.token === browserToken && <Text>Current</Text>}
								</HStack>
								<IconButton
									icon={<IconCrossCancel />}
									variant="tertiary"
									onClick={() => removeBrowser(browser.token)}
								/>
							</HStack>
						</Box>
					))}
				</VStack>
			)}

			<HStack wrap="nowrap" align="center">
				{addingBrowser && (
					<Input
						autoFocus
						value={newBrowserName}
						placeholder={'e.g. "Home Edge"'}
						label="addbrowser"
						hideLabel
						onChange={(e: any) => setNewBrowserName(e.target.value)}
						onKeyDown={async (e: any) => {
							if (e.key === 'Enter') {
								await triggerAddButton();
							} else if (e.key === 'Escape') {
								setAddingBrowser(false);
							}
						}}
					/>
				)}
				<Button
					text="Add current"
					variant={addingBrowser ? 'primary' : 'secondary'}
					isDisabled={
						notificationsDeniedOrUnsupported ||
						noNewBrowserNameSpecified ||
						currentBrowserActivated ||
						!isSupported
					}
					onClick={async () => await triggerAddButton()}
				/>
			</HStack>
		</VStack>
	);
};

export default BrowserList;
