import { FunctionComponent } from 'react';
import { RequestStatus } from '@montel/montelpro-shared-components/enums';
import {
	Button,
	Modal,
	ModalBody,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
} from '@montel/montelpro-shared-components-v2';
import { ORDER_API_ROUTE } from 'apiRoutes';
import { Order } from 'common/interfaces/Order';
import useSubmit, { POST } from 'hooks/useSubmit';

interface DeleteAllOrdersModalProps {
	isOpen: boolean;
	closeModal: () => void;
	onSuccess: () => void;
	orders: Order[];
}

export const DeleteAllOrdersModal: FunctionComponent<DeleteAllOrdersModalProps> = ({
	isOpen,
	closeModal,
	orders,
	onSuccess,
}) => {
	const [submit, status] = useSubmit(`${ORDER_API_ROUTE}/CancelOrders`, POST, {
		messageOnSuccess: 'All your orders have been deleted.',
		messageOnError:
			'An error occurred when deleting one or more of your orders. Please refresh the page and try again.',
		showApiError: false,
	});

	const onCancelAllActiveOrders = async () => {
		const request = {
			orders: orders.map((o: Order) => {
				return {
					id: o.id,
					expectedVersion: o.version,
				};
			}),
		};

		const result = await submit(request);

		if (result.success) {
			onSuccess();
			closeModal();
		}
	};

	return (
		<Modal isOpen={isOpen} onClose={closeModal}>
			<ModalOverlay />
			<ModalContent size={6}>
				<ModalHeader>Cancel all my orders</ModalHeader>
				<ModalBody>This will cancel all your active orders from the market.</ModalBody>
				<ModalFooter>
					<Button text="Cancel" variant="secondary" onClick={closeModal} />
					<Button
						text="Confirm"
						variant="primary"
						type="submit"
						onClick={onCancelAllActiveOrders}
						loadingText="Cancelling"
						isLoading={status === RequestStatus.LOADING}
					/>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};
