import ProgressLine from '../Progress/ProgressLine';
import AnimateWidth from '../Prompt/AnimateWidth';
import OrderDetailsBody from './OrderDetailsBody/OrderDetailsBody';
import { useOrderDetailsContext } from './OrderDetailsContext';
import OrderDetailsHeader from './OrderDetailsHeader';
import QuoteDetails from './QuoteDetails';

const OrderDetailsContent = () => {
	const { minutesUntilExpiry, selectedQuote } = useOrderDetailsContext();

	return (
		<>
			<ProgressLine minutesUntilExpiry={minutesUntilExpiry} />
			<OrderDetailsHeader />
			<OrderDetailsBody />

			<AnimateWidth xFrom={650} xTo={selectedQuote ? 0 : 650}>
				{selectedQuote && <QuoteDetails />}
			</AnimateWidth>
		</>
	);
};

export default OrderDetailsContent;
