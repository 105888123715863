import React, { createContext, FunctionComponent, useContext, useEffect, useState } from 'react';
import { Order } from 'common/interfaces/Order';
import { Quote } from 'common/interfaces/Quote';

type PriorityColor = 'success' | 'error' | 'heritageOrange';

export interface IOrderDetailsContext {
	order: Order;
	isExpired: boolean;
	setIsExpired: (isExpired: boolean) => void;
	priorityColor: PriorityColor;
	minutesUntilExpiry: number;
	closeModal: () => void;
	tradePromptOpen: boolean;
	openTradePrompt: () => void;
	closeTradePrompt: () => void;
	tradeSubmitted: boolean;
	setOrderAsTraded: () => void;
	selectedQuote: Quote | null;
	setSelectedQuote: (quote: Quote | null) => void;
	onCloseQuoteView: () => void;
	onOpenQuoteView: (quote: Quote) => void;
	acceptQuoteWithId: string | null;
	setAcceptQuoteWithId: (quoteId: string | null) => void;
}

export const OrderDetailsContext = createContext<any>({});

const getTimeRemainingInMinutes = (order: Order) => (new Date(order.expiryTime).getTime() - Date.now()) / 1000 / 60;

const getProgressColor = (timeRemainingInMinutes: number) => {
	if (timeRemainingInMinutes <= 30) return 'error';
	else if (timeRemainingInMinutes <= 180) return 'heritageOrange';
	else return 'success';
};

export const OrderDetailsContextProvider: FunctionComponent<{
	order: Order;
	children: any;
	closeModal: () => void;
}> = ({ order, children, closeModal }) => {
	const initExpiryTime = getTimeRemainingInMinutes(order);
	const [minutesUntilExpiry, setMinutesUntilExpiry] = useState<number>(initExpiryTime);
	const [isExpired, setIsExpired] = useState<boolean>(new Date(order.expiryTime) < new Date());
	const [priorityColor, setPriorityColor] = useState<PriorityColor>(
		getProgressColor(getTimeRemainingInMinutes(order)),
	);
	const [tradePromptOpen, setTradePromptOpen] = useState<boolean>(false);
	const [tradeSubmitted, setTradeSubmitted] = useState<boolean>(false);

	const [acceptQuoteWithId, setAcceptQuoteWithId] = useState<string | null>(null);

	// Quote view
	const [selectedQuote, setSelectedQuote] = useState<Quote | null>(null);

	const onCloseQuoteView = () => {
		setSelectedQuote(null);
	};

	const onOpenQuoteView = (quote: Quote) => {
		setSelectedQuote(quote);
	};

	useEffect(() => {
		const updateRemainingTime = setInterval(() => {
			if (new Date(order.expiryTime) <= new Date()) setIsExpired(true);

			const timeRemainingInMinutes = getTimeRemainingInMinutes(order);

			setMinutesUntilExpiry(timeRemainingInMinutes);
			setPriorityColor(getProgressColor(timeRemainingInMinutes));
		}, 10000);

		return () => clearInterval(updateRemainingTime);
	}, [initExpiryTime]);

	useEffect(() => {
		if (selectedQuote === null) return;
		const { id } = selectedQuote;
		const { quotes } = order;
		const quote = quotes.find((quote) => quote.id === id);
		if (quote) setSelectedQuote(quote);
	}, [order]);

	return (
		<OrderDetailsContext.Provider
			value={{
				order: order,
				isExpired: isExpired,
				setIsExpired: setIsExpired,
				minutesUntilExpiry: minutesUntilExpiry,
				priorityColor: priorityColor,
				closeModal,
				tradePromptOpen,
				openTradePrompt: () => setTradePromptOpen(true),
				closeTradePrompt: () => setTradePromptOpen(false),
				tradeSubmitted,
				setOrderAsTraded: () => setTradeSubmitted(true),
				selectedQuote,
				setSelectedQuote,
				onCloseQuoteView,
				acceptQuoteWithId,
				setAcceptQuoteWithId,
				onOpenQuoteView,
			}}
		>
			{children}
		</OrderDetailsContext.Provider>
	);
};

export const useOrderDetailsContext = () => {
	const context: IOrderDetailsContext = useContext(OrderDetailsContext);
	if (context === undefined) {
		throw new Error(`useOrderDetailsContext must be used within a OrderDetailsContext`);
	}

	return context;
};
