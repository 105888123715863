import { FunctionComponent } from 'react';
import { Text } from '@montel/montelpro-shared-components-v2';

export interface ResultsCounterProps {
	count?: number;
}

const ResultsCounter: FunctionComponent<ResultsCounterProps> = ({ count }) => {
	if (!count) return null;
	const text = count === 1 ? 'result' : 'results';
	return <Text color="secondaryText" weight="semiBold" size="sm">{`${count} ${text}`}</Text>;
};

export default ResultsCounter;
