import { FunctionComponent, useState } from 'react';
import { ProductEnum, Region } from '@montel/montelpro-shared-components/enums';
import { HStack, Text } from '@montel/montelpro-shared-components-v2';
import {
	createColumnHelper,
	getCoreRowModel,
	getSortedRowModel,
	Row,
	SortingState,
	useReactTable,
} from '@tanstack/react-table';
import { flexRender } from '@tanstack/react-table';
import { Alert } from 'common/interfaces/Alert';
import ActiveTech from 'components/ActiveTech';
import { HeadCell } from 'components/Tables/Cells';
import { AlertType } from '../types';
import { StyledIndeterminateCheckbox, StyledTable } from './CustomAlertsTable.style';
import { StyledHeadRow } from './HeadRow.style';
import ManageCell from './ManageCell';
import ProductionCell from './ProductionCell';
import StatusCell from './StatusCell';
import { StyledTableRow } from './TableRow.style';

export interface TableProps {
	alerts: Alert[];
	enabledAlertTypes: AlertType[];
	onEdit: (alert: Alert) => void;
	onDelete: (alert: Alert) => void;
	onToggleActive: (alert: Alert) => void;
	setSelectedAlertIDs: (alertIDs: number[]) => void;
	rowSelection: { [key: number]: boolean } | NonNullable<unknown>;
	setRowSelection: (rowSelection: { [key: number]: boolean } | NonNullable<unknown>) => void;
}

const CustomAlertsTable: FunctionComponent<TableProps> = ({
	alerts,
	enabledAlertTypes,
	onEdit,
	onDelete,
	onToggleActive,
	rowSelection,
	setRowSelection,
}) => {
	if (!alerts || alerts.length === 0) return null;

	const [sorting, setSorting] = useState<SortingState>([]);

	const columnHelper = createColumnHelper<Alert>();

	const columns = [
		{
			id: 'select',
			header: ({ table }: { table: any }) => (
				<StyledIndeterminateCheckbox
					{...{
						checked: table.getIsAllRowsSelected(),
						indeterminate: table.getIsSomeRowsSelected(),
						onChange: table.getToggleAllRowsSelectedHandler(),
					}}
				/>
			),
			cell: ({ row }: { row: any }) => (
				<StyledIndeterminateCheckbox
					{...{
						checked: row.getIsSelected(),
						disabled: !row.getCanSelect(),
						indeterminate: row.getIsSomeSelected(),
						onChange: row.getToggleSelectedHandler(),
					}}
				/>
			),
		},
		columnHelper.accessor((row) => AlertType[row.type], {
			id: 'type',
			enableSorting: true,
			header: ({ header }) => (
				<HeadCell
					text="Type"
					sortable
					onClick={header.column.getToggleSortingHandler()}
					order={header.column.getIsSorted() as 'asc' | 'desc'}
				/>
			),
			cell: (info) => <Text size="md">{info.getValue()}</Text>,
		}),
		columnHelper.accessor('product', {
			id: 'product',
			enableSorting: true,
			header: ({ header }) => (
				<HeadCell
					text="Product"
					sortable
					onClick={header.column.getToggleSortingHandler()}
					order={header.column.getIsSorted() as 'asc' | 'desc'}
				/>
			),
			cell: (info) => <Text size="md">{ProductEnum[info.getValue()]}</Text>,
		}),
		columnHelper.accessor('technology', {
			id: 'technology',
			enableSorting: true,
			header: ({ header }) => (
				<HeadCell
					text="Technology"
					sortable
					onClick={header.column.getToggleSortingHandler()}
					order={header.column.getIsSorted() as 'asc' | 'desc'}
				/>
			),
			cell: (info) => {
				return (
					<HStack align="center" justify="flex-start">
						<ActiveTech tech={[info.getValue()]} />
					</HStack>
				);
			},
		}),
		columnHelper.accessor('region', {
			id: 'region',
			enableSorting: true,
			header: ({ header }) => (
				<HeadCell
					text="Region"
					sortable
					onClick={header.column.getToggleSortingHandler()}
					order={header.column.getIsSorted() as 'asc' | 'desc'}
				/>
			),
			cell: (info) => <Text size="md">{Region[info.getValue()]}</Text>,
		}),
		columnHelper.accessor((row) => row.productionYear, {
			id: 'productionPeriod',
			enableSorting: true,
			header: ({ header }) => (
				<HeadCell
					text="Production period"
					sortable
					onClick={header.column.getToggleSortingHandler()}
					order={header.column.getIsSorted() as 'asc' | 'desc'}
				/>
			),
			cell: (info) => <ProductionCell row={info.row.original} />,
		}),
		columnHelper.accessor((row) => row.id, {
			id: 'manage',
			header: () => <HeadCell text="Manage" />,
			cell: (info) => (
				<ManageCell onDelete={() => onDelete(info.row.original)} onEdit={() => onEdit(info.row.original)} />
			),
		}),
		columnHelper.accessor((row) => row.active, {
			id: 'status',
			enableSorting: true,
			header: ({ header }) => (
				<HeadCell
					text="Status"
					sortable
					onClick={header.column.getToggleSortingHandler()}
					order={header.column.getIsSorted() as 'asc' | 'desc'}
				/>
			),
			cell: (info) => (
				<StatusCell
					key={info.row.original.id}
					isDisabled={!info.row.getCanSelect()}
					active={info.getValue()}
					onToggleActive={() => onToggleActive(info.row.original)}
				/>
			),
		}),
	];

	const table = useReactTable({
		data: alerts,
		columns,
		state: {
			rowSelection,
			sorting,
		},
		enableRowSelection: (row: Row<Alert>) => !enabledAlertTypes.includes(row.original.type),
		onRowSelectionChange: setRowSelection,
		onSortingChange: setSorting,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
	});

	return (
		<StyledTable>
			<thead>
				{table.getHeaderGroups().map((headerGroup) => (
					<StyledHeadRow key={headerGroup.id}>
						{headerGroup.headers.map((header) => (
							<th key={header.id}>
								{header.isPlaceholder
									? null
									: flexRender(header.column.columnDef.header, header.getContext())}
							</th>
						))}
					</StyledHeadRow>
				))}
			</thead>
			<tbody>
				{table.getRowModel().rows.map((row) => (
					<StyledTableRow $isDisabled={!row.getCanSelect()} key={row.id}>
						{row.getVisibleCells().map((cell) => (
							<td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
						))}
					</StyledTableRow>
				))}
			</tbody>
		</StyledTable>
	);
};

export default CustomAlertsTable;
