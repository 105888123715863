import { SelectOptionType } from '@montel/montelpro-shared-components-v2';
import { Label } from 'common/enums/OrderEnums';
import { deprecatedLabels, labelNames } from 'common/enums/valueLookups/LabelLookup';

const getLabelOptions = (removeDeprecatedLabels = true): SelectOptionType[] => {
	let options = Object.keys(labelNames)
		.slice(1)
		.map((r) => ({
			value: Label[r as keyof typeof Label],
			label: labelNames[r as keyof typeof Label],
		}));

	if (removeDeprecatedLabels) {
		options = options.filter((o) => !deprecatedLabels.includes(o.value));
	}

	return options;
};

export default getLabelOptions;
