import { VStack } from '@montel/montelpro-shared-components-v2';
import { css, styled } from 'styled-components';

export const StyledYearHeading = styled(VStack)<{ $topGroup?: boolean }>`
	${({ theme, $topGroup }) => css`
		margin: ${$topGroup ? theme.spacing.sm : theme.spacing.xl} 0 ${theme.spacing.sm} 0;
	`};
`;

export const StyledUnderline = styled.div`
	height: 2px;
	width: 100%;

	${({ theme }) => css`
		background-color: ${theme.colors.element};
	`}
`;
