import { Box, VStack } from '@montel/montelpro-shared-components-v2';
import { styled } from 'styled-components';
import Td from 'components/Tables/Td';

export const StyledTable = styled.table`
	table-layout: fixed;
	width: 100%;
	border-collapse: collapse;
	td:first-child,
	th:first-child {
		padding-left: ${({ theme }) => theme.spacing.xl};
	}

	td:last-child,
	th:last-child {
		padding-right: ${({ theme }) => theme.spacing.xl};
	}
`;

export const TableWrapper = styled(VStack)`
	border-radius: ${({ theme }) => theme.borderRadii.lg};
	padding: ${({ theme }) => `${theme.spacing.lg} 0`};
	background-color: ${({ theme }) => theme.colors.widget};
`;

export const StyledBox = styled(Box)`
	display: flex;
	flex-direction: row;
	background-color: ${({ theme }) => theme.colors.widget};
	padding: ${({ theme }) => `0 ${theme.spacing.lg}`};
	gap: ${({ theme }) => theme.spacing.lg};
`;

export const StyledTableData = styled(Td)<{ $width?: string }>`
	padding: 0.5rem 0.25rem;
	${({ $width }) => $width && `width: ${$width};`}
`;

export const StyledTableRow = styled.tr<{ $clickable?: boolean }>`
	${({ $clickable }) => $clickable && `cursor: pointer;`}
	&:hover {
		background-color: ${({ theme }) => theme.colors.element};
	}
`;

export const StyledHeadRow = styled.tr`
	text-align: left;
	vertical-align: middle;
	${({ theme }) => `
		padding-bottom: ${theme.spacing.sm};
		height: ${theme.spacing.xxl};
	`};
`;
