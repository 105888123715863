import { useState } from 'react';
import { toast } from 'react-toastify';
import { SelectOptionType, ToastMessageWithAction } from '@montel/montelpro-shared-components';
import { RequestStatus } from '@montel/montelpro-shared-components/enums';
import { JOIN_COMPANY_API_ROUTE } from 'apiRoutes';
import useSubmit, { POST } from 'hooks/useSubmit';

type useSendJoinRequestType = {
	sendJoinRequest: () => Promise<void>;
	showValidationError: boolean;
	submitStatus: RequestStatus;
};

const useSendJoinRequest = (
	chosenCompany: SelectOptionType | null,
	setChosenCompany: (option: SelectOptionType | null) => void,
	addNewPendingCompany: (id: number) => void,
	cancelJoinCompany: (id: number) => Promise<void>,
): useSendJoinRequestType => {
	const companyId = chosenCompany && chosenCompany.value;

	const [submit, submitStatus, , resetSubmitState] = useSubmit(
		JOIN_COMPANY_API_ROUTE.replace(':companyId', companyId?.toString()),
		POST,
		{
			messageOnError: 'An error occured when requesting to join the company, please try again!',
		},
	);
	const [showValidationError, setShowValidationError] = useState(false);

	const sendJoinRequest = async () => {
		if (!chosenCompany) {
			setShowValidationError(true);
			return;
		}
		const result = await submit(undefined);
		if (result.success) {
			addNewPendingCompany(companyId);
			toast.success(
				<ToastMessageWithAction
					message={'Join request sent'}
					actionMessage={'Cancel Join'}
					action={() => cancelJoinCompany(companyId)}
				/>,
			);
			setShowValidationError(false);
			setChosenCompany(null);
			resetSubmitState();
		}
	};

	return {
		sendJoinRequest,
		showValidationError,
		submitStatus,
	};
};

export default useSendJoinRequest;
