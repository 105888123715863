import { FunctionComponent } from 'react';
import {
	Center,
	Modal,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
} from '@montel/montelpro-shared-components-v2';
import { Order } from 'common/interfaces/Order';
import { styled } from 'styled-components';
import PlaceQuoteForm from './PlaceQuoteForm/PlaceQuoteForm';
import { PlaceQuoteFormContextProvider } from './PlaceQuoteForm/PlaceQuoteFormContext';
import TradePrompt from './TradePrompt/TradePrompt';
import OrderDetailsContent from './OrderDetailsContent';
import { OrderDetailsContextProvider } from './OrderDetailsContext';

export interface OrderDetailsModalProps {
	order: Order | undefined;
	isOpen: boolean;
	onClose: () => void;
}

const StyledModalContent = styled(ModalContent).attrs({ size: 6 })`
	section {
		overflow: hidden;
	}
`;

const OrderDetailsModal: FunctionComponent<OrderDetailsModalProps> = ({ order, isOpen, onClose }) => (
	<Modal isOpen={isOpen} onClose={onClose}>
		<ModalOverlay />
		<StyledModalContent>
			{order ? (
				<OrderDetailsContextProvider order={order} closeModal={onClose}>
					<PlaceQuoteFormContextProvider>
						<OrderDetailsContent />
						<TradePrompt />

						<PlaceQuoteForm />
					</PlaceQuoteFormContextProvider>
				</OrderDetailsContextProvider>
			) : (
				<>
					<ModalHeader />
					<Center style={{ minHeight: '600px' }}>
						<Text color="secondaryText" fontStyle="italic">
							Order is no longer available
						</Text>
					</Center>
				</>
			)}
			<ModalFooter />
		</StyledModalContent>
	</Modal>
);

export default OrderDetailsModal;
