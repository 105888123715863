import React, { FunctionComponent } from 'react';
import { HStack, Input, Select, VStack } from '@montel/montelpro-shared-components-v2';
import { selectCountries } from 'store/lists/listsSelectors';
import { MultipleEmails } from 'components/MultipleEmails';
import { getMarketRoleOptions } from '../../../common/options/getMarketRoleOptions';
import { useAppSelector } from '../../../store/hooks';
import tooltips from '../tooltips';
import { CompanyRegistrationState } from '../types';
import getValidationErrorsInfoStep from './getValidationErrorsInfoStep';

const CompanyInfoStep: FunctionComponent<{
	state: CompanyRegistrationState;
	setState: React.Dispatch<any>;
	showErrors: boolean;
}> = ({ state, setState, showErrors }) => {
	const countryOptions = useAppSelector(selectCountries);

	const errors = getValidationErrorsInfoStep(state);
	return (
		<VStack gap={'lg'} align={'stretch'}>
			<Input
				label={'Company name'}
				required
				value={state.companyName}
				onChange={(event: any) =>
					setState({
						...state,
						companyName: event.target.value,
					})
				}
				errorMessage={showErrors && errors.companyName}
			/>
			<Input
				label={'VAT / Registration No.'}
				tooltip={tooltips.vat}
				required
				value={state.vat}
				onChange={(event: any) => setState({ ...state, vat: event.target.value })}
				errorMessage={showErrors && errors.vat}
			/>
			<HStack gap={'md'} wrap={'nowrap'}>
				<Input
					label={'Street'}
					required
					value={state.street}
					onChange={(event: any) => setState({ ...state, street: event.target.value })}
					errorMessage={showErrors && errors.street}
				/>
				<Input
					label={'Postal code'}
					required
					value={state.postalCode}
					onChange={(event: any) =>
						setState({
							...state,
							postalCode: event.target.value,
						})
					}
					errorMessage={showErrors && errors.postalCode}
				/>
			</HStack>

			<HStack gap={'md'} wrap={'nowrap'}>
				<Input
					label={'City'}
					required
					value={state.city}
					onChange={(event: any) => setState({ ...state, city: event.target.value })}
					errorMessage={showErrors && errors.city}
				/>
				<Select
					label={'Country'}
					options={countryOptions}
					required
					value={state.countryCode}
					onChange={(value) => setState({ ...state, countryCode: value })}
					errorMessage={showErrors && errors.countryCode}
				/>
			</HStack>
			<MultipleEmails
				label={"Signer's email addresses"}
				value={state.signersEmails}
				tooltip={tooltips.signersEmails}
				setValue={(value: string[]) =>
					setState({
						...state,
						signersEmails: value,
					})
				}
				errorMessage={showErrors && errors.signersEmails}
			/>
			<MultipleEmails
				label={'KYC contact email addresses'}
				value={state.kycContactEmails}
				tooltip={tooltips.kycContactEmails}
				setValue={(value: string[]) =>
					setState({
						...state,
						kycContactEmails: value,
					})
				}
				errorMessage={showErrors && errors.kycContactEmails}
			/>
			<Select
				label={'Market role'}
				options={getMarketRoleOptions()}
				required
				value={state.marketRole}
				onChange={(value) => setState({ ...state, marketRole: value })}
				errorMessage={showErrors && errors.marketRole}
			/>
		</VStack>
	);
};

export default CompanyInfoStep;
