import { FunctionComponent } from 'react';
import { HStack, Link, MailtoLink, VStack } from '@montel/montelpro-shared-components-v2';
import LabelAndValue from 'primitives/LabelAndValue';
import { styled } from 'styled-components';
import { toAbsoluteUrl } from 'utils/formatUtils/toAbsoluteUrl';
import { MyCompanyState } from '../MyCompanyState';

const ContactOverview: FunctionComponent<{ state: MyCompanyState }> = ({ state }) => {
	const {
		street,
		city,
		postalCode,
		countryCode,
		signersEmails,
		kycContactEmails,
		companyWebPage,
		facebook,
		twitter,
		linkedIn,
	} = state;
	return (
		<VStack gap="xl">
			<LabelAndValue label="Address" value={street} size="sm" />
			<HStack gap="xl" wrap="nowrap" fullWidth>
				<LabelAndValue label="Postal code" value={postalCode} size="sm" />
				<LabelAndValue label="City" value={city} size="sm" />
			</HStack>
			<LabelAndValue label="Country" value={countryCode?.label} size="sm" />
			<LabelAndValue
				label="Signer's email addresses"
				value={signersEmails?.map((email) => (
					<Comma key={email}>
						<MailtoLink email={email} />
					</Comma>
				))}
				size="sm"
			/>
			<LabelAndValue
				label="KYC contact email addresses"
				value={kycContactEmails?.map((email) => (
					<Comma key={email}>
						<MailtoLink email={email} />
					</Comma>
				))}
				size="sm"
			/>
			<LabelAndValue
				label="Web page"
				value={
					companyWebPage && (
						<Link external href={toAbsoluteUrl(companyWebPage)}>
							{companyWebPage}
						</Link>
					)
				}
				size="sm"
			/>
			<LabelAndValue
				label="Facebook"
				value={
					facebook && (
						<Link external href={toAbsoluteUrl(facebook)}>
							{facebook}
						</Link>
					)
				}
				size="sm"
			/>
			<LabelAndValue
				label="X / Twitter"
				value={
					twitter && (
						<Link external href={toAbsoluteUrl(twitter)}>
							{twitter}
						</Link>
					)
				}
				size="sm"
			/>
			<LabelAndValue
				label="LinkedIn"
				value={
					linkedIn && (
						<Link external href={toAbsoluteUrl(linkedIn)}>
							{linkedIn}
						</Link>
					)
				}
				size="sm"
			/>
		</VStack>
	);
};

const Comma = styled.span`
	&:not(:last-child)::after {
		content: ', ';
		color: ${({ theme }) => theme.colors.white};
		margin-right: ${({ theme }) => theme.spacing.xxs};
	}
`;
export default ContactOverview;
