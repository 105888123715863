import { SelectOptionType } from '@montel/montelpro-shared-components';
import { DeliveryDatePresetEnum, ExpiresPresetEnum, SubsidizedEnum } from 'common/enums';
import { OrderLifecycleEnum, OrderTypeEnum, Side as SideEnum } from 'common/enums/OrderEnums';
import { Order } from 'common/interfaces/Order';
import { OrderPreset } from 'common/interfaces/OrderPreset';
import getRegistryOperatorOptions from 'common/options/getRegistryOperatorOptions';
import getOptionByValue from 'utils/getOptionByValue';
import { filterValidProductionPeriodOptions } from 'components/NewOrder/utils/filterValidProductionPeriodOption';
import { getMaxPlantAgeOptions, getMinimumValidityOptions, getPlantCommissioningYearOptions } from '../options';
import { Technologies } from '../Steps/ProductStep/ProductStep.types';
import { OrderFormState } from '../types';
import { filterValidCounterpartyOptions } from './filterValidCounterpartyOptions';

export const convertPresetToOrderState = (
	preset: OrderPreset,
	counterpartyOptions: SelectOptionType[],
): OrderFormState => {
	const {
		presetId,
		side,
		product,
		expiryTime,
		expiryPreset,
		deliveryPreset,
		cost,
		availability,
		details,
		orderType,
	} = preset;

	const {
		tech,
		region,
		production,
		deliveryDate,
		registryOperator,
		label,
		subsidized,
		plantMaxAge,
		plantCommissioningYear,
	} = product;

	return {
		presetId: presetId,
		side: side as SideEnum,
		product: {
			technologies: tech as Technologies,
			region: region,
			label: label,
			subsidized: getSubsidized(subsidized),
			periodType: production.type,
			productionPeriods: filterValidProductionPeriodOptions(production),
			plantMaxAge:
				side === SideEnum.Bid ? getOptionByValue(getMaxPlantAgeOptions(), plantMaxAge)?.value : undefined,
			plantCommissioningYear:
				side === SideEnum.Ask
					? getOptionByValue(getPlantCommissioningYearOptions(), plantCommissioningYear)?.value
					: undefined,
		},
		delivery: {
			deliveryDatePreset: deliveryDate ? DeliveryDatePresetEnum.DATE : (deliveryPreset as DeliveryDatePresetEnum),
			deliveryDate: deliveryDate ? new Date(deliveryDate) : null,
			registryOperator: getOptionByValue(getRegistryOperatorOptions(true, true), registryOperator)?.value,
			minimumValidity: getOptionByValue(getMinimumValidityOptions(), details.minimumValidity)?.value || 4,
			comment: details.comments || '',
		},
		price: {
			orderType: orderType || OrderTypeEnum.FixedPrice,
			currency: cost.currency,
			quantity: cost.quantity.toString(),
			pricePerUnit: cost.price.toString(),
			expiryPreset: expiryTime ? ExpiresPresetEnum.SPECIFY : expiryPreset,
			expires: expiryTime ? new Date(expiryTime) : null,
			availableTo: availability.availableTo,
			orderCounterparties: filterValidCounterpartyOptions(
				counterpartyOptions,
				availability.selectedCounterparties,
			),
		},
		type: OrderLifecycleEnum.Preset,
	};
};

export const convertOrderToOrderState = (data: Order, counterpartyOptions: SelectOptionType[]): OrderFormState => {
	const { id, version, side, availability, product, cost, expiryTime, details, orderType } = data;
	const {
		subsidized,
		tech,
		region,
		production,
		registryOperator,
		label,
		deliveryDate,
		plantMaxAge,
		plantCommissioningYear,
	} = product;

	return {
		id: id,
		version: version,
		side: side as SideEnum,
		product: {
			technologies: tech as Technologies,
			region: region,
			label: label,
			subsidized: getSubsidized(subsidized),
			periodType: production.type,
			productionPeriods: filterValidProductionPeriodOptions(production),
			plantMaxAge:
				side === SideEnum.Bid ? getOptionByValue(getMaxPlantAgeOptions(), plantMaxAge)?.value : undefined,
			plantCommissioningYear:
				side === SideEnum.Ask
					? getOptionByValue(getPlantCommissioningYearOptions(), plantCommissioningYear)?.value
					: undefined,
		},
		delivery: {
			deliveryDatePreset: deliveryDate ? DeliveryDatePresetEnum.DATE : DeliveryDatePresetEnum.SPOT,
			deliveryDate: deliveryDate ? new Date(deliveryDate) : null,
			registryOperator: getOptionByValue(getRegistryOperatorOptions(true, true), registryOperator)?.value,
			minimumValidity: getOptionByValue(getMinimumValidityOptions(), details.minimumValidity)?.value,
			comment: details.comments || '',
		},
		price: {
			orderType: orderType,
			currency: cost.currency,
			quantity: cost?.quantity.toString(),
			pricePerUnit: cost?.price.toString(),
			expiryPreset: ExpiresPresetEnum.SPECIFY,
			expires: new Date(expiryTime),
			availableTo: availability.availableTo,
			orderCounterparties: filterValidCounterpartyOptions(
				counterpartyOptions,
				availability.selectedCounterparties,
			),
		},
		type: OrderLifecycleEnum.Edit,
	};
};

const getSubsidized = (subsidized?: boolean | null): SubsidizedEnum =>
	subsidized ? SubsidizedEnum.YES : subsidized === false ? SubsidizedEnum.NO : SubsidizedEnum.NOT_REQUIRED;
