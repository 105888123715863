import { useState } from 'react';
import z from 'zod';

export interface ValidationResult {
	success: boolean;
	errors: any;
}

export const useZodValidation = (): {
	errors: any;
	setErrors: (errors: any) => void;
	hasErrors: boolean;
	validate: (schema: z.Schema, values: any) => ValidationResult;
	validateSingle: (schema: z.Schema, values: any, path: string) => Zod.SafeParseReturnType<any, any>;
} => {
	const [errors, setErrors] = useState<any>();

	const validate = (schema: z.Schema, values: any, dry = false): ValidationResult => {
		const validationResult = schema.safeParse(values);
		const newErrors: any = {};

		if (!validationResult.success) {
			const result = validationResult.error.flatten();

			for (const property in result.fieldErrors) {
				const error = result.fieldErrors[property];

				if (error) {
					if (error[0] === 'Invalid date') {
						const dateFormattedErrors = customizeInvalidDateErrors(property);
						newErrors[property] = dateFormattedErrors[property];
					} else {
						newErrors[property] = error[0];
					}
				} else {
					newErrors[property] = undefined;
				}
			}

			if (dry) {
				return {
					success: validationResult.success,
					errors: newErrors,
				};
			}

			setErrors(newErrors);
		}

		return {
			success: validationResult.success,
			errors: newErrors,
		};
	};

	const customizeInvalidDateErrors = (path: string) => {
		const customizedErrors = { ...errors };
		if (path === 'expires') {
			customizedErrors.expires = 'Invalid dd/mm/yyyy hh:mm';
		}
		if (path === 'deliveryDate') {
			customizedErrors.deliveryDate = 'Invalid dd/mm/yyyy date';
		}
		return customizedErrors;
	};

	const validateSingle = (schema: z.Schema, value: any, path: string) => {
		const validationResult = schema.safeParse(value);
		const error = validationResult.success ? undefined : validationResult.error.flatten();

		if (error?.formErrors[0] === 'Invalid date') {
			const dateFormattedErrors = customizeInvalidDateErrors(path);
			setErrors({ ...dateFormattedErrors });
		} else {
			setErrors({ ...errors, [path]: error?.formErrors[0] });
		}

		return validationResult;
	};

	const anyErrorHasValue = () => {
		for (const property in errors) {
			if (errors[property]) return true;
		}

		return false;
	};

	const hasErrors = anyErrorHasValue();

	return {
		errors,
		setErrors,
		hasErrors,
		validate,
		validateSingle,
	};
};
