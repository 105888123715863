import { FunctionComponent, useMemo } from 'react';
import { Progressbar } from '@montel/montelpro-shared-components-v2';
import { ProgressProps } from './types';
import { getProgressColor, getProgressValue } from './utils';

const ProgressLine: FunctionComponent<ProgressProps> = ({ minutesUntilExpiry }) => {
	const value = useMemo(() => getProgressValue(minutesUntilExpiry), [minutesUntilExpiry]);
	const color = useMemo(() => getProgressColor(minutesUntilExpiry), [minutesUntilExpiry]);

	return <Progressbar value={value} color={color} />;
};

export default ProgressLine;
