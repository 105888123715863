import { FunctionComponent } from 'react';
import { HStack, VStack } from '@montel/montelpro-shared-components-v2';
import { motion } from 'framer-motion';
import { useMenuContext } from '../menuContext';
import { MenuItemProps } from '../menuTypes';
import { StyledListItem, StyledTopMenuItem, StyledUnderline } from './TopMenuItem.styles';

const TopMenuItem: FunctionComponent<MenuItemProps> = ({ text, icon, link, className, ...otherProps }) => {
	const ItemIcon = icon;
	const { isActive } = useMenuContext();

	return (
		<StyledListItem>
			<StyledTopMenuItem $isActive={isActive} className={className} to={link} {...otherProps}>
				<VStack justify="center">
					<HStack align="center">
						{ItemIcon && <ItemIcon />}
						{text}
					</HStack>

					<StyledUnderline as={motion.div} layout $active={isActive} />
				</VStack>
			</StyledTopMenuItem>
		</StyledListItem>
	);
};

export default TopMenuItem;
