import {
	IconTechnologyAny,
	IconTechnologyBio,
	IconTechnologyGeoThermal,
	IconTechnologyHydro,
	IconTechnologyNuclear,
	IconTechnologySolar,
	IconTechnologyWind,
} from '@montel/montelpro-shared-components-v2';
import { Tech } from 'common/enums/OrderEnums';

const getTechnologyOptions = (withAny: boolean = true, withNuclear: boolean = false): any[] => [
	{ label: <IconTechnologyBio boxSize="18px" />, value: Tech.Biomass, caption: 'BIOMASS' },
	{ label: <IconTechnologyGeoThermal boxSize="18px" />, value: Tech.Geothermal, caption: 'GEO' },
	{ label: <IconTechnologyHydro boxSize="18px" />, value: Tech.Hydro, caption: 'HYDRO' },
	{ label: <IconTechnologySolar boxSize="18px" />, value: Tech.Solar, caption: 'SOLAR' },
	{ label: <IconTechnologyWind boxSize="18px" />, value: Tech.Wind, caption: 'WIND' },
	...(withAny ? [{ label: <IconTechnologyAny boxSize="18px" />, value: Tech.AnyRenewable, caption: 'ANY' }] : []),
	...(withNuclear
		? [{ label: <IconTechnologyNuclear boxSize="18px" />, value: Tech.Nuclear, caption: 'NUCLEAR' }]
		: []),
];

export default getTechnologyOptions;
