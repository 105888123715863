import styled, { css } from 'styled-components';

export const StyledChip = styled.button`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	font-style: normal;
	white-space: nowrap;
	background-color: transparent;
	letter-spacing: 0.6px;

	${({ theme }) => {
		const { spacing, borderRadii, colors } = theme;
		const { white, transparent, pineGreen, lightPine, subElement } = colors;

		return css`
			gap: ${spacing.xxs};
			border-radius: ${borderRadii.xxxl};
			padding: ${spacing.xxs} ${spacing.sm};
			color: ${white};
			background-color: ${transparent};
			border: 1px solid ${pineGreen};

			&:hover {
				color: ${white};
				background-color: ${lightPine};
				border: 1px solid ${lightPine};
				cursor: pointer;
			}

			&:active {
				color: ${white};
				background-color: ${pineGreen};
				border: 1px solid ${pineGreen};
			}

			&:disabled {
				color: ${subElement};
				background-color: ${transparent};
				border: 1px solid ${subElement};
				cursor: default;
				pointer-events: none;
			}

			&:focus-visible {
				outline: 2px solid ${colors.heritageOrange};
			}
		`;
	}};
`;
