import { DropdownMenu } from '@montel/montelpro-shared-components-v2';
import styled, { css } from 'styled-components';

export const StyledCheckBoxItem = styled.li`
	${({ theme }) => {
		const { colors, spacing, typography } = theme;
		return css`
			label {
				> :nth-child(2) {
					flex-shrink: 0;
					border: 2px solid ${colors.white};
				}
				span {
					display: flex;
					align-items: center;
					font-size: ${typography.size.xs};
					color: ${colors.primaryText};
				}

				svg {
					width: 1rem;
					height: 1rem;
				}
			}
			&:hover {
				background-color: ${colors.elementHighlight};
			}
			padding: ${spacing.xs} ${spacing.sm};
		`;
	}}
`;

export const StyledDropdownMenu = styled(DropdownMenu)`
	max-height: 37.5rem;
	overflow: auto;
`;
