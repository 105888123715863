import { FunctionComponent } from 'react';
import { GET_USER_TRADES_API_ROUTE } from 'apiRoutes';
import { TradeStatusEnum } from 'common/interfaces/Trade';
import { orderTableColumns } from 'components/OrderTables/Columns/orderTableColumns';
import { tradeTableColumns } from 'components/OrderTables/Columns/tradeTableColumns';
import TradePage from '../TradePage';

const MyTrades: FunctionComponent = () => {
	const columns = [
		tradeTableColumns.tradeTime(),
		orderTableColumns.productionPeriod({ columnDefProps: { meta: { width: '8%' } } }),
		orderTableColumns.tech({ columnDefProps: { enableSorting: false, meta: { width: '8%' } } }),
		orderTableColumns.region(),
		orderTableColumns.label(),
		orderTableColumns.delivery(),
		tradeTableColumns.transaction(),
		tradeTableColumns.counterparty(),
		orderTableColumns.quantity({ columnDefProps: { meta: { width: '5%' } } }),
		orderTableColumns.price(),
		tradeTableColumns.tradeStatus({
			cellProps: { offsetLeft: '1.5rem' },
			headCellProps: { offsetLeft: '1.5rem', text: 'Status' },
			columnDefProps: {
				enableSorting: false,
				meta: {
					width: '180px',
				},
			},
		}),
	];

	return (
		<TradePage
			pageTitle="My Trades"
			tableHeader="My trades"
			statuses={[TradeStatusEnum.Pending, TradeStatusEnum.Signed, TradeStatusEnum.Cancelled]}
			columns={columns}
			url={GET_USER_TRADES_API_ROUTE}
		/>
	);
};

export default MyTrades;
