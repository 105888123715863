import { css, styled } from 'styled-components';

export const StyledContainer = styled.div`
	position: absolute;
	bottom: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;

	${({ theme }) => css`
		background-color: ${theme.colors.element};
		z-index: ${theme.zIndices.popover};
	`}
`;
