import { FunctionComponent, ReactNode } from 'react';
import { StyledPromptContent } from './PromptContent.style';

interface PromptContentProps {
	children: ReactNode;
}

export const PromptContent: FunctionComponent<PromptContentProps> = ({ children }) => {
	return <StyledPromptContent>{children}</StyledPromptContent>;
};
