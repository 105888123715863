import React, { FunctionComponent } from 'react';
import { AvatarName, Text, VStack } from '@montel/montelpro-shared-components-v2';
import { StyledProfileButton } from './ProfileButton.style';

interface ProfileButtonProps {
	firstName: string | null;
	lastName: string | null;
	company: string | null;
	onCLick: () => void;
}

const ProfileButton: FunctionComponent<ProfileButtonProps> = ({ firstName, lastName, company, onCLick }) => {
	return (
		<StyledProfileButton onClick={() => onCLick()} align="center">
			<VStack align="flex-end" gap="xxs">
				<Text>{firstName && lastName ? `${firstName} ${lastName}` : 'Profile'}</Text>
				<Text weight="semiBold">{company ? company : 'No company connected'}</Text>
			</VStack>
			<AvatarName name={`${firstName} ${lastName}`} size="xxl" color="white" />
		</StyledProfileButton>
	);
};

export default ProfileButton;
