import { useEffect } from 'react';
import { HubConnection } from '@microsoft/signalr';
import { OrderEventEnum } from 'store/orders/OrderStateEnum';
import { getOrder } from 'store/orders/ordersThunks';
import { useAppDispatch } from '../store/hooks';

export interface IuseSubscribeToOrders {
	hubConnection?: HubConnection | null;
}

const useSubscribeToOrders = ({ hubConnection }: IuseSubscribeToOrders) => {
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (hubConnection) {
			hubConnection.on(OrderEventEnum.ORDER_CREATED, function (orderId: string) {
				dispatch(
					getOrder({
						orderId: orderId,
						event: OrderEventEnum.ORDER_CREATED,
					}),
				);
			});

			hubConnection.on(OrderEventEnum.ORDER_UPDATED, function (orderId: string) {
				dispatch(
					getOrder({
						orderId: orderId,
						event: OrderEventEnum.ORDER_UPDATED,
					}),
				);
			});

			hubConnection.on(OrderEventEnum.ORDER_REMOVED, function (orderId: string) {
				dispatch(
					getOrder({
						orderId: orderId,
						event: OrderEventEnum.ORDER_REMOVED,
					}),
				);
			});

			return () => {
				hubConnection.off(OrderEventEnum.ORDER_CREATED);
				hubConnection.off(OrderEventEnum.ORDER_UPDATED);
				hubConnection.off(OrderEventEnum.ORDER_REMOVED);
			};
		}
	}, [hubConnection]);
};

export default useSubscribeToOrders;
