import { FunctionComponent } from 'react';
import { StyledCell } from '../styles';
import LabelAccepted from './Labels/LabelAccepted';
import { StatusCellProps } from './types';
import { getCellProps, getStatusCellProps } from './utils';

const AcceptedCell: FunctionComponent<StatusCellProps> = (props) => {
	const color = 'success';
	const styledCellProps = getCellProps(props);
	const { offsetLeft, offsetRight, ...restStyle } = styledCellProps;
	const { size, boxSize } = getStatusCellProps(props);

	return (
		<StyledCell $offsetLeft={offsetLeft} $offsetRight={offsetRight} {...restStyle}>
			<LabelAccepted color={color} boxSize={boxSize} size={size} />
		</StyledCell>
	);
};

export default AcceptedCell;
