import { FunctionComponent } from 'react';
import { Input } from '@montel/montelpro-shared-components-v2';

interface QuantityProps {
	value: string;
	onChange: (quantity: string, validate?: boolean) => void;
	error?: string;
	maxValue?: number;
}

const Quantity: FunctionComponent<QuantityProps> = ({ value, error, onChange, maxValue }) => {
	const MAX_QUANTITY = 10000000;
	const max = maxValue ?? MAX_QUANTITY;

	const onQuantityChange = (event: any) => {
		const quantity = event.target.value;

		const numericValue = quantity.toFloat();

		if (quantity.startsWith('-')) {
			onChange(quantity.slice(1));
			return;
		}

		if (isNaN(numericValue)) {
			onChange(quantity.slice(0, -1));

			return;
		}

		if (numericValue <= max) {
			onChange(numericValue.toDefaultNumberFormat(0), true);
		}
	};

	return (
		<Input
			label={'Quantity (MWh)'}
			value={value}
			onChange={onQuantityChange}
			required
			errorMessage={error ?? ''}
			placeholder="0"
		/>
	);
};

export default Quantity;
