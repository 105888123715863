import { Company } from 'common/interfaces/Company';
import getEncodedInBase64 from 'utils/fileUtils/getEncodedInBase64';
import getFileExtension from 'utils/fileUtils/getFileExtension';
import getValuesFromOptions from 'utils/getValuesFromOptions';
import { CompanyRegistrationState } from '../types';

const getPayload = async (state: CompanyRegistrationState, logo?: File): Promise<Company> => {
	return {
		...state,
		countryCode: state.countryCode?.value,
		technologies: state.technologies,
		tradingPosition: state.tradingPosition,
		tradingFrequency: state.tradingFrequency,
		marketRole: state.marketRole?.value,
		products: getValuesFromOptions(state.products),
		labels: getValuesFromOptions(state.labels),
		registryOperators: getValuesFromOptions(state.registryOperators),
		currencies: getValuesFromOptions(state.currencies),
		logo: logo && (await getEncodedInBase64(logo)),
		logoExtension: getFileExtension(logo),
		subsidised: state.subsidized,
		bankName: '',
		bankAccountNo: '',
		bic: '',
		iban: '',
	} as Company;
};

export default getPayload;
