import { FunctionComponent, ReactNode } from 'react';
import { HStack, Text, VStack } from '@montel/montelpro-shared-components-v2';
import AnimateHeight from './AnimateHeight';
import AnimateOpacity from './AnimateOpacity';
import { PromptContent } from './PromptContent';
import { PromptFooter } from './PromptFooter';
import { StyledCloseButton, StyledVStack } from './style';

interface PromptProps {
	headerText: string;
	body: ReactNode;
	footer?: ReactNode;
	/**
	 * This is the start height of the content.
	 */
	animateFromHeight: number;
	/**
	 * Set this height to > 0 to show the content. The value will also influence the actual height of the content.
	 */
	animateToHeight: number;
	onClosePrompt: () => void;
	onAnimationComplete?: () => void;
}

const Prompt: FunctionComponent<PromptProps> = ({
	headerText,
	animateFromHeight,
	animateToHeight,
	onAnimationComplete,
	onClosePrompt,
	body,
	footer,
}) => {
	return (
		<AnimateHeight from={animateFromHeight} to={animateToHeight} onAnimationComplete={onAnimationComplete}>
			<StyledVStack justify="space-between">
				<VStack fullWidth gap="md" align="flex-start">
					<HStack fullWidth justify="space-between">
						<Text size="lg" weight="700">
							{headerText}
						</Text>
						<StyledCloseButton color="white" onClick={onClosePrompt} />
					</HStack>
					<AnimateOpacity>{body && <PromptContent>{body}</PromptContent>}</AnimateOpacity>
				</VStack>
				{footer && <PromptFooter>{footer}</PromptFooter>}
			</StyledVStack>
		</AnimateHeight>
	);
};

export default Prompt;
