import { Currency, Side } from '@montel/montelpro-shared-components/enums';
import { getRegionName, getTechName } from '../../../../../common/enums/valueLookups';
import { getProductionPeriodString } from '../../../../../utils/orderUtils/getProductionPeriodString';
import { getTypeOfQuote } from '../../../../OrderDetails/PlaceQuoteForm/getTypeOfQuote';

function translateOrder(order: any) {
	const updatedOrder = structuredClone(order);
	const { orderId, side, production, tech, region, quantity, price, currency } = updatedOrder;

	return {
		...updatedOrder,
		...(orderId && { id: orderId }),
		...(side && { side: Side[side] }),
		...(side && { quoteType: getTypeOfQuote(side) }),
		product: {
			...(tech && { tech: tech.map(getTechName).join(', ') }),
			...(region && { region: getRegionName(region) }),
			production: {
				...(production && production),
				...(production &&
					production.periods && {
						periods: production.periods.map((p: any) => getProductionPeriodString(p)).join(', '),
					}),
			},
		},
		cost: {
			...(quantity && { quantity: quantity !== 0 ? quantity.toDefaultNumberFormat(0) : '-' }),
			...(price && { price: price !== 0 ? price.toDefaultNumberFormat(4) : '-' }),
			...(currency && { currency: Currency[currency] }),
		},
	};
}

export default {
	created: {
		en: {
			version_1: translateOrder,
		},
	},
	ownAccepted: {
		en: {
			version_1: translateOrder,
		},
	},
	accepted: {
		en: {
			version_1: translateOrder,
		},
	},
	tradeCancelled: {
		en: {
			version_1: translateOrder,
		},
	},
	orderWithActiveQuotesClosed: {
		en: {
			version_1: translateOrder,
		},
	},
	quoteCreated: {
		en: {
			version_1: translateOrder,
		},
	},
	quoteUpdated: {
		en: {
			version_1: translateOrder,
		},
	},
	partiallyTraded: {
		en: {
			version_1: translateOrder,
		},
	},
	quoteAccepted: {
		en: {
			version_1: translateOrder,
		},
	},
};
