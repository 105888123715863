import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useServerSideTableContext } from './Pagination/ServerSideTableContext';

interface UsePaginatedTableProps<T> {
	data: Array<T>;
	columns: ColumnDef<T, any>[];
	pageSize?: number;
}

function useServerSideTable<T>({ data, columns, pageSize }: UsePaginatedTableProps<T>) {
	const { sorting, setSorting } = useServerSideTableContext();
	const table = useReactTable<T>({
		data: data,
		columns,
		state: {
			sorting,
		},
		initialState: {
			pagination: {
				pageSize: pageSize || 10,
				pageIndex: 0,
			},
		},
		manualSorting: true,
		enableSortingRemoval: false,
		onSortingChange: setSorting,
		getCoreRowModel: getCoreRowModel(),
	});

	return table;
}

export default useServerSideTable;
