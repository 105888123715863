import { PublicCompanyInformation } from '../../../common/interfaces';
import getTechnologyOptions from '../../../pages/CompanyRegistration/steps/getTechnologyOptions';
import sortBySortingOrder from '../../../utils/sorting/sortBySortingOrder';

const techSortingOrder = getTechnologyOptions().map((item) => item.value);

export const translateApiCompany = (company: PublicCompanyInformation): PublicCompanyInformation => ({
	...company,
	technologies: sortBySortingOrder(company.technologies, techSortingOrder),
});

export const translateApiCompanies = (companies: PublicCompanyInformation[]): PublicCompanyInformation[] =>
	companies.map(translateApiCompany);
