import styled from 'styled-components';

export const StyledIndeterminateCheckbox = styled.label<any>`
	input[type='checkbox'] {
		-webkit-appearance: none;
		appearance: none;
		background-color: transparent;
		margin: 0;
		box-sizing: border-box;
		color: ${({ theme }) => theme.colors.primaryText};
		width: 1rem;
		height: 1rem;
		border: 2px solid ${({ theme }) => theme.colors.primaryText};
		border-radius: 2px;
		transform: translateY(-0.075em);
		place-content: center;
		display: inline-grid;
	}

	input[type='checkbox']:not(:indeterminate)::before {
		content: '';
		width: 0.6rem;
		height: 0.6rem;
		clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
		transform: scale(0);
		transform-origin: bottom left;
		transition: 100ms transform ease-in-out;
		box-shadow: inset 1em 1em ${({ theme }) => theme.colors.primaryText};
	}

	input[type='checkbox']:indeterminate::before {
		content: '';
		width: 0.6rem;
		height: 0.6rem;
		clip-path: polygon(100% 35%, 100% 60%, 0% 60%, 0% 35%);
		transform: scale(0);
		transform-origin: bottom left;
		transition: 100ms transform ease-in-out;
		box-shadow: inset 1em 1em ${({ theme }) => theme.colors.primaryText};
	}

	input[type='checkbox']:indeterminate::before {
		transform: scale(1);
	}

	input[type='checkbox']:checked::before {
		transform: scale(1);
	}

	input[type='checkbox']:focus {
		outline: 1px solid ${({ theme }) => theme.colors.heritageOrange};
		outline-offset: 1px;
	}
`;
