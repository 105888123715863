import { FunctionComponent } from 'react';
import { HStack, IconCheck, Text } from '@montel/montelpro-shared-components-v2';
import { OrderTypeEnum } from 'common/enums';
import { useTheme } from 'styled-components';
import { Notice } from 'components/Notice';
import QuotesTable from '../../QuotesTable';
import AcceptQuotePrompts from '../AcceptQuotePrompt';
import { useOrderDetailsContext } from '../OrderDetailsContext';
import OwnerActions from './OwnerActions/OwnerActions';
import TraderActions from './TraderActions/TraderActions';
import ActionLine from './ActionLine';
import { StyledBody } from './OrderDetailsBody.styles';
import OrderDetailsProperties from './OrderDetailsProperties';
import { FixedPriceTopDetails, RFQTopDetails } from './TopDetails';

const OrderDetailsBody: FunctionComponent = () => {
	const { order, tradeSubmitted } = useOrderDetailsContext();
	const theme = useTheme();

	const isNotOurOrder = !order.isMyOrder && !order.isMyCompanysOrder;
	const isCoworkersOrder = !order.isMyOrder && order.isMyCompanysOrder;

	const showUnavailableToTradeNotice = !order.isMyOrder && (!order.isAvailableToTrade || isCoworkersOrder);

	const unavailableNotice = isCoworkersOrder
		? "You cannot trade this order as it is your coworkers' order."
		: 'You cannot trade this order as it is not available to you.';

	return (
		<StyledBody>
			{order.orderType === OrderTypeEnum.FixedPrice ? <FixedPriceTopDetails /> : <RFQTopDetails />}
			<ActionLine>{order.isMyOrder && <OwnerActions />}</ActionLine>

			<OrderDetailsProperties />
			{showUnavailableToTradeNotice ? (
				<Notice>{unavailableNotice}</Notice>
			) : (
				<ActionLine>
					{tradeSubmitted ? (
						<HStack wrap="nowrap">
							<IconCheck color={theme.colors.success} />
							<Text color={theme.colors.success}>Accepted</Text>
						</HStack>
					) : (
						isNotOurOrder && <TraderActions />
					)}
				</ActionLine>
			)}
			<QuotesTable />
			<AcceptQuotePrompts />
		</StyledBody>
	);
};
export default OrderDetailsBody;
