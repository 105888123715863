import { RadioOptionType, SelectOptionType } from '@montel/montelpro-shared-components-v2';
import { AvailableTo, PeriodTypeEnum, Region, SubsidizedEnum } from 'common/enums';
import { regionNames } from 'common/enums/valueLookups/RegionLookup';
import { ProductionPeriodOption } from './Steps/ProductStep/ProductStep.types';
import { getRegionTooltip } from './tooltips';

export const getSubsidizedOptions = (): RadioOptionType[] => [
	{ label: 'Any', value: SubsidizedEnum.NOT_REQUIRED },
	{ label: 'Yes', value: SubsidizedEnum.YES },
	{ label: 'No', value: SubsidizedEnum.NO },
];

export const getMinimumValidityOptions = (): SelectOptionType[] => {
	const options: SelectOptionType[] = [];
	for (let i = 1; i <= 15; i++) {
		options.push({
			label: `${i}`,
			value: i,
		});
	}

	return options;
};

export const getMaxPlantAgeOptions = (): SelectOptionType[] => {
	const options: SelectOptionType[] = [];
	for (let i = 1; i <= 100; i++) {
		options.push({
			label: `${i}`,
			value: i,
		});
	}

	return options;
};

export const getPlantCommissioningYearOptions = (): SelectOptionType[] => {
	const from = 1900;
	const to = 2050;
	const options: SelectOptionType[] = [];
	for (let i = from; i <= to; i++) {
		options.push({
			label: `${i}`,
			value: i,
		});
	}

	return options;
};

export const getRegionOptions = (withTooltip: boolean = false): SelectOptionType[] =>
	Object.keys(regionNames)
		.slice(1)
		.map((r) => ({
			value: Region[r as keyof typeof Region],
			label: regionNames[r as keyof typeof Region],
			tooltip: withTooltip ? getRegionTooltip(Region[r as keyof typeof Region]) : undefined,
		}));

export const getProductionPeriodTypeOptions = (): SelectOptionType[] => [
	{ label: 'Year', value: PeriodTypeEnum.YEAR },
	{ label: 'Quarter', value: PeriodTypeEnum.QUARTER },
	{ label: 'Month', value: PeriodTypeEnum.MONTH },
];

export const getProductionPeriodOptions = (periodType: PeriodTypeEnum | null): ProductionPeriodOption[] => {
	switch (periodType) {
		case PeriodTypeEnum.YEAR: {
			return getYearOptions(new Date());
		}
		case PeriodTypeEnum.QUARTER: {
			return getQuarterOptions(new Date());
		}
		case PeriodTypeEnum.MONTH: {
			return getMonthOptions(new Date());
		}
		default:
			return [];
	}
};

export const getYearOptions = (date: Date): ProductionPeriodOption[] => {
	const options = [];
	const from = new Date(date.getFullYear() - 1, date.getMonth(), date.getDate());
	const to = new Date(date.getFullYear() + 10, date.getMonth(), date.getDate());

	const current = from;
	while (current <= to) {
		const year = current.getFullYear();
		options.push({ label: year.toString(), value: year.toString(), year });
		current.setFullYear(current.getFullYear() + 1);
	}

	return options;
};

export const getMonthOptions = (date: Date): ProductionPeriodOption[] => {
	const options = [];
	const from = new Date(date.getFullYear() - 1, date.getMonth(), date.getDate());
	const to = new Date(date.getFullYear() + 10, date.getMonth(), date.getDate());

	const current = from;
	while (current <= to) {
		const monthName = current.toLocaleString('en', { month: 'long' });
		const month = current.getMonth();
		const year = current.getFullYear();
		const value = `${current.getFullYear()} ${monthName}`;
		options.push({ label: value, value, month, year });
		if (current.getMonth() >= 12) {
			current.setFullYear(current.getFullYear() + 1);
			current.setMonth(0);
		} else {
			current.setMonth(current.getMonth() + 1);
		}
	}

	return options;
};

export const getQuarter = (dt: Date): number => {
	const month = dt.getMonth();
	const quarters = [
		[0, 1, 2],
		[3, 4, 5],
		[6, 7, 8],
		[9, 10, 11],
	];
	let quarter = 0;
	for (; quarter < quarters.length; quarter++) {
		if (quarters[quarter].includes(month)) {
			break;
		}
	}
	return quarter + 1;
};

export const getQuarterOptions = (date: Date): ProductionPeriodOption[] => {
	const options = [];
	const from = new Date(date.getFullYear() - 1, date.getMonth(), date.getDate());
	const to = new Date(date.getFullYear() + 10, date.getMonth(), date.getDate());

	const currentQuarter = getQuarter(date);
	const current = from;
	let quarter = currentQuarter;
	while (current <= to) {
		if (current.getFullYear() === to.getFullYear() && quarter > currentQuarter) {
			break;
		}

		const year = current.getFullYear();
		const value = `${year} Q${quarter}`;
		options.push({ label: value, value, quarter, year });

		if (quarter >= 4) {
			quarter = 1;
			current.setFullYear(current.getFullYear() + 1);
		} else {
			quarter += 1;
		}
	}

	return options;
};

export const getAvailableToOptions = (): RadioOptionType[] => [
	{ label: 'Public', value: AvailableTo.Public },
	{ label: 'All CP', value: AvailableTo.Counterparties },
	{ label: 'Selected', value: AvailableTo.Selected },
];
