import { FunctionComponent } from 'react';
import { SubsidizedEnum } from '@montel/montelpro-shared-components/enums';
import { RadioButtonList, Select, SelectOptionType, VStack } from '@montel/montelpro-shared-components-v2';
import { Side } from 'common/enums/OrderEnums';
import getLabelOptions from 'common/options/getLabelOptions';
import getOptionByValue from 'utils/getOptionByValue';
import FormFooter from '../../Footer';
import { getMaxPlantAgeOptions, getPlantCommissioningYearOptions, getSubsidizedOptions } from '../../options';
import { getRegionOptions } from '../../options';
import { useOrderFormContext } from '../../OrderFormProvider';
import Production from './Production';
import { EqualColumnsContainer, StyledProductStep } from './ProductStep.styles';
import { fields, ProductSchema } from './ProductStep.types';
import Technology from './Technology';

const ProductStep: FunctionComponent<{ onProgress: () => void }> = ({ onProgress }) => {
	const { formState, setFormState, errors, validate, validateSingle } = useOrderFormContext();
	const { side } = formState;
	const { label, region, subsidized, plantMaxAge, plantCommissioningYear } = formState.product;

	const regionOptions = getRegionOptions(true);
	const labelOptions = getLabelOptions();
	const subsidizedOptions = getSubsidizedOptions();
	const plantCommissiningYearOptions = getPlantCommissioningYearOptions();
	const maxPlantAgeOptions = getMaxPlantAgeOptions();

	const validateAndProgress = () => {
		const result = validate(ProductSchema, formState.product);

		if (result.success) {
			onProgress();
		}
	};

	const onLabelChange = (label: SelectOptionType) => {
		validateSingle(fields.label, label.value, 'label');
		setFormState({ ...formState, product: { ...formState.product, label: label.value } });
	};

	const onRegionChange = (region: SelectOptionType) => {
		validateSingle(fields.region, region.value, 'region');
		setFormState({ ...formState, product: { ...formState.product, region: region.value } });
	};

	const onSubsidizedChange = (subsidized: SubsidizedEnum) => {
		validateSingle(fields.subsidized, subsidized, 'subsidized');
		setFormState({ ...formState, product: { ...formState.product, subsidized: subsidized } });
	};

	const onMaxPlantAgeChange = (plantMaxAge: SelectOptionType) => {
		const numericValue = Number(plantMaxAge.value);
		validateSingle(fields.plantMaxAge, numericValue, 'maxPlantAge');
		setFormState({
			...formState,
			product: { ...formState.product, plantMaxAge: numericValue },
		});
	};

	const onPlantCommissioningYearChange = (selected: SelectOptionType) => {
		const numericValue = Number(selected.value);
		validateSingle(fields.plantCommissioningYear, numericValue, 'plantCommissioningYear');
		setFormState({
			...formState,
			product: { ...formState.product, plantCommissioningYear: numericValue },
		});
	};

	return (
		<StyledProductStep>
			<VStack gap="lg" align="initial" wrap="nowrap" fullWidth>
				<Technology />
				<EqualColumnsContainer>
					<Select
						label="Region"
						options={regionOptions}
						errorMessage={errors?.region && 'Please select a region'}
						required
						tooltipContentSize="xl"
						onChange={onRegionChange}
						value={getOptionByValue(regionOptions, region)}
						placeholder="Select region"
					/>
					<Select
						label="Label"
						options={labelOptions}
						errorMessage={errors?.label && errors.label}
						required
						onChange={onLabelChange}
						value={getOptionByValue(labelOptions, label)}
					/>
				</EqualColumnsContainer>
				<Production />
				<EqualColumnsContainer>
					<RadioButtonList
						label="Subsidised"
						required
						options={subsidizedOptions}
						errorMessage={errors?.subsidized && errors.subsidized}
						onChange={onSubsidizedChange}
						value={subsidized}
					/>
					{side === Side.Ask ? (
						<Select
							label="Plant commissioning year"
							placeholder="Select year"
							onChange={onPlantCommissioningYearChange}
							options={plantCommissiningYearOptions}
							errorMessage={errors?.plantCommissioningYear && errors.plantCommissioningYear}
							value={getOptionByValue(plantCommissiningYearOptions, plantCommissioningYear)}
						/>
					) : (
						<Select
							label="Max plant age (years)"
							placeholder="Select max age"
							onChange={onMaxPlantAgeChange}
							options={maxPlantAgeOptions}
							errorMessage={errors?.plantMaxAge && errors.plantMaxAge}
							value={getOptionByValue(maxPlantAgeOptions, plantMaxAge)}
						/>
					)}
				</EqualColumnsContainer>
			</VStack>
			<FormFooter onNext={validateAndProgress} showPrev={false} />
		</StyledProductStep>
	);
};

export default ProductStep;
