import { FunctionComponent } from 'react';
import { HStack } from '@montel/montelpro-shared-components-v2';
import { useTheme } from 'styled-components';
import { getTechIcon, getTechName } from '../../common/enums/valueLookups';
import { IconPlus } from './IconPlus';
import { StyledTooltip } from './style';

interface ActiveTechProps {
	tech: number[];
	color?: string;
	boxSize?: string;
	numberOfTechToShow?: number;
	tooltipPosition?: 'top' | 'bottom';
}

const ActiveTech: FunctionComponent<ActiveTechProps> = ({
	tech,
	color = 'white',
	boxSize = '1.5rem',
	numberOfTechToShow,
	tooltipPosition = 'top',
}) => {
	const { colors } = useTheme();
	const iconProps = {
		color: colors[color] || color,
		boxSize: boxSize,
	};

	const isReducedTechArr = numberOfTechToShow !== undefined && tech.length > numberOfTechToShow;
	const techReduced = isReducedTechArr ? tech.slice(0, numberOfTechToShow) : tech;

	return (
		<HStack>
			{techReduced.map((tech) => {
				const Icon = getTechIcon(tech);
				return (
					<StyledTooltip key={tech} component={<Icon {...iconProps} />} tooltipPosition={tooltipPosition}>
						{getTechName(tech)}
					</StyledTooltip>
				);
			})}
			{isReducedTechArr && (
				<StyledTooltip
					key="more-tech"
					component={<IconPlus {...iconProps} />}
					tooltipPosition={tooltipPosition}
				>
					{tech.map(getTechName).join(', ')}
				</StyledTooltip>
			)}
		</HStack>
	);
};

export default ActiveTech;
