import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useUpdate } from '@montel/montelpro-shared-components/hooks';
import {
	HStack,
	IconOrders,
	IconSync,
	Tab,
	TabPanel,
	TabPanels,
	Tabs,
	useOnMount,
	VStack,
} from '@montel/montelpro-shared-components-v2';
import { GET_ALL_TRADES_API_ROUTE } from 'apiRoutes';
import { Side } from 'common/enums/OrderEnums';
import { Trade, TradeStatusEnum } from 'common/interfaces/Trade';
import TradeTable from 'pages/Embedded/TradeTable/TradeTable';
import { TradeQueryParams } from 'pages/Trades/TradeQueryParams';
import { useGetTrades } from 'pages/Trades/useGetTrades';
import SuspenseStream from 'primitives/SuspenseStream';
import { getActiveOrders } from 'store/orders/ordersThunks';
import styled from 'styled-components';
import { getAllProductionYears } from 'utils/orderUtils/getAllProductionYears';
import { getYearGroups } from 'components/OrderTables/YearGroupedOrderTables/getYearGroups';
import YearGroupedOrderTable from 'components/OrderTables/YearGroupedOrderTables/YearGroupedOrderTable';
import { SortBy, SortDirection } from 'components/Tables/Pagination/ServerSideTableContext';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
	selectActiveAskStrips,
	selectActiveBidStrips,
	selectActiveBuyOrders,
	selectActiveOrders,
	selectActiveOrdersStatus,
	selectActiveSellOrders,
} from '../../../store/orders/activeOrders/activeOrdersSelectors';
import { orderColumns, tradeColumns } from './columns';

const StyledContainer = styled(VStack)``;

export type DataType = 'orders' | 'trades';

const OrderTradeList: FunctionComponent<{
	side: Side;
	setType: (type: DataType) => void;
	sideBySideOrders?: boolean;
}> = ({ side, setType, sideBySideOrders = true }) => {
	const [trades, setTrades] = useState<Trade[]>([]);
	const { status: tradeStatus, getTrades } = useGetTrades(GET_ALL_TRADES_API_ROUTE);
	const dispatch = useAppDispatch();

	useEffect(() => {
		const fetchTrades = async () => {
			const params = new TradeQueryParams();

			params.tradeStatuses = [TradeStatusEnum.Pending, TradeStatusEnum.Signed];
			params.limit = 50;
			params.sortBy = SortBy.TradeTime;
			params.sortDirection = SortDirection.Descending;
			const trades = await getTrades(params);

			setTrades(trades.items);
		};

		fetchTrades();
	}, []);

	const [activeTab, setActiveTab] = useState<DataType>('orders');

	useUpdate(() => {
		setType(activeTab);
	}, [activeTab]);

	const orders = useAppSelector(selectActiveOrders);
	const activeBidOrders = useAppSelector(selectActiveBuyOrders);
	const activeAskOrders = useAppSelector(selectActiveSellOrders);
	const askStrips = useAppSelector(selectActiveAskStrips);
	const bidStrips = useAppSelector(selectActiveBidStrips);
	const orderStatus = useAppSelector(selectActiveOrdersStatus);
	const nrOfRows = bidStrips.length > askStrips.length ? bidStrips.length : askStrips.length;
	const activeProductionYears = getAllProductionYears(orders);

	const productionYears = useMemo(() => {
		return getYearGroups(activeProductionYears, activeAskOrders, activeBidOrders);
	}, [activeAskOrders, activeBidOrders]);

	const initialSorting = [{ id: 'productionPeriod', desc: false }];
	const tableBottomMargin = 100;

	useOnMount(() => {
		dispatch(getActiveOrders());
	});

	return (
		<StyledContainer wrap="nowrap" gap="md">
			<Tabs activeTab={activeTab} setActiveTab={(value) => setActiveTab(value as DataType)}>
				<Tab label="Orders" value="orders" Icon={IconOrders} />
				<Tab label="Trades" value="trades" Icon={IconSync} />
			</Tabs>
			<TabPanels currentTab={activeTab}>
				<TabPanel name="orders">
					<SuspenseStream status={orderStatus}>
						{sideBySideOrders ? (
							<HStack fullWidth gap="md" wrap="nowrap">
								<VStack fullWidth gap="md">
									<YearGroupedOrderTable
										header="Buy orders"
										orders={activeBidOrders}
										nrOfStripsRows={nrOfRows}
										productionYears={productionYears}
										noDataText="No active buy orders"
										initialSorting={initialSorting}
										columns={orderColumns}
									/>
								</VStack>
								<VStack fullWidth gap="md">
									<YearGroupedOrderTable
										header="Sell orders"
										orders={activeAskOrders}
										nrOfStripsRows={nrOfRows}
										productionYears={productionYears}
										noDataText="No active sell orders"
										initialSorting={initialSorting}
										columns={orderColumns}
									/>
								</VStack>
							</HStack>
						) : (
							<HStack fullWidth gap="md" wrap="wrap">
								{side === Side.Ask ? (
									<YearGroupedOrderTable
										header="Sell orders"
										orders={activeAskOrders}
										nrOfStripsRows={nrOfRows}
										productionYears={productionYears}
										noDataText="No active sell orders"
										initialSorting={initialSorting}
										columns={orderColumns}
									/>
								) : (
									<YearGroupedOrderTable
										header="Buy orders"
										orders={activeBidOrders}
										nrOfStripsRows={nrOfRows}
										productionYears={productionYears}
										noDataText="No active buy orders"
										initialSorting={initialSorting}
										columns={orderColumns}
									/>
								)}
							</HStack>
						)}
						<span style={{ height: tableBottomMargin }} />
					</SuspenseStream>
				</TabPanel>
				<TabPanel name="trades">
					<SuspenseStream status={tradeStatus}>
						<TradeTable data={trades} columns={tradeColumns} bottomMarginAfterLastRow={tableBottomMargin} />
					</SuspenseStream>
				</TabPanel>
			</TabPanels>
		</StyledContainer>
	);
};

export default OrderTradeList;
