import { Fragment, FunctionComponent, useMemo, useState } from 'react';
import {
	ColumnDef,
	flexRender,
	getCoreRowModel,
	getSortedRowModel,
	SortingState,
	useReactTable,
} from '@tanstack/react-table';
import { Label } from 'common/enums/OrderEnums';
import { labelNames } from 'common/enums/valueLookups/LabelLookup';
import { Trade } from 'common/interfaces/Trade';
import moment from 'moment';
import { Container, SubContainer, TableContainer, TBody, TD } from 'pages/Embedded/Table';
import { TableHead } from 'pages/Embedded/Table';
import styled, { css } from 'styled-components';
import { ServerSideTableContextProvider } from 'components/Tables/Pagination/ServerSideTableContext';

interface TableProps<T> {
	data: T[];
	columns: ColumnDef<any, any>[];
	bottomMarginAfterLastRow?: number;
}

const TR = styled.tr`
	width: 100%;
`;

const SubRow = styled.tr`
	${({ theme }) => css`
		font-size: ${theme.typography.size.xxs};
		border-bottom: 1px solid ${theme.colors.element};
	`}

	width: 100%;
	color: #a3ede3;
	font-weight: 600;

	td {
		padding-top: 0;
		padding-bottom: 0;

		div {
			width: 100%;
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			p {
				font-weight: 600;
				margin: 0 0 8px 0;
				text-transform: uppercase;
			}
		}
	}
`;

const TradeTable: FunctionComponent<TableProps<Trade>> = ({ data, columns, bottomMarginAfterLastRow }) => {
	const memoizedColumns = useMemo<ColumnDef<any, any>[]>(() => columns, [columns]);

	const [sorting, setSorting] = useState<SortingState>([]);

	const table = useReactTable({
		data: data,
		columns: memoizedColumns,
		getCoreRowModel: getCoreRowModel(),
		state: {
			sorting,
		},
		onSortingChange: setSorting,
		getSortedRowModel: getSortedRowModel(),
	});

	return (
		<ServerSideTableContextProvider defaultSort={[]}>
			<Container>
				<SubContainer $bottomMargin={bottomMarginAfterLastRow}>
					<TableContainer>
						<TableHead table={table} />
						<TBody>
							{table.getRowModel().rows.map((row) => {
								const cells = row.getVisibleCells();

								return (
									<Fragment key={row.id}>
										<TR key={row.id}>
											{cells.map((cell) => (
												<TD key={cell.id}>
													{flexRender(cell.column.columnDef.cell, cell.getContext())}
												</TD>
											))}
										</TR>
										<SubRow>
											<td colSpan={cells.length}>
												<div>
													<p>
														{`TRADED: ${moment(row.original.tradeTime).format(
															'DD MMM YY HH:mm',
														)}`}
													</p>
													<p>
														{`LABEL: ${
															labelNames[
																Label[row.original.product.label] as keyof typeof Label
															]
														}`}
													</p>
												</div>
											</td>
										</SubRow>
									</Fragment>
								);
							})}
						</TBody>
					</TableContainer>
				</SubContainer>
			</Container>
		</ServerSideTableContextProvider>
	);
};

export default TradeTable;
