import { IconBellOutlined } from '@montel/montelpro-shared-components-v2';
import { TMenuItem } from '../../components/Menu';

export const alertsMenuConfig: TMenuItem[] = [
	{
		type: 'Separator',
	},
	{
		type: 'Clickable',
		text: 'Notification settings',
		link: '/notification-settings',
		icon: IconBellOutlined,
		isEnd: true,
	},
];
