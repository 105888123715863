import { FunctionComponent } from 'react';
import { HStack } from '@montel/montelpro-shared-components-v2';
import { Quote } from 'common/interfaces/Quote';
import { Currency } from '../../../common/enums';
import { QuoteStatusEnum } from '../../../common/enums/quoteStatusEnum';
import { Order } from '../../../common/interfaces';
import { toTitleCase } from '../../../utils/string-extension';
import getTotalPriceFormatted from '../../NewOrder/utils/getTotalPriceFormatted';
import QuoteExpiryCell from '../../QuotesTable/Cells/QuoteExpiryCell';
import { getTypeOfQuote } from '../PlaceQuoteForm/getTypeOfQuote';
import { SummaryValue } from '../PlaceQuoteForm/SummaryStep';

export interface QuoteSummaryProps {
	quote?: Quote;
	order: Order;
}

const QuoteSummary: FunctionComponent<QuoteSummaryProps> = ({ quote, order }) => {
	const { expiryTime: quoteExpiry, quantity, price } = quote || {};
	const { cost, expiryTime: orderExpiry, side } = order;
	const { currency } = cost;
	const quoteType = getTypeOfQuote(side);
	const quoteExpiryInMs = new Date(quoteExpiry ?? 0).getTime();

	if (!quote) return null;

	return (
		<HStack fullWidth gap="md" justify="space-between">
			<SummaryValue
				label={`${toTitleCase(quoteType)} expires`}
				value={
					<QuoteExpiryCell
						status={QuoteStatusEnum.Active}
						expiryTimestamp={Math.min(quoteExpiryInMs ?? -1, new Date(orderExpiry).getTime())}
					/>
				}
			/>
			<SummaryValue label="Quantity" value={`${quantity} MWh`} />
			<SummaryValue label="Unit Price" value={`${price} ${Currency[currency]}`} />
			<SummaryValue
				label="Total Price"
				value={`${getTotalPriceFormatted((quantity ?? '').toString(), (price ?? '').toString() ?? '0')} ${
					Currency[order.cost.currency]
				}`}
			/>
		</HStack>
	);
};

export default QuoteSummary;
