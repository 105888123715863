import { FunctionComponent } from 'react';
import { Quote } from 'common/interfaces/Quote';
import { QuoteStatusEnum } from '../../../../common/enums/quoteStatusEnum';
import { useOrderDetailsContext } from '../../../OrderDetails/OrderDetailsContext';
import AcceptedCell from '../../../Tables/Cells/StatusCell/AcceptedCell';
import CancelledCell from '../../../Tables/Cells/StatusCell/CancelledCell';
import ExpiredCell from '../../../Tables/Cells/StatusCell/ExpiredCell';
import PendingCell from '../../../Tables/Cells/StatusCell/PendingCell';
import { StatusCellProps } from '../../../Tables/Cells/StatusCell/types';
import QuoteActionCell from '../QuoteActionCell';

export interface QuoteStatusCellProps extends StatusCellProps {
	quote: Quote;
}

const QuoteStatusCell: FunctionComponent<QuoteStatusCellProps> = ({ quote, ...otherProps }) => {
	const { order } = useOrderDetailsContext();
	const { isMyOrder } = order || {};

	const { status } = quote;

	switch (status) {
		case QuoteStatusEnum.Accepted:
			return <AcceptedCell {...otherProps} />;
		case QuoteStatusEnum.Cancelled:
			return <CancelledCell {...otherProps} />;
		case QuoteStatusEnum.Expired:
			return <ExpiredCell {...otherProps} />;
		case QuoteStatusEnum.Active:
			return (
				<>{isMyOrder ? <QuoteActionCell quote={quote} {...otherProps} /> : <PendingCell {...otherProps} />}</>
			);
		default:
			return <></>;
	}
};

export default QuoteStatusCell;
