import { SelectOptionType } from '@montel/montelpro-shared-components';
import { ICountryBackend } from '../listsTypes';

export const translateCountryApi = (country: ICountryBackend): SelectOptionType => ({
	label: country.countryName,
	value: country.shortCode,
});

export const translateCountriesApi = (countries: ICountryBackend[]): SelectOptionType[] =>
	countries.map(translateCountryApi);
