import { FunctionComponent, useEffect, useState } from 'react';
import { Box, HStack, Switch, Text, VStack } from '@montel/montelpro-shared-components-v2';
import { getToken } from 'firebase/messaging';
import { Suspense } from '../../../components/Suspense';
import { useAlertSettingsContext } from '../../../providers/AlertSettingsProvider';
import { useFirebaseContext } from '../../../providers/FirebaseProvider';
import { StyledBrowserSwitchBox } from '../style';
import BrowserList from './BrowserList';

const BrowserAlert: FunctionComponent = () => {
	const {
		updateBrowserEnabled,
		browserNotificationsEnabled,
		browsers,
		addBrowser,
		removeBrowser,
		settingsStatus: status,
	} = useAlertSettingsContext();

	const { messaging, vapidKey } = useFirebaseContext();
	const [browserToken, setBrowserToken] = useState('');

	const notificationsGranted = () => Notification.permission === 'granted';
	const notificationsDenied = () => Notification.permission === 'denied';
	const notificationsSupported = 'Notification' in window;

	useEffect(() => {
		(async () => {
			if (notificationsGranted() && messaging) {
				const token = await getToken(messaging, { vapidKey });
				setBrowserToken(token);
			}
		})();
	}, [browsers, messaging]);

	return (
		<StyledBrowserSwitchBox>
			<Suspense status={status} loaderType="sm">
				<VStack gap="lg">
					<HStack justify="space-between" gap="lg" align="center" wrap="nowrap">
						<VStack>
							<Text weight="semiBold">Browser</Text>
							<Text color="secondaryText">Notifications will appear as a pop up in your web browser</Text>
						</VStack>
						<Switch
							isChecked={browserNotificationsEnabled}
							onChange={async (event) => {
								await updateBrowserEnabled(event.target.checked);
							}}
						/>
					</HStack>
					<>
						{browserNotificationsEnabled && (
							<BrowserList
								browserToken={browserToken}
								setBrowserToken={setBrowserToken}
								browsers={browsers}
								addBrowser={addBrowser}
								removeBrowser={removeBrowser}
							/>
						)}
						{browserNotificationsEnabled && notificationsDenied() && (
							<Box bg="error">
								<Text>
									{`Browser notifications are actively denied for this browser.
										If you wish to enable notifications in this browser, please grant
										or reset your notification settings for montelgroup.com.`}
								</Text>
							</Box>
						)}
						{browserNotificationsEnabled && !notificationsSupported && (
							<Box bg="error">
								<Text>Notifications are not supported by your current browser.</Text>
							</Box>
						)}
					</>
				</VStack>
			</Suspense>
		</StyledBrowserSwitchBox>
	);
};

export default BrowserAlert;
