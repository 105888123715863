import styled, { css } from 'styled-components';
import { MenuBarProps } from '../menuTypes';

/**
 * Sidebar styled component that serves as the container for the side menu.
 */
export const Sidebar = styled.nav<MenuBarProps>`
	margin: 0;
	box-sizing: border-box;
	padding: 8px 0;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	position: fixed;
	height: calc(100vh - 5rem);

	${({ $w, $bg, theme }) => {
		const { colors, zIndices } = theme;
		return css`
			width: ${$w};
			background: ${colors[$bg] || $bg};
			z-index: ${zIndices.docked};
		`;
	}}
`;
