import { Label, PeriodTypeEnum, Region, SubsidizedEnum, Tech } from 'common/enums';
import { isDeprecatedLabel } from 'utils/labelUtils';
import { z } from 'zod';
import { getMaxPlantAgeOptions, getPlantCommissioningYearOptions } from 'components/NewOrder/options';
import { isWithinRange } from '../../utils/isWithinRange';

const productionPeriod = z.object({
	label: z.string(),
	value: z.string(),
	year: z.number(),
	quarter: z.number().optional(),
	month: z.number().optional(),
});

const TechnologiesField = z.nativeEnum(Tech).array().nonempty();
export type ProductionPeriodOption = z.infer<typeof productionPeriod>;

export const fields = {
	technologies: TechnologiesField,
	label: z.nativeEnum(Label).refine((val) => val && !isDeprecatedLabel(val), {
		message: 'Label is deprecated. Please select another.',
	}),
	region: z
		.nativeEnum(Region)
		.optional()
		.refine((val) => val && val in Region),
	subsidized: z.nativeEnum(SubsidizedEnum),
	periodType: z
		.nativeEnum(PeriodTypeEnum)
		.optional()
		.refine((val) => val && val in PeriodTypeEnum, 'Please select a period type'),
	productionPeriods: z
		.array(productionPeriod)
		.optional()
		.refine(
			(val) => val?.length && val.every((period) => Boolean(period.year)),
			'Please select at least one period',
		),
	plantCommissioningYear: z
		.number()
		.optional()
		.refine((val) => {
			if (!val) return true;

			return isWithinRange(val, getPlantCommissioningYearOptions());
		}, 'Plant commissioning year must be within the selectable range'),
	plantMaxAge: z
		.number()
		.optional()
		.refine((val) => {
			if (!val) return true;

			return isWithinRange(val, getMaxPlantAgeOptions());
		}, 'Max plant age must be within the selectable range'),
};

export const ProductSchema = z.object(fields);
export type ProductState = z.infer<typeof ProductSchema>;

export type Technologies = z.infer<typeof TechnologiesField>;
