import { FunctionComponent } from 'react';
import { Production } from '../../../../common/interfaces';
import { filterFirstProductionPeriod } from '../../../../utils/orderUtils/filterFirstProductionPeriod';
import MultiplePeriod from './MultiplePeriod';
import SinglePeriod from './SinglePeriod';

export interface ProductionCellProps {
	/**
	 * Production object.
	 * @type Production
	 */
	production: Production;

	/**
	 * If true, the cell will be disabled.
	 * @type boolean
	 */
	disabled?: boolean;

	[otherProps: string]: any;
}
const ProductionCell: FunctionComponent<ProductionCellProps> = ({ production, disabled, ...otherProps }) => {
	const { periods, type: periodType } = production;
	const isMulti = periods.length > 1;
	const period = filterFirstProductionPeriod(periods);

	return (
		<>
			{isMulti ? (
				<MultiplePeriod
					period={period}
					periods={periods}
					disabled={disabled}
					periodType={periodType}
					{...otherProps}
				/>
			) : (
				<SinglePeriod period={period} disabled={disabled} {...otherProps} />
			)}
		</>
	);
};

export default ProductionCell;
