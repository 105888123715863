import { FunctionComponent } from 'react';
import { Outlet } from 'react-router';
import { IconMyCompany, IconPersons } from '@montel/montelpro-shared-components-v2';
import useSetPageTitle from 'hooks/useSetPageTitle';
import PageHeader from 'primitives/PageHeader';
import { TMenuItem } from 'components/Menu';

const MyCompanyRoot: FunctionComponent = () => {
	useSetPageTitle('My Company');

	const topMenuConfig: TMenuItem[] = [
		{
			type: 'Separator',
		},
		{
			type: 'Clickable',
			text: `Company profile`,
			link: '/my-company',
			icon: IconMyCompany,
			isEnd: true,
		},
		{
			type: 'Clickable',
			text: `Users`,
			link: '/my-company/users',
			icon: IconPersons,
			isEnd: true,
		},
	];

	return (
		<>
			<PageHeader title="My company" topMenuConfig={topMenuConfig} />
			<Outlet />
		</>
	);
};

export default MyCompanyRoot;
