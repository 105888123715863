import { FunctionComponent, useState } from 'react';
import { Steps, useStepper } from '@montel/montelpro-shared-components-v2';
import useSetPageTitle from 'hooks/useSetPageTitle';
import PageHeader from 'primitives/PageHeader';
import NavigationBar from './navigationBar/NavigationBar';
import CompanyInfoStep from './steps/CompanyInfoStep';
import MediaStep from './steps/MediaStep';
import PositionStep from './steps/PositionStep';
import VerificationStep from './steps/VerificationStep';
import { Container, StepContentContainer, StyledStepper } from './CompanyRegistration.styles';
import { initialState } from './initialState';
import steps, { isVerificationStep } from './stepsDefinition';
import { CompanyRegistrationState } from './types';

const CompanyRegistration: FunctionComponent = () => {
	useSetPageTitle('Company Registration');
	const [state, setState] = useState<CompanyRegistrationState>(initialState);
	const [logo, setLogo] = useState<File>();
	const [isNextPressed, setIsNextPressed] = useState(false);
	const { activeStep, setActiveStep, isCompleteStep, goToNext, goToPrevious } = useStepper();

	return (
		<>
			<PageHeader title="Company registration" />
			<Container>
				<StyledStepper
					activeStep={activeStep}
					steps={steps}
					setActiveStep={setActiveStep}
					isCompleteStep={isCompleteStep}
					disabled={isVerificationStep(activeStep)}
				/>
				<StepContentContainer align="stretch" gap="xl">
					<Steps currentStep={activeStep}>
						<CompanyInfoStep state={state} setState={setState} showErrors={isNextPressed} />
						<PositionStep state={state} setState={setState} showErrors={isNextPressed} />
						<MediaStep
							state={state}
							setState={setState}
							showErrors={isNextPressed}
							setLogo={setLogo}
							logo={logo}
						/>
						<VerificationStep />
					</Steps>
					<NavigationBar
						goToNext={goToNext}
						goToPrevious={goToPrevious}
						activeStep={activeStep}
						state={state}
						logo={logo}
						setIsNextPressed={setIsNextPressed}
					/>
				</StepContentContainer>
			</Container>
		</>
	);
};

export default CompanyRegistration;
