import React, { FunctionComponent } from 'react';
import { getActionText } from './getActionText';
import { getIcon } from './getIcon';
import { StyledActionButton } from './style';
import { TAction } from './types';

export interface ActionProps {
	action: TAction;
	onClick: () => void;
}

const Action: FunctionComponent<ActionProps> = ({ action, onClick }) => {
	const Icon = getIcon(action);
	const text = getActionText(action);
	return (
		<StyledActionButton onClick={onClick}>
			<span>{text}</span>
			<Icon boxSize="16px" />
		</StyledActionButton>
	);
};

export default Action;
