import { Input } from '@montel/montelpro-shared-components-v2';
import { Currency } from 'common/enums';
import { styled } from 'styled-components';
import getTotalPriceFormatted from 'components/NewOrder/utils/getTotalPriceFormatted';

interface TotalPriceProps {
	quantity: string;
	pricePerUnit: string | undefined;
	currency: Currency;
}

const StyledInput = styled(Input)`
	input {
		background: #465b63;
		border-color: #465b63;

		&:hover,
		&:focus {
			background: #465b63;
			border-color: #465b63;
		}
	}
`;

export const TotalPrice: React.FC<TotalPriceProps> = ({ quantity, pricePerUnit, currency }) => {
	return (
		<StyledInput
			value={`${getTotalPriceFormatted(quantity, pricePerUnit ?? '0')} ${Currency[currency]}`}
			isDisabled
			label="Total price"
			onChange={() => {}}
		/>
	);
};
