import { UserRole } from '@montel/montelpro-shared-components/enums';
import { useAppSelector } from 'store/hooks';
import { selectUserId, selectUserRole } from 'store/user/userSelectors';

const useUserCanBeManaged = (userId: string, role: UserRole) => {
	const currentUserId = useAppSelector(selectUserId);
	const currentUserRole = useAppSelector(selectUserRole);
	const userIsCurrentUser = currentUserId === userId;
	const userHasHigherRoleThanCurrentUser = role === UserRole.TradeManager && currentUserRole !== role;
	return !userIsCurrentUser && !userHasHigherRoleThanCurrentUser;
};

export default useUserCanBeManaged;
