import { DropdownMenu, HStack } from '@montel/montelpro-shared-components-v2';
import styled from 'styled-components';

export const StyledNotificationsContainer = styled(DropdownMenu)`
	width: 26.75rem;
	right: -7.875rem;
	max-height: 92vh;
	min-height: 26.75rem;
	overflow: auto;
	padding: ${({ theme }) => theme.spacing.md};
	background-color: ${({ theme }) => theme.colors.widget};
	display: flex;
	flex-direction: column;
`;

export const StyledKebabMenu = styled(DropdownMenu)`
	background-color: ${({ theme }) => theme.colors.element};
	width: 12.5rem;
	right: -0.375rem;
	overflow: hidden;
`;

export const StyledHStack = styled(HStack)`
	margin-bottom: ${({ theme }) => theme.spacing.lg};
`;
