import getEnvironment from 'utils/environment/getEnvironment';
import isProd from 'utils/environment/isProd';
import useOnMount from './useOnMount';

const useSetPageTitle = (title: string): void => {
	useOnMount(() => {
		const environment = isProd() ? '' : `${getEnvironment()} `;
		const APPLICATION_NAME = 'Montel Marketplace';
		document.title = title ? `${environment}${title} | ${APPLICATION_NAME}` : APPLICATION_NAME;
	});
};

export default useSetPageTitle;
