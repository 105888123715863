import { FunctionComponent } from 'react';
import { Currency, Side } from '@montel/montelpro-shared-components/enums';
import { HStack, VStack } from '@montel/montelpro-shared-components-v2';
import {
	getAvailableToName,
	getLabelName,
	getRegionName,
	getRegistryOperatorName,
	getTechName,
} from 'common/enums/valueLookups';
import LabelAndValue from 'primitives/LabelAndValue';
import { convertDeliveryDateToDateFormat } from 'utils/convertDeliveryDateToDateFormat';
import { getProductionPeriodsStringFromOrder } from 'utils/orderUtils/getProductionPeriodsStringFromOrder';
import { getSubsidisedStringFromOrder } from 'utils/orderUtils/getSubsidisedStringFromOrder';
import { useOrderDetailsContext } from '../OrderDetailsContext';

const OrderDetailsProperties: FunctionComponent = () => {
	const { order } = useOrderDetailsContext();

	return (
		<VStack align="stretch" wrap="nowrap" gap="md">
			<HStack wrap="nowrap">
				<LabelAndValue label="Technology" value={order.product.tech.map(getTechName).join(', ')} />
				<LabelAndValue label="Label" value={getLabelName(order.product.label)} />
				<LabelAndValue label="Available to" value={getAvailableToName(order.availability.availableTo)} />
			</HStack>
			<HStack wrap="nowrap">
				<LabelAndValue label="Region" value={getRegionName(order.product.region)} />
				<LabelAndValue label="Subsidised" value={getSubsidisedStringFromOrder(order)} />
				<LabelAndValue label="Minimum validity (weeks)" value={order.details?.minimumValidity ?? '-'} />
			</HStack>
			<HStack wrap="nowrap">
				<LabelAndValue
					label="Delivery period"
					value={convertDeliveryDateToDateFormat(order.product.deliveryDate)}
				/>
				<LabelAndValue
					label="Registry operator"
					value={getRegistryOperatorName(order.product.registryOperator)}
				/>
				{order.side === Side.Ask ? (
					<LabelAndValue label="Plant commissioning year" value={order.product.plantCommissioningYear} />
				) : (
					<LabelAndValue label="Max plant age (years)" value={order.product.plantMaxAge} />
				)}
			</HStack>
			<HStack wrap="nowrap">
				<LabelAndValue label="Currency" value={Currency[order.cost.currency]} />
				<LabelAndValue label="Production period" value={getProductionPeriodsStringFromOrder(order)} />
				<div style={{ width: '100%' }}></div>
			</HStack>
			<HStack wrap="nowrap">
				<LabelAndValue label="Comment" value={order.details?.comments ?? '-'} />
			</HStack>
		</VStack>
	);
};

export default OrderDetailsProperties;
