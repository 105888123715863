import { FunctionComponent, useEffect, useMemo } from 'react';
import { RequestStatus } from '@montel/montelpro-shared-components/enums';
import {
	Modal,
	ModalBody,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Steps,
	useStepper,
} from '@montel/montelpro-shared-components-v2';
import { OrderLifecycleEnum, Side } from 'common/enums/OrderEnums';
import { styled } from 'styled-components';
import DeliveryStep from './Steps/DeliveryStep/DeliveryStep';
import { DeliverySchema } from './Steps/DeliveryStep/DeliveryStep.types';
import OverviewStep from './Steps/OverviewStep/OverviewStep';
import PriceStep from './Steps/PriceStep/PriceStep';
import { PriceSchema } from './Steps/PriceStep/PriceStep.types';
import ProductStep from './Steps/ProductStep/ProductStep';
import { ProductSchema } from './Steps/ProductStep/ProductStep.types';
import { FormContainer, Header, HeaderAndPresets, PresetsError, StyledStepper } from './NewOrder.styles';
import { useOrderFormContext } from './OrderFormProvider';
import Presets from './Presets';
import { usePresetsContext } from './PresetsProvider';
import { IOrderFormStep, NewOrderSchema } from './types';

const StyledModalBody = styled(ModalBody)`
	padding-bottom: 5rem;
`;

const NewOrder: FunctionComponent = () => {
	const { formState, modalIsOpen, setModalIsOpen, reset, isSubmitted, validate } = useOrderFormContext();
	const { selectedPreset, setSelectedPreset, presetsStatus } = usePresetsContext();

	const steps: IOrderFormStep[] = useMemo(() => {
		const { product, delivery, price } = formState;

		const productStep = { title: 'Product', canBeNavigatedTo: true };
		const deliveryStep = { title: 'Delivery', canBeNavigatedTo: validate(ProductSchema, product, true).success };
		const priceStep = {
			title: 'Price',
			canBeNavigatedTo:
				deliveryStep.canBeNavigatedTo && validate(DeliverySchema, { ...product, ...delivery }, true).success,
		};
		const overviewStep = {
			title: 'Overview',
			canBeNavigatedTo:
				priceStep.canBeNavigatedTo &&
				validate(PriceSchema, { ...product, ...delivery, ...price }, true).success,
		};

		return [productStep, deliveryStep, priceStep, overviewStep];
	}, [formState]);

	const { activeStep, goToNext, goToPrevious, setActiveStep } = useStepper({
		index: 0,
		count: steps.length,
	});

	useEffect(() => {
		if (!modalIsOpen) return;

		if (formState.type === OrderLifecycleEnum.Edit || formState.type === OrderLifecycleEnum.Preset) {
			const result = validate(NewOrderSchema, formState);
			result.success ? setActiveStep(steps.length - 1) : setActiveStep(0);
		} else {
			setActiveStep(0);
			setSelectedPreset(null);
		}
	}, [modalIsOpen]);

	const orderSide = formState.side === Side.Ask ? 'sell' : 'buy';
	const isEdit = formState.type === OrderLifecycleEnum.Edit;

	const onModalClose = () => {
		setModalIsOpen(false);
		setSelectedPreset(null);
		reset();
	};

	const header = () => {
		if (selectedPreset) {
			return selectedPreset.presetName;
		} else if (isEdit) {
			return `Edit ${orderSide} order`;
		} else {
			return `New ${orderSide} order`;
		}
	};

	const showPresets = presetsStatus === RequestStatus.SUCCESS && !isEdit;

	return (
		<Modal isOpen={modalIsOpen} onClose={onModalClose}>
			<ModalOverlay />
			<ModalContent size={6} minHeight="840px">
				<ModalHeader>
					<HeaderAndPresets>
						<Header>{header()}</Header>
						{showPresets && (
							<Presets
								isLastStep={activeStep === steps.length - 1}
								onPresetChange={() => setActiveStep(steps.length - 1)}
							/>
						)}
						{presetsStatus === RequestStatus.ERROR && (
							<PresetsError>
								We were unable to load your presets. Please refresh the page and try again.
							</PresetsError>
						)}
					</HeaderAndPresets>
				</ModalHeader>
				<StyledModalBody>
					<StyledStepper
						steps={steps}
						isCompleteStep={(index: number) => steps[index].canBeNavigatedTo}
						setActiveStep={setActiveStep}
						activeStep={activeStep}
						disabled={isSubmitted}
					/>
					<FormContainer>
						<Steps currentStep={activeStep}>
							<ProductStep onProgress={goToNext} />
							<DeliveryStep onProgress={goToNext} onBack={goToPrevious} />
							<PriceStep onProgress={goToNext} onBack={goToPrevious} />
							<OverviewStep onStepChange={setActiveStep} onPrev={goToPrevious} />
						</Steps>
					</FormContainer>
				</StyledModalBody>
			</ModalContent>
		</Modal>
	);
};

export default NewOrder;
