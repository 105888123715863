import {
	Icon,
	IconArrowPointedLeftRight,
	IconArticle,
	IconCompany,
	IconMyCompany,
} from '@montel/montelpro-shared-components-v2';
import { TMenuItem } from '../../components/Menu';

const menuConfig: TMenuItem[] = [
	{
		type: 'Clickable',
		text: 'Orders',
		link: '/orders',
		icon: IconArticle,
	},
	{
		type: 'Clickable',
		text: 'Trade log',
		link: '/trades',
		icon: IconArrowPointedLeftRight,
		isEnd: false,
	},
	{
		type: 'Clickable',
		text: 'Companies',
		link: '/companies',
		icon: IconCompany,
		isEnd: true,
	},
	{
		type: 'Clickable',
		text: 'My Company',
		link: '/my-company',
		icon: IconMyCompany,
		isEnd: true,
	},
	{
		type: 'Separator',
	},
	{
		type: 'Clickable',
		text: 'Help',
		link: 'https://support.montelgroup.com/montel-marketplace',
		icon: Icon,
		isEnd: true,
	},
];

export default menuConfig;
