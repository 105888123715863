import React, { FunctionComponent, useEffect, useState } from 'react';
import { RequestStatus } from '@montel/montelpro-shared-components/enums';
import { Button, ModalOverlay } from '@montel/montelpro-shared-components-v2';
import { CounterpartyRelationStatus } from '../../../common/enums';
import { PublicCompanyInformation } from '../../../common/interfaces';
import { getCompanies } from '../../../store/companies/companiesThunks';
import { useAppDispatch } from '../../../store/hooks';
import Client, { TApiResponse } from '../api/Client';
import { VStackFullWidth } from '../style';
import Action from './Action';
import Confirmation from './Confirmation';
import { StyledActionsContainer, StyledContainer } from './style';
import { TAction } from './types';

export interface SecondaryActionButtonsProps {
	companyInformation: PublicCompanyInformation;
	refreshContent: () => void;
}

const SecondaryActionButtons: FunctionComponent<SecondaryActionButtonsProps> = ({
	companyInformation,
	refreshContent,
}) => {
	const { id, counterpartyStatus, isBlockedByMyCompany } = companyInformation;

	const [isOpen, setIsOpen] = useState(false);
	const [isOpenBlockPauseConfirmation, setIsOpenBlockPauseConfirmation] = useState(false);
	const [isOpenRemoveConfirmation, setIsOpenRemoveConfirmation] = useState(false);
	const dispatch = useAppDispatch();

	const { useBlockCompany, useUnblockCompany, usePauseCompany, useResumeCompany, useRemoveCompany } = Client;
	const [blockCompany, blockStatus] = useBlockCompany(id);
	const [unblockCompany, unblockStatus] = useUnblockCompany(id);
	const [pauseCompany, pauseStatus] = usePauseCompany(id);
	const [resumeCompany, resumeStatus] = useResumeCompany(id);
	const [removeCompany, removeStatus] = useRemoveCompany(id);

	useEffect(() => {
		const statuses = [blockStatus, unblockStatus, pauseStatus, resumeStatus];
		if (statuses.some((status) => status === RequestStatus.SUCCESS)) {
			setIsOpenBlockPauseConfirmation(false);
			refreshContent();
			setIsOpen(false);
		}
	}, [blockStatus, unblockStatus, pauseStatus, resumeStatus]);

	useEffect(() => {
		if (removeStatus === RequestStatus.SUCCESS) {
			setIsOpenRemoveConfirmation(false);
			refreshContent();
			setIsOpen(false);
		}
	}, [removeStatus]);

	let action: TAction = 'Default';
	if (counterpartyStatus !== CounterpartyRelationStatus.Approved) {
		action = isBlockedByMyCompany ? 'Unblock' : 'Block';
	} else {
		action = isBlockedByMyCompany ? 'Unpause' : 'Pause';
	}

	const actionMap = {
		Block: { apiCall: blockCompany, status: blockStatus },
		Unblock: { apiCall: unblockCompany, status: unblockStatus },
		Pause: { apiCall: pauseCompany, status: pauseStatus },
		Unpause: { apiCall: resumeCompany, status: resumeStatus },
		Remove: { apiCall: removeCompany, status: removeStatus },
	};

	const isAbleToRemove = counterpartyStatus === CounterpartyRelationStatus.Approved;

	return (
		<StyledContainer>
			<Button text="More..." variant="secondary" onClick={() => setIsOpen(true)} />
			{isOpen && (
				<>
					<ModalOverlay
						onClick={() => {
							setIsOpen(false);
							setIsOpenBlockPauseConfirmation(false);
							setIsOpenRemoveConfirmation(false);
						}}
					/>
					<StyledActionsContainer>
						<VStackFullWidth gap="0">
							{isOpenBlockPauseConfirmation ? (
								<Confirmation
									action={action}
									onConfirm={actionMap[action].apiCall}
									onCancel={() => setIsOpenBlockPauseConfirmation(false)}
									isLoading={actionMap[action].status === RequestStatus.LOADING}
								/>
							) : (
								<Action
									action={action}
									onClick={() => {
										setIsOpenBlockPauseConfirmation(true);
										setIsOpenRemoveConfirmation(false);
									}}
								/>
							)}
							{isAbleToRemove &&
								(isOpenRemoveConfirmation ? (
									<Confirmation
										action="Remove"
										onConfirm={async () => {
											const result = await removeCompany();

											if (result === true || (result as TApiResponse).success) {
												dispatch(getCompanies());
											}
										}}
										onCancel={() => setIsOpenRemoveConfirmation(false)}
										isLoading={removeStatus === RequestStatus.LOADING}
									/>
								) : (
									<Action
										action="Remove"
										onClick={() => {
											setIsOpenRemoveConfirmation(true);
											setIsOpenBlockPauseConfirmation(false);
										}}
									/>
								))}
						</VStackFullWidth>
					</StyledActionsContainer>
				</>
			)}
		</StyledContainer>
	);
};

export default SecondaryActionButtons;
