import { FunctionComponent } from 'react';
import Error from '../Error';

const NotFound: FunctionComponent = () => (
	<Error
		heading="403"
		subHeading="Forbidden"
		bodyText="It seems you don't have permission to access this page. If you believe this is an error, contact us for assistance. Otherwise, you can return to the homepage or continue browsing."
	/>
);

export default NotFound;
