import { ProductionPeriod } from 'common/interfaces';
import moment, { Moment } from 'moment';

const getFirstDateAfterProdPeriodUTC = (productionPeriod?: ProductionPeriod | null): Moment | undefined => {
	const year = productionPeriod?.year;
	const month = productionPeriod?.month;
	const quarter = productionPeriod?.quarter;
	if (month) {
		return moment.utc({ year, month }).startOf('month').add(1, 'month');
	}
	if (quarter) {
		return moment
			.utc({ year })
			.startOf('year')
			.add(quarter * 3, 'month');
	}
	return moment.utc({ year }).startOf('year').add(1, 'year');
};

export default getFirstDateAfterProdPeriodUTC;
