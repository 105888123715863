import { FunctionComponent } from 'react';
import { Avatar } from '@montel/montelpro-shared-components-v2';
import { Order } from 'common/interfaces/Order';
import { Quote } from 'common/interfaces/Quote';
import { StyledCell } from 'components/Tables/Cells/styles';
import { CellProps } from 'components/Tables/Cells/types';

const renderOwner = (order: Order | Quote) => {
	const isMine = (order as Order).isMyOrder || (order as Quote).isMyQuote;
	if (isMine) {
		return <Avatar variant="person" />;
	}

	const isMyCompanys = (order as Order).isMyCompanysOrder || (order as Quote).isMyCompanyQuote;
	if (isMyCompanys) {
		return <Avatar variant="company" />;
	}

	return null;
};

export interface OwnerIconCellProps extends CellProps {
	order: Order | Quote;
}

const OwnerIconCell: FunctionComponent<OwnerIconCellProps> = ({ order, align = 'center', justify = 'flex-start' }) => {
	return (
		<StyledCell direction="row" wrap="nowrap" align={align} justify={justify} margin-left={'-24px'}>
			{renderOwner(order)}
		</StyledCell>
	);
};

export default OwnerIconCell;
