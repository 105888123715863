export interface INotificationType {
	readonly name: string;
	readonly version: number;
}

export enum ENotificationStatus {
	Unread = 'Unread',
	Read = 'Read',
}

export interface INotification {
	readonly id: string;
	readonly type: INotificationType;
	readonly created: string;
	readonly data: object;
	readonly source: string;
	status: ENotificationStatus;
}
