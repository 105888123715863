import { FunctionComponent, useState } from 'react';
import {
	DropdownButton as DropdownMenuButton,
	DropdownMenu,
	IconButton,
	IconEdit,
	IconPersonRemove,
	IconThreeDots,
} from '@montel/montelpro-shared-components-v2';
import { UserRole } from 'common/enums/UserRoleEnum';
import styled, { useTheme } from 'styled-components';
import ManageUserRoleModal from './ManageUserRoleModal';
import RemoveUserModal from './RemoveUserModal';
import useUserCanBeManaged from './useUserCanBeManaged';

const DropdownButton: FunctionComponent<{ userId: string; userName: string; role: UserRole }> = ({
	userId,
	userName,
	role,
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const [isEditRoleModalOpen, setIsEditRoleModalOpen] = useState(false);
	const [isRemoveUserModalOpen, setIsRemoveUserModalOpen] = useState(false);
	const theme = useTheme();

	const userCanBeManaged = useUserCanBeManaged(userId, role);
	if (!userCanBeManaged) return null;

	return (
		<StyledContainer>
			<IconButton icon={<IconThreeDots />} variant="tertiary" onClick={() => setIsOpen(true)} />
			{isOpen && (
				<DropdownMenu closeMenu={() => setIsOpen(false)} topPosition="2rem">
					<DropdownMenuButton onClick={() => setIsEditRoleModalOpen(true)}>
						Edit role <IconEdit boxSize={theme.typography.size.xs} />
					</DropdownMenuButton>
					<DropdownMenuButton onClick={() => setIsRemoveUserModalOpen(true)}>
						Remove from company <IconPersonRemove boxSize={theme.typography.size.xs} />
					</DropdownMenuButton>
				</DropdownMenu>
			)}
			{isEditRoleModalOpen && (
				<ManageUserRoleModal
					isOpen={isEditRoleModalOpen}
					onClose={() => setIsEditRoleModalOpen(false)}
					userId={userId}
					userName={userName}
					role={role}
				/>
			)}
			{isRemoveUserModalOpen && (
				<RemoveUserModal
					isOpen={isRemoveUserModalOpen}
					onClose={() => setIsRemoveUserModalOpen(false)}
					userId={userId}
					userName={userName}
				/>
			)}
		</StyledContainer>
	);
};

const StyledContainer = styled.div`
	position: relative;
	display: flex;
	justify-content: flex-end;
	line-height: normal;
`;

export default DropdownButton;
