import { getErrorMessageEmails } from 'components/MultipleEmails';
import { MyCompanyState } from '../MyCompanyState';

const getContactErrors = (state: MyCompanyState) => ({
	signersEmails: getErrorMessageEmails(state.signersEmails),
	kycContactEmails: getErrorMessageEmails(state.kycContactEmails),
	countryCode: !state.countryCode && 'This field is required',
	street: !state.street && 'This field is required',
	postalCode: !state.postalCode && 'This field is required',
	city: !state.city && 'This field is required',
});

export default getContactErrors;
